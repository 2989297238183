import { Component, input } from '@angular/core';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';

@Component({
    selector: 'app-payment-modal-plan-features-list',
    templateUrl: './payment-modal-plan-features-list.component.html',
    styleUrl: './payment-modal-plan-features-list.component.scss',
    imports: [TextReplacementPipe],
})
export class PaymentModalPlanFeaturesListComponent {
    public features = input.required<string[]>();
}
