import { subscriptionFeature } from '@frontend/data-access/subscription';
import { createSelector } from '@ngrx/store';
import {
    selectDefaultOfferingAvailablePackagesSortedByPrice,
    selectTieredPricingBasicOfferingSortedByPrice,
    selectTieredPricingLimitedOfferProductCards,
    selectTieredPricingPremiumOfferingSortedByPrice,
} from '../store/payment/store/payment.selectors';
import { mapOfferingToPaymentProductCardArray } from './payment-modal.utils';

export const selectDefaultOfferingProductCards = createSelector(
    selectDefaultOfferingAvailablePackagesSortedByPrice,
    subscriptionFeature.selectHasHistoricalPurchase,
    (defaultOffering, hasHistoricalPurchase) => {
        return mapOfferingToPaymentProductCardArray(defaultOffering, !!hasHistoricalPurchase);
    },
);

export const selectPremiumOfferingProductCards = createSelector(
    selectTieredPricingPremiumOfferingSortedByPrice,
    subscriptionFeature.selectHasHistoricalPurchase,
    (premiumPackages, hasHistoricalPurchase) => {
        return mapOfferingToPaymentProductCardArray(premiumPackages, !!hasHistoricalPurchase);
    },
);

export const selectBasicOfferingProductCards = createSelector(
    selectTieredPricingBasicOfferingSortedByPrice,
    subscriptionFeature.selectHasHistoricalPurchase,
    (basicPackages, hasHistoricalPurchase) => {
        return mapOfferingToPaymentProductCardArray(basicPackages, !!hasHistoricalPurchase);
    },
);

export const selectPaymentProducts = createSelector(
    selectDefaultOfferingProductCards,
    selectPremiumOfferingProductCards,
    selectBasicOfferingProductCards,
    selectTieredPricingLimitedOfferProductCards,
    (defaultProducts, premiumProducts, basicProducts, limitedOfferProducts) => {
        return {
            defaultProducts,
            basicProducts,
            premiumProducts,
            limitedOfferProducts,
        };
    },
);
