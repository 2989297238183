import { NgClass, NgStyle } from '@angular/common';
import { Component, inject } from '@angular/core';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import {
    IonButton,
    IonContent,
    IonFooter,
    IonHeader,
    IonToolbar,
    Platform,
    ViewWillEnter,
    ViewWillLeave,
} from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { Subscription } from 'rxjs';
import { NourishProductHeaderComponent } from '../../nourish-product-header/nourish-product-header.component';
import { nourishProductCtaClicked, nourishProductHeaderBackButtonClicked } from '../../store/nourish.actions';
import { selectSelectedNourishProductTile } from '../../store/nourish.selectors';

@Component({
    selector: 'app-nourish-product',
    templateUrl: './nourish-product.page.html',
    styleUrl: './nourish-product.page.scss',
    imports: [
        NgClass,
        NgStyle,
        IonContent,
        IonHeader,
        IonToolbar,
        IonButton,
        IonFooter,
        TextReplacementPipe,
        NourishProductHeaderComponent,
    ],
})
export class NourishProductPage implements ViewWillEnter, ViewWillLeave {
    private readonly store = inject(Store);
    private readonly platform = inject(Platform);

    public product = this.store.selectSignal(selectSelectedNourishProductTile);

    protected readonly Color = Color;

    private backButtonSub: Subscription = new Subscription();

    public ionViewWillEnter(): void {
        this.backButtonSub = this.platform.backButton.subscribeWithPriority(20, () => {
            if (!this.product()) {
                return;
            }

            this.store.dispatch(
                nourishProductHeaderBackButtonClicked({
                    id: this.product()!.id,
                }),
            );
        });
    }

    public ionViewWillLeave(): void {
        this.backButtonSub.unsubscribe();
    }

    public onCtaClick(): void {
        if (!this.product()) {
            return;
        }

        this.store.dispatch(
            nourishProductCtaClicked({
                id: this.product()!.id,
                url: this.product()!.callToAction.link,
            }),
        );
    }
}
