import { RadioWithLogicResult } from '@frontend/ui/radio-with-logic';
import { addDays, format, subDays, subMonths, subYears } from 'date-fns';
import {
    FIVE_TO_ELEVEN_MONTHS_RADIO_OPTION,
    ONE_TO_TWO_YEARS_RADIO_OPTION,
    OVER_TWO_YEARS_RADIO_OPTION,
    ZERO_TO_FOUR_MONTHS_RADIO_OPTION,
} from '../constants/question-age.constants';

// TODO: NBSon - similar to todo below
export const getMinValue = (radioResult: RadioWithLogicResult | undefined) => {
    if (radioResult?.selected?.value === ZERO_TO_FOUR_MONTHS_RADIO_OPTION.value) {
        const fiveMonthsAgo = subMonths(new Date(), 5);

        return format(addDays(fiveMonthsAgo, 1), 'yyyy-MM-dd');
    }

    if (radioResult?.selected?.value === FIVE_TO_ELEVEN_MONTHS_RADIO_OPTION.value) {
        return format(subMonths(new Date(), 12), 'yyyy-MM');
    }

    if (radioResult?.selected?.value === ONE_TO_TWO_YEARS_RADIO_OPTION.value) {
        return format(subYears(new Date(), 2), 'yyyy-MM');
    }

    return format(new Date(), 'yyyy-MM-dd');
};

// TODO: NBSon - come back and check this logic for if 5 months ago lands on feb when month is 30/31 days
export const getMaxValue = (radioResult: RadioWithLogicResult | undefined) => {
    if (radioResult?.selected?.value === FIVE_TO_ELEVEN_MONTHS_RADIO_OPTION.value) {
        const fiveMonthsAgo = subMonths(new Date(), 5);

        return format(subDays(fiveMonthsAgo, 1), 'yyyy-MM');
    }

    if (radioResult?.selected?.value === ONE_TO_TWO_YEARS_RADIO_OPTION.value) {
        return format(subYears(new Date(), 1), 'yyyy-MM');
    }

    return format(new Date(), 'yyyy-MM-dd');
};

export const getInitialDateOfBirth = (radioResult: RadioWithLogicResult) => {
    if (!radioResult.complete) {
        return format(new Date(), 'yyyy-MM-dd');
    }

    if (radioResult.selected?.value === OVER_TWO_YEARS_RADIO_OPTION.value) {
        return format(subYears(new Date(), 2), 'yyyy-MM-dd');
    }

    return getMaxValue(radioResult);
};
