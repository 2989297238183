import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { openPrivacy, openTerms, routeToPrevious } from '@frontend/data-access/router';
import { loginWithApple, loginWithGoogle, signUpWithEmailAndPassword } from '@frontend/data-access/user/authentication';
import { resetHouseholdErrorState } from '@frontend/data-access/user/household';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { HeaderComponent } from '@frontend/feature/header';
import { IonButton, IonContent, IonHeader, IonSpinner, IonToolbar } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { CreateAccountFormComponent } from './create-account-form/create-account-form.component';
import { CreateAccountForm, signUpForm } from './create-account.model';
import { selectCreateAccountPageVm } from './create-account.page.selectors';

@Component({
    selector: 'app-onboarding-create-account',
    templateUrl: './create-account.page.html',
    styleUrls: ['./create-account.page.scss', '../authentication.scss'],
    imports: [
        AnalyticsTrackClickDirective,
        AsyncPipe,
        CreateAccountFormComponent,
        HeaderComponent,
        IonButton,
        IonContent,
        IonHeader,
        IonSpinner,
        IonToolbar,
    ],
})
export class CreateAccountPage {
    private readonly store = inject(Store);

    public vm$ = this.store.select(selectCreateAccountPageVm);

    // TODO: NBSon - investigate the need for this, it was causing some weird behaviour with cypress/requiring force where it shouldn't
    public form = signUpForm;

    protected readonly Color = Color;

    public onBackButtonPressed(): void {
        this.form.reset();

        // TODO: NBSon - think about making single action and fire off multiple actions in effect
        this.store.dispatch(resetHouseholdErrorState());

        void this.store.dispatch(routeToPrevious({}));
    }

    public onSignUpFormChange(event: CreateAccountForm): void {
        this.form = event;
    }

    public onSignUpWithAppleClick(): void {
        this.store.dispatch(loginWithApple());
    }

    public onSignUpWithGoogleClick(): void {
        this.store.dispatch(loginWithGoogle());
    }

    public onOpenTermsClick(countryCode: string): void {
        this.store.dispatch(openTerms({ countryCode }));
    }

    public onPrivacyClick(countryCode: string): void {
        this.store.dispatch(openPrivacy({ countryCode }));
    }

    public onSignUpClick(email: string | undefined | null, password: string | undefined | null): void {
        if (!email || !password) {
            return;
        }

        this.store.dispatch(signUpWithEmailAndPassword({ email, password }));
    }
}
