import { NgStyle } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
    selector: 'app-payment-modal-terms',
    templateUrl: './payment-modal-terms.component.html',
    styleUrl: './payment-modal-terms.component.scss',
    imports: [NgStyle],
})
export class PaymentModalTermsComponent {
    public fontColor = input<string>();
}
