import { Component, effect } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { MultiSelectComponent } from '@frontend/ui/multi-select';
import { ProgressHeaderComponent } from '@frontend/ui/progress-header';
import { IonButton, IonContent, IonFooter, IonHeader, IonSpinner, IonToolbar } from '@ionic/angular/standalone';
import { onboardingQuestionSubmittedNeeds } from '../../data-access/onboarding.actions';
import { onboardingFeature } from '../../data-access/onboarding.reducer';
import { selectNeedsByCountry } from '../../data-access/onboarding.selectors';
import { OnboardingQuestionBasePage } from '../question-base.page';

@Component({
    selector: 'app-onboarding-question-needs',
    templateUrl: './question-needs.page.html',
    styleUrls: ['./question-needs.page.scss', '../onboarding.scss'],
    imports: [
        ReactiveFormsModule,
        IonButton,
        IonContent,
        IonFooter,
        IonHeader,
        IonSpinner,
        IonToolbar,
        AnalyticsTrackClickDirective,
        MultiSelectComponent,
        ProgressHeaderComponent,
    ],
})
export class OnboardingQuestionNeedsPage extends OnboardingQuestionBasePage {
    public selectedNeeds = this.store.selectSignal(onboardingFeature.selectSelectedNeeds);
    public options = this.store.selectSignal(selectNeedsByCountry);

    public needsForm = new FormGroup({
        needs: new FormControl<string[]>([], {
            nonNullable: true,
        }),
    });

    constructor() {
        super();

        effect(() => {
            this.needsForm.setValue({
                needs: this.selectedNeeds(),
            });
        });
    }

    // TODO: NBSon - use ngsubmit pattern like with other pages
    public onNextClick() {
        if (this.isLoading()) {
            return;
        }

        const needs = this.options().reduce((acc: Record<string, boolean>, option) => {
            acc[option.value] = this.needsForm.controls.needs.value.includes(option.value);

            return acc;
        }, {});

        this.store.dispatch(
            onboardingQuestionSubmittedNeeds({
                needs,
            }),
        );
    }
}
