import { Component, effect } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { ProgressHeaderComponent } from '@frontend/ui/progress-header';
import { SingleSelectComponent } from '@frontend/ui/single-select';
import { IonButton, IonContent, IonFooter, IonHeader, IonSpinner, IonToolbar } from '@ionic/angular/standalone';
import { onboardingQuestionSubmittedHowDidYouHear } from '../../data-access/onboarding.actions';
import { onboardingFeature } from '../../data-access/onboarding.reducer';
import { OnboardingQuestionBasePage } from '../question-base.page';
import { HOW_DID_YOU_HEAR_OPTIONS } from './question-how-did-you-hear.constants';

@Component({
    selector: 'app-onboarding-question-how-did-you-hear',
    templateUrl: './question-how-did-you-hear.page.html',
    styleUrls: ['./question-how-did-you-hear.page.scss', '../onboarding.scss'],
    imports: [
        AnalyticsTrackClickDirective,
        IonButton,
        IonContent,
        IonFooter,
        IonHeader,
        IonSpinner,
        IonToolbar,
        ProgressHeaderComponent,
        ReactiveFormsModule,
        SingleSelectComponent,
    ],
})
export class OnboardingQuestionHowDidYouHearPage extends OnboardingQuestionBasePage {
    public options = HOW_DID_YOU_HEAR_OPTIONS;

    public howDidYouHear = this.store.selectSignal(onboardingFeature.selectHowDidYouHear);

    public howDidYouHearForm = new FormGroup({
        selectedOption: new FormControl('', { nonNullable: true }),
    });

    constructor() {
        super();

        effect(() => {
            this.howDidYouHearForm.setValue({
                selectedOption: this.howDidYouHear(),
            });
        });
    }

    // TODO: NBSon - use ngSubmit like with other pages
    public onNextClick() {
        if (this.isLoading()) {
            return;
        }

        this.store.dispatch(
            onboardingQuestionSubmittedHowDidYouHear({
                howDidYouHear: this.howDidYouHearForm.controls.selectedOption.value,
            }),
        );
    }
}
