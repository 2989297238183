import { NgStyle } from '@angular/common';
import { Component, computed, EventEmitter, inject, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectProgressStreakInfo } from '../../today/progress-streak/data-access/progress-streak-modal.selectors';
import { ProgressStreakComponent } from '../../today/progress-streak/progress-streak/progress-streak.component';
import { selectSelectedCourseWithProgressInfo } from '../course-path/store/course-path.selectors';

@Component({
    selector: 'app-course-streak',
    templateUrl: './course-streak.component.html',
    styleUrl: './course-streak.component.scss',
    imports: [NgStyle, ProgressStreakComponent],
})
export class CourseStreakComponent {
    @Output() public todayLessonButtonClicked = new EventEmitter();

    private readonly store = inject(Store);

    public selectedCourse = this.store.selectSignal(selectSelectedCourseWithProgressInfo);
    public allCourseStreaks = this.store.selectSignal(selectProgressStreakInfo);

    public courseStreak = computed(() => {
        const courseStreakFound = this.allCourseStreaks().find(
            (courseStreak) => courseStreak.id === this.selectedCourse()?.id,
        );

        return {
            length: courseStreakFound?.length ?? 0,
        };
    });

    public isStreakCardOpen = false;

    public expandStreakCard() {
        this.isStreakCardOpen = !this.isStreakCardOpen;
    }
}
