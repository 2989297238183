<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <app-header
            class="authentication-app-header"
            title="Reset Password"
            [leftButton]="leftButtonConfig"
            [rightButton]="false"
            (leftButtonPressed)="onNoEmailReceivedClick()"
        />
    </ion-toolbar>
</ion-header>

<ion-content class="reset-password-check-email-ion-content authentication-ion-content" [fullscreen]="true">
    <main class="page-content">
        <section>
            <h1 data-test="check-email-title">Check your email</h1>
            @if (email()) {
            <p data-test="check-email-explanation">
                We've sent an email to {{ email() }}. Be sure to open the email on your phone.
            </p>
            }
        </section>
    </main>
</ion-content>

<ion-footer class="reset-password-check-email-footer authentication-ion-footer ion-text-center page-footer">
    <button
        class="reset-password-check-email-footer__not-received zz-button"
        appTrackClick
        identifier="no-email-received"
        data-test="no-email-received"
        (click)="onNoEmailReceivedClick()"
    >
        I didn't receive an email
    </button>
    <ion-button
        appTrackClick
        identifier="check-mail-sign-in-btn"
        expand="block"
        data-test="check-mail-sign-in-btn"
        [color]="Color.Max"
        (click)="onLoginClick()"
    >
        Sign in
    </ion-button>
</ion-footer>
