import { Gender } from '@shared/user-domain';
import { InternalUserWithoutTokenDto } from '../../../dtos/user/internal-user.dto';

export class InternalUserWithoutTokenDtoMock {
    private defaultValue: InternalUserWithoutTokenDto = {
        termsAndPrivacyAccepted: false,
        isApproximateDateOfBirth: undefined,
        partners: [],
        purinaMarketingAccepted: undefined,
        id: 'uuid-1234',
        accountId: 'accountId-1234',
        mail: 'revenue-cat-user@e2e.zigzag.dog',
        name: 'Fred',
        breedId: 'Bergamasco-id',
        gender: Gender.MALE,
        ownerName: 'Test Tester',
        dateOfOnboarding: '2021-03-24T00:00:00.000Z',
        dateOfArrival: '2021-03-24T00:00:00.000Z',
        dateOfBirth: '2020-11-11T00:00:00.000Z',
        countryCode: 'GB',
        region: 'United Kingdom',
        city: 'London',
        hasArrived: true,
        firstDayAtHomeWhenOnboarding: false,
        notifications: true,
        isRescuePup: false,
    };

    constructor(overrides?: Partial<InternalUserWithoutTokenDto>) {
        this.defaultValue = { ...this.defaultValue, ...overrides };
    }

    public get value(): InternalUserWithoutTokenDto {
        return this.defaultValue;
    }
}
