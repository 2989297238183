import { Component, inject } from '@angular/core';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { Store } from '@ngrx/store';
import { DailyBiteCardComponent } from '../../daily-bites/daily-bite-card/daily-bite-card.component';
import { showDailyBiteModal } from '../../daily-bites/daily-bite-modal/daily-bite-modal.actions';
import { selectDailyBiteForToday } from '../../store/daily-bites/daily-bites.selectors';

@Component({
    selector: 'app-today-daily-bites',
    templateUrl: './today-daily-bites.component.html',
    styleUrl: './today-daily-bites.component.scss',
    imports: [DailyBiteCardComponent, TextReplacementPipe],
})
export class TodayDailyBitesComponent {
    private readonly store = inject(Store);

    public dailyBite = this.store.selectSignal(selectDailyBiteForToday);

    public openDailyBiteModal(): void {
        if (!this.dailyBite()) {
            return;
        }

        this.store.dispatch(
            showDailyBiteModal({
                dailyBite: this.dailyBite()!,
            }),
        );
    }
}
