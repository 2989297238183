@if (vm$ | async; as vm) {
<ion-content class="page-safe-margin" [ngClass]="{ 'is-searching' : isSearching }" [fullscreen]="true">
    <main class="library-page page-padding-top">
        <header class="text-block">
            <h1 class="page-title" data-test="library-page-title">Library</h1>
        </header>

        <app-library-search-bar [onLibraryPage]="arriveOnPage$" (searching)="isSearching=$event" />

        @if (!isSearching) {
        <ui-generic-card-slim
            class="library-page__ai-chat-card"
            appTrackClick
            [identifier]="'library-page-ziggy-ai-tile'"
            [attr.data-test]="'library-page-ziggy-ai-tile'"
            [title]="'Ask Ziggy'"
            [imageUrl]="'assets/images/ziggy/ziggy-close-up.svg'"
            [background]="Color.Sophie"
            [disabled]="vm.isStreamChatBusyCleaningUp"
            [unlocked]="true"
            [isBasicPlan]="vm.isBasicPlan"
            (click)="openZiggyChat(!vm.isStreamChatBusyCleaningUp)"
        />

        <app-library-topic-overview />

        <app-library-daily-bite-tile />
        }
    </main>
</ion-content>
}
