import { Component } from '@angular/core';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { ProgressHeaderComponent } from '@frontend/ui/progress-header';
import { IonButton, IonContent, IonFooter, IonHeader, IonSpinner, IonToolbar } from '@ionic/angular/standalone';
import { subDays } from 'date-fns';
import { onboardingQuestionSubmittedFirstDayAtHome } from '../../data-access/onboarding.actions';
import { OnboardingQuestionBasePage } from '../question-base.page';

@Component({
    selector: 'app-onboarding-question-first-day-at-home',
    templateUrl: './question-first-day-at-home.page.html',
    styleUrls: ['./question-first-day-at-home.page.scss', '../onboarding.scss'],
    imports: [
        AnalyticsTrackClickDirective,
        IonButton,
        IonContent,
        IonFooter,
        IonHeader,
        IonSpinner,
        IonToolbar,
        ProgressHeaderComponent,
    ],
})
export class OnboardingQuestionFirstDayAtHomePage extends OnboardingQuestionBasePage {
    public onFirstDayAtHomeClick(hasArrivedToday: boolean): void {
        if (this.isLoading()) {
            return;
        }

        this.store.dispatch(
            onboardingQuestionSubmittedFirstDayAtHome({
                dateOfArrival: hasArrivedToday ? new Date() : subDays(new Date(), 2),
            }),
        );
    }
}
