import { Routes } from '@angular/router';
import { stepRoutes } from '../step/step.routes';
import { LibraryDailyBiteOverviewComponent } from './daily-bites/library-daily-bite-overview/library-daily-bite-overview.component';
import { TopicPage } from './library-topic-overview/topic/topic.page';
import { LibraryPage } from './library.page';

const libraryRoutes: Routes = [
    {
        path: '',
        component: LibraryPage,
        pathMatch: 'full',
    },
    {
        path: 'daily-bites',
        component: LibraryDailyBiteOverviewComponent,
    },
    {
        path: 'topic/:topic',
        component: TopicPage,
    },
    {
        path: 'topic/:topic/step',
        children: stepRoutes,
    },
    {
        path: 'step',
        children: stepRoutes,
    },
    {
        path: 'chat',
        loadChildren: () => import('../chat/chat.routes'),
    },
];

export default libraryRoutes;
