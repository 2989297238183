import { InternalIterableDataFieldsMock } from './internal-iterable-data-fields.mock';
import { IterablePostUserDto } from '../dtos/iterable-post-user.dto';

export class InternalIterablePostUserDtoMock {
    private defaultValue: IterablePostUserDto = {
        dataFields: new InternalIterableDataFieldsMock().value,
    };

    constructor(overrides?: Partial<IterablePostUserDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): IterablePostUserDto {
        return this.defaultValue;
    }
}
