import { Routes } from '@angular/router';
import { AiChatPage } from './ai-chat/ai-chat.page';
import { TrainingCoachChatPage } from './training-coach-chat/training-coach-chat.page';

const chatRoutes: Routes = [
    {
        path: '',
        redirectTo: 'ai',
        pathMatch: 'full',
    },
    {
        path: 'coaches',
        component: TrainingCoachChatPage,
    },
    {
        path: 'ai',
        component: AiChatPage,
    },
];

export default chatRoutes;
