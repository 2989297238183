import { AdditionalUserInfo as FirebaseAdditionalUserInfo, User as FirebaseUser } from '@angular/fire/auth';
import { AdditionalUserInfo as GoogleAdditionalUserInfo, User as GoogleUser } from '@capacitor-firebase/authentication';
import { User } from '../models/user.model';

export enum AuthenticationErrorMessages {
    GENERIC = 'Oops, looks like something went wrong... Please try again later or contact contact@zigzag.dog.',
    TOO_MANY_REQUESTS = 'Oops, you’ve reached your maximum number of login attempts. Please try again later. Forgot your password? Click the link below.',
    USER_DISABLED = 'Oops, looks like your account has been disabled. Please contact us at contact@zigzag.dog.',
    NETWORK_REQUEST_FAILED = 'Oops, looks like a network error has occurred. Please try again.',
    USER_NOT_FOUND = 'We couldn’t find this account, please try again with a different email or contact contact@zigzag.com.',
    INVALID_CREDENTIAL = 'Oops, the email or password is invalid. Forgot your password? Click the link below.',
    EMAIL_ALREADY_IN_USE = 'This email is already in use, please try another email or go back to ',
}

export interface AuthenticationErrorLink {
    text: string;
    route: string[];
}

export interface AuthenticationErrorObject {
    message: AuthenticationErrorMessages;
    link?: AuthenticationErrorLink;
}

const firebaseAuthErrorMap = new Map<string, AuthenticationErrorObject>([
    ['auth/user-not-found', { message: AuthenticationErrorMessages.USER_NOT_FOUND }],
    ['auth/invalid-credential', { message: AuthenticationErrorMessages.INVALID_CREDENTIAL }],
    ['auth/too-many-requests', { message: AuthenticationErrorMessages.TOO_MANY_REQUESTS }],
    ['auth/user-disabled', { message: AuthenticationErrorMessages.USER_DISABLED }],
    ['auth/network-request-failed', { message: AuthenticationErrorMessages.NETWORK_REQUEST_FAILED }],
    [
        'auth/email-already-in-use',
        {
            message: AuthenticationErrorMessages.EMAIL_ALREADY_IN_USE,
            link: { text: 'sign in', route: ['auth', 'login'] },
        },
    ],
]);

export const mapAuthenticationError = (error: string | undefined): AuthenticationErrorObject | undefined => {
    if (!error) {
        return undefined;
    }

    return firebaseAuthErrorMap.get(error) ?? { message: AuthenticationErrorMessages.GENERIC };
};

export const mapFireBaseUserToUser = (user: FirebaseUser | GoogleUser | null, ownerName?: string): { user: User } => {
    if (user === undefined || user === null || user.email === null || user.uid === null || user.uid === '') {
        throw new Error('User is not defined');
    }

    return {
        user: {
            id: user.uid,
            email: user.email,
            ownerName,
            signInProvider: user.providerData[0].providerId,
        },
    };
};

export const mapFireBaseUserWithAdditionalUserInfoToUser = (
    user: FirebaseUser | GoogleUser | null,
    additionalUserInfo: FirebaseAdditionalUserInfo | GoogleAdditionalUserInfo,
    ownerName?: string,
): {
    user: User;
    additionalUserInfo: FirebaseAdditionalUserInfo | GoogleAdditionalUserInfo;
    ownerName?: string;
} => {
    return {
        ...mapFireBaseUserToUser(user, ownerName),
        additionalUserInfo,
    };
};
