import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BASE_ENVIRONMENT_CONFIG, BaseEnvironmentConfig } from '@frontend/configuration';
import { httpOptions } from '@frontend/utility/constants';
import { internalPaths } from '@shared/internal-api-interface';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UuidService {
    private readonly http = inject(HttpClient);
    private readonly environment = inject<BaseEnvironmentConfig>(BASE_ENVIRONMENT_CONFIG);

    public getUuid(): Observable<{ id: string }> {
        return this.http.get<{ id: string }>(
            `${this.environment.internalApiUrl}/api/${internalPaths.uuidPath}`,
            httpOptions,
        );
    }
}
