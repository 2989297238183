import { createFeature, createReducer, on } from '@ngrx/store';
import {
    onboardingQuestionCareNeedsSetOrderSeed,
    onboardingQuestionSubmittedCareNeeds,
    onboardingQuestionSubmittedHowDidYouHear,
    onboardingQuestionSubmittedNeeds,
} from './onboarding.actions';

export interface OnboardingState {
    selectedNeeds: string[];
    selectedCareNeeds: string[];
    careNeedsOrderSeed: number | undefined;
    howDidYouHear: string;
}

export const initialState: OnboardingState = {
    selectedNeeds: [],
    selectedCareNeeds: [],
    careNeedsOrderSeed: undefined,
    howDidYouHear: '',
};

export const onboardingFeature = createFeature({
    name: 'onboarding',
    reducer: createReducer(
        initialState,
        on(onboardingQuestionSubmittedNeeds, (state, { needs }): OnboardingState => {
            // Convert the Record<string, boolean> '{ aCat: true, aDog: false }' to an array of selected needs ['aCat']
            const selectedNeeds = Object.keys(needs).filter((need) => needs[need]);

            return {
                ...state,
                selectedNeeds,
            };
        }),
        on(onboardingQuestionCareNeedsSetOrderSeed, (state, { seed }): OnboardingState => {
            return {
                ...state,
                careNeedsOrderSeed: seed,
            };
        }),
        on(onboardingQuestionSubmittedCareNeeds, (state, { careNeeds }): OnboardingState => {
            // Convert the Record<string, boolean> '{ healthcare: true, nutrition: false }' to an array of selected needs ['healthcare']
            const selectedCareNeeds = Object.keys(careNeeds).filter((careNeed) => careNeeds[careNeed]);

            return {
                ...state,
                selectedCareNeeds,
            };
        }),
        on(onboardingQuestionSubmittedHowDidYouHear, (state, { howDidYouHear }): OnboardingState => {
            return {
                ...state,
                howDidYouHear,
            };
        }),
    ),
});
