import { DatePipe } from '@angular/common';
import { Component, effect } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { timeFeature } from '@frontend/data-access/time';
import { householdFeature } from '@frontend/data-access/user/household';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { DatetimeComponent } from '@frontend/ui/datetime';
import { ProgressHeaderComponent } from '@frontend/ui/progress-header';
import { IonButton, IonContent, IonFooter, IonHeader, IonSpinner, IonToolbar } from '@ionic/angular/standalone';
import { formatISO } from 'date-fns';
import { onboardingQuestionSubmittedArrivalDate } from '../../data-access/onboarding.actions';
import { OnboardingQuestionBasePage } from '../question-base.page';

@Component({
    selector: 'app-onboarding-question-arrival',
    templateUrl: './question-arrival.page.html',
    styleUrls: ['./question-arrival.page.scss', '../onboarding.scss'],
    imports: [
        AnalyticsTrackClickDirective,
        DatePipe,
        IonButton,
        IonContent,
        IonFooter,
        IonHeader,
        IonSpinner,
        IonToolbar,
        ProgressHeaderComponent,
        DatetimeComponent,
        ReactiveFormsModule,
    ],
})
export class OnboardingQuestionArrivalPage extends OnboardingQuestionBasePage {
    public currentDate = this.store.selectSignal<Date>(timeFeature.selectToday);

    private dateOfArrival = this.store.selectSignal(householdFeature.selectDateOfArrival);

    public arrivalForm = new FormGroup({
        dateOfArrival: new FormControl(formatISO(new Date(), { representation: 'date' }), {
            validators: [Validators.required],
            nonNullable: true,
        }),
    });

    constructor() {
        super();

        effect(() => {
            if (this.dateOfArrival()) {
                this.arrivalForm.setValue({
                    dateOfArrival: formatISO(this.dateOfArrival()!, { representation: 'date' }),
                });
            }
        });
    }

    public onSubmit(): void {
        if (this.arrivalForm.invalid || this.isLoading()) {
            return;
        }

        this.store.dispatch(
            onboardingQuestionSubmittedArrivalDate({
                dateOfArrival: new Date(this.arrivalForm.controls.dateOfArrival.value),
            }),
        );
    }
}
