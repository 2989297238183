import { IterableSendPushNotificationDto } from '../dtos/iterable-send-push-notification.dto';

export class InternalIterableSendPushNotificationDtoMock {
    private defaultValue: IterableSendPushNotificationDto = {
        recipientUserId: 'user-id',
        campaignId: 1,
    };

    constructor(overrides?: Partial<IterableSendPushNotificationDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): IterableSendPushNotificationDto {
        return this.defaultValue;
    }
}
