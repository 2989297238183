import { UserInteractionItem } from '../models/user-interactions.model';

export class UserInteractionItemMock {
    private defaultValue: UserInteractionItem = {
        id: 'some-modal-id',
        timestamp: '2020-11-15T12:00:00.000Z',
        dontShowAgain: undefined,
    };

    constructor(overrides?: Partial<UserInteractionItem>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): UserInteractionItem {
        return this.defaultValue;
    }
}
