import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map } from 'rxjs/operators';
import {
    getLocalStorage,
    getLocalStorageSuccess,
    LocalStorageKeys,
    removeLocalStorage,
    setLocalStorage,
} from '@frontend/data-access/capacitor';
import { getUserIdForFeatureFlags } from '../../feature-authentication/landing-page/landing.page.actions';
import { getUuid, getUuidSuccess } from '@frontend/data-access/uuid';
import { getHouseholdSuccess } from '@frontend/data-access/user/household';

@Injectable()
export class DuplicateProfileManagementEffects {
    private readonly actions$ = inject(Actions);
    getUserIdFromLocalStorage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getUserIdForFeatureFlags),
            map(() => getLocalStorage({ key: LocalStorageKeys.userId })),
        );
    });

    createEmptyUserUsingExistingUserIdIfItsExists$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getLocalStorageSuccess),
            filter(({ key }) => key === LocalStorageKeys.userId),
            map(({ data }) => {
                return data ? getUuidSuccess({ id: data as string }) : getUuid();
            }),
        );
    });

    setUserIdInLocalStorage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getUuidSuccess),
            map(({ id }) => setLocalStorage({ key: LocalStorageKeys.userId, data: id })),
        );
    });

    clearUserIdFromLocalStorage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getHouseholdSuccess),
            map(() => removeLocalStorage({ key: LocalStorageKeys.userId })),
        );
    });
}
