import { inject, Injectable } from '@angular/core';
import { trackEvent } from '@frontend/data-access/analytics';
import { ModalService } from '@frontend/utility/modal';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, tap } from 'rxjs/operators';
import { showCourseInformationModal } from './course-information-modal.actions';
import { CourseInformationModalComponent } from './course-information-modal.component';

@Injectable()
export class CourseInformationModalEffects {
    private readonly actions$ = inject(Actions);
    private readonly modalService = inject(ModalService);

    showCourseInformationModal$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(showCourseInformationModal),
                tap(({ course }) => {
                    void this.modalService.showModal({
                        component: CourseInformationModalComponent,
                        cssClass: ['modal', 'modal-fullscreen'],
                        componentProps: {
                            course,
                        },
                    });
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    trackShowCourseInformationModal$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(showCourseInformationModal),
            map(({ course }) => {
                return trackEvent({
                    eventName: '[Course] Course Information Modal Initiated',
                    eventProperties: {
                        courseId: course.id,
                    },
                });
            }),
        );
    });
}
