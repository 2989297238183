import { inject, Injectable } from '@angular/core';
import { trackEvent } from '@frontend/data-access/analytics';
import {
    clearLocalNotification,
    clearLocalNotificationSuccess,
    getLocalNotifications,
    LocalNotificationService,
    requestPermissions,
    scheduleLocalNotification,
    scheduleLocalNotificationSuccess,
    selectLocalNotifications,
} from '@frontend/data-access/local-notification';
import { openToast } from '@frontend/ui/toast';
import { ModalService } from '@frontend/utility/modal';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { from } from 'rxjs';
import { concatMap, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { TrainingReminderModalComponent } from '../components/training-reminder-modal/training-reminder-modal.component';
import { TRAINING_REMINDER_MODAL_ID } from '../training-reminder.constant';
import {
    closeTrainingReminderModal,
    createTrainingReminder,
    launchTrainingReminderModal,
    trainingReminderModalCloseClicked,
} from './training-reminder-modal.actions';

@Injectable()
export class TrainingReminderModalEffects {
    private readonly store = inject(Store);
    private readonly actions$ = inject(Actions);
    private readonly modalService = inject(ModalService);
    private readonly localNotificationsService = inject(LocalNotificationService);

    showTrainingReminderModal$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(launchTrainingReminderModal),
            tap(({ dogName }) => {
                return this.modalService.showModal({
                    id: TRAINING_REMINDER_MODAL_ID,
                    component: TrainingReminderModalComponent,
                    componentProps: {
                        dogName,
                    },
                    cssClass: ['modal', 'modal-training-reminder'],
                });
            }),
            map(({ trigger }) => {
                return trackEvent({
                    eventName: '[Training Reminder] Show Modal',
                    eventProperties: {
                        trigger,
                    },
                });
            }),
        );
    });

    closeTrainingReminderModal$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(closeTrainingReminderModal),
                tap(() => {
                    return this.modalService.dismissById(TRAINING_REMINDER_MODAL_ID);
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    trainingReminderModalCloseClicked$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trainingReminderModalCloseClicked),
            mergeMap(() => {
                return [
                    closeTrainingReminderModal(),
                    trackEvent({
                        eventName: `[Training Reminder] Close Button Clicked`,
                    }),
                ];
            }),
        );
    });

    createTrainingReminder$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createTrainingReminder),
            filter(({ weekdays }) => weekdays.length > 0),
            concatMap(({ dogName, time, weekdays }) => {
                // We want to check if the user has granted permissions before we schedule the notification, this is because the settings can be changed externally, e.g. in the phone settings
                return from(this.localNotificationsService.checkAllPermissions()).pipe(
                    filter(({ display }) => display === 'granted'),
                    concatLatestFrom(() => this.store.select(selectLocalNotifications)),
                    switchMap(([, currentNotifications]) => {
                        const actions: Action[] = [];

                        // Find current notifications
                        const sameNotification = currentNotifications.find(
                            (notification) =>
                                notification.hour === time.getHours() && notification.minute === time.getMinutes(),
                        );

                        let newWeekdays = [...weekdays];

                        // If exists, extract out current weekdays
                        if (sameNotification) {
                            // Combine with the new weekdays and order them numerically
                            newWeekdays = [...new Set([...weekdays, ...sameNotification.weekdays])].sort();

                            // Delete the existing notification record
                            actions.push(
                                clearLocalNotification({
                                    notifications: sameNotification.notificationIds.map((id) => {
                                        return { id };
                                    }),
                                }),
                            );
                        }

                        // Create a new record with ordered weekdays
                        const notifications = newWeekdays.map((weekday) => {
                            // Generate a positive 32-bit JAVA integer
                            const min = 1;
                            const max = 2147483647;
                            const id = Math.floor(Math.random() * (max - min + 1) + min);

                            return {
                                id,
                                title: `${dogName}'s training reminder`,
                                body: `It's time to train ${dogName}.`,
                                schedule: {
                                    on: {
                                        weekday,
                                        hour: time.getHours(),
                                        minute: time.getMinutes(),
                                        second: time.getSeconds(),
                                    },
                                },
                            };
                        });

                        actions.push(scheduleLocalNotification({ notifications }));

                        return actions;
                    }),
                );
            }),
        );
    });

    requestPermissionsForNotifications$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createTrainingReminder),
            concatMap(() => {
                return from(this.localNotificationsService.checkAllPermissions()).pipe(
                    // Only prompt if the permission is not granted, if it's been 'denied', we can't display the native prompt due to how Google and Apple handle permissions
                    filter(({ display }) => display === 'prompt' || display === 'prompt-with-rationale'),
                    map(() => {
                        return requestPermissions();
                    }),
                );
            }),
        );
    });

    handlePermissionsDenied$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createTrainingReminder),
            concatMap(() => {
                return from(this.localNotificationsService.checkAllPermissions()).pipe(
                    // The user has explicitly denied the permission, we can't display the native prompt due to how Google and Apple handle permissions, so we show a toast instead
                    filter(({ display }) => display === 'denied'),
                    map(() => {
                        return openToast({
                            message: 'Please re-enable notifications in your phone settings to use training reminders',
                            color: Color.Luna,
                        });
                    }),
                );
            }),
        );
    });

    handleTrainingReminderCreatedSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(scheduleLocalNotificationSuccess),
            mergeMap(() => {
                return [
                    closeTrainingReminderModal(),
                    openToast({
                        message: 'Training reminder has been set',
                        color: Color.Luna,
                    }),
                    trackEvent({
                        eventName: '[Training Reminder] Training Reminder Created',
                    }),
                    getLocalNotifications(),
                ];
            }),
        );
    });

    handleClearLocalNotificationSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(clearLocalNotificationSuccess),
            map(() => {
                return openToast({
                    message: 'Training reminder has been removed',
                    color: Color.Luna,
                });
            }),
        );
    });
}
