import { Component, computed } from '@angular/core';
import { IonContent } from '@ionic/angular/standalone';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { NourishModalBaseComponent } from '../nourish-modal-base.component';
import { AuthorChipComponent } from '@frontend/ui/author-chip';
import { GenericFeedbackComponent } from '@frontend/ui/generic-feedback';

@Component({
    selector: 'app-pup-not-eating-modal',
    templateUrl: './pup-not-eating-modal.component.html',
    styleUrl: './pup-not-eating-modal.component.scss',
    imports: [IonContent, AuthorChipComponent, AnalyticsTrackClickDirective, GenericFeedbackComponent],
})
export class PupNotEatingModalComponent extends NourishModalBaseComponent {
    public flavourText = computed(() => {
        return this.isUsUser() ? 'flavor' : 'flavour';
    });
}
