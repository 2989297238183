import { Component, inject } from '@angular/core';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { ModalService } from '@frontend/utility/modal';
import { IonButton, IonFooter } from '@ionic/angular/standalone';

@Component({
    selector: 'app-arrival-modal',
    templateUrl: './arrival-modal.component.html',
    styleUrl: './arrival-modal.component.scss',
    imports: [AnalyticsTrackClickDirective, IonButton, IonFooter, TextReplacementPipe],
})
export class ArrivalModalComponent {
    private readonly modalService = inject(ModalService);

    public closeModal(arrived: boolean): void {
        void this.modalService.dismiss({
            arrived,
        });
    }
}
