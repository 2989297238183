import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { selectIsInputOpenOnAndroid } from '@frontend/data-access/keyboard';
import { routeToPrevious } from '@frontend/data-access/router';
import { requestPasswordReset } from '@frontend/data-access/user/authentication';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { HeaderButton, HeaderComponent } from '@frontend/feature/header';
import { InvalidInputErrorMessageComponent } from '@frontend/ui/invalid-input-error-message';
import { IonButton, IonContent, IonFooter, IonHeader, IonInput, IonItem, IonToolbar } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.page.html',
    styleUrls: ['../authentication.scss', './reset-password.page.scss'],
    imports: [
        AnalyticsTrackClickDirective,
        HeaderComponent,
        InvalidInputErrorMessageComponent,
        IonButton,
        IonContent,
        IonFooter,
        IonHeader,
        IonInput,
        IonItem,
        IonToolbar,
        ReactiveFormsModule,
    ],
})
export class ResetPasswordPage {
    private readonly store = inject(Store);

    protected readonly leftButtonConfig: HeaderButton = {
        analyticsIdentifier: 'reset-password-enter-email-back-btn',
        background: 'none',
    };

    public isInputOpenOnAndroid = this.store.selectSignal(selectIsInputOpenOnAndroid);

    public requestPasswordResetForm = new FormGroup({
        email: new FormControl('', {
            validators: [Validators.required, Validators.email],
            nonNullable: true,
        }),
    });

    public showEmailError(): boolean {
        return (
            this.requestPasswordResetForm.controls.email.dirty && this.requestPasswordResetForm.controls.email.invalid
        );
    }

    public routeToPrevious(): void {
        this.store.dispatch(routeToPrevious({}));
    }

    public send(): void {
        this.store.dispatch(
            requestPasswordReset({
                email: this.requestPasswordResetForm.controls.email.value,
            }),
        );
    }
}
