import { NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { IonContent } from '@ionic/angular/standalone';
import { NourishModalBaseComponent } from '../nourish-modal-base.component';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { NourishPetFoodFinderComponent } from '../../nourish-pet-food-finder/nourish-pet-food-finder.component';
import { NourishFoodFinderOriginId, NourishTileId } from '../../constants/nourish.constants';
import { GenericFeedbackComponent } from '@frontend/ui/generic-feedback';

@Component({
    selector: 'app-help-with-tummy-troubles-modal',
    templateUrl: './help-with-tummy-troubles-modal.component.html',
    styleUrl: './help-with-tummy-troubles-modal.component.scss',
    imports: [
        NgClass,
        IonContent,
        AnalyticsTrackClickDirective,
        NourishPetFoodFinderComponent,
        GenericFeedbackComponent,
    ],
})
export class HelpWithTummyTroublesModalComponent extends NourishModalBaseComponent {
    protected readonly NourishTileId = NourishTileId;
    protected readonly NourishFoodFinderOriginId = NourishFoodFinderOriginId;
}
