import { inject, Injectable } from '@angular/core';
import { trackEvent } from '@frontend/data-access/analytics';
import { backButtonPressed } from '@frontend/data-access/platform';
import { openUrl, routeTo } from '@frontend/data-access/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { concatMap, filter, map, switchMap } from 'rxjs/operators';
import { routeToAiChat } from '../../chat/chat.actions';
import { setCourseSliderScrollToFirstInactiveStep } from '../../courses/course-slider/course-slider.actions';
import {
    aiChatInputClicked,
    aiChatInputSubmitted,
    goToCourseFromTile,
    goToSeasonalTopicFromTile,
    hideAiChatView,
    showAiChatView,
    suggestedAiChatQuestionClicked,
    zukesVoucherClicked,
} from './today.page.actions';
import { todayPageFeature } from './today.page.reducer';
import { TodayPageUserInteractionKeys } from '../today.page.enum';
import {
    shouldDisplayModal,
    ShowModalFrequency,
    storeUserInteraction,
    userInteractionFeature,
} from '@frontend/data-access/user-interactions';

@Injectable()
export class TodayPageEffects {
    private store = inject(Store);
    private actions$ = inject(Actions);

    //TODO: JL - add tests
    trackSuggestedAiChatQuestionClickAndTriggerRoute$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(suggestedAiChatQuestionClicked),
            switchMap(({ optionId, option, card }) => [
                routeToAiChat({ message: optionId }),
                trackEvent({
                    eventName: '[Today] Ai Chat Suggested Question Clicked',
                    eventProperties: { option, card },
                }),
            ]),
        );
    });

    trackTodayAiChatBarQuestionAsked$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(aiChatInputSubmitted),
            switchMap(({ message }) => [
                trackEvent({
                    eventName: '[Today] Ai Chat Question Asked',
                    eventProperties: { message },
                }),
            ]),
        );
    });

    navigateToAiChatOnTodayAiInputClicked$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(aiChatInputClicked),
            concatLatestFrom(() => this.store.select(userInteractionFeature.selectUserInteractions)),
            filter(([, userInteractionItems]) => {
                return shouldDisplayModal(
                    ShowModalFrequency.ONCE,
                    'ai-chat-intro-modal',
                    userInteractionItems,
                    new Date(),
                );
            }),
            map(() => {
                return routeToAiChat({});
            }),
        );
    });

    showTodayAiChatView$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(aiChatInputClicked),
            concatLatestFrom(() => [
                this.store.select(userInteractionFeature.selectUserInteractions),
                this.store.select(todayPageFeature.selectShowAiChatView),
            ]),
            filter(([, userInteractionItems, showAiChatView]) => {
                return (
                    !shouldDisplayModal(
                        ShowModalFrequency.ONCE,
                        'ai-chat-intro-modal',
                        userInteractionItems,
                        new Date(),
                    ) && !showAiChatView
                );
            }),
            map(() => {
                return showAiChatView();
            }),
        );
    });

    hideTodayAiChatView$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(backButtonPressed),
            concatLatestFrom(() => this.store.select(todayPageFeature.selectShowAiChatView)),
            filter(([, showAiChatView]) => showAiChatView),
            map(() => {
                return hideAiChatView();
            }),
        );
    });

    goToCourseFromTile$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(goToCourseFromTile),
            concatMap(({ courseId }) => {
                return [
                    setCourseSliderScrollToFirstInactiveStep({ courseId: courseId }),
                    routeTo({ dtos: ['main', 'courses'] }),
                ];
            }),
        );
    });

    goToTopicFromTile$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(goToSeasonalTopicFromTile),
            concatMap(({ topicId }) => {
                return [routeTo({ dtos: ['main', 'library', 'topic', topicId] })];
            }),
        );
    });

    zukesVoucherClicked$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(zukesVoucherClicked),
            concatMap(({ url }) => {
                return [
                    openUrl({ url }),
                    trackEvent({ eventName: '[Zukes Voucher] Clicked' }),
                    storeUserInteraction({ id: TodayPageUserInteractionKeys.ZukesVoucher, dontShowAgain: false }),
                ];
            }),
        );
    });
}
