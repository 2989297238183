import { Directive, HostListener, inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { trackEvent } from '@frontend/data-access/analytics';
import { Store } from '@ngrx/store';

// TODO: NBSon - check if we still want to do this, we only use this on RatingQualifierModalComponent, but was also track the comment on submission
@Directive({
    selector: '[appTrackInput]',
})
export class AnalyticsTrackInputDirective {
    private readonly router = inject(Router);
    private readonly store = inject(Store);

    @Input() identifier = '';

    @HostListener('ionBlur', ['$event']) onIonBlur($event: FocusEvent): void {
        this.store.dispatch(
            trackEvent({
                eventName: 'input',
                eventProperties: {
                    identifier: this.identifier,
                    page: this.router.url,
                    inputValue: ($event.target as HTMLInputElement).value || '',
                },
            }),
        );
    }
}
