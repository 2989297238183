import { FeedbackOption } from '../models/generic-feedback.model';

export const otherFeedbackOptionKey = 'other';

export const otherFeedbackOption = {
    key: otherFeedbackOptionKey,
    value: 'Something else',
};

export const defaultFeedbackOptions: FeedbackOption[] = [
    {
        key: 'not-clear',
        value: 'Not clear',
    },
    {
        key: 'too-generic',
        value: 'Too generic',
    },
    {
        key: 'incorrect',
        value: 'Incorrect',
    },
    otherFeedbackOption,
];
