import { Component, inject, input, model, OnInit } from '@angular/core';
import { purchaseProduct, PurchaseProduct, restoreSubscription } from '@frontend/data-access/subscription';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { ModalService } from '@frontend/utility/modal';
import { IonButton, IonContent, IonFooter } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { PaymentModalProductComponent } from '../../payment-modal-product/payment-modal-product.component';
import { PaymentProductCard } from '../../payment-modal-product/payment-product-card.model';
import { PaymentModalTermsComponent } from '../../payment-modal-terms/payment-modal-terms.component';
import { TieredPricingComparisonToggleComponent } from '../tiered-pricing-comparison-toggle/tiered-pricing-comparison-toggle.component';
import { TieredPricingPlanComparisonComponent } from '../tiered-pricing-plan-comparison/tiered-pricing-plan-comparison.component';
import { selectTieredPricingComparisonModalVm } from './tiered-pricing-comparison-modal.selectors';

@Component({
    selector: 'app-tiered-pricing-comparison-modal',
    templateUrl: './tiered-pricing-comparison-modal.component.html',
    styleUrl: './tiered-pricing-comparison-modal.component.scss',
    imports: [
        AnalyticsTrackClickDirective,
        TextReplacementPipe,
        IonButton,
        IonFooter,
        IonContent,
        PaymentModalProductComponent,
        PaymentModalTermsComponent,
        TieredPricingComparisonToggleComponent,
        TieredPricingPlanComparisonComponent,
    ],
})
export class TieredPricingComparisonModalComponent implements OnInit {
    private readonly store = inject(Store);
    private readonly modalService = inject(ModalService);
    protected readonly Color = Color;

    public premiumProducts = input<PaymentProductCard[]>([]);
    public basicProducts = input<PaymentProductCard[]>([]);
    public initialSelectedProduct = input.required<PaymentProductCard>();
    public initialIsPremiumSelected = input<boolean>(true);

    public isPremiumSelected = model<boolean>();
    public selectedProduct = model<PaymentProductCard>();

    public vm = this.store.selectSignal(selectTieredPricingComparisonModalVm);

    ngOnInit() {
        this.isPremiumSelected.set(this.initialIsPremiumSelected());
        this.selectedProduct.set(this.initialSelectedProduct());
    }

    public closeModalClicked(): void {
        this.modalService.dismiss({
            isPremiumSelected: this.isPremiumSelected(),
            selectedProduct: this.selectedProduct(),
        });
    }

    public onPurchaseClick(product: PurchaseProduct): void {
        this.store.dispatch(purchaseProduct({ data: product }));
    }

    public selectTier(tier: 'premium' | 'basic'): void {
        this.isPremiumSelected.set(tier === 'premium');
        this.selectedProduct.set(this.isPremiumSelected() ? this.premiumProducts()[0] : this.basicProducts()[0]);
    }

    public selectProduct(product: PaymentProductCard): void {
        this.selectedProduct.set(product);
    }

    public onRestoreClick(): void {
        this.store.dispatch(restoreSubscription());
    }
}
