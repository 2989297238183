import { inject, Injectable } from '@angular/core';
import { AdjustEventType, trackAdjustEvent } from '@frontend/data-access/adjust';
import { setAnalyticsProfile, trackEvent } from '@frontend/data-access/analytics';
import { setPushNotifications } from '@frontend/data-access/push-notification';
import { routeTo, routeToPrevious } from '@frontend/data-access/router';
import { LoadingState } from '@frontend/data-access/shared-models';
import { grantEntitlement, invalidateCustomerInfoCache } from '@frontend/data-access/subscription';
import { isItemInteracted, userInteractionFeature } from '@frontend/data-access/user-interactions';
import { configCatFeature, selectIsPurinaOptInUkAb } from '@frontend/data-access/user/config-cat';
import {
    householdFeature,
    patchDog,
    patchDogSuccess,
    patchHouseholdUser,
    patchHouseholdUserSuccess,
    selectAgeInWeeks,
    selectCountryCode,
    selectHasArrived,
    selectIsUsUser,
} from '@frontend/data-access/user/household';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { map, skipUntil } from 'rxjs';
import { concatMap, filter } from 'rxjs/operators';
import { OnboardingRoutes } from '../onboarding-routing.model';
import {
    onboardingComplete,
    onboardingNavigateBack,
    onboardingQuestionSubmittedArrivalDate,
    onboardingQuestionSubmittedBreed,
    onboardingQuestionSubmittedCareNeeds,
    onboardingQuestionSubmittedDateOfBirth,
    onboardingQuestionSubmittedFirstDayAtHome,
    onboardingQuestionSubmittedGender,
    onboardingQuestionSubmittedHasArrived,
    onboardingQuestionSubmittedHowDidYouHear,
    onboardingQuestionSubmittedIsRescue,
    onboardingQuestionSubmittedMarketingOptIn,
    onboardingQuestionSubmittedName,
    onboardingQuestionSubmittedNeeds,
    onboardingQuestionSubmittedNotifications,
    onboardingQuestionSubmittedOwnerName,
    onboardingQuestionSubmittedPurinaOptIn,
    onboardingQuestionSubmittedPurinaOptInUkAb,
    onboardingRouteTo,
} from './onboarding.actions';
import { selectOnboardingPreviousPage } from './onboarding.selectors';

@Injectable()
export class OnboardingEffects {
    private readonly store = inject(Store);
    private readonly actions$ = inject(Actions);

    routeForwardTo$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingRouteTo),
            skipUntil(
                this.store
                    .select(configCatFeature.selectLoading)
                    .pipe(filter((loading) => loading === LoadingState.LOADED)),
            ),
            map(({ route }) => {
                return routeTo({ dtos: ['onboarding', route], isAnimated: true });
            }),
        );
    });

    routeToPreviousPageOnBackButtonClicked$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingNavigateBack),
            concatLatestFrom(() => this.store.select(selectOnboardingPreviousPage)),
            filter(([_, onboardingRoute]) => !!onboardingRoute),
            map(([_, route]) => {
                return routeToPrevious({
                    dtos: ['onboarding', route!],
                    isAnimated: true,
                });
            }),
        );
    });

    handleQuestionHowDidYouHearSubmitted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingQuestionSubmittedHowDidYouHear),
            concatMap(({ howDidYouHear }) => {
                return [
                    onboardingRouteTo({ route: OnboardingRoutes.NAME }),
                    trackEvent({
                        eventName: '[Onboarding] How Did You Hear About Us Answered',
                        eventProperties: { howDidYouHear },
                    }),
                ];
            }),
        );
    });

    handleQuestionNameSubmitted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingQuestionSubmittedName),
            concatLatestFrom(() => this.store.select(householdFeature.selectCurrentDogId)),
            filter(([, dogId]) => !!dogId),
            concatMap(([{ name }, dogId]) => {
                return [
                    patchDog({ dogId: dogId!, dog: { name }, correlationId: onboardingQuestionSubmittedName.type }),
                ];
            }),
        );
    });

    handleQuestionNameSubmittedSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(patchDogSuccess),
            filter(({ correlationId }) => correlationId === onboardingQuestionSubmittedName.type),
            concatMap(() => {
                return [onboardingRouteTo({ route: OnboardingRoutes.RESCUE })];
            }),
        );
    });

    handleQuestionIsRescueSubmitted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingQuestionSubmittedIsRescue),
            concatLatestFrom(() => this.store.select(householdFeature.selectCurrentDogId)),
            filter(([, dogId]) => !!dogId),
            concatMap(([{ isRescuePup }, dogId]) => {
                return [
                    patchDog({
                        dogId: dogId!,
                        dog: { isRescue: isRescuePup },
                        correlationId: onboardingQuestionSubmittedIsRescue.type,
                    }),
                ];
            }),
        );
    });

    handleQuestionIsRescueSubmittedSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(patchDogSuccess),
            filter(({ correlationId }) => correlationId === onboardingQuestionSubmittedIsRescue.type),
            concatMap(() => {
                return [onboardingRouteTo({ route: OnboardingRoutes.AGE })];
            }),
        );
    });

    handleQuestionDateOfBirthSubmitted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingQuestionSubmittedDateOfBirth),
            concatLatestFrom(() => this.store.select(householdFeature.selectCurrentDogId)),
            filter(([, dogId]) => !!dogId),
            concatMap(([{ dateOfBirth, isApproximateDateOfBirth }, dogId]) => {
                return [
                    patchDog({
                        dogId: dogId!,
                        dog: { dateOfBirth, isApproximateDateOfBirth },
                        correlationId: onboardingQuestionSubmittedDateOfBirth.type,
                    }),
                ];
            }),
        );
    });

    handleQuestionDateOfBirthSubmittedSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(patchDogSuccess),
            filter(({ correlationId }) => correlationId === onboardingQuestionSubmittedDateOfBirth.type),
            concatMap(() => {
                return [onboardingRouteTo({ route: OnboardingRoutes.HAS_ARRIVED })];
            }),
        );
    });

    handleQuestionHasArrivedSubmitted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingQuestionSubmittedHasArrived),
            concatLatestFrom(() => this.store.select(householdFeature.selectCurrentDogId)),
            filter(([, dogId]) => !!dogId),
            concatMap(([{ hasArrived }, dogId]) => {
                return [
                    patchDog({
                        dogId: dogId!,
                        dog: { hasArrived },
                        correlationId: onboardingQuestionSubmittedHasArrived.type,
                    }),
                ];
            }),
        );
    });

    handleQuestionHasArrivedSubmittedSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(patchDogSuccess),
            filter(({ correlationId }) => correlationId === onboardingQuestionSubmittedHasArrived.type),
            concatLatestFrom(() => [this.store.select(selectHasArrived), this.store.select(selectAgeInWeeks)]),
            concatMap(([_, hasArrived, ageInWeeks]) => {
                let nextRoute: OnboardingRoutes;

                if (!hasArrived) {
                    nextRoute = OnboardingRoutes.ARRIVAL;
                } else if (ageInWeeks !== undefined && ageInWeeks <= 9) {
                    nextRoute = OnboardingRoutes.FIRST_DAY_AT_HOME;
                } else {
                    nextRoute = OnboardingRoutes.GENDER;
                }

                return [onboardingRouteTo({ route: nextRoute })];
            }),
        );
    });

    handleQuestionDateOfArrivalSubmitted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingQuestionSubmittedArrivalDate),
            concatLatestFrom(() => this.store.select(householdFeature.selectCurrentDogId)),
            filter(([, dogId]) => !!dogId),
            concatMap(([{ dateOfArrival }, dogId]) => {
                return [
                    patchDog({
                        dogId: dogId!,
                        dog: { dateOfArrival },
                        correlationId: onboardingQuestionSubmittedArrivalDate.type,
                    }),
                ];
            }),
        );
    });

    handleQuestionDateOfArrivalSubmittedSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(patchDogSuccess),
            filter(({ correlationId }) => correlationId === onboardingQuestionSubmittedArrivalDate.type),
            concatMap(() => {
                return [onboardingRouteTo({ route: OnboardingRoutes.GENDER })];
            }),
        );
    });

    handleQuestionFirstDayAtHomeSubmitted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingQuestionSubmittedFirstDayAtHome),
            concatLatestFrom(() => this.store.select(householdFeature.selectCurrentDogId)),
            filter(([, dogId]) => !!dogId),
            concatMap(([{ dateOfArrival }, dogId]) => {
                return [
                    patchDog({
                        dogId: dogId!,
                        dog: { dateOfArrival },
                        correlationId: onboardingQuestionSubmittedFirstDayAtHome.type,
                    }),
                ];
            }),
        );
    });

    handleQuestionFirstDayAtHomeSubmittedSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(patchDogSuccess),
            filter(({ correlationId }) => correlationId === onboardingQuestionSubmittedFirstDayAtHome.type),
            concatMap(() => {
                return [onboardingRouteTo({ route: OnboardingRoutes.GENDER })];
            }),
        );
    });

    handleQuestionGenderSubmitted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingQuestionSubmittedGender),
            concatLatestFrom(() => this.store.select(householdFeature.selectCurrentDogId)),
            filter(([, dogId]) => !!dogId),
            concatMap(([{ gender }, dogId]) => {
                return [
                    patchDog({
                        dogId: dogId!,
                        dog: { gender },
                        correlationId: onboardingQuestionSubmittedGender.type,
                    }),
                ];
            }),
        );
    });

    handleQuestionGenderSubmittedSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(patchDogSuccess),
            filter(({ correlationId }) => correlationId === onboardingQuestionSubmittedGender.type),
            concatMap(() => {
                return [onboardingRouteTo({ route: OnboardingRoutes.BREED })];
            }),
        );
    });

    handleQuestionBreedSubmitted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingQuestionSubmittedBreed),
            concatLatestFrom(() => this.store.select(householdFeature.selectCurrentDogId)),
            filter(([, dogId]) => !!dogId),
            concatMap(([{ breedId }, dogId]) => {
                return [
                    patchDog({
                        dogId: dogId!,
                        dog: { breedId },
                        correlationId: onboardingQuestionSubmittedBreed.type,
                    }),
                ];
            }),
        );
    });

    handleQuestionBreedSubmittedSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(patchDogSuccess),
            filter(({ correlationId }) => correlationId === onboardingQuestionSubmittedBreed.type),
            concatMap(() => {
                return [onboardingRouteTo({ route: OnboardingRoutes.NOURISH })];
            }),
        );
    });

    handleQuestionOwnerNameSubmitted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingQuestionSubmittedOwnerName),
            concatMap(({ ownerName }) => {
                return [
                    patchHouseholdUser({
                        user: { name: ownerName },
                        correlationId: onboardingQuestionSubmittedOwnerName.type,
                    }),
                ];
            }),
        );
    });

    handleQuestionOwnerNameSubmittedSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(patchHouseholdUserSuccess),
            filter(({ correlationId }) => correlationId === onboardingQuestionSubmittedOwnerName.type),
            concatMap(() => {
                return [
                    onboardingRouteTo({
                        route: OnboardingRoutes.NEEDS,
                    }),
                ];
            }),
        );
    });

    handleQuestionNeedsSubmitted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingQuestionSubmittedNeeds),
            concatMap(({ needs }) => {
                return [
                    onboardingRouteTo({ route: OnboardingRoutes.CARE_NEEDS }),
                    trackEvent({
                        eventName: 'onboarding-special-needs',
                        eventProperties: needs,
                    }),
                ];
            }),
        );
    });

    handleQuestionCareNeedsSubmitted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingQuestionSubmittedCareNeeds),
            concatMap(({ careNeeds }) => {
                return [
                    onboardingRouteTo({ route: OnboardingRoutes.NOTIFICATIONS }),
                    trackEvent({
                        eventName: '[Onboarding] Care Needs Answered',
                        eventProperties: careNeeds,
                    }),
                ];
            }),
        );
    });

    // TODO: NBSon - check if we should be doing ifs inside or just more effects with filters/concats
    handleQuestionNotificationsSubmitted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingQuestionSubmittedNotifications),
            concatLatestFrom(() => this.store.select(selectIsUsUser)),
            concatMap(([{ notifications }, isUsUser]) => {
                const actions: Action[] = [];

                if (notifications) {
                    actions.push(setPushNotifications());
                }

                if (isUsUser) {
                    actions.push(onboardingRouteTo({ route: OnboardingRoutes.PURINA_OPT_IN }));
                } else {
                    actions.push(onboardingRouteTo({ route: OnboardingRoutes.MARKETING_OPT_IN }));
                }

                return actions;
            }),
        );
    });

    handleQuestionMarketingOptIn$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingQuestionSubmittedMarketingOptIn),
            concatLatestFrom(() => [
                this.store.select(householdFeature.selectCurrentUserId),
                this.store.select(selectIsPurinaOptInUkAb),
                this.store.select(selectCountryCode),
            ]),
            concatMap(([{ marketingAccepted }, userId, isPurinaOptInUkAb, countryCode]) => {
                const actions: Action[] = [
                    trackEvent({
                        eventName: '[Onboarding] Marketing Opt-in Answered',
                        eventProperties: { optedIn: marketingAccepted },
                    }),
                ];

                if (isPurinaOptInUkAb && countryCode === 'GB') {
                    actions.push(onboardingRouteTo({ route: OnboardingRoutes.PURINA_OPT_IN_UK_AB }));
                } else {
                    actions.push(onboardingComplete());
                }

                if (userId) {
                    actions.push(setAnalyticsProfile({ profile: { marketingOptIn: marketingAccepted }, id: userId }));
                }

                return actions;
            }),
        );
    });

    handleQuestionPurinaOptInSubmitted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingQuestionSubmittedPurinaOptIn),
            concatMap(({ purinaMarketingAccepted }) => {
                return [
                    patchHouseholdUser({
                        user: {
                            purinaMarketingAccepted,
                        },
                        correlationId: onboardingQuestionSubmittedPurinaOptIn.type,
                    }),
                    trackEvent({
                        eventName: '[Onboarding] Purina Opt-in Answered',
                        eventProperties: { optedIn: purinaMarketingAccepted },
                    }),
                ];
            }),
        );
    });

    handleQuestionPurinaOptInUkAbSubmitted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingQuestionSubmittedPurinaOptInUkAb),
            concatMap(({ purinaMarketingAccepted }) => {
                return [
                    patchHouseholdUser({
                        user: {
                            purinaMarketingAccepted,
                        },
                        correlationId: onboardingQuestionSubmittedPurinaOptInUkAb.type,
                    }),
                    trackEvent({
                        eventName: '[Onboarding] Purina Opt-in UK AB Answered',
                        eventProperties: { optedIn: purinaMarketingAccepted },
                    }),
                ];
            }),
        );
    });

    purinaOptInSubmittedAdjustEvent$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingQuestionSubmittedPurinaOptIn),
            filter(({ purinaMarketingAccepted }) => purinaMarketingAccepted),
            map(() => {
                return trackAdjustEvent({ event: AdjustEventType.PURINA_MARKETING_ACCEPTED });
            }),
        );
    });

    purinaOptInUKSubmittedAdjustEvent$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingQuestionSubmittedPurinaOptInUkAb),
            filter(({ purinaMarketingAccepted }) => purinaMarketingAccepted),
            map(() => {
                return trackAdjustEvent({ event: AdjustEventType.PURINA_MARKETING_ACCEPTED });
            }),
        );
    });

    handleQuestionPurinaOptInSubmittedSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(patchHouseholdUserSuccess),
            filter(({ correlationId }) => correlationId === onboardingQuestionSubmittedPurinaOptIn.type),
            concatMap(() => {
                return [onboardingComplete()];
            }),
        );
    });

    handleQuestionPurinaOptInUkAbSubmittedSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(patchHouseholdUserSuccess),
            filter(({ correlationId }) => correlationId === onboardingQuestionSubmittedPurinaOptInUkAb.type),
            concatMap(() => {
                return [onboardingComplete()];
            }),
        );
    });

    handleOnboardingComplete$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingComplete),
            concatLatestFrom(() => this.store.select(householdFeature.selectCurrentDogId)),
            filter(([, dogId]) => !!dogId),
            concatMap(([, dogId]) => {
                return [
                    trackEvent({ eventName: 'complete-onboarding' }),
                    patchHouseholdUser({
                        user: {
                            dateOfOnboarding: new Date(),
                        },
                        correlationId: onboardingComplete.type,
                    }),
                    patchDog({
                        dogId: dogId!,
                        dog: {
                            dateOfOnboarding: new Date(),
                        },
                        correlationId: onboardingComplete.type,
                    }),
                ];
            }),
        );
    });

    handleOnboardingCompleteSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(patchHouseholdUserSuccess),
            filter(({ correlationId }) => correlationId === onboardingComplete.type),
            map(() => {
                return onboardingRouteTo({ route: OnboardingRoutes.CREATING_PLAN });
            }),
        );
    });

    invalidateRevenueCatCacheOnOnboardingComplete$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingComplete),
            map(() => {
                return invalidateCustomerInfoCache();
            }),
        );
    });

    getUserPartnersOnOnboardingComplete$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(onboardingComplete),
            concatLatestFrom(() => [
                this.store.select(householdFeature.selectCurrentUserId),
                this.store.select(householdFeature.selectPartners),
                this.store.select(userInteractionFeature.selectUserInteractions),
            ]),
            filter(
                ([_, , partners, userInteractionItems]) =>
                    !!partners && partners.length >= 1 && !isItemInteracted(userInteractionItems, partners[0].id),
            ),
            map(([_, id, partners]) => {
                return grantEntitlement({
                    userId: id!,
                    partnerId: partners![0].id,
                    freeAccessLengthInDays: partners![0].freeAccessLengthInDays,
                });
            }),
        );
    });
}
