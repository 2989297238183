import { Component, forwardRef, input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SingleSelectOption } from './single-select.model';

@Component({
    selector: 'ui-single-select',
    templateUrl: './single-select.component.html',
    styleUrl: './single-select.component.scss',
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SingleSelectComponent), multi: true }],
})
export class SingleSelectComponent implements ControlValueAccessor {
    public options = input.required<SingleSelectOption[]>();

    public defaultValue = '';

    public onTouched = () => {
        /* Do nothing */
    };

    public onChange: (value: string) => void = () => {
        /* Do nothing */
    };

    public writeValue(value: string): void {
        this.defaultValue = value;
    }

    public registerOnChange(onChange: (value: string) => void): void {
        this.onChange = onChange;
    }

    public registerOnTouched(onTouched: () => void): void {
        this.onTouched = onTouched;
    }

    public isSelected(value: string): boolean {
        return this.defaultValue === value;
    }

    public onOptionSelected(selectedValue: string): void {
        this.defaultValue = this.isSelected(selectedValue) ? '' : selectedValue;

        this.onChange(this.defaultValue);
        this.onTouched();
    }
}
