import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BASE_ENVIRONMENT_CONFIG, BaseEnvironmentConfig } from '@frontend/configuration';
import { httpOptions } from '@frontend/utility/constants';
import { internalPaths, InternalPostPartnerUserDto } from '@shared/internal-api-interface';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class RevenueCatInternalApiService {
    private readonly http = inject(HttpClient);
    private readonly environment = inject<BaseEnvironmentConfig>(BASE_ENVIRONMENT_CONFIG);

    public grantPromotionalEntitlement(dto: InternalPostPartnerUserDto): Observable<void> {
        return this.http.post<void>(
            `${this.environment.internalApiUrl}/api/${internalPaths.revenueCatPath}`,
            dto,
            httpOptions,
        );
    }
}
