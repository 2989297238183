<app-header
    class="app-header--modal-small"
    [leftButton]="false"
    [rightButton]="rightButtonConfig"
    (rightButtonPressed)="onClickCloseModal()"
/>

<ion-content class="training-reminder-modal" [fullscreen]="true">
    <h2 class="training-reminder-modal__title unset-margin">
        With training reminders you are <span class="highlight">75%</span> more likely to achieve your goals.
    </h2>

    <form class="training-reminder-modal-datetime" [formGroup]="form">
        <p class="training-reminder-modal-datetime__title unset-margin">Select your best time to train</p>

        <ion-datetime
            class="training-reminder-modal-datetime__ion-datetime"
            formControlName="time"
            presentation="time"
            hourCycle="h12"
            size="cover"
        />

        <p class="training-reminder-modal-datetime__title unset-margin">Choose the days for your training reminders</p>

        <app-weekday-buttons class="training-reminder-modal__weekday-buttons" formControlName="days" />
    </form>

    <ion-footer class="training-reminder-modal-footer page-footer">
        <ion-button
            expand="block"
            data-test="training-reminder-modal-set-reminder-btn"
            [color]="Color.Max"
            (click)="onClickSetReminder()"
        >
            Set reminder
        </ion-button>
    </ion-footer>
</ion-content>
