import { NgClass, NgStyle } from '@angular/common';
import { Component, input } from '@angular/core';
import { IonCard } from '@ionic/angular/standalone';
import { Color } from '@shared/utils/typescript';
import { GenericChipComponent } from '@frontend/ui/generic-chip';

@Component({
    selector: 'ui-generic-card-slim',
    templateUrl: './generic-card-slim.component.html',
    styleUrl: './generic-card-slim.component.scss',
    imports: [NgClass, NgStyle, IonCard, GenericChipComponent],
})
export class GenericCardSlimComponent {
    public title = input.required<string>();
    public imageUrl = input.required<string>();
    public background = input.required<Color>();
    public unlocked = input.required<boolean>();
    public isBasicPlan = input<boolean>();

    public disabled = input<boolean>(false);
    protected readonly Color = Color;
}
