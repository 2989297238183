import { InternalPostHappinessCheckDto } from '../../../dtos/happiness-check/internal-post-happiness-check.dto';

export class InternalPostHappinessCheckDtoMock {
    private defaultValue: InternalPostHappinessCheckDto = {
        ownerHappinessScore: 0.5,
        dogHappinessScore: 0.6,
        timestamp: 0,
    };

    constructor(overrides?: Partial<InternalPostHappinessCheckDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): InternalPostHappinessCheckDto {
        return this.defaultValue;
    }
}
