import { DomainPostRescheduledStepDto } from './domain-post-rescheduled-step.dto';
import { IsArray, ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';

export class DomainPostManyRescheduledStepDto {
    @IsArray()
    @ValidateNested({ each: true })
    @Type(() => DomainPostRescheduledStepDto)
    rescheduledSteps!: DomainPostRescheduledStepDto[];
}
