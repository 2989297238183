import { InternalPatchHouseholdDto } from '../../../dtos/user/household/internal-patch-household.dto';

export class InternalPatchHouseholdDtoMock {
    private defaultValue: InternalPatchHouseholdDto = {
        name: 'household',
        userId: 'uuid-1234',
        dogId: '123',
    };

    constructor(overrides?: Partial<InternalPatchHouseholdDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): InternalPatchHouseholdDto {
        return this.defaultValue;
    }
}
