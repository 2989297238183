import { internalPaths, InternalUserFeedbackContentDto } from '@shared/internal-api-interface';
import { Observable } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BASE_ENVIRONMENT_CONFIG, BaseEnvironmentConfig } from '@frontend/configuration';
import { httpOptions } from '@frontend/utility/constants';
import { InternalPostUserFeedbackContentDto } from '@backend/internal';
import { UserFeedbackContent } from '../models/user-feedback-content.model';

@Injectable({
    providedIn: 'root',
})
export class UserFeedbackContentService {
    private readonly http = inject(HttpClient);
    private readonly environment = inject<BaseEnvironmentConfig>(BASE_ENVIRONMENT_CONFIG);

    public getFeedback(): Observable<InternalUserFeedbackContentDto[]> {
        return this.http.get<InternalUserFeedbackContentDto[]>(
            `${this.environment.internalApiUrl}/api/${internalPaths.feedbackContentPath}`,
            httpOptions,
        );
    }

    public createFeedback(feedback: UserFeedbackContent): Observable<InternalUserFeedbackContentDto> {
        const dto: InternalPostUserFeedbackContentDto = {
            contentfulId: feedback.contentfulId,
            contentTypeId: feedback.contentTypeId,
            thumbsDown: feedback.thumbsDown,
            comment: feedback.comment,
        };

        return this.http.post<InternalUserFeedbackContentDto>(
            `${this.environment.internalApiUrl}/api/${internalPaths.feedbackContentPath}`,
            dto,
            httpOptions,
        );
    }
}
