import { NgClass, NgOptimizedImage, NgStyle } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';
import { trackEvent } from '@frontend/data-access/analytics';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { Store } from '@ngrx/store';
import { StepType } from '@shared/content-api-interface';
import { selectContentUnlocked } from '../../../store/payment/store/payment.selectors';
import { routeToStep } from '../../../store/router/router.actions';
import { StepStatus } from '../../../store/step/step.model';
import { selectSelectedCourseColor } from '../store/course-path.selectors';
import { CoursePageStep } from '../store/models/course-path.model';
import { getTemplateThemeColor } from '@frontend/utility/angular';

@Component({
    selector: 'app-course-path-step',
    templateUrl: './course-path-step.component.html',
    styleUrl: './course-path-step.component.scss',
    imports: [NgStyle, NgClass, NgOptimizedImage, TextReplacementPipe],
})
export class CoursePathStepComponent {
    private readonly store = inject(Store);

    protected readonly StepStatus = StepStatus;

    public unlocked = this.store.selectSignal(selectContentUnlocked);
    public selectedCourseColour = this.store.selectSignal(selectSelectedCourseColor);

    public step = input.required<CoursePageStep>();

    public setBorderAnimationColour = computed(() => {
        return {
            border: `4px solid ${getTemplateThemeColor(this.selectedCourseColour())}`,
        };
    });

    public setImageClass = computed(() => {
        return this.step().content.contentType === StepType.ARTICLE ? 'article-image' : '';
    });

    public goToStep(): void {
        this.store.dispatch(
            routeToStep({
                baseUrl: ['main', 'courses'],
                stepId: this.step().id,
                from: 'courses',
                fromPracticeStep: this.step().progress.isPractice,
            }),
        );
        this.store.dispatch(
            trackEvent({
                eventName: 'click',
                eventProperties: {
                    identifier: '[Step] To Step From Course Path',
                    stepId: this.step().id,
                    contentId: this.step().content.id,
                    stepType: this.step().content.contentType,
                    title: this.step().content.title,
                    topic: this.step().topic.title,
                    progressState: this.step().progress.state,
                    progressRating: this.step().progress.rating,
                    unlocked: this.unlocked(),
                },
            }),
        );
    }
}
