import { Gender } from '@shared/user-domain';
import { IsBoolean, IsEmail, IsOptional, IsString } from 'class-validator';

export class DomainPostUserDto {
    @IsEmail()
    mail!: string;

    @IsOptional()
    @IsString()
    accountId?: string;

    @IsOptional()
    @IsString()
    id?: string;

    @IsOptional()
    @IsString()
    name?: string;

    @IsOptional()
    @IsString()
    ownerName?: string;

    @IsOptional()
    @IsString()
    dateOfBirth?: string;

    @IsOptional()
    @IsString()
    dateOfOnboarding?: string;

    @IsOptional()
    @IsString()
    dateOfArrival?: string;

    @IsOptional()
    @IsString()
    breedId?: string;

    @IsOptional()
    @IsString()
    gender?: Gender;

    @IsOptional()
    @IsBoolean()
    notifications?: boolean;

    @IsOptional()
    @IsBoolean()
    hasArrived?: boolean;

    @IsOptional()
    @IsString()
    countryCode?: string;

    @IsOptional()
    @IsBoolean()
    purinaMarketingAccepted?: boolean;

    @IsOptional()
    @IsBoolean()
    isRescuePup?: boolean;

    @IsOptional()
    @IsBoolean()
    isApproximateDateOfBirth?: boolean;

    @IsOptional()
    @IsString()
    partnerId?: string;
}
