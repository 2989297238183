import { Component, inject } from '@angular/core';
import { LoadingState } from '@frontend/data-access/shared-models';
import { householdFeature } from '@frontend/data-access/user/household';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { NgClass } from '@angular/common';
import { NourishPetFoodFinderComponent } from '../nourish-pet-food-finder/nourish-pet-food-finder.component';
import { NourishFoodFinderOriginId } from '../constants/nourish.constants';

@Component({
    selector: 'app-nourish-header-us',
    templateUrl: './nourish-header-us.component.html',
    styleUrl: './nourish-header-us.component.scss',
    imports: [NgClass, NourishPetFoodFinderComponent],
})
export class NourishHeaderUsComponent {
    private readonly store = inject(Store);

    protected readonly loadingState = LoadingState;
    protected readonly Color = Color;
    protected readonly NourishPetFoodFinderComponent = NourishPetFoodFinderComponent;
    protected readonly NourishFoodFinderOriginId = NourishFoodFinderOriginId;

    public dogName = this.store.selectSignal(householdFeature.selectDogName);
}
