import { Component, input, output } from '@angular/core';
import { IonButton } from '@ionic/angular/standalone';

@Component({
    selector: 'ui-voucher-large',
    templateUrl: './voucher-large.component.html',
    styleUrl: './voucher-large.component.scss',
    imports: [IonButton],
})
export class VoucherLargeComponent {
    public handleButtonClick = output();

    public heading = input.required<string>();
    public subHeading = input.required<string>();
    public buttonColour = input.required<string>();
    public buttonText = input.required<string>();
    public image1Url = input.required<string>();
    public image2Url = input.required<string>();
    public image1Alt = input.required<string>();
    public image2Alt = input.required<string>();
    public locked = input.required<boolean>();

    public handleClick(): void {
        this.handleButtonClick.emit();
    }
}
