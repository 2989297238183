import { Gender } from '@shared/user-domain';
import { InternalDogDto } from '../../../dtos/user/dog/dog.dto';

export class InternalDogDtoMock {
    private defaultValue: InternalDogDto = {
        id: 'uuid-1234',
        breedId: 'Bergamasco-id',
        dateOfArrival: new Date('12-16-2024').getTime(),
        dateOfBirth: new Date('11-16-2024').getTime(),
        dateOfOnboarding: new Date('12-17-2024').getTime(),
        gender: Gender.MALE,
        hasArrived: true,
        isApproximateDateOfBirth: false,
        isRescue: false,
        name: 'Bella',
    };

    private empty: InternalDogDto = {
        id: 'uuid-1234',
        name: undefined,
        dateOfArrival: undefined,
        dateOfBirth: undefined,
        dateOfOnboarding: undefined,
        hasArrived: undefined,
        gender: undefined,
        breedId: undefined,
        isRescue: undefined,
        isApproximateDateOfBirth: undefined,
    };

    constructor(overrides?: Partial<InternalDogDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): InternalDogDto {
        return this.defaultValue;
    }

    public get emptyValue(): InternalDogDto {
        return this.empty;
    }
}
