import { Location, NgClass } from '@angular/common';
import { Component, computed, inject, ViewChild } from '@angular/core';
import { Article } from '@frontend/data-access/contentful';
import {
    createUserFeedbackContent,
    FeedbackContentTypeEnum,
    FeedbackContentTypeIdEnum,
    UserFeedbackContent,
    userFeedbackContentFeature,
} from '@frontend/data-access/feedback';
import { LoadingState } from '@frontend/data-access/shared-models';
import { subscriptionFeature } from '@frontend/data-access/subscription';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { householdFeature } from '@frontend/data-access/user/household';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { HeaderComponent } from '@frontend/feature/header';
import { AuthorChipComponent } from '@frontend/ui/author-chip';
import { defaultFeedbackOptions, GenericFeedbackComponent } from '@frontend/ui/generic-feedback';
import { RichContentPipe } from '@frontend/ui/pipes';
import { IonButton, IonContent, IonFooter, IonHeader, IonToolbar } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { StepType } from '@shared/content-api-interface';
import { showPaymentModal } from '../store/payment/store/payment.actions';
import { selectContentUnlocked } from '../store/payment/store/payment.selectors';
import {
    setStepCompleted,
    TRIGGERED_FROM_STEP_CORRELATION_ID,
} from '../store/progress/step-progress/step-progress.actions';
import { selectQueryParamFrom, selectRouteParamStepId } from '../store/router/router.selectors';
import { Step } from '../store/step/step.model';
import { selectSteps } from '../store/step/step.selectors';

@Component({
    selector: 'app-article',
    templateUrl: './article.page.html',
    styleUrl: './article.page.scss',
    imports: [
        NgClass,
        IonButton,
        IonContent,
        IonFooter,
        IonHeader,
        IonToolbar,
        AnalyticsTrackClickDirective,
        AuthorChipComponent,
        GenericFeedbackComponent,
        HeaderComponent,
        RichContentPipe,
        TextReplacementPipe,
    ],
})
export class ArticlePage {
    private readonly store = inject(Store);
    private readonly location = inject(Location);

    protected readonly DefaultFeedbackOptions = defaultFeedbackOptions;
    protected readonly loadingState = LoadingState;

    @ViewChild('feedbackComponent') feedback!: GenericFeedbackComponent;

    public userId = this.store.selectSignal(householdFeature.selectCurrentUserId);
    public stepId = this.store.selectSignal(selectRouteParamStepId);
    public steps = this.store.selectSignal(selectSteps);
    public unlocked = this.store.selectSignal(selectContentUnlocked);
    public hasHistoricalPurchase = this.store.selectSignal(subscriptionFeature.selectHasHistoricalPurchase);
    public userFeedbackList = this.store.selectSignal(userFeedbackContentFeature.selectFeedback);
    public userFeedbackLoading = this.store.selectSignal(userFeedbackContentFeature.selectLoadingState);
    public fromQueryParams = this.store.selectSignal(selectQueryParamFrom);

    public step = computed(() => {
        return this.steps().find(
            (step): step is Step<Article> => step.id === this.stepId() && step.content.contentType === StepType.ARTICLE,
        );
    });
    public userFeedback = computed(() => {
        return this.userFeedbackList()
            ?.filter((feedback) => feedback.contentfulId === this.stepId() && feedback.userId === this.userId())
            ?.sort((a, b) => +b.timestamp! - +a.timestamp!)
            ?.shift();
    });

    public routeToPreviousPage(): void {
        this.location.back();
    }

    public articleCompleted(step: Step): void {
        this.feedback.onClickDone();

        this.store.dispatch(
            setStepCompleted({
                properties: {
                    title: step.content.title,
                    stepId: step.id,
                    contentType: step.content.contentType,
                    contentId: step.content.id,
                    topicId: step.topic.title,
                },
                correlationId: this.fromQueryParams() === 'courses' ? TRIGGERED_FROM_STEP_CORRELATION_ID : undefined,
            }),
        );

        this.routeToPreviousPage();
    }

    public openPaymentModal(): void {
        this.store.dispatch(showPaymentModal({ trigger: 'article-page' }));
    }

    public onFeedbackSubmitted(feedback: UserFeedbackContent): void {
        this.store.dispatch(
            createUserFeedbackContent({
                feedback: {
                    contentTypeId: FeedbackContentTypeIdEnum.ARTICLE,
                    contentfulId: feedback.contentfulId,
                    thumbsDown: feedback.thumbsDown,
                    thumbsUp: feedback.thumbsDown === null ? undefined : !feedback.thumbsDown,
                    comment: feedback.comment,
                },
                feedbackType: FeedbackContentTypeEnum.ARTICLE,
            }),
        );
    }
}
