<ion-content class="prevent-diarrhoea-modal" [color]="Color.Maggie">
    <button
        class="xl prevent-diarrhoea-modal__close-button"
        appTrackClick
        identifier="nourish-prevent-diarrhoea-modal-close-btn"
        (click)="onCloseClick()"
    >
        <i class="fa-light fa-xmark"></i>
    </button>
    <article class="prevent-diarrhoea-modal__article page-padding-sides page-padding-bottom ignore-global-color">
        <h2>How to prevent {{ diarrhoeaText() }}?</h2>
        <img
            class="prevent-diarrhoea-modal__illustration"
            src="/assets/images/buddy/buddy-diarrhoea.svg"
            alt="Buddy looks sad sitting next to diarrhoea"
        />
        <p>Prevent {{ diarrhoeaText() }} in your pup by taking simple, proactive steps:</p>

        <ol [ngClass]="Color.AppOutline" class="prevent-diarrhoea-modal__list no-margin-top">
            <li class="prevent-diarrhoea-modal__list-item">
                <h3 class="no-margin">
                    <span class="prevent-diarrhoea-modal__list-number">①</span> Stick to a consistent diet
                </h3>
                <p class="no-margin">
                    Sudden food changes can upset sensitive tummies. If switching foods, transition gradually over 7–10
                    days.
                </p>
            </li>

            <hr class="prevent-diarrhoea-modal__list-break" />

            <li class="prevent-diarrhoea-modal__list-item">
                <h3 class="no-margin">
                    <span class="prevent-diarrhoea-modal__list-number">②</span> Avoid table scraps
                </h3>
                <p class="no-margin">
                    Human food, especially fatty or spicy items, can trigger digestive upset. Stick to dog-friendly
                    treats!
                </p>
            </li>

            <hr class="prevent-diarrhoea-modal__list-break" />

            <li class="prevent-diarrhoea-modal__list-item">
                <h3 class="no-margin">
                    <span class="prevent-diarrhoea-modal__list-number">③</span> Puppy-proof your home
                </h3>
                <p class="no-margin">
                    Remove access to harmful or toxic items, like garbage, houseplants, or chemicals. Puppies love to
                    explore!
                </p>
            </li>

            <hr class="prevent-diarrhoea-modal__list-break" />

            <li class="prevent-diarrhoea-modal__list-item">
                <h3 class="no-margin">
                    <span class="prevent-diarrhoea-modal__list-number">④</span> Keep them worm-free
                </h3>
                <p class="no-margin">Regular deworming as recommended by your vet prevents parasite-related issues.</p>
            </li>

            <hr class="prevent-diarrhoea-modal__list-break" />

            <li class="prevent-diarrhoea-modal__list-item">
                <h3 class="no-margin">
                    <span class="prevent-diarrhoea-modal__list-number">⑤</span> Monitor treats & chews
                </h3>
                <p class="no-margin">
                    Overindulging in treats or certain chews can overwhelm a young digestive system.
                </p>
            </li>
        </ol>

        <p class="prevent-diarrhoea-modal__highlighted-text no-margin-top">
            💡 Always provide fresh water and consult your vet if {{ diarrhoeaText() }} persists for more than 24 hours
            or includes blood. Prevention keeps tails wagging! 🐕
        </p>

        @if (isUsUser()) {
            <app-nourish-pet-food-finder
                [shouldShowHeading]="true"
                [origin]="NourishFoodFinderOriginId.PREVENT_DIARRHOEA_MODAL"
            />
        }

        <br />

        <ui-generic-feedback
            [autoSubmitOnChange]="true"
            [contentfulId]="currentInsightsModalId() ?? ''"
            [existingRating]="userFeedback()"
            [feedbackOptions]="DefaultFeedbackOptions"
            (feedbackSubmitted)="onFeedbackSubmitted($event)"
        />
    </article>
</ion-content>
