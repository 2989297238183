import { AsyncPipe, NgClass, NgOptimizedImage, NgStyle } from '@angular/common';
import { Component, inject } from '@angular/core';
import { routeTo } from '@frontend/data-access/router';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { ProfileImageComponent, ProfileImageType } from '@frontend/feature/profile-picture';
import { EndorsementsComponent } from '@frontend/ui/endorsements';
import { AgePipe } from '@frontend/ui/pipes';
import { IonButton, IonContent } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { SHOW_PAYMENT_MODAL_TRIGGERS } from '../../../store/payment/constants/payment.constants';
import { showPaymentModal } from '../../../store/payment/store/payment.actions';
import { selectCoursePreviewPageVm } from './course-preview.page.selectors';

@Component({
    selector: 'app-onboarding-course-preview',
    templateUrl: './course-preview.page.html',
    styleUrl: './course-preview.page.scss',
    imports: [
        NgClass,
        NgOptimizedImage,
        NgStyle,
        AsyncPipe,
        IonButton,
        IonContent,
        AgePipe,
        AnalyticsTrackClickDirective,
        EndorsementsComponent,
        ProfileImageComponent,
        TextReplacementPipe,
    ],
})
export class OnboardingCoursePreviewPage {
    public readonly store = inject(Store);

    public vm$ = this.store.select(selectCoursePreviewPageVm);

    protected readonly ProfileImageType = ProfileImageType;

    public next(hasPartner: boolean, contentUnlocked: boolean) {
        if (!hasPartner || !contentUnlocked) {
            this.store.dispatch(showPaymentModal({ trigger: SHOW_PAYMENT_MODAL_TRIGGERS.COURSE_PREVIEW_PAGE }));
        }

        this.store.dispatch(routeTo({ dtos: ['main', 'today'] }));
    }
}
