import { DomainPatchDogDto } from '../../../dtos/user/dog/domain-patch-dog.dto';

export class DomainPatchDogDtoMock {
    private defaultValue: DomainPatchDogDto = {
        name: 'Bella',
    };

    constructor(overrides?: Partial<DomainPatchDogDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): DomainPatchDogDto {
        return this.defaultValue;
    }
}
