<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <ui-progress-header
            [showBackButton]="!!previousOnboardingPage()"
            [progressRatio]="progress()"
            [isDisabled]="isLoading()"
            (backButtonClicked)="onBackClicked()"
        />
    </ion-toolbar>
</ion-header>

<ion-content class="onboarding-ion-content" [fullscreen]="true">
    <main class="owner-name-page page-content ion-justify-content-start">
        <section>
            <h1>What is your name?</h1>
            <p data-test="question-owner-name-subtitle">
                We'd love to refer to the dynamic duo as {{ dogName() }} and {{ ownerNameForm.controls.ownerName.value
                || ' ...' }}
            </p>
        </section>
        <form
            class="onboarding-form onboarding-form--harley"
            id="ownerNameForm"
            [formGroup]="ownerNameForm"
            (ngSubmit)="onSubmit()"
        >
            <ion-item class="ion-margin-top">
                <i slot="start" class="fa-regular fa-user onboarding-form__input-icon"></i>
                <ion-input
                    class="zz-input"
                    appTrackClick
                    identifier="question-owner-name-input"
                    autocapitalize="words"
                    autocomplete="given-name"
                    placeholder="Your name"
                    formControlName="ownerName"
                    type="text"
                    data-test="question-owner-name-input"
                    [tabindex]="1"
                    [maxlength]="OWNER_NAME_MAX_LENGTH"
                >
                </ion-input>
            </ion-item>
        </form>
    </main>
</ion-content>

<ion-footer class="ion-text-center page-footer">
    <ion-button
        class="ion-button-color-max"
        appTrackClick
        identifier="question-owner-name-next-btn"
        expand="block"
        type="submit"
        form="ownerNameForm"
        data-test="question-owner-name-next-btn"
        [disabled]="isLoading() || ownerNameForm.invalid"
    >
        @if (isLoading()) {
        <ion-spinner name="crescent" />
        } @else { Next }
    </ion-button>
</ion-footer>
