<ion-card class="image-card">
    <div class="card-image" [style.background-image]="'url(' + content().imageUrl + ')'"></div>
    <ion-card-header class="card-header">
        <h3 class="card-title ignore-screen-based-font-scaling" data-test="image-card-title">
            {{ content().title | textReplacement }}
        </h3>
    </ion-card-header>
    <ion-card-content class="card-content">
        <img class="author-image" src="assets/images/trainers/{{ content().author.split(' ').join('_') }}.jpg" />
        <p class="author-text xs" data-test="image-card-author">{{ content().author }}, Zigzag training coach</p>
    </ion-card-content>
</ion-card>
