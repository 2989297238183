import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BASE_ENVIRONMENT_CONFIG, BaseEnvironmentConfig } from '@frontend/configuration';
import { httpOptions } from '@frontend/utility/constants';
import {
    InternalHappinessCheckDto,
    internalPaths,
    InternalPostHappinessCheckDto,
} from '@shared/internal-api-interface';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class HappinessCheckService {
    private readonly http = inject(HttpClient);
    private readonly environment = inject<BaseEnvironmentConfig>(BASE_ENVIRONMENT_CONFIG);

    public postHappinessCheck(dto: InternalPostHappinessCheckDto): Observable<InternalHappinessCheckDto> {
        return this.http.post<InternalHappinessCheckDto>(
            `${this.environment.internalApiUrl}/api/${internalPaths.happinessCheckPath}`,
            dto,
            httpOptions,
        );
    }
}
