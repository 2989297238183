import { inject, Injectable, NgZone } from '@angular/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { Platform } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { CapacitorPushActions } from '../constants/push-listeners.constants';
import {
    capacitorPushNotificationOpened,
    capacitorPushNotificationReceived,
    capacitorPushNotificationTokenReceived,
} from '../store/push-listeners.actions';

@Injectable({
    providedIn: 'root',
})
export class PushListenersService {
    private readonly store = inject(Store);
    private readonly ngZone = inject(NgZone);
    private readonly platform = inject(Platform);

    public async addPushNotificationListeners(): Promise<void> {
        if (this.platform.is('ios') || this.platform.is('android')) {
            await PushNotifications.addListener('registration', (token) => {
                this.ngZone.run(() => {
                    this.store.dispatch(capacitorPushNotificationTokenReceived({ token: token.value }));
                });
            });

            await PushNotifications.addListener('registrationError', (err) => {
                console.error('Registration error: ', err.error);
            });

            await PushNotifications.addListener('pushNotificationReceived', (notification) => {
                this.ngZone.run(() => {
                    this.store.dispatch(capacitorPushNotificationReceived({ id: notification.id }));
                });
            });

            await PushNotifications.addListener('pushNotificationActionPerformed', ({ notification, actionId }) => {
                if (actionId === CapacitorPushActions.OPENED) {
                    this.ngZone.run(() => {
                        if (this.platform.is('android')) {
                            const parsedNotification = JSON.parse(notification.data.itbl, (key, value) => {
                                if (key === 'messageId') {
                                    return String(value);
                                }
                                return value;
                            });

                            this.store.dispatch(
                                capacitorPushNotificationOpened({
                                    campaignId: parsedNotification.campaignId,
                                    messageId: parsedNotification.messageId,
                                    templateId: parsedNotification.templateId,
                                    urlToOpen: parsedNotification.defaultAction.data,
                                }),
                            );
                        } else {
                            this.store.dispatch(
                                capacitorPushNotificationOpened({
                                    campaignId: notification.data.itbl.campaignId,
                                    messageId: notification.data.itbl.messageId,
                                    templateId: notification.data.itbl.templateId,
                                    urlToOpen: notification.data.itbl.defaultAction.data,
                                }),
                            );
                        }
                    });
                }
            });
        }

        console.info('[PushListenersService] addPushNotificationListeners - method_not_available_on_web');
        return;
    }
}
