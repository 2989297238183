import { AfterContentInit, Component, input, output } from '@angular/core';
import { VisibleHeightPipe } from '@frontend/ui/pipes';
import { IonButton } from '@ionic/angular/standalone';
import { Color } from '@shared/utils/typescript';

@Component({
    selector: 'ui-no-access',
    templateUrl: './no-access.component.html',
    styleUrl: './no-access.component.scss',
    imports: [IonButton, VisibleHeightPipe],
})
export class NoAccessComponent implements AfterContentInit {
    public openPaymentModal = output();

    public title = input.required<string>();
    public subTitle = input.required<string>();
    public buttonText = input.required<string>();

    public icon = input<string>('fa-regular fa-crown');
    public buttonBackground = input<Color>(Color.Max);

    public clientRects: DOMRect = {} as DOMRect;

    public ngAfterContentInit(): void {
        //  This directive is used to manually set the height of an element based on window height
        //  and it's position in the DOM. Main usage at the moment is the no-access component, ensuring that
        //  the content inside is relatively-positioned centrally to avoid inline hard-coded padding/margin
        setTimeout(() => {
            //  Based on the way Ionic routing works by leaving behind the parent element's DOM in place,
            //  we need to ensure that we are always selecting the last item in the array as that will
            //  always be the current view
            const elems = document.getElementsByClassName('no-access');
            const elem = elems[elems.length - 1];

            this.clientRects = elem!.getBoundingClientRect();
        });
    }

    public triggerPaymentModal(): void {
        this.openPaymentModal.emit();
    }
}
