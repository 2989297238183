import { Component, forwardRef, input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Weekday } from '@capacitor/local-notifications';
import { WeekdayButton } from '../../models/training-reminder.model';

@Component({
    selector: 'app-weekday-buttons',
    templateUrl: './weekday-buttons.component.html',
    styleUrl: './weekday-buttons.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => WeekdayButtonsComponent),
            multi: true,
        },
    ],
})
export class WeekdayButtonsComponent implements ControlValueAccessor {
    public days = input<WeekdayButton[]>([
        {
            label: 'M',
            value: Weekday.Monday,
        },
        {
            label: 'T',
            value: Weekday.Tuesday,
        },
        {
            label: 'W',
            value: Weekday.Wednesday,
        },
        {
            label: 'T',
            value: Weekday.Thursday,
        },
        {
            label: 'F',
            value: Weekday.Friday,
        },
        {
            label: 'S',
            value: Weekday.Saturday,
        },
        {
            label: 'S',
            value: Weekday.Sunday,
        },
    ]);

    public value: Weekday[] = [];

    public onTouched = () => {
        /* Do nothing */
    };

    public onChange: (value: Weekday[]) => void = () => {
        /* Do nothing */
    };

    public writeValue(value: Weekday[]): void {
        this.value = value;
    }

    public registerOnChange(onChange: (value: Weekday[]) => void): void {
        this.onChange = onChange;
    }

    public registerOnTouched(onTouched: () => void): void {
        this.onTouched = onTouched;
    }

    public isSelected(value: Weekday): boolean {
        return this.value.includes(value);
    }

    public onOptionSelected(selectedValue: Weekday): void {
        if (this.isSelected(selectedValue)) {
            this.value = this.value.filter((value) => value !== selectedValue);
        } else {
            this.value = [...this.value, selectedValue];
        }

        this.onChange(this.value);
        this.onTouched();
    }
}
