import { Component, input } from '@angular/core';
import { DailyBite } from '@frontend/data-access/contentful';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { IonCard, IonCardContent, IonCardHeader } from '@ionic/angular/standalone';

@Component({
    selector: 'app-daily-bite-card',
    templateUrl: './daily-bite-card.component.html',
    styleUrl: './daily-bite-card.component.scss',
    imports: [IonCard, IonCardHeader, IonCardContent, TextReplacementPipe],
})
export class DailyBiteCardComponent {
    public content = input.required<DailyBite>();
}
