import { RadioWithExplanationResult } from '@frontend/ui/radio-with-explanation';
import { createAction, props } from '@ngrx/store';
import { CourseSplit } from '../../models/courses.models';

export const showJumpAheadModal = createAction(
    '[Jump Ahead Modal] Show Jump Ahead Modal',
    props<{ split: CourseSplit }>(),
);

export const startNextPartClicked = createAction(
    '[Jump Ahead Modal] Start Next Part Clicked',
    props<{
        radioResult: RadioWithExplanationResult;
        split: CourseSplit;
    }>(),
);
