<section class="chat-message-rating-feedback-heading">
    <img
        class="chat-message-rating-feedback-heading__image"
        alt="Ziggy reading a book"
        [src]="'/assets/images/ziggy/ziggy-reading-close-up.svg'"
    />
    <h3 class="chat-message-rating-feedback-heading__title">Why did you choose this rating?</h3>
</section>

<section class="chat-message-rating-feedback-options">
    @for (option of feedbackOptions(); track option.key) {
        <button
            class="zz-button chat-message-rating-feedback-options__option"
            [ngClass]="{
                'chat-message-rating-feedback-options__option--selected': option.key === selectedFeedbackOption?.key,
            }"
            [attr.data-test]="'chat-message-rating-feedback-option-' + option.key"
            (click)="onClickFeedbackOption(option)"
        >
            {{ option.value }}
        </button>
    }
</section>

@if (shouldShowCommentInput) {
    <ion-item class="chat-message-rating-feedback-comment">
        <ion-input
            class="chat-message-rating-feedback-comment__input"
            autocapitalize="sentences"
            placeholder="Please add your comment"
            type="text"
            data-test="chat-message-rating-feedback-comment-input"
            [tabindex]="1"
            [formControl]="commentFormControl"
        />
    </ion-item>
}

<section class="chat-message-rating-feedback-footer">
    <ion-button
        class="chat-message-rating-feedback-footer__done"
        size="small"
        expand="block"
        data-test="chat-message-rating-feedback-done"
        [color]="Color.Max"
        (click)="onClickDone()"
    >
        Done
    </ion-button>
</section>
