import { SingleSelectOption } from './single-select.model';

export class SingleSelectOptionMock {
    private defaultValue: SingleSelectOption = {
        title: 'single select',
        value: 'value',
    };

    constructor(overrides?: Partial<SingleSelectOption>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): SingleSelectOption {
        return this.defaultValue;
    }
}
