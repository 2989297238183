import { Component, inject } from '@angular/core';
import { routeTo } from '@frontend/data-access/router';
import { householdFeature } from '@frontend/data-access/user/household';
import { ProfileImageComponent, ProfileImageType } from '@frontend/feature/profile-picture';
import { Store } from '@ngrx/store';
import { DevelopmentalWindowTimelineComponent } from './developmental-window-timeline/developmental-window-timeline.component';
import { selectShowDevelopmentalWindows } from './today-developmental-window.component.selectors';

@Component({
    selector: 'app-today-developmental-window',
    templateUrl: './today-developmental-window.component.html',
    styleUrl: './today-developmental-window.component.scss',
    imports: [DevelopmentalWindowTimelineComponent, ProfileImageComponent],
})
export class TodayDevelopmentalWindowComponent {
    private readonly store = inject(Store);

    public ownerName = this.store.selectSignal(householdFeature.selectOwnerName);
    public dogName = this.store.selectSignal(householdFeature.selectDogName);
    public showDevelopmentalWindows = this.store.selectSignal(selectShowDevelopmentalWindows);

    protected readonly ProfileImageType = ProfileImageType;

    public routeToProfile(): void {
        void this.store.dispatch(
            routeTo({
                dtos: ['main/today/profile'],
            }),
        );
    }
}
