import { Injectable } from '@angular/core';
import { CustomerInfo } from '@awesome-cordova-plugins/purchases/ngx';
import { Observable } from 'rxjs';
import { SubscriptionOffering } from './store/models/subscription.model';

@Injectable()
export abstract class SubscriptionService {
    public abstract initialize(id?: string): void;

    public abstract logInToPurchases(id: string, email: string): Observable<{ customerInfo: CustomerInfo }>;

    public abstract getEntitlements(): Observable<CustomerInfo>;

    public abstract getOfferings(): Observable<SubscriptionOffering[]>;

    public abstract purchaseProduct(id: string): Observable<{ productIdentifier: string; customerInfo: CustomerInfo }>;

    public abstract collectDeviceIdentifiers(id: string): void;

    public abstract restoreTransactions(): void;

    public abstract syncPurchases(): void;

    public abstract presentPromoCodePrompt(): void;

    public abstract invalidateCustomerInfoCache(): void;
}
