import { IsBoolean, IsOptional, IsString } from 'class-validator';

export class DomainPostUserFeedbackContentDto {
    @IsString()
    userId!: string;

    @IsString()
    contentfulId!: string;

    @IsString()
    contentTypeId!: string;

    @IsOptional()
    @IsString()
    comment?: string | null;

    @IsOptional()
    @IsBoolean()
    thumbsDown?: boolean | null;
}
