<ion-content class="pup-not-eating-modal" [color]="Color.AppBackground">
    <button
        class="xl pup-not-eating-modal__close-button"
        appTrackClick
        identifier="nourish-pup-not-eating-modal-close-btn"
        (click)="onCloseClick()"
    >
        <i class="fa-light fa-xmark"></i>
    </button>
    <article class="pup-not-eating-modal__content page-padding-sides page-padding-bottom ignore-global-color">
        <section class="pup-not-eating-modal__top-section">
            <h2 class="pup-not-eating-modal__text">Why is my pup not eating?</h2>

            <img src="/assets/images/nourish/golden-retriever-puppy.png" alt="a golden retriever puppy" />

            <ui-author-chip
                [authorImageUrl]="'assets/images/trainers/coach-petrina-red-circle.png'"
                [author]="'Petrina Firth'"
            ></ui-author-chip>
        </section>

        <section>
            <h3>
                When your pup stops eating, it may well be that they’ve decided that they’re full or have simply stopped
                fancying the food you’ve been feeding them. It’s understandable – they do eat the same thing every day
                after all. However, it’s important to rule out the potential of an underlying health problem.
            </h3>

            <p>
                Whether it’s something to worry about or not, we’ll help go through some of the most common reasons your
                pup isn’t eating, what you should do if your pup won’t eat, and how to rule out any health problems that
                might be connected to it. We’ve also added some tips and tricks to make mealtimes more fun and keep it
                interesting for your pup. As if they weren’t exciting enough.
            </p>

            <figure>
                <img
                    src="/assets/images/nourish/puppy-bowl-treat.png"
                    alt="a puppy looks at the camera while a trainer holds a treat above their head"
                />

                <figcaption class="pup-not-eating-modal__caption">
                    Photo by
                    <a
                        href="https://unsplash.com/@typowy?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
                        target="_blank"
                        >Kacper Chrzanowski</a
                    >
                    on
                    <a
                        href="https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
                        target="_blank"
                        >Unsplash</a
                    >
                </figcaption>
            </figure>
        </section>

        <section>
            <h3 class="pup-not-eating-modal__text">5 common reasons to keep in mind</h3>

            <p>If you’re concerned about why your pup isn’t eating, the first step is not to panic.</p>

            <p>Here are some of the most common reasons why your pup doesn’t want to eat their regular food.</p>

            <ol class="pup-not-eating-modal__list page-padding-sides">
                <li>
                    <h3>Your pup doesn’t like their regular food</h3>
                    <p class="pup-not-eating-modal__text">
                        Yes, puppies have a sense of taste. Food left out all day can easily make food go rancid and
                        taste rather bad to your pup. It might just smell like regular dog food to us, but you can’t
                        fool a dog’s nose. They’ll know it’s yucky.
                    </p>
                </li>

                <li>
                    <h3>Your pup has a sore mouth and doesn’t want to eat hard food</h3>
                    <p class="pup-not-eating-modal__text">
                        When your puppy is
                        <a
                            class="pup-not-eating-modal__link"
                            href="https://zigzag.dog/blog/puppy-health/grooming/puppy-teething/"
                            target="_blank"
                            >teething</a
                        >, their teeth and gums will be in quite some pain, so it can be challenging for them to find
                        their food enjoyable. Sometimes they won’t even want to eat, even if it’s wet food! Kinda says a
                        lot about teething, doesn’t it?
                    </p>
                </li>

                <li>
                    <h3>The weather is hot</h3>
                    <p class="pup-not-eating-modal__text">
                        Just like humans, in hot weather, puppies will often not want to eat. This is quite common since
                        they’ll slow down and become less active, making them not want to eat as much.
                    </p>
                </li>

                <li>
                    <h3>Your pup is stressed or upset</h3>
                    <p class="pup-not-eating-modal__text">
                        Stress can make puppies not want to eat, unlike lots of us who find comfort in endless Jaffa
                        Cakes. Perhaps they are in a new environment and are in the midst of settling in with their new
                        family. Give them some time! They still have to develop a taste for you.
                    </p>
                </li>

                <li>
                    <h3>Maybe your pup doesn’t like a certain bowl</h3>
                    <p class="pup-not-eating-modal__text">
                        Some puppies can be fussy about the
                        <a
                            class="pup-not-eating-modal__link"
                            href="https://zigzag.dog/blog/new-puppy/puppy-essentials/best-puppy-bowls/"
                            arget="_blank"
                        >
                            bowl
                        </a>
                        they eat from. Yep, puppies can be picky too. Some get startled by their ID tags clinking on
                        china or ceramic bowls, and to others, stainless steel might not do the trick. Some dogs with
                        big ears might prefer a narrower bowl so their ears don’t get in their food. Bet you didn’t know
                        you’d face this kind of trouble with your new pup!
                    </p>
                </li>
            </ol>

            <figure>
                <img
                    src="/assets/images/nourish/dog-with-bowl.png"
                    alt="beautiful dog with a food bowl on white background"
                />

                <figcaption class="pup-not-eating-modal__caption">
                    Photo by
                    <a
                        href="https://unsplash.com/@theflouffy?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
                        target="_blank"
                        >FLOUFFY</a
                    >
                    on
                    <a
                        href="https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
                        target="_blank"
                    >
                        Unsplash
                    </a>
                </figcaption>
            </figure>
        </section>

        <section>
            <h3 class="pup-not-eating-modal__text">What to do when your pup won’t eat</h3>

            <p>
                When your pup decides that their kibble just isn’t doing it, there are a few changes you can make to
                encourage them to eat. As long as this hasn’t been going on for an extended period, try these things
                out! If you think your pup has been fussy about food for longer than normal, going for a quick trip to
                the vets won’t hurt.
            </p>

            <ul class="pup-not-eating-modal__list-unordered">
                <li>
                    <h3>Try a different type or brand of food to get your pup to eat</h3>
                    <p class="pup-not-eating-modal__text">
                        If your pup is eating kibble, don’t be afraid to try a different {{ flavourText() }} or brand.
                        You can’t be too surprised about puppies having a sense of taste, right? You can even mix some
                        wet food in to soften it up. Imagine eating cornflakes without milk. See what we mean?
                    </p>
                </li>

                <li>
                    <h3>Wet the pup's food if you feel your pup might be teething</h3>
                    <p class="pup-not-eating-modal__text">
                        Pop some warm (not hot) water on your puppy’s kibble to soften it slightly. If your puppy is
                        teething or they’re
                        <a
                            class="pup-not-eating-modal__link"
                            href="https://zigzag.dog/blog/puppy-health/grooming/puppy-teething/#:~:text=By%2012%20weeks%20old%20those%20milk%20teeth%20will%20start%20to%20fall%20out."
                            target="_blank"
                        >
                            losing teeth
                        </a>
                        , then they appreciate having slightly softer food.
                    </p>
                </li>

                <li>
                    <h3>Experiment with a different food bowl and see if it helps your pup eat</h3>
                    <p class="pup-not-eating-modal__text">
                        There are many
                        <a
                            class="pup-not-eating-modal__link"
                            href="https://zigzag.dog/blog/new-puppy/puppy-essentials/best-puppy-bowls/"
                            target="_blank"
                        >
                            different types of pup bowls
                        </a>
                        to choose from. Try a different height, material, size or depth of pup bowl and see if it suits
                        them more. We all have that favourite mug, don’t we? It’s only fair they get to pick their bowl.
                    </p>
                </li>

                <li>
                    <h3>Pick out a puzzle toy or interactive feeder</h3>
                    <p class="pup-not-eating-modal__text">
                        <a
                            class="pup-not-eating-modal__link"
                            href="https://zigzag.dog/blog/new-puppy/games-and-toys/puppy-puzzle-toys/"
                            target="_blank"
                        >
                            Puzzle toys </a
                        >, interactive feeders, and
                        <a
                            class="pup-not-eating-modal__link"
                            href="https://zigzag.dog/blog/new-puppy/games-and-toys/why-puppy-brain-games-help-your-pet/"
                            target="_blank"
                            >brain games</a
                        >
                        can make food seem more interesting to them by turning it into a game – a fun puzzle for your
                        pup to solve. Fancy dog experts call this ‘’contrafreeloading’’; where dogs show a preference
                        for eating from a problem-solving toy, rather than being freely offered food. It’s kind of like
                        when you feed babies, you know doing aeroplane sounds with a spoon.
                    </p>
                </li>

                <li>
                    <h3>Take your pup out to the toilet</h3>
                    <p>
                        Pups aren’t likely to eat on a full tummy. You’ll likely face puppies not feeling like food in
                        the morning if they haven’t had a poo since the previous day. Gotta make room first, right?
                    </p>
                    <p>
                        If your pup is sick, has diarrhoea, is not drinking water or seems lethargic, please take them
                        to the vet. While puppies not eating can have common causes, young puppies are still sensitive
                        and need to eat and drink regularly.
                    </p>
                </li>

                <li>
                    <h3>How do I rule out any serious health issues?</h3>
                    <p>
                        If you have tried the suggestions above but your pup is still refusing to eat, then you should
                        likely seek veterinary advice.
                    </p>
                    <p>
                        Call your Vet surgery and have a chat with the staff. Trust us. You’ll feel much better after
                        talking to them instead of reading what their symptoms on Google might mean. When has Google
                        ever made you feel any better when searching for symptoms anyway?
                    </p>
                </li>
            </ul>

            <figure>
                <img
                    src="/assets/images/nourish/french-bulldog-with-bowl.png"
                    alt="A french bulldog puppy stands next to a food bowl and looks at the camera"
                />

                <figcaption class="pup-not-eating-modal__caption">
                    Photo by
                    <a
                        href="https://unsplash.com/@karsten116?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
                        target="_blank"
                    >
                        Karsten Winegeart
                    </a>
                    on
                    <a
                        class="pup-not-eating-modal__link"
                        href="https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
                        target="_blank"
                    >
                        Unsplash
                    </a>
                </figcaption>
            </figure>
        </section>

        <section>
            <h3 class="pup-not-eating-modal__text">How do I keep my pup’s food interesting?</h3>

            <p>
                One of the best ways to make your pup’s food more interesting is by changing how it’s being served. We
                can use their regular food to teach
                <a
                    class="pup-not-eating-modal__link"
                    href="https://zigzag.dog/blog/new-puppy/games-and-toys/puppy-training-games-library/"
                    target="_blank"
                >
                    tricks
                </a>
                , put them in
                <a
                    class="pup-not-eating-modal__link"
                    target="_blank"
                    href="https://zigzag.dog/blog/new-puppy/games-and-toys/puppy-puzzle-toys/"
                >
                    puzzle toys
                </a>
                , or do some
                <a
                    class="pup-not-eating-modal__link"
                    href="https://zigzag.dog/blog/new-puppy/games-and-toys/puppy-training-games-library/"
                    target="_blank"
                >
                    scent or training games </a
                >.
            </p>

            <p>
                If your pup normally eats their daily food happily, it’s a good idea to keep feeding them like you are
                without new bowls or puzzle toys. That way you’ll avoid turning them into fussy eaters. They could be
                left thinking ‘what else have you got for me’ and might wait for you to offer something better like
                cooked chicken, or a magician show while they eat. Clever puppies!
            </p>

            <p>
                Dogs do not starve themselves, so if you’ve done everything to figure out why your pup is not eating,
                try putting the food down for 10 minutes and allowing your pup a chance to eat. If they’re choosing to
                stay away, pick the bowl back up and provide them a fresh meal at their next scheduled time. They’ll
                most likely be more hungry by then and will gladly eat! I know, it sounds a bit mean, but dogs should
                feast and famine rather than graze all day. They aren’t sheep after all. For puppies,
                <a
                    class="pup-not-eating-modal__link"
                    target="_blank"
                    href="https://zigzag.dog/blog/new-puppy/getting-your-puppy/ideal-puppy-training-routine/"
                >
                    routines
                </a>
                will help them tremendously in general – they’ll also help with
                <a
                    class="pup-not-eating-modal__link"
                    href="https://zigzag.dog/blog/puppy-training/toilet-training/how-to-toilet-train-a-puppy-full-guide/"
                    target="_blank"
                >
                    toilet training
                </a>
                and
                <a
                    class="pup-not-eating-modal__link"
                    href="https://zigzag.dog/blog/puppy-behaviour/separation-and-sleep/how-to-get-a-puppy-to-sleep-through-the-night/"
                    target="_blank"
                >
                    sleep training </a
                >.
            </p>

            <p>
                If they’re not a picky eater and you just want to make their food have more variety, then you can look
                at cooking it yourself. Lucky pup, getting a gourmet treatment. Check out our guide to
                <a
                    class="pup-not-eating-modal__link"
                    href="https://zigzag.dog/blog/puppy-health/feeding/homemade-puppy-food-and-treats/"
                    target="_blank"
                    >homemade puppy food and treats</a
                >
                for inspiration.
            </p>

            <p>
                We’ve made it to the end! We hope that ‘Why is my puppy not eating?’ is a question of the past. As you
                saw, maybe it’s just a matter of changing a few things around.
            </p>

            <p>
                Keep your vet close though – you want to make sure it’s nothing serious and it’s just that your puppy
                prefers turkey to chicken.
            </p>
        </section>

        <br />

        <ui-generic-feedback
            [autoSubmitOnChange]="true"
            [contentfulId]="currentInsightsModalId() ?? ''"
            [existingRating]="userFeedback()"
            [feedbackOptions]="DefaultFeedbackOptions"
            (feedbackSubmitted)="onFeedbackSubmitted($event)"
        />
    </article>
</ion-content>
