import { Injectable } from '@angular/core';
import { MultiFactorInfo } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { TotpSecret, User } from '../models/user.model';

@Injectable()
export abstract class AuthenticationService {
    abstract readonly token$: Observable<string | null>;

    abstract get user(): User | undefined;

    abstract initialize(): void;

    abstract signUpWithEmailAndPassword(email: string, password: string): Observable<{ user: User }>;

    abstract loginWithEmailAndPassword(email: string, password: string): Observable<{ user: User }>;

    abstract loginWithEmailAndPasswordAndTotp(email: string, password: string, code: string): Promise<{ user: User }>;

    abstract loginWithGoogle(): Observable<any>;

    abstract loginWithApple(): Observable<any>;

    abstract logout(): Observable<void>;

    abstract isLoggedIn(): Observable<boolean>;

    abstract requestPasswordReset(email: string): Observable<void>;

    abstract getSignInProvider(): Observable<string>;

    abstract generateTOTPSecret(): Promise<TotpSecret>;

    abstract getMultiFactorInfo(): Promise<MultiFactorInfo[]>;

    abstract enrollUserTOTP(verificationCode: string): Promise<void>;

    abstract unenrollMultiFactor(id: string): Promise<void>;
}
