import { ValidationErrors } from '@angular/forms';
import { differenceInDays, differenceInMonths } from 'date-fns';
import { mapDateOfBirthToAgeInWeeks } from '@shared/utils/typescript';

export const formatCountdown = (timestamp: number) => {
    const hours = Math.floor(timestamp / (1000 * 60 * 60));
    const minutes = Math.floor((timestamp % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timestamp % (1000 * 60)) / 1000);

    const paddedHours = String(hours).padStart(2, '0');
    const paddedMinutes = String(minutes).padStart(2, '0');
    const paddedSeconds = String(seconds).padStart(2, '0');

    return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
};

export const mapDateOfBirthToAgeInMonths = (
    dateOfBirth: Date | undefined | string,
    dateOfReference: Date = new Date(),
): number => {
    if (dateOfBirth === undefined) {
        return 0;
    }

    let dateOfBirthAsDate: Date;

    if (typeof dateOfBirth === 'string') {
        dateOfBirthAsDate = new Date(dateOfBirth);
    } else {
        dateOfBirthAsDate = dateOfBirth;
    }

    return differenceInMonths(dateOfReference, dateOfBirthAsDate);
};

export const mapDateOfBirthToAgeString = (
    dateOfBirth: Date | undefined | string,
    dateOfReference: Date = new Date(),
    shouldPluralise = true,
): string => {
    if (!dateOfBirth) {
        return '';
    }

    const ageInDays = mapDateOfBirthToAgeInDays(dateOfBirth, dateOfReference);

    if (ageInDays < 7) {
        return `${ageInDays} day${ageInDays === 1 || !shouldPluralise ? '' : 's'}`;
    }

    const ageInWeeks = mapDateOfBirthToAgeInWeeks(dateOfBirth, dateOfReference);

    if (ageInWeeks < 17) {
        return `${ageInWeeks} week${ageInWeeks === 1 || !shouldPluralise ? '' : 's'}`;
    }

    const ageInMonths = mapDateOfBirthToAgeInMonths(dateOfBirth, dateOfReference);

    return `${ageInMonths} month${ageInMonths === 1 || !shouldPluralise ? '' : 's'}`;
};

export const mapDateOfBirthToAgeInDays = (
    dateOfBirth: Date | undefined | string,
    dateOfReference: Date = new Date(),
): number => {
    if (dateOfBirth === undefined) {
        return 0;
    }

    let dateOfBirthAsDate: Date;

    if (typeof dateOfBirth === 'string') {
        dateOfBirthAsDate = new Date(dateOfBirth);
    } else {
        dateOfBirthAsDate = dateOfBirth;
    }

    return differenceInDays(dateOfReference, dateOfBirthAsDate);
};

export const getDateInStreamChatFormat = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}+00:00`;
};

export const maxDate = (date: Date) => {
    return (value: string | null | undefined): ValidationErrors => {
        if (!value || stripTime(value) > stripTime(date)) {
            return { invalidMaxDate: true } as ValidationErrors;
        }

        return {};
    };
};

export const minDate = (date: Date) => {
    return (value: string | null | undefined): ValidationErrors => {
        if (!value || stripTime(value) < stripTime(date)) {
            return { invalidMinDate: true } as ValidationErrors;
        }

        return {};
    };
};

export const stripTime = (date: Date | string): Date => {
    const newDate = new Date(date).setHours(12, 0, 0, 0);

    return new Date(newDate);
};

export const mapDateToIterableDateFormat = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};
