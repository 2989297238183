import { Pipe, PipeTransform } from '@angular/core';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS } from '@contentful/rich-text-types';

@Pipe({
    name: 'richContent',
})
export class RichContentPipe implements PipeTransform {
    transform(richText: any): string {
        const options = {
            renderNode: {
                [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
                    if (!node.data.target || !node.data.target.fields) {
                        return '';
                    }

                    const { title, file } = node.data.target.fields;
                    const imageUrl = `https:${file.url}`;

                    return `<img class="contentful-embedded-image" src="${imageUrl}" alt="${title}" />`;
                },
                [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
                    if (!node.data.target || !node.data.target.fields) {
                        return '';
                    }

                    // Any changes made here will need to be copied to dynamic-content-block.component.html
                    return `<div class="dynamic-content-block">
                                <div class="dynamic-content-block__sidebar"></div>
                                  <h3 class="dynamic-content-block__title">${node.data.target.fields.title}</h3>
                                <div class="dynamic-content-block__content">${documentToHtmlString(node)}</div>
                            </div>`;
                },
            },
        };

        return documentToHtmlString(richText, options);
    }
}
