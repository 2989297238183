import { createSelector } from '@ngrx/store';
import { isEmpty } from '@shared/utils/typescript';
import { subscriptionFeature } from './subscription.reducer';

export const selectIsNonNativeSubscriptionActive = createSelector(
    subscriptionFeature.selectNonNativeSubscriptionInfo,
    (nonNativeSubscriptionInfoData) => nonNativeSubscriptionInfoData?.isActive || false,
);

export const selectIsSubscriptionLoading = createSelector(
    subscriptionFeature.selectAreSubscriptionsLoading,
    subscriptionFeature.selectEntitlements,
    subscriptionFeature.selectAreSubscriptionsLoading,
    (areEntitlementsLoading, entitlements, isNonNativeSubscriptionInfoLoading) =>
        areEntitlementsLoading || (isEmpty(entitlements) && isNonNativeSubscriptionInfoLoading),
);
