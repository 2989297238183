import { Color } from '@shared/utils/typescript';

export const darkContrastColors = [
    Color.Luna,
    Color.Lola,
    Color.Maggie,
    Color.Bailey,
    Color.Bella,
    Color.Oliver,
    Color.Disabled,
    Color.AppBackground,
    Color.AppOutline,
    Color.Snowy,
    Color.DisabledText,
    Color.Barney,
];
export const lightContrastColors = [
    Color.Max,
    Color.Harley,
    Color.Alvin,
    Color.Buddy,
    Color.Charly,
    Color.Sophie,
    Color.Jack,
    Color.SemiJack,
    Color.Paragraph,
];

export const isColorContrastLight = (color: Color): boolean => {
    return lightContrastColors.includes(color);
};

export const getSquiggleColor = (color: Color): string => {
    switch (color) {
        case Color.Charly:
        case Color.Sophie: {
            return `var(--ion-color-${color}-secondary-light)`;
        }
        default: {
            return `var(--ion-color-${color}-secondary)`;
        }
    }
};

export const getTemplateThemeColor = (color: Color, isContrast?: boolean, opacity = 1): string => {
    return `rgba(var(--ion-color-${color}${isContrast ? '-contrast' : ''}-rgb), ${opacity})`;
};

export const setCourseBackgroundColor = (color: Color) => {
    return {
        'background-color': getTemplateThemeColor(color),
        color: getTemplateThemeColor(color, true),
    };
};
