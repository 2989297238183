import { NgStyle } from '@angular/common';
import { AfterViewInit, Component, ElementRef, inject, input, Renderer2, viewChild } from '@angular/core';

@Component({
    selector: 'ui-slider',
    templateUrl: './slider.component.html',
    styleUrl: './slider.component.scss',
    imports: [NgStyle],
})
export class SliderComponent implements AfterViewInit {
    private renderer = inject(Renderer2);

    public boxRef = viewChild('box', { read: ElementRef });

    public gap = input<number>();
    public lastItemPadding = input<number>();

    //TODO: NBSon - investigate unit testing this method, for some reason nativeElement.children returns empty
    // tried with createHost so we can test projected content
    public ngAfterViewInit(): void {
        if (!this.lastItemPadding() || !this.boxRef()) {
            return;
        }

        const items: HTMLCollection = this.boxRef()!.nativeElement.children;

        if (!items.length) {
            return;
        }

        // Add a class to the last item
        const lastItem = items[items.length - 1];

        this.renderer.setStyle(lastItem, 'padding-right', `${this.lastItemPadding()}px`);
    }
}
