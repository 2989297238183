import { IsNumber, IsString } from 'class-validator';

export class DomainChannelTagDto {
    @IsString()
    channelId!: string;

    @IsString()
    tagId!: string;

    @IsNumber()
    timeStamp?: number;
}
