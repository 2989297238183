import { NgStyle } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { Color } from '@shared/utils/typescript';
import { Store } from '@ngrx/store';
import { NourishTileId } from '../constants/nourish.constants';
import { nourishTileClicked } from '../store/nourish.actions';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';

@Component({
    selector: 'app-nourish-tile',
    templateUrl: './nourish-tile.component.html',
    styleUrl: './nourish-tile.component.scss',
    imports: [NgStyle, TextReplacementPipe],
})
export class NourishTileComponent {
    private readonly store = inject(Store);

    public id = input.required<NourishTileId>();
    public title = input.required<string>();
    public pill = input.required<string>();
    public image = input.required<{
        src: string;
        alt: string;
    }>();
    public colour = input<Color>(Color.Max);

    public onNourishTileClicked() {
        return this.store.dispatch(nourishTileClicked({ id: this.id() }));
    }
}
