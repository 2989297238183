import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { GenericCardSlimComponent } from '@frontend/ui/generic-cards';
import { IonContent, ViewWillEnter } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { routeToAiChat, routeToCoachChat } from '../chat/chat.actions';
import { showPaymentModal } from '../store/payment/store/payment.actions';
import { TrainerInfoComponent } from './our-trainers/trainer-info/trainer-info.component';
import { selectSupportPageVm } from './support.selectors';

@Component({
    selector: 'app-support',
    templateUrl: './support.page.html',
    styleUrl: './support.page.scss',
    imports: [IonContent, GenericCardSlimComponent, AnalyticsTrackClickDirective, TrainerInfoComponent, AsyncPipe],
})
export class SupportPage implements ViewWillEnter {
    private readonly store = inject(Store);
    private readonly cdr = inject(ChangeDetectorRef);
    public vm$ = this.store.select(selectSupportPageVm);

    public async cardClicked(unlocked: boolean, cardName: string, shouldAllowClick: boolean) {
        if (shouldAllowClick) {
            return;
        }

        if (unlocked) {
            switch (cardName) {
                case 'expertsupport':
                    void this.store.dispatch(routeToCoachChat());
                    break;
                case 'ai-chat':
                    void this.store.dispatch(routeToAiChat({}));
                    break;
                default:
                    break;
            }
        } else {
            await this.openPaymentModal(cardName);
        }
    }

    async openPaymentModal(cardName: string): Promise<void> {
        void this.store.dispatch(showPaymentModal({ trigger: `support-${cardName}` }));
    }

    public ionViewWillEnter(): void {
        this.cdr.markForCheck();
    }
}
