<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <app-header
            [leftButton]="false"
            [rightButton]="{
                analyticsIdentifier: 'close-course-information-modal-btn',
                sprite: 'light',
                iconName: 'xmark',
                background: 'white',
                iconColour: 'black',
            }"
            (rightButtonPressed)="closeModal()"
        />
    </ion-toolbar>
</ion-header>

<ion-content
    class="course-information-modal"
    data-test="course-information-content-container"
    [fullscreen]="true"
    [color]="course().color"
>
    <article class="course-information-modal__content" [ngStyle]="dynamicallyAppliedStyles()">
        <section class="ion-align-self-center">
            <img class="course-information-modal__image" [src]="course().imageUrl" alt="course image" />
        </section>
        <section>
            <h1 class="ion-text-center" data-test="course-information-modal-title">
                {{ course().title }}
            </h1>
            @if (course().subTitle) {
                <p
                    class="unset-margin"
                    data-test="course-information-modal-subtitle"
                    [ngStyle]="{ color: 'var(--ion-color-' + course().color + '-contrast)' }"
                >
                    {{ course().subTitle }}
                </p>
            }
        </section>
        @if (course().explanation && course().explanation.content?.length > 0) {
            <section
                class="ignore-global-color"
                [innerHTML]="course().explanation | richContent | textReplacement"
                [ngStyle]="{ color: 'var(--ion-color-' + course().color + '-contrast)' }"
            ></section>
        }
    </article>
    @if (vm().selectedPathCourseId !== course().id) {
        <ion-footer
            class="course-information-modal__footer"
            [ngStyle]="{
                background:
                    'linear-gradient(to top, rgba(var(--ion-color-' +
                    course().color +
                    '-rgb), 1) 0%, rgba(var(--ion-color-' +
                    course().color +
                    '-rgb), 1) 65%, rgba(var(--ion-color-' +
                    course().color +
                    '-rgb), 0) 100% )',
                '--gradient-color': course().color,
            }"
        >
            <section class="ion-text-center">
                <ion-button
                    appTrackClick
                    [identifier]="'go-to-course-information-modal-btn'"
                    color="max"
                    expand="full"
                    shape="round"
                    data-test="go-to-course-information-modal-btn"
                    (click)="onGoToCourseClicked()"
                >
                    @if (vm().isBasicPlan && course().type === CourseType.OPTIONAL) {
                        Upgrade to Premium
                        <i class="fa-regular fa-crown course-information-modal__premium-icon"></i>
                    } @else {
                        Go to course
                    }
                </ion-button>
                <button
                    class="zz-button course-information-modal__cancel-button"
                    [ngStyle]="{ color: 'var(--ion-color-' + course().color + '-contrast)' }"
                    appTrackClick
                    identifier="cancel-course-information-modal-btn"
                    data-test="cancel-course-information-modal-btn"
                    (click)="closeModal()"
                >
                    Cancel
                </button>
            </section>
        </ion-footer>
    }
</ion-content>
