import { inject, Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { householdFeature, selectGetOrCreateHouseholdIsCompleted } from '@frontend/data-access/user/household';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { map, Observable, skipWhile } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class OnboardingCompleteGuard {
    private readonly store = inject(Store);
    private readonly router = inject(Router);

    public canActivate(): Observable<boolean | UrlTree> {
        return this.store.select(selectGetOrCreateHouseholdIsCompleted).pipe(
            skipWhile((getOrCreateHouseholdCompleted) => !getOrCreateHouseholdCompleted),
            concatLatestFrom(() => this.store.select(householdFeature.selectDateOfOnboarding)),
            map(([, dateOfOnboarding]) => {
                if (dateOfOnboarding) {
                    return this.router.createUrlTree(['main', 'today']);
                }

                return true;
            }),
        );
    }
}
