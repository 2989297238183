import { IsNumber, IsOptional, IsString } from 'class-validator';

export class DomainPostHappinessCheckDto {
    @IsString()
    userId!: string;

    @IsOptional()
    @IsString()
    dogId?: string;

    @IsNumber()
    ownerHappinessScore!: number;

    @IsNumber()
    dogHappinessScore!: number;

    @IsOptional()
    @IsNumber()
    timestamp!: number;
}
