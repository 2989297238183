import { NgClass } from '@angular/common';
import { Component, inject, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { selectIsStartCourseAb } from '@frontend/data-access/user/config-cat';
import { ElementRerenderDirective } from '@frontend/ui/directives';
import { GenericCardLargeComponent } from '@frontend/ui/generic-cards';
import { NoAccessComponent } from '@frontend/ui/no-access';
import { isColorContrastLight } from '@frontend/utility/angular';
import { IonContent, ScrollDetail } from '@ionic/angular/standalone';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CourseType } from '@shared/content-api-interface';
import { CourseProgressType } from '@shared/user-domain';
import { Color } from '@shared/utils/typescript';
import { CourseStatus } from '../../store/course/course.model';
import { showPaymentModal } from '../../store/payment/store/payment.actions';
import { selectContentUnlocked, selectIsBasicPlan } from '../../store/payment/store/payment.selectors';
import {
    triggerCreateManyCourseProgress,
    TRIGGERED_FROM_MY_JOURNEY_CORRELATION_ID,
} from '../../store/progress/course-progress/course-progress.actions';
import { showCourseInformationModal } from '../course-information-modal/course-information-modal.actions';
import { CoursePathComponent } from '../course-path/course-path.component';
import {
    selectHeightOfFirstInactiveStep,
    selectSelectedCourseWithProgressInfo,
} from '../course-path/store/course-path.selectors';
import {
    setSelectedPathCourseIdScrollToFirstInactiveStep,
    setSelectedPathCourseIdScrollToTop,
} from '../course-slider/course-slider.actions';
import { CourseSliderComponent } from '../course-slider/course-slider.component';
import { CourseStreakComponent } from '../course-streak/course-streak.component';
import {
    selectCourses,
    selectNextCourse,
    selectShouldShowStartCourseButtonForSelectedCourse,
} from './courses.page.selectors';

// TODO: NBSon - think about making some of these methods either computed or with pipes
@Component({
    selector: 'app-courses',
    templateUrl: './courses.page.html',
    styleUrl: './courses.page.scss',
    imports: [
        IonContent,
        CourseSliderComponent,
        CoursePathComponent,
        NgClass,
        GenericCardLargeComponent,
        ElementRerenderDirective,
        NoAccessComponent,
        CourseStreakComponent,
    ],
})
export class CoursesPage {
    @ViewChild(IonContent, { static: false })
    public ionContent!: IonContent;

    private readonly store = inject(Store);
    private readonly actions$ = inject(Actions);

    protected readonly CourseStatus = CourseStatus;
    protected readonly Color = Color;

    public scrollHeight = 0;

    public courses = this.store.selectSignal(selectCourses);
    public heightOfFirstInactiveStep = this.store.selectSignal(selectHeightOfFirstInactiveStep);
    public isBasicPlan = this.store.selectSignal(selectIsBasicPlan);
    public showStartCourseButton = this.store.selectSignal(selectShouldShowStartCourseButtonForSelectedCourse);
    public isStartCourseAb = this.store.selectSignal(selectIsStartCourseAb);
    public contentUnlocked = this.store.selectSignal(selectContentUnlocked);
    public nextCourse = this.store.selectSignal(selectNextCourse);
    public selectedCourse = this.store.selectSignal(selectSelectedCourseWithProgressInfo);

    constructor() {
        this.actions$.pipe(takeUntilDestroyed(), ofType(setSelectedPathCourseIdScrollToTop)).subscribe(() => {
            setTimeout(() => {
                void this.ionContent.scrollToPoint(0, 0, 100);
            }, 100);
        });

        this.actions$
            .pipe(takeUntilDestroyed(), ofType(setSelectedPathCourseIdScrollToFirstInactiveStep))
            .subscribe(() => {
                setTimeout(() => {
                    void this.ionContent.scrollToPoint(undefined, this.heightOfFirstInactiveStep(), 200);
                }, 100);
            });
    }

    public openCourseInfoModal(): void {
        if (!this.nextCourse()) {
            return;
        }
        this.store.dispatch(
            showCourseInformationModal({
                course: this.nextCourse()!,
            }),
        );
    }

    public setScrollHeight(event: CustomEvent<ScrollDetail>): void {
        this.scrollHeight = event.detail.scrollTop;
    }

    public scrollToFirstInactiveStep(): void {
        void this.ionContent.scrollToPoint(undefined, this.heightOfFirstInactiveStep(), 500);
    }

    public getCourseCardDoodle(): string {
        if (!this.nextCourse()) {
            return '';
        }
        return isColorContrastLight(this.nextCourse()!.color)
            ? '/assets/images/squiggles/doodle.png'
            : '/assets/images/squiggles/doodle-dark.png';
    }

    public canScroll(): boolean {
        if (this.selectedCourse()?.type !== CourseType.OPTIONAL) {
            return true;
        }

        return !this.isBasicPlan();
    }

    public displayNoAccess(): boolean {
        return this.isBasicPlan() && this.selectedCourse()?.type === CourseType.OPTIONAL;
    }

    public openPaymentModal(): void {
        return this.store.dispatch(showPaymentModal({ trigger: 'courses page' }));
    }

    public setCourseAsInProgress(): void {
        if (!this.selectedCourse()) {
            return;
        }
        const courseProgressEntry = {
            id: this.selectedCourse()!.id,
            title: this.selectedCourse()!.title,
            progressType: CourseProgressType.IN_PROGRESS,
        };

        this.store.dispatch(
            triggerCreateManyCourseProgress({
                courseProgress: [courseProgressEntry],
                correlationId: TRIGGERED_FROM_MY_JOURNEY_CORRELATION_ID,
            }),
        );
    }
}
