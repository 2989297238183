import { Component, inject, Input } from '@angular/core';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { ModalResultEnum } from '@frontend/utility/modal';
import { IonButton, IonText } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { dismissSkipLessonNotificationModal } from '../../store/modal/modal.actions';
import { setStepSkipped } from '../../store/progress/step-progress/step-progress.actions';
import { SkipReason, SkipStepOptions } from '../../store/progress/step-progress/step-progress.model';
import { Step } from '../../store/step/step.model';
import { SKIP_STEP_OPTIONS } from './lesson-skip-notification-modal.constants';

@Component({
    selector: 'app-lesson-skip-notification-modal',
    templateUrl: './lesson-skip-notification-modal.component.html',
    styleUrl: './lesson-skip-notification-modal.component.scss',
    imports: [AnalyticsTrackClickDirective, TextReplacementPipe, IonButton, IonText],
})
export class LessonSkipNotificationModalComponent {
    private readonly store = inject(Store);

    @Input() step: Step | undefined;

    protected readonly skipStepOptions: SkipStepOptions = SKIP_STEP_OPTIONS;

    public onSkipClick(skipReason: SkipReason): void {
        this.store.dispatch(
            dismissSkipLessonNotificationModal({
                result: skipReason,
            }),
        );

        if (this.step) {
            this.store.dispatch(
                setStepSkipped({
                    properties: {
                        title: this.step.content.title,
                        stepId: this.step.id,
                        contentType: this.step.content.contentType,
                        contentId: this.step.content.id,
                        topicId: this.step.content.topic,
                        skipReason: skipReason.id,
                    },
                }),
            );
        }
    }

    public onCancelClick(): void {
        this.store.dispatch(
            dismissSkipLessonNotificationModal({
                result: ModalResultEnum.CANCELLED_BY_USER,
            }),
        );
    }
}
