import { createAction, props } from '@ngrx/store';
import {
    NourishModalId,
    NourishProductFeedbackModalState,
    NourishProductId,
    NourishTileId,
} from '../constants/nourish.constants';

export const nourishCalculateFoodButtonClicked = createAction('[Nourish] Calculate Food Button Clicked');

export const nourishFindFoodButtonClicked = createAction('[Nourish] Find Food Button Clicked');

export const nourishTileClicked = createAction('[Nourish] Tile Clicked', props<{ id: NourishTileId }>());

export const nourishProductTileClicked = createAction(
    '[Nourish] Product Tile Clicked',
    props<{
        id: NourishProductId;
        correlationId?: 'page' | 'modal';
    }>(),
);

export const nourishProductCtaClicked = createAction(
    '[Nourish] Product CTA Clicked',
    props<{
        id: NourishProductId;
        url: string;
    }>(),
);

export const nourishSuggestedAiChatQuestionClicked = createAction(
    '[Nourish] Suggested Ai Chat Question Clicked',
    props<{ optionId: string; card: string; option: string }>(),
);

// TODO: NBSon - rename to 'show' for consistency
export const openNourishModal = createAction('[Nourish] Open Nourish Modal', props<{ id: NourishTileId }>());

export const closeNourishModal = createAction('[Nourish] Close Nourish Modal', props<{ id?: NourishModalId }>());

export const closeNourishProductFeedbackModal = createAction('[Nourish] Close Nourish Product Feedback Modal');

export const nourishProductFeedbackModalThumbsDownClicked = createAction(
    '[Nourish] Product Feedback Modal Thumbs Down Clicked',
    props<{
        state: NourishProductFeedbackModalState.INITIAL | NourishProductFeedbackModalState.NEGATIVE;
    }>(),
);

export const nourishProductFeedbackModalSubmitted = createAction(
    '[Nourish] Product Feedback Modal Submitted',
    props<{
        id: string;
        response: NourishProductFeedbackModalState.POSITIVE | NourishProductFeedbackModalState.NEGATIVE;
        reason?: string;
        comment?: string;
    }>(),
);

export const nourishProductHeaderBackButtonClicked = createAction(
    '[Nourish] Product Header Back Button Clicked',
    props<{ id: NourishProductId }>(),
);
