<ion-chip class="author-chip no-margin">
    @if (authorImageUrl()) {
        <img
            class="author-chip__image"
            data-test="author-chip-image"
            [src]="authorImageUrl()"
            [alt]="'Photo of ' + author()"
            loading="eager"
        />
    }
    <p class="author-chip__text body-1 body-1--xxs no-margin" data-test="author-chip-text">
        By {{ author() }}, Zigzag training coach
    </p>
</ion-chip>
