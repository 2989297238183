import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConversationMessageRatingType } from '@shared/domain-api-interface';

@Component({
    selector: 'app-chat-message-rating',
    templateUrl: './chat-message-rating.component.html',
    styleUrl: './chat-message-rating.component.scss',
})
export class ChatMessageRatingComponent implements OnInit {
    @Input()
    public rating: ConversationMessageRatingType | undefined;

    @Output()
    public ratingChanged: EventEmitter<ConversationMessageRatingType> =
        new EventEmitter<ConversationMessageRatingType>();

    protected readonly ConversationMessageRatingType = ConversationMessageRatingType;

    private previousRating: ConversationMessageRatingType | undefined;

    public ngOnInit(): void {
        this.previousRating = this.rating;
    }

    public onClickRating(rating: ConversationMessageRatingType): void {
        this.previousRating = this.rating;

        if (this.rating === rating) {
            this.rating = ConversationMessageRatingType.NONE;
        } else {
            this.rating = rating;
        }

        if (this.previousRating !== this.rating) {
            this.ratingChanged.emit(this.rating);
        }
    }
}
