<div class="lesson-rating-component-container page-padding-sides page-content-padding-top">
    <div class="lesson-rating">
        <p class="lesson-rating__compliment" data-test="lesson-rating-compliment">Good work!</p>

        <h1 class="lesson-rating__title">
            {{ 'How well did [NAME] do this lesson?' | textReplacement }}
        </h1>

        <img
            class="lesson-rating__image"
            src="assets/images/buddy/buddy-gets-head-pats-large.png"
            alt="Buddy getting a nice pat on the head from his guardian for his hard work with this lesson"
            data-test="lesson-rating-image"
        />

        <ui-star-rating
            [maxNumberOfStars]="maxRating()"
            [starValueSelected]="rating()"
            (ratingChanged)="onRatingChange($event)"
        />

        <h2 class="lesson-rating__rating-text" data-test="lesson-rating-rating-text">
            {{ ratingText | textReplacement }}
        </h2>

        @if (ratedStep && currentRating <= 3) {
            <p class="lesson-rating__practice-text" data-test="lesson-rating-practice-text">
                We’ll add a practice lesson to your journey
            </p>
        }
    </div>
</div>
