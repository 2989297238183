<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <app-header
            title="Training coach"
            background="white"
            [leftButton]="{ analyticsIdentifier: 'chat-page-back-button', background: 'none' }"
            [rightButton]="false"
            [rightImage]="{
                content: 'assets/images/trainers/all-three-coaches-circles.svg',
            }"
            (leftButtonPressed)="routeToPrevious()"
        />
    </ion-toolbar>
</ion-header>

@if (vm$ | async; as vm) {
<ion-content
    class="training-coach-chat"
    [ngClass]="{
            'page-ion-content--safe-area': vm.platformInfo.isIos && !vm.platformInfo.isKeyboardOpen,
        }"
>
    @if (vm.channelInitialized) { @if (vm.channelEmpty) {
    <app-training-coach-chat-intro [dogName]="vm.dogName" />
    }
    <stream-channel>
        <stream-message-list />
        <stream-message-input (messageUpdate)="messageSent($event)" />
    </stream-channel>
    } @else {
    <ui-loading-screen />
    }
</ion-content>
}

<ng-template #messageTemplate let-message="message" let-isLastSentMessage="isLastSentMessage">
    <stream-message [message]="message" [isLastSentMessage]="isLastSentMessage" />
</ng-template>
