import { Component, inject, Input } from '@angular/core';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { HeaderComponent } from '@frontend/feature/header';
import { RadioWithExplanationComponent, RadioWithExplanationResult } from '@frontend/ui/radio-with-explanation';
import { ModalService } from '@frontend/utility/modal';
import { IonButton, IonFooter, IonHeader, IonToolbar } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { CourseSplit } from '../../models/courses.models';
import { startNextPartClicked } from './jump-ahead-modal.actions';

@Component({
    imports: [
        RadioWithExplanationComponent,
        AnalyticsTrackClickDirective,
        IonButton,
        IonFooter,
        HeaderComponent,
        IonHeader,
        IonToolbar,
    ],
    selector: 'app-jump-ahead-modal',
    templateUrl: './jump-ahead-modal.component.html',
    styleUrl: './jump-ahead-modal.component.scss',
})
export class JumpAheadModalComponent {
    private readonly store = inject(Store);
    private readonly modalService = inject(ModalService);

    @Input()
    public split: CourseSplit | undefined;

    public radioResult: RadioWithExplanationResult | undefined;

    public buttons = [
        {
            title: 'Completed lessons elsewhere',
            value: 'completed-elsewhere',
            explainMore: {
                placeholder: 'Please explain more',
                required: true,
            },
        },
        {
            title: 'Lessons are not suitable',
            value: 'not-suitable',
            explainMore: {
                placeholder: 'Please explain more',
                required: true,
            },
        },
        {
            title: 'Something else',
            value: 'something-else',
            explainMore: {
                placeholder: 'Please add your reason',
                required: true,
            },
        },
    ];

    protected readonly Color = Color;

    public setAnswer(event: RadioWithExplanationResult): void {
        this.radioResult = event;
    }

    public startNextPart(): void {
        if (this.radioResult && this.split) {
            this.store.dispatch(startNextPartClicked({ radioResult: this.radioResult, split: this.split }));
        }

        this.closeModal();
    }

    public closeModal(): void {
        void this.modalService.dismiss({
            dismissed: true,
        });
    }
}
