import { InternalStepProgressDto } from '@shared/internal-api-interface';
import { StepProgress, StepProgressPayload } from '../models/step-progress.model';

export const mapInternalStepProgressDtoToStepProgress = (
    stepProgressItems: InternalStepProgressDto[],
): StepProgress[] => {
    return stepProgressItems.map((stepProgress) => {
        return {
            id: stepProgress.id,
            stepId: stepProgress.stepId,
            contentId: stepProgress.contentId,
            progress: stepProgress.progressType,
            rating: stepProgress.rating,
            timestamp: stepProgress.timestamp,
            isPractice: false,
            dogId: stepProgress.dogId,
        };
    });
};

export const addIsPracticeToStepProgress = (
    stepProgressItems: StepProgress[],
    stepProgressPayload: StepProgressPayload[],
): StepProgress[] => {
    return stepProgressItems.map((stepProgress) => {
        return {
            ...stepProgress,
            isPractice: findIsPracticeForStepProgress(stepProgress.stepId, stepProgressPayload),
        };
    });
};

const findIsPracticeForStepProgress = (stepId: string, stepProgressPayload: StepProgressPayload[]): boolean => {
    const progressItem = stepProgressPayload.find((item) => item.stepId === stepId);
    return progressItem?.isPractice ?? false;
};
