import { createSelector } from '@ngrx/store';
import { householdFeature, selectBreedName } from '@frontend/data-access/user/household';
import { mapDateOfBirthToAgeInWeeks } from '@shared/utils/typescript';
import { mapDateOfBirthToAgeString } from '@frontend/utility/angular';

export const selectOurPickString = createSelector(
    householdFeature.selectDateOfBirth,
    selectBreedName,
    (dateOfBirth, breedName): string => {
        const currentDate = new Date();
        const ageInWeeks = mapDateOfBirthToAgeInWeeks(dateOfBirth, currentDate);

        if (ageInWeeks! > 104) {
            return `Our picks for ${breedName}`;
        }

        const age = mapDateOfBirthToAgeString(dateOfBirth, currentDate, false);

        return `Our picks for ${age} old ${breedName}`;
    },
);
