import { TabButton } from '@frontend/ui/tab';

export const TabRoute_TODAY = 'today';
export const TabRoute_COURSES = 'courses';
export const TabRoute_LIBRARY = 'library';
export const TabRoute_NOURISH = 'nourish';
export const TabRoute_SUPPORT = 'support';
export const ALL_TAB_ROUTES = [
    TabRoute_TODAY,
    TabRoute_COURSES,
    TabRoute_LIBRARY,
    TabRoute_NOURISH,
    TabRoute_SUPPORT,
] as const;

export const todayTab: TabButton = {
    tab: TabRoute_TODAY,
    label: 'Home',
    iconSrc: 'assets/images/illustrations/tabs/tab-today-icon.svg',
    identifier: 'tab-to-today-btn',
};

export const coursesTab: TabButton = {
    tab: TabRoute_COURSES,
    label: 'My Journey',
    iconSrc: 'assets/images/illustrations/tabs/tab-journey-icon.svg',
    identifier: 'tab-to-courses-btn',
};

export const libraryTab: TabButton = {
    tab: TabRoute_LIBRARY,
    label: 'Library',
    iconSrc: 'assets/images/illustrations/tabs/tab-library-icon.svg',
    identifier: 'tab-to-library-btn',
};

export const nourishTab: TabButton = {
    tab: TabRoute_NOURISH,
    label: 'Nourish',
    iconSrc: 'assets/images/illustrations/tabs/tab-food-calculator-icon.svg',
    identifier: 'tab-to-nourish-btn',
};

export const supportTab: TabButton = {
    tab: TabRoute_SUPPORT,
    label: 'My Coach',
    iconSrc: 'assets/images/illustrations/tabs/tab-my-coach-icon.svg',
    identifier: 'tab-to-support-btn',
};
