import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';
import { IonChip } from '@ionic/angular/standalone';

@Component({
    selector: 'ui-generic-chip',
    templateUrl: './generic-chip.component.html',
    styleUrl: './generic-chip.component.scss',
    imports: [IonChip, NgClass],
})
export class GenericChipComponent {
    public text = input.required<string>();

    public icon = input<string>();
    public textClass = input<string>('s');
}
