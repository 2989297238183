import { Component, inject, input } from '@angular/core';
import { SliderComponent, SliderItemComponent } from '@frontend/ui/slider';
import { Store } from '@ngrx/store';
import { NourishProductId } from '../constants/nourish.constants';
import { NourishProductTile } from '../models/nourish.model';
import { NourishProductTileComponent } from '../nourish-product-tile/nourish-product-tile.component';
import { nourishProductTileClicked } from '../store/nourish.actions';
import { selectOurPickString } from './nourish-product-tiles.selectors';

@Component({
    selector: 'app-nourish-product-tiles',
    templateUrl: './nourish-product-tiles.component.html',
    imports: [NourishProductTileComponent, SliderComponent, SliderItemComponent],
})
export class NourishProductTilesComponent {
    public productTiles = input<NourishProductTile[]>([]);
    public correlationId = input<'page' | 'modal' | undefined>();

    private readonly store = inject(Store);
    public ourPickString = this.store.selectSignal(selectOurPickString);

    public onClickProductTile(id: NourishProductId) {
        this.store.dispatch(
            nourishProductTileClicked({
                id,
                correlationId: this.correlationId(),
            }),
        );
    }
}
