import { differenceInMonths, isSameDay } from 'date-fns';
import { ShowModalFrequency, UserInteractionItem } from '../models/user-interactions.model';

export const getUserInteractionItemById = (
    userInteractionItems: UserInteractionItem[],
    itemId: string,
): UserInteractionItem | undefined =>
    userInteractionItems.find((userInteractionItem) => userInteractionItem.id === itemId);

export const isItemInteracted = (userInteractionItems: UserInteractionItem[], itemId: string): boolean =>
    userInteractionItems.some((userInteractionItem) => userInteractionItem.id === itemId);

export const shouldDisplayModal = (
    showModalFrequency: ShowModalFrequency,
    itemId: string,
    userInteractionItems: UserInteractionItem[],
    today: Date,
): boolean => {
    switch (showModalFrequency) {
        case ShowModalFrequency.ONCE: {
            return userInteractionItems.every((userInteractionItem) => userInteractionItem.id !== itemId);
        }
        case ShowModalFrequency.DAILY: {
            return userInteractionItems.every((userInteractionItem) => {
                return (
                    userInteractionItem.id !== itemId ||
                    (userInteractionItem.id === itemId &&
                        userInteractionItem.dontShowAgain !== true &&
                        !isSameDay(new Date(userInteractionItem.timestamp), today))
                );
            });
        }
        case ShowModalFrequency.MONTHLY: {
            return userInteractionItems.every((userInteractionItem) => {
                return (
                    userInteractionItem.id !== itemId ||
                    (userInteractionItem.id === itemId &&
                        userInteractionItem.dontShowAgain !== true &&
                        differenceInMonths(today, new Date(userInteractionItem.timestamp)) > 1)
                );
            });
        }
    }
};
