import { BreedInfo } from '@frontend/data-access/contentful';
import { ConfigCatFlag } from '@frontend/data-access/user/config-cat';
import { Household } from '@frontend/data-access/user/household';
import { APP_VERSION_NUMBER } from '@shared/constants';
import { Breed, BreedType, Gender } from '@shared/user-domain';
import { isEmpty, mapDateOfBirthToAgeInWeeks, mapDateToIterableDateFormat } from '@shared/utils/typescript';
import {
    IterableOrigin,
    IterablePostEventDto,
    IterablePostPushOpenEventDto,
    IterableUpsertUserDto,
} from '@shared/iterable-api-interface';

export const mapUserToInternalIterableUpsertUserDto = (
    household: Household,
    deviceOs: string,
    featureFlags: string[],
    setIterableOriginApp: boolean,
    ipAddress?: string,
    breedInfo?: BreedInfo,
    stepsComplete?: number,
): IterableUpsertUserDto => {
    if (!household) {
        throw new Error('no household to map to iterable user');
    }

    if (!household.currentUser) {
        throw new Error('no current user to map to iterable user');
    }

    if (!household.dogs[0]) {
        throw new Error('no current dog to map to iterable user');
    }

    let dateOfBirthMapped = undefined;
    let dateOfArrivalMapped = undefined;

    const user = household.currentUser;
    const dog = household.dogs[0];

    if (dog.dateOfBirth) {
        dateOfBirthMapped = mapDateToIterableDateFormat(dog.dateOfBirth);
    }

    if (dog.dateOfArrival) {
        dateOfArrivalMapped = mapDateToIterableDateFormat(dog.dateOfArrival);
    }

    return {
        email: user.mail,
        dataFields: {
            userId: user.id,
            householdId: household.id,
            ownerName: user.name ?? '',
            locale: user.countryCode ? `en-${user.countryCode}` : '',
            partners: user.partners.map((partner) => partner.name),
            stepsComplete: stepsComplete || 0,
            appVersion: APP_VERSION_NUMBER,
            deviceOs: deviceOs,
            ip: ipAddress || '',
            purinaMarketingAccepted: user.purinaMarketingAccepted ?? undefined,
            enabledFeatureFlags: featureFlags,
            ...(setIterableOriginApp ? { origin: IterableOrigin.APP } : {}),
            dogs: [
                {
                    dogId: dog.id,
                    dogName: dog.name ?? '',
                    breedGroup: breedInfo?.breedGroup.name || BreedType.RETRIEVERS,
                    breed: breedInfo?.name ?? Breed.MIXED_BREED,
                    ageInWeeks: mapDateOfBirthToAgeInWeeks(dog.dateOfBirth),
                    gender: dog.gender ?? Gender.MALE,
                    dateOfBirth: dateOfBirthMapped,
                    dateOfArrival: dateOfArrivalMapped,
                    isRescuePup: dog.isRescue ?? false,
                    hasArrived: dog.hasArrived ?? true,
                    isApproximateDateOfBirth: dog.isApproximateDateOfBirth ?? false,
                },
            ],
        },
    };
};

export const mapTrackEventToInternalIterablePostEventDto = (
    userId: string,
    eventName: string,
    eventProperties: Record<string, unknown> | undefined,
): IterablePostEventDto => {
    return {
        userId,
        eventName,
        dataFields: !isEmpty(eventProperties) ? eventProperties : undefined,
        createdAt: new Date().getTime(),
    };
};

export const mapNotificationToInternalIterablePostPushOpenEventDto = (
    userId: string,
    campaignId: number,
    templateId: number,
    messageId: string,
): IterablePostPushOpenEventDto => {
    return {
        userId,
        campaignId,
        templateId,
        messageId,
        createdAt: new Date().getTime(),
    };
};

export const mapFeatureFlagsForIterable = (featureFlags: ConfigCatFlag[]): string[] => {
    const activeFeatureFlags = featureFlags.filter((featureFlag) => featureFlag.flagValue);

    return activeFeatureFlags.map((activeFeatureFlag) => activeFeatureFlag.name);
};
