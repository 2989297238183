import { IterablePlatformType } from '../interfaces/iterable-platform-type.model';
import { IterableRegisterForPushNotificationsDto } from '../dtos/iterable-register-for-push-notifications.dto';

export class InternalIterableRegisterForPushNotificationsDtoMock {
    private defaultValue: IterableRegisterForPushNotificationsDto = {
        userId: 'user-id',
        device: {
            token: 'token',
            platform: IterablePlatformType.FIREBASE_MESSAGING,
            applicationName: 'zigzag',
        },
    };

    constructor(overrides?: Partial<IterableRegisterForPushNotificationsDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): IterableRegisterForPushNotificationsDto {
        return this.defaultValue;
    }
}
