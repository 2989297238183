import { Component } from '@angular/core';
import { IonContent } from '@ionic/angular/standalone';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { NourishModalBaseComponent } from '../nourish-modal-base.component';
import { NgClass } from '@angular/common';
import { NourishPetFoodFinderComponent } from '../../nourish-pet-food-finder/nourish-pet-food-finder.component';
import { NourishFoodFinderOriginId } from '../../constants/nourish.constants';
import { GenericFeedbackComponent } from '@frontend/ui/generic-feedback';

@Component({
    selector: 'app-prevent-diarrhoea-modal',
    templateUrl: './prevent-diarrhoea-modal.component.html',
    styleUrl: './prevent-diarrhoea-modal.component.scss',
    imports: [
        IonContent,
        AnalyticsTrackClickDirective,
        NgClass,
        NourishPetFoodFinderComponent,
        GenericFeedbackComponent,
    ],
})
export class PreventDiarrhoeaModalComponent extends NourishModalBaseComponent {
    protected readonly NourishFoodFinderOriginId = NourishFoodFinderOriginId;
}
