import { createAction, props } from '@ngrx/store';
import { HouseholdUser } from '../../models/household-user.model';

export const patchHouseholdUser = createAction(
    '[Household User] Patch User',
    props<{ user: Partial<HouseholdUser>; correlationId: string }>(),
);

export const patchHouseholdUserSuccess = createAction(
    '[Household User] Patch User Success',
    props<{
        user: HouseholdUser;
        dto: Partial<HouseholdUser>;
        correlationId: string;
    }>(),
);

export const patchHouseholdUserFailure = createAction(
    '[Household User] Patch User Failure',
    props<{ error: Error; correlationId: string }>(),
);
