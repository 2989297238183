import { Component, inject, input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Weekday } from '@capacitor/local-notifications';
import { HeaderComponent, RightHeaderButton } from '@frontend/feature/header';
import { IonButton, IonContent, IonDatetime, IonFooter } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { format } from 'date-fns';
import { createTrainingReminder, trainingReminderModalCloseClicked } from '../../store/training-reminder-modal.actions';
import { WeekdayButtonsComponent } from '../weekday-buttons/weekday-buttons.component';

@Component({
    selector: 'app-training-reminder-modal',
    templateUrl: './training-reminder-modal.component.html',
    styleUrl: './training-reminder-modal.component.scss',
    imports: [
        WeekdayButtonsComponent,
        HeaderComponent,
        IonButton,
        IonContent,
        IonDatetime,
        IonFooter,
        ReactiveFormsModule,
    ],
})
export class TrainingReminderModalComponent {
    private readonly store = inject(Store);

    public dogName = input.required<string>();

    public form = new FormGroup({
        time: new FormControl<string>(format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"), {
            nonNullable: true,
            validators: [Validators.required],
        }),
        days: new FormControl<Weekday[]>(
            [
                Weekday.Monday,
                Weekday.Tuesday,
                Weekday.Wednesday,
                Weekday.Thursday,
                Weekday.Friday,
                Weekday.Saturday,
                Weekday.Sunday,
            ],
            {
                nonNullable: true,
                validators: [Validators.required],
            },
        ),
    });

    protected readonly rightButtonConfig: RightHeaderButton = {
        analyticsIdentifier: 'training-reminder-modal-close-btn',
        background: 'none',
        sprite: 'light',
        iconName: 'xmark',
    };
    protected readonly Color = Color;

    public onClickCloseModal(): void {
        this.store.dispatch(trainingReminderModalCloseClicked());
    }

    public onClickSetReminder(): void {
        this.store.dispatch(
            createTrainingReminder({
                dogName: this.dogName(),
                time: new Date(this.form.controls.time.value),
                weekdays: this.form.controls.days.value,
            }),
        );
    }
}
