import { InternalHappinessCheckDto } from '../../../dtos/happiness-check/internal-happiness-check.dto';

export class InternalHappinessCheckDtoMock {
    private defaultValue: InternalHappinessCheckDto = {
        userId: 'userId',
        ownerHappinessScore: 0.5,
        dogHappinessScore: 0.6,
        timestamp: 0,
    };

    constructor(overrides?: Partial<InternalHappinessCheckDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): InternalHappinessCheckDto {
        return this.defaultValue;
    }
}
