import { DomainPostUserFeedbackContentDto } from '../../../dtos/feedback/domain-post-user-feedback-content.dto';

export class DomainPostUserFeedbackContentDtoMock {
    private defaultValue: DomainPostUserFeedbackContentDto = {
        userId: 'user-123',
        contentfulId: 'contentful-id-123',
        contentTypeId: 'type-123',
        comment: undefined,
        thumbsDown: false,
    };

    constructor(overrides?: Partial<DomainPostUserFeedbackContentDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): DomainPostUserFeedbackContentDto {
        return this.defaultValue;
    }
}
