import { DomainPostHouseholdDto } from '../../../dtos/user/household/domain-post-household.dto';

export class DomainPostHouseholdDtoMock {
    private defaultValue: DomainPostHouseholdDto = {
        name: 'household',
    };

    constructor(overrides?: Partial<DomainPostHouseholdDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): DomainPostHouseholdDto {
        return this.defaultValue;
    }
}
