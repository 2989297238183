<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <app-header
            title="Food calculator"
            background="var(--ion-color-bailey)"
            [rightButton]="false"
            [leftButton]="{analyticsIdentifier:'food-calculator-back-btn', background: 'none', iconColour: 'black'}"
            (leftButtonPressed)="routeToPrevious()"
        />
    </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true" [color]="Color.Bailey">
    <section class="food-calculator-page ion-text-center">
        <div class="food-calculator-page__image-heading page-padding-sides">
            <img
                alt="pup with treat"
                class="food-calculator-image"
                [src]="'assets/images/buddy/buddy-looks-longingly-at-steak.png'"
            />
            <h2>{{ "Fill in [NAME_C]'s weight & select the food you give [HIM/HER]" | textReplacement }}</h2>
        </div>

        <form [formGroup]="foodForm">
            <section class="food-calculator-page__form page-padding-sides">
                <ion-card>
                    <ion-item lines="full" detail="false">
                        <ion-label position="stacked"> Pup's weight</ion-label>
                        <ion-input
                            aria-label="Pup's weight"
                            class="zz-input current-weight"
                            inputmode="decimal"
                            type="tel"
                            maxlength="4"
                            data-test="current-weight-input"
                            placeholder="-"
                            labelPlacement="stacked"
                            formControlName="currentWeight"
                            [min]="minExpectedWeight"
                            [tabindex]="1"
                        >
                            <ion-button
                                class="food-calculator-page__control-appendix"
                                fill="clear"
                                slot="end"
                                aria-label="kg"
                                >kg
                            </ion-button>
                        </ion-input>

                        <span class="food-calculator-page__last-updated" data-test="last-calculated">
                            @if (foodPortionLastCalculated()) {
                            <p>Last updated: {{ foodPortionLastCalculated()! | date: 'mediumDate' }}</p>
                            }
                        </span>

                        @if (foodForm.controls.currentWeight.errors?.['min']) {
                        <ui-invalid-input-error-message
                            [errorMessage]="'Current weight should be minimum ' + minExpectedWeight + ' kilogram'"
                        />
                        }
                    </ion-item>
                    <app-food-product-selector formControlName="foodProductId" [nourishForm]="true" />
                    <ion-item lines="full" detail="false">
                        <ion-label position="stacked">
                            <div
                                class="expected-weight-block info-button"
                                appTrackClick
                                identifier="expected-weight-info-button"
                                data-test="expected-weight-info-button"
                                (click)="showWeightInfoModal()"
                            >
                                Expected adult weight
                                <i class="fa-regular fa-circle-info"></i>
                            </div>
                        </ion-label>
                        <ion-input
                            aria-label="Expected adult weight"
                            class="zz-input expected-weight"
                            inputmode="decimal"
                            type="tel"
                            maxlength="4"
                            data-test="expected-weight-input"
                            placeholder="-"
                            labelPlacement="stacked"
                            formControlName="expectedWeight"
                            [min]="minExpectedWeight"
                            [tabindex]="1"
                        >
                            <ion-button
                                class="food-calculator-page__control-appendix"
                                fill="clear"
                                slot="end"
                                aria-label="kg"
                                >kg
                            </ion-button>
                        </ion-input>
                        @if (foodForm.controls.expectedWeight.errors?.['min']) {
                        <ui-invalid-input-error-message
                            [errorMessage]="'Expected weight should be minimum ' + minExpectedWeight + ' kilogram'"
                        />
                        }
                    </ion-item>

                    <ng-container>
                        <ion-item lines="none" detail="false" data-test="age-item">
                            <ion-label position="stacked">Age in weeks</ion-label>
                            <ion-input
                                class="zz-input"
                                aria-label="Age in weeks"
                                inputmode="numeric"
                                type="tel"
                                maxlength="2"
                                data-test="age-in-weeks-input"
                                placeholder="..."
                                formControlName="ageInWeeks"
                                [min]="minAgeInWeeks"
                                [tabindex]="2"
                            />
                            @if (foodForm.controls.ageInWeeks.errors?.['min']) {
                            <ui-invalid-input-error-message
                                data-test="invalid-input-age-in-weeks"
                                [errorMessage]="
                                    'You can use the calculator when [NAME] is at least ' +
                                        minAgeInWeeks +
                                        ' weeks old.' | textReplacement
                                "
                            />
                            }
                        </ion-item>
                    </ng-container>
                </ion-card>
            </section>
        </form>
    </section>
    <ion-footer class="food-calculator-footer ion-text-center page-padding-sides">
        <ion-button
            appTrackClick
            identifier="nourish-food-portion-calculate-btn"
            color="max"
            expand="block"
            (click)="calculateFoodPortion()"
        >
            Calculate
        </ion-button>
    </ion-footer>
</ion-content>
