import { NgClass } from '@angular/common';
import { Component, effect } from '@angular/core';
import { householdFeature } from '@frontend/data-access/user/household';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { ProgressHeaderComponent } from '@frontend/ui/progress-header';
import { IonContent, IonHeader, IonSpinner, IonToolbar } from '@ionic/angular/standalone';
import { Gender } from '@shared/user-domain';
import { Color } from '@shared/utils/typescript';
import { onboardingQuestionSubmittedGender } from '../../data-access/onboarding.actions';
import { OnboardingQuestionBasePage } from '../question-base.page';

@Component({
    selector: 'app-onboarding-question-gender',
    templateUrl: './question-gender.page.html',
    styleUrls: ['../onboarding.scss', './question-gender.page.scss'],
    imports: [
        AnalyticsTrackClickDirective,
        IonContent,
        IonHeader,
        IonSpinner,
        IonToolbar,
        NgClass,
        ProgressHeaderComponent,
    ],
})
export class OnboardingQuestionGenderPage extends OnboardingQuestionBasePage {
    protected readonly Color = Color;
    protected readonly Gender = Gender;

    public gender = this.store.selectSignal(householdFeature.selectGender);

    public selectedGender!: Gender;

    constructor() {
        super();

        effect(() => {
            this.selectedGender = this.gender()!;
        });
    }

    public onGenderAnswered(gender: Gender): void {
        if (this.isLoading()) {
            return;
        }

        this.selectedGender = gender;

        this.store.dispatch(onboardingQuestionSubmittedGender({ gender }));
    }
}
