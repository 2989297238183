<ion-content [color]="Color.Alvin" [fullscreen]="true">
    <ion-header class="page-header ion-no-border">
        <ion-toolbar>
            <app-header
                [leftButton]="false"
                [rightButton]="{
                    analyticsIdentifier: 'payment-modal-limited-offer-close-btn',
                    sprite: 'light',
                    iconName: 'xmark',
                    background: 'white',
                    iconColour: 'black',
                }"
                (rightButtonPressed)="onCloseModalClick()"
            />
        </ion-toolbar>
    </ion-header>

    <section class="payment-modal-limited-offer">
        <img
            class="payment-modal-limited-offer__logo"
            [src]="'assets/images/zigzag/zigzag-logo.svg'"
            alt="zigzag logo"
        />

        <h3 class="payment-modal-limited-offer__limited-text">Limited offer</h3>

        <h1 class="payment-modal-limited-offer__title l">
            Save&nbsp;<span class="payment-modal-limited-offer__percentage">{{ discount() }}</span
            >&nbsp;on <br />Premium <i class="fa-regular fa-crown"></i>
        </h1>

        <img
            class="payment-modal-limited-offer__dog"
            [src]="'assets/images/bailey/bailey-runs-after-tennis-ball.svg'"
            alt="Bailey running after a tennis ball with excitement"
        />

        <ul class="value-prop-section">
            <li class="value-prop-item">
                <p class="xxl bullet-icon"><i class="fa-thin fa-circle-check circle-icon"></i></p>
                <p class="s bullet-text">
                    {{ "A training journey that reacts to [NAME]'s progress" | textReplacement }}
                </p>
            </li>

            <li class="value-prop-item">
                <p class="xxl bullet-icon"><i class="fa-thin fa-circle-check circle-icon"></i></p>
                <p class="s bullet-text">Unlimited access to 250+ lessons to overcome any issue</p>
            </li>

            <li class="value-prop-item">
                <p class="xxl bullet-icon"><i class="fa-thin fa-circle-check circle-icon"></i></p>
                <p class="s bullet-text">24/7 live chat support with training coaches</p>
            </li>
        </ul>

        <div class="payment-modal-limited-offer__products">
            @for (product of shownProducts(); track product.identifier) {
                <app-payment-limited-offer-modal-product
                    [isSelected]="product.identifier === selectedProduct()?.identifier"
                    [product]="product"
                    (click)="onProductClick(product)"
                />
            }
        </div>

        <ion-chip outline="true" class="payment-modal-limited-offer__compare-plans" (click)="launchComparisonModal()"
            >Compare plans
        </ion-chip>

        <p class="payment-modal-limited-offer__cancel-anytime s">Cancel anytime in the App Store</p>

        <app-payment-modal-terms fontColor="snowy" class="payment-modal-limited-offer__disclaimer xs" />

        <button
            class="zz-button payment-modal-limited-offer__restore-purchases"
            appTrackClick
            identifier="limited-offer-paywall-payment-restore-button"
            (click)="onRestoreClick()"
        >
            Restore purchases
        </button>
    </section>
</ion-content>

<ion-footer class="payment-modal-limited-offer-footer page-padding-sides">
    <ion-button
        appTrackClick
        identifier="payment-modal-limited-offer-start-trial-btn"
        expand="full"
        shape="round"
        data-test="payment-modal-limited-offer-start-trial-btn"
        [color]="Color.Max"
        (click)="onStartTrialClick()"
        [disabled]="isPurchaseInProgress()"
    >
        Start my free 7 days
    </ion-button>
</ion-footer>
