import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CardItemComponent } from '@frontend/ui/card-item';
import { IonCard } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { selectLibraryDailyBiteTileVm } from './library-daily-bite-tile.selectors';

@Component({
    selector: 'app-library-daily-bite-tile',
    templateUrl: './library-daily-bite-tile.component.html',
    styleUrl: '../../library.scss',
    imports: [CardItemComponent, RouterLink, AsyncPipe, IonCard],
})
export class LibraryDailyBiteTileComponent {
    private readonly store = inject(Store);

    public vm$ = this.store.select(selectLibraryDailyBiteTileVm);
}
