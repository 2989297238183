import { InternalIterableDataFieldsMock } from './internal-iterable-data-fields.mock';
import { IterableUpsertUserDto } from '../dtos/iterable-upsert-user.dto';

export class InternalIterableUpsertUserDtoMock {
    private defaultValue: IterableUpsertUserDto = {
        email: 'jasper@mattijs.milos',
        dataFields: new InternalIterableDataFieldsMock().value,
    };

    constructor(overrides?: Partial<IterableUpsertUserDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): IterableUpsertUserDto {
        return this.defaultValue;
    }
}
