<ion-content class="page-ion-content" [fullscreen]="true">
    <div class="daily-bite-modal">
        <ion-header class="page-header ion-no-border">
            <ion-toolbar>
                <app-header
                    [leftButton]="{ analyticsIdentifier: 'daily-bite-modal-close-btn', background: 'white' }"
                    [rightButton]="false"
                    (leftButtonPressed)="closeModal()"
                />
            </ion-toolbar>
        </ion-header>

        <img src="{{ dailyBite.imageUrl }}" class="image-self" alt="header-image" />

        <div class="text-block double-padding" style="padding-bottom: 20px">
            <h1>{{ dailyBite.title | textReplacement }}</h1>
            @if (dailyBite.dailyBiteAuthor) {
                <ui-author-chip
                    [authorImageUrl]="
                        'assets/images/trainers/' + dailyBite.dailyBiteAuthor.authorName.split(' ').join('_') + '.jpg'
                    "
                    [author]="dailyBite.dailyBiteAuthor.authorName"
                />
            } @else if (dailyBite.author) {
                <ui-author-chip
                    [authorImageUrl]="'assets/images/trainers/' + dailyBite.author.split(' ').join('_') + '.jpg'"
                    [author]="dailyBite.author"
                />
            }
            @if (dailyBite.content) {
                <div class="content" [innerHTML]="dailyBite.content | textReplacement" data-test="legacy-content"></div>
            }
            @if (dailyBite.richContent) {
                <div
                    class="content"
                    data-test="rich-content"
                    [innerHTML]="dailyBite.richContent | richContent | textReplacement"
                ></div>
            }
        </div>
    </div>
</ion-content>
