import { AsyncPipe, Location } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { SliderComponent } from '@frontend/ui/slider';
import { IonCard } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { TopicCardComponent } from '../../components/topic-card/topic-card.component';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { selectPinnedTopicOverviewVm } from './today-pinned-topics.selectors';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';

@Component({
    selector: 'app-pinned-topic-overview',
    templateUrl: './today-pinned-topics.component.html',
    styleUrls: ['../../library/library.scss', 'today-pinned-topics.component.scss'],
    imports: [
        SliderComponent,
        TopicCardComponent,
        AnalyticsTrackClickDirective,
        RouterLink,
        AsyncPipe,
        TextReplacementPipe,
        IonCard,
    ],
})
export class TodayPinnedTopicsComponent {
    private readonly store = inject(Store);
    public readonly location = inject(Location);
    public vm$ = this.store.select(selectPinnedTopicOverviewVm);
}
