export enum ProjectEnvironment {
    DEVELOPMENT = 'development',
    ACCEPTANCE = 'acceptance',
    PRODUCTION = 'production',
    CI = 'ci',
}

export interface BaseEnvironmentConfig {
    production: boolean;
    environment: ProjectEnvironment;
    internalApiUrl: string;
    internalAuthSecret: string;
    mfaDisplayName: string;
    applicationToInternalHashedApiKey: string; // todo place in keyvault
}
