<ul class="payment-modal-plan-features-list unset-margin">
    @for (feature of features(); track $index) {
        <li class="payment-modal-plan-features-list__item">
            <i class="fa-thin fa-circle-check payment-modal-plan-features-list__circle-icon"></i>
            <p class="s payment-modal-plan-features-list__bullet-text unset-margin">
                {{ feature | textReplacement }}
            </p>
        </li>
    }
</ul>
