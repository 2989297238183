<div
    class="step-card-slim"
    [ngClass]="{
        'step-card-slim--locked': content().locked,
    }"
>
    <section class="step-card-slim__image-container" [ngClass]="content().color">
        <img
            class="step-card-slim__image"
            [ngClass]="'step-card-slim__image--' + content().contentType"
            fill="true"
            loading="eager"
            [ngSrc]="content().thumbnailUrl"
            [alt]="content().title"
        />
        @switch (content().status) {
            @case (StepStatus.SKIPPED) {
                <div class="state-icon state-icon--skipped">
                    <i class="fa-solid fa-forward state-icon__icon" data-test="step-card-slim-skipped-status-icon"></i>
                </div>
            }
            @case (StepStatus.COMPLETED) {
                <div class="state-icon state-icon--completed">
                    <i class="fa-solid fa-check state-icon__icon" data-test="step-card-slim-completed-status-icon"></i>
                </div>
            }
        }
        @if (content().locked) {
            <div class="step-card-slim__locked-icon">
                <i class="fa-solid fa-lock" data-test="step-card-slim-locked-icon"></i>
            </div>
        }
    </section>

    <section class="step-card-slim__text-container">
        <div class="step-card-slim__title-container">
            <h3 class="step-card-slim__title heading-3 no-margin">
                {{ content().title | textReplacement }}
            </h3>
            @if (content().isPractice) {
                <p
                    class="step-card-slim__practice body-1 body-1--sm no-margin"
                    data-test="step-card-slim-practice-text"
                >
                    Practice
                </p>
            }
        </div>

        <div class="step-card-slim__details-container">
            @switch (content().contentType) {
                @case (StepType.ARTICLE) {
                    <div class="step-card-slim__detail">
                        <i class="fa-regular fa-memo"></i>
                        @if (content().duration) {
                            <span data-test="step-card-slim-article-duration-text">{{ content().duration }} min</span>
                        }
                    </div>
                }
                @case (StepType.LESSON) {
                    <div class="step-card-slim__detail">
                        <i class="fa-regular fa-dog"></i>
                        @if (content().duration) {
                            <span data-test="step-card-slim-lesson-duration-text">{{ content().duration }} min</span>
                        }
                    </div>

                    <div class="step-card-slim__detail" data-test="step-card-slim-lesson-location-detail">
                        @switch (content().location) {
                            @case (LessonLocation.INDOORS) {
                                <i
                                    class="fa-regular fa-home"
                                    data-test="step-card-slim-lesson-location-indoors-icon"
                                ></i>
                            }
                            @case (LessonLocation.OUTDOORS) {
                                <i
                                    class="fa-regular fa-sun-cloud"
                                    data-test="step-card-slim-lesson-location-outdoors-icon"
                                ></i>
                            }
                            @case (LessonLocation.ANYWHERE) {
                                <i
                                    class="fa-regular fa-earth-europe"
                                    data-test="step-card-slim-lesson-location-anywhere-icon"
                                ></i>
                            }
                        }
                        <span>{{ content().location }}</span>
                    </div>
                }
            }

            @if (content().rating) {
                <div class="step-card-slim__rating" data-test="step-card-slim-rating">
                    <i class="step-card-slim__rating-icon fa-solid fa-star"></i>
                    <p class="body-1 body-1--sm no-margin">{{ content().rating }}</p>
                </div>
            }
        </div>
    </section>
</div>
