import { ModalResultEnum } from '@frontend/utility/modal';
import { createAction, props } from '@ngrx/store';
import { SkipReason } from '../progress/step-progress/step-progress.model';
import { Step } from '../step/step.model';

export const showArrivalModal = createAction('[Modals] Show Arrival Modal');

export const showSkipLessonNotificationModal = createAction(
    '[Modals] Show Skip Lesson Notification Modal',
    props<{
        step: Step;
    }>(),
);

export const dismissSkipLessonNotificationModal = createAction(
    '[Modals] Dismiss Skip Lesson Notification Modal',
    props<{
        result: SkipReason | ModalResultEnum;
    }>(),
);

export const closeModal = createAction('[Modals] Close Modal');

export const showLilysKitchenTreatModal = createAction('[Modals] Show Lilys Kitchen Treat Modal');

export const dismissLilysKitchenTreatModal = createAction(
    '[Modals] Dismiss Lilys Kitchen Treat Modal',
    props<{
        result: SkipReason | ModalResultEnum;
    }>(),
);
