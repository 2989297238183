import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BASE_ENVIRONMENT_CONFIG, BaseEnvironmentConfig } from '@frontend/configuration';
import { httpOptions } from '@frontend/utility/constants';
import {
    InternalCourseProgressDto,
    internalPaths,
    InternalPostCourseProgressDto,
    InternalPostManyCourseProgressDto,
} from '@shared/internal-api-interface';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CourseProgressService {
    private readonly http = inject(HttpClient);
    private readonly environment = inject<BaseEnvironmentConfig>(BASE_ENVIRONMENT_CONFIG);

    public getCourseProgress(): Observable<InternalCourseProgressDto[]> {
        return this.http.get<InternalCourseProgressDto[]>(
            `${this.environment.internalApiUrl}/api/${internalPaths.courseProgressPath}?takeLatest=true`,
            httpOptions,
        );
    }

    public createCourseProgress(dto: InternalPostCourseProgressDto): Observable<InternalCourseProgressDto> {
        return this.http.post<InternalCourseProgressDto>(
            `${this.environment.internalApiUrl}/api/${internalPaths.courseProgressPath}`,
            dto,
            httpOptions,
        );
    }

    public createManyCourseProgress(dto: InternalPostManyCourseProgressDto): Observable<InternalCourseProgressDto[]> {
        return this.http.post<InternalCourseProgressDto[]>(
            `${this.environment.internalApiUrl}/api/${internalPaths.courseProgressPath}/many`,
            dto,
            httpOptions,
        );
    }
}
