import { Adjust, AdjustConfig, AdjustEvent, AdjustEventFailure } from '@awesome-cordova-plugins/adjust/ngx';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ADJUST_CONFIG } from './adjust-injection-tokens';
import {
    adjustEventFailure,
    adjustRequestTrackingConsentResponse,
    adjustUpdateAttributionData,
} from './store/adjust.actions';
import { adjustEventMap, AdjustEventType } from './store/adjust.model';
import { Platform } from '@ionic/angular/standalone';

@Injectable({
    providedIn: 'root',
})
export class AdjustService {
    private readonly store = inject(Store);
    private readonly adjustConfig = inject<AdjustConfig>(ADJUST_CONFIG);
    private readonly platform = inject(Platform);

    constructor(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        private adjust: Adjust,
    ) {}

    public initialize(): void {
        this.addListeners(this.adjustConfig);
        this.createAdjust(this.adjustConfig);
        this.updateSubscriptionData();
    }

    public async requestTrackingAuthorization(): Promise<void> {
        if (this.platform.is('ios')) {
            const consentValue = await this.adjust.requestAppTrackingAuthorization();

            await this.updateSubscriptionData();

            const constValueToTextMap = new Map<number, string>([
                [0, "The user hasn't been asked yet"],
                [1, 'The user device is restricted'],
                [2, 'The user denied access to IDFA'],
                [3, 'The user authorized access to IDFA'],
                [-1, 'The status is not available'],
            ]);

            this.store.dispatch(
                adjustRequestTrackingConsentResponse({
                    consentValue,
                    consentText: constValueToTextMap.get(consentValue) ?? 'The status is not available',
                }),
            );
            return;
        }

        console.info('[Mock Adjust Service] requestTrackingAuthorization()');
    }

    public trackEvent(eventType: AdjustEventType): void {
        if (this.platform.is('ios') || this.platform.is('android')) {
            const adjustEventId = adjustEventMap[eventType];

            if (!adjustEventId) {
                throw new Error('Adjust event not in map');
            }

            const event = new AdjustEvent(adjustEventId);
            this.adjust.trackEvent(event);
            return;
        }

        console.info('[Mock Adjust Service] trackEvent()', { eventType });
    }

    private createAdjust(config: AdjustConfig): void {
        if (this.platform.is('ios') || this.platform.is('android')) {
            this.adjust.initSdk(config);
            return;
        }
        console.info('[Mock Adjust Service] createAdjust()');
    }

    private async updateSubscriptionData(): Promise<void> {
        if (this.platform.is('ios') || this.platform.is('android')) {
            const attributionData = await this.adjust.getAttribution();
            attributionData.adid = await this.adjust.getAdid();
            this.store.dispatch(adjustUpdateAttributionData({ attributionData }));
            return;
        }

        console.info('[Mock Adjust Service] updateSubscriptionData()');
    }

    private addListeners(adjustConfig: AdjustConfig): void {
        if (this.platform.is('ios') || this.platform.is('android')) {
            adjustConfig.setAttributionCallback(async () => {
                await this.updateSubscriptionData();
            });

            adjustConfig.setEventTrackingFailedCallback((event: AdjustEventFailure) => {
                this.store.dispatch(adjustEventFailure({ event }));
            });
        } else {
            console.info('[Mock Adjust Service] addListeners()');
        }
    }
}
