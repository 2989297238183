import { AsyncPipe } from '@angular/common';
import { Component, HostBinding, inject, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { Device } from '@capacitor/device';
import { trackEvent } from '@frontend/data-access/analytics';
import { ActionSheetButton, ActionSheetController } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { ProfileImageType } from './profile-image.model';
import { removeProfileImage, setProfileImage } from './store/profile-image.actions';
import { profileImageFeature } from './store/profile-image.reducer';

@Component({
    selector: 'app-profile-image',
    templateUrl: './profile-image.component.html',
    styleUrl: './profile-image.component.scss',
    imports: [AsyncPipe],
})
export class ProfileImageComponent {
    private readonly actionSheetCtrl = inject(ActionSheetController);
    private readonly store = inject(Store);
    private readonly sanitizer = inject(DomSanitizer);
    @Input() isClickable = false;
    @Input()
    @HostBinding('style.--image-size')
    size = '65px';
    @Input()
    @HostBinding('style.--border-width')
    borderWidth = '2px';
    @Input() type = ProfileImageType.VIEW_ONLY;
    public profileImageIsSet = false;
    public ProfileImageType = ProfileImageType;

    profileImage$ = this.store.select(profileImageFeature.selectImage).pipe(
        map((profileImage) => {
            if (profileImage) {
                this.profileImageIsSet = true;

                return this.sanitizer.bypassSecurityTrustResourceUrl(
                    `data:image/${profileImage.format};base64, ${profileImage.base64String}`,
                );
            }

            this.profileImageIsSet = false;

            switch (this.type) {
                case ProfileImageType.VIEW_ONLY: {
                    return 'assets/images/charly/charly-pokes-out-tongue-and-winks-profile-pic.png';
                }
                case ProfileImageType.GO_TO_PROFILE: {
                    return 'assets/images/charly/charly-pokes-out-tongue-and-winks-profile-pic.png';
                }
                case ProfileImageType.EDIT: {
                    return 'assets/images/illustrations/today/add-photo-icon.svg';
                }
            }
        }),
    );

    async selectImageSource(): Promise<void> {
        const actionSheet = await this.actionSheetCtrl.create({
            header: 'Select Image Source',
            buttons: this.getActionButtons(this.profileImageIsSet),
        });
        await actionSheet.present();
    }

    private getActionButtons(showDeleteButton: boolean): ActionSheetButton[] {
        const buttons: ActionSheetButton[] = [
            {
                text: 'Delete Photo',
                role: 'destructive',
                handler: () => {
                    void this.removeImage();
                },
            },
            {
                text: 'Take Photo',
                handler: () => {
                    void this.addImage(CameraSource.Camera);
                },
            },
            {
                text: 'Choose Existing',
                handler: () => {
                    void this.addImage(CameraSource.Photos);
                },
            },
            {
                text: 'Cancel',
                role: 'cancel',
            },
        ];

        if (!showDeleteButton) {
            buttons.shift();
        }
        return buttons;
    }

    async readWebPathToString(path: string) {
        const blob = await fetch(path).then((img) => img.blob());

        return new Promise<string>((resolve) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result as string);
            reader.readAsDataURL(blob);
        });
    }

    async addImage(source: CameraSource): Promise<void> {
        let image: Photo;
        const deviceInfo = await Device.getInfo();

        if (source === 'PHOTOS' && deviceInfo.platform !== 'ios') {
            const gallery = await Camera.pickImages({
                quality: 75,
                width: 400,
                height: 400,
                limit: 1,
            });
            const imgBase64 = await this.readWebPathToString(gallery.photos[0].webPath);
            image = { base64String: imgBase64.replace('data:image/jpeg;base64,', ''), format: 'jpeg' } as Photo;
        } else {
            image = await Camera.getPhoto({
                quality: 75,
                allowEditing: true,
                resultType: CameraResultType.Base64,
                width: 400,
                height: 400,
                source,
            });
        }

        this.store.dispatch(
            trackEvent({
                eventName: 'addProfileImage',
                eventProperties: {
                    source,
                },
            }),
        );
        void this.store.dispatch(setProfileImage({ image }));
    }

    removeImage(): void {
        this.store.dispatch(
            trackEvent({
                eventName: 'deleteProfileImage',
            }),
        );
        void this.store.dispatch(removeProfileImage());
    }
}
