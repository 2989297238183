import { IsEnum, IsOptional, IsString } from 'class-validator';
import { ConversationMessageRatingTypeID } from '../../models/conversation/conversation.model';

export class InternalPatchConversationMessage {
    @IsOptional()
    @IsString()
    content?: string;

    @IsOptional()
    @IsString()
    senderId?: string;

    @IsOptional()
    @IsString()
    streamChatMessageId?: string;

    @IsOptional()
    @IsEnum(ConversationMessageRatingTypeID)
    ratingId?: ConversationMessageRatingTypeID;
}

export class InternalPatchConversationMessageDto extends InternalPatchConversationMessage {
    @IsString()
    conversationId!: string;
}
