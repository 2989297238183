import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { IonButton, IonCard } from '@ionic/angular/standalone';
import { Color } from '@shared/utils/typescript';
import { GenericChipComponent } from '@frontend/ui/generic-chip';

@Component({
    selector: 'app-nourish-product-tile',
    templateUrl: './nourish-product-tile.component.html',
    styleUrl: './nourish-product-tile.component.scss',
    imports: [NgClass, IonButton, IonCard, GenericChipComponent, TextReplacementPipe],
})
export class NourishProductTileComponent {
    public title = input.required<string>();
    public image = input.required<{
        src: string;
        alt: string;
    }>();

    public chip = input<string>();
    public colour = input<Color>(Color.Max);
}
