<ion-content class="help-with-tummy-troubles-modal" [color]="Color.Maggie" xmlns="http://www.w3.org/1999/html">
    <button
        class="xl help-with-tummy-troubles-modal__close-button"
        appTrackClick
        identifier="nourish-help-with-tummy-troubles-modal-close-btn"
        (click)="onCloseClick()"
    >
        <i class="fa-light fa-xmark"></i>
    </button>
    <article class="help-with-tummy-troubles-modal__article page-padding-sides page-padding-bottom ignore-global-color">
        <h2>Help with tummy troubles</h2>

        <p class="no-margin">
            Your pup's digestive system is extra sensitive to changes in diet, stress, or environmental factors.
        </p>

        <section class="help-with-tummy-troubles-modal__image-with-text">
            <p class="no-margin">
                Watch out for common signs of digestive issues, such as loose stools, vomiting, or excessive gas.
            </p>
            <img src="/assets/images/charly/charly-looks-at-poo-medium.svg" alt="Charly looking at their poo" />
        </section>

        <p class="no-margin">
            These could be caused by overeating, eating something they shouldn’t, or even a food allergy.
        </p>

        <section class="help-with-tummy-troubles-modal__image-with-text">
            <img
                src="/assets/images/bailey/bailey-holds-phone-in-mouth.svg"
                alt="Bailey holding a phone in their mouth"
            />
            <p class="no-margin">
                If the symptoms last longer than 24 hours or your pup seems lethargic, consult your vet immediately to
                rule out more serious conditions.
            </p>
        </section>

        <section class="help-with-tummy-troubles-modal__highlighted-text" [ngClass]="Color.Sophie">
            <ul class="help-with-tummy-troubles-modal__list">
                <li>
                    <p class="help-with-tummy-troubles-modal__text">
                        Monitor your pup’s stool daily for consistency and changes
                    </p>
                </li>

                <br />

                <li>
                    <p class="help-with-tummy-troubles-modal__text">
                        Keep a journal of new foods or treats introduced to track any reactions
                    </p>
                </li>

                <br />

                <li>
                    <p class="help-with-tummy-troubles-modal__text">
                        Pup-proof your home to prevent access to harmful foods or objects
                    </p>
                </li>
            </ul>
        </section>

        @if (isUsUser()) {
            <app-nourish-pet-food-finder
                [shouldShowHeading]="true"
                [origin]="NourishFoodFinderOriginId.TUMMY_TROUBLES_MODAL"
            />
        }

        <br />

        <ui-generic-feedback
            [autoSubmitOnChange]="true"
            [contentfulId]="currentInsightsModalId() ?? ''"
            [existingRating]="userFeedback()"
            [feedbackOptions]="DefaultFeedbackOptions"
            (feedbackSubmitted)="onFeedbackSubmitted($event)"
        />
    </article>
</ion-content>
