<section class="voucher-medium voucher-medium__background-{{ cardColour() }}">
    @if (locked()) {
        <div class="voucher-medium__locked-background">
            <div class="voucher-medium__locked-icon">
                <i class="fa-solid fa-lock" data-test="voucher-medium-locked-icon"></i>
            </div>
        </div>
    }
    <h3 class="voucher-medium__heading unset-margin" data-test="voucher-medium-heading">{{ heading() }}</h3>
    <p class="voucher-medium__sub-heading unset-margin" data-test="voucher-medium-subheading">{{ subHeading() }}</p>
    <div class="voucher-medium__divider-left"></div>
    <div class="voucher-medium__divider-right"></div>
    <div class="voucher-medium__info">
        <ion-button
            class="voucher-medium__button s"
            type="button"
            size="small"
            data-test="voucher-medium-btn"
            [color]="buttonColour()"
            (click)="handleClick()"
        >
            {{ buttonText() }}
        </ion-button>
        <img data-test="voucher-medium-image" [src]="imageUrl()" [alt]="imageAlt()" />
    </div>
</section>
