import { selectIsInputOpenOnAndroid } from '@frontend/data-access/keyboard';
import { selectUrl } from '@frontend/data-access/router';
import { isItemInteracted, userInteractionFeature } from '@frontend/data-access/user-interactions';
import { TabButton } from '@frontend/ui/tab';
import { createSelector } from '@ngrx/store';
import { NOURISH_TAB_INDICATOR_ID } from '../../nourish/constants/nourish.constants';
import { selectIsRouteChildRoute } from '../../store/router/router.selectors';
import { todayPageFeature } from '../../today/data-access/today.page.reducer';
import { coursesTab, libraryTab, nourishTab, supportTab, TabRoute_NOURISH, todayTab } from '../tabs.constants';

export const selectShouldHideTabsOnAndroid = createSelector(
    selectIsInputOpenOnAndroid,
    selectUrl,
    (isInputFocusedOnAndroid, currentUrl) => {
        const routesToHideTabOn = ['/main/library'];
        return isInputFocusedOnAndroid && routesToHideTabOn.includes(currentUrl);
    },
);

export const selectTabsVm = createSelector(
    selectIsRouteChildRoute,
    selectShouldHideTabsOnAndroid,
    todayPageFeature.selectShowAiChatView,
    userInteractionFeature.selectUserInteractions,
    (isChildRoute, shouldHideTabsOnAndroid, showAiChatView, userInteractions) => {
        const tabButtons: TabButton[] = [todayTab, coursesTab, libraryTab, nourishTab, supportTab];

        const mappedTabButtons = tabButtons.map((tabButton) => {
            if (tabButton.tab === TabRoute_NOURISH) {
                return {
                    ...tabButton,
                    shouldShowIndicator: !isItemInteracted(userInteractions, NOURISH_TAB_INDICATOR_ID),
                };
            }

            return tabButton;
        });

        return {
            hideTabs: isChildRoute || shouldHideTabsOnAndroid || showAiChatView,
            // TODO: NBSon - The tabs below do not correctly show as highlighted when navigating directly via URL, possibly due to how the class ('tab-selected') is applied before being rendered, we should look at storing currentTab in state
            tabButtons: mappedTabButtons,
            userInteractions,
        };
    },
);
