@if (vm()) {
<ion-tabs class="ion-no-border" [class.tab-bar-hidden]="vm().hideTabs" data-test="tabs-ion-tabs">
    <ion-tab-bar slot="bottom" color="transparent" class="ion-no-border">
        @for (tabButton of vm().tabButtons; track tabButton.tab) { @if (!tabButton.isHidden) {
        <ion-tab-button
            appTrackClick
            [tab]="tabButton.tab"
            [appTrackClickEnabled]="!!tabButton.identifier"
            [identifier]="tabButton.identifier ?? ''"
            [attr.data-test]="'tabs-tab-btn-' + tabButton.tab"
            (click)="tabClicked(tabButton)"
        >
            @if (tabButton.shouldShowIndicator) {
            <ui-indicator />
            }
            <ion-icon
                size="large"
                [src]="tabButton.iconSrc"
                [attr.data-test]="'tabs-tab-btn-' + tabButton.tab + '-icon'"
            ></ion-icon>
            <ion-label class="zz-ion-label xs" [attr.data-test]="'tabs-tab-btn-' + tabButton.tab + '-label'"
                >{{ tabButton.label }}
            </ion-label>
        </ion-tab-button>
        } }
    </ion-tab-bar>
</ion-tabs>
}
