@for (button of buttons(); track button.value) {
    @if (selected === undefined || button.value === selected.value) {
        <ion-button
            class="radio-with-logic-button"
            [ngStyle]="{
                '--background': button.value === selected?.value ? 'var(--ion-color-max)' : '',
                '--background-hover': button.value === selected?.value ? 'var(--ion-color-max)' : '',
                '--border-color': button.value === selected?.value ? 'var(--ion-color-max)' : '',
                '--color': button.value === selected?.value ? 'var(--ion-color-max-contrast)' : '',
                top: button.value === selected?.value ? 0 : $index * 70 + 'px',
            }"
            expand="block"
            fill="solid"
            [disabled]="isDisabled()"
            [attr.data-test]="'radio-button-' + button.value"
            (click)="onButtonClick(button)"
        >
            {{ button.value }}
        </ion-button>
    }
}
