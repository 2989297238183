import { Directive, HostListener, inject, Input } from '@angular/core';
import { HapticFeedbackService } from './haptic-feedback.service';
import type { HapticStyle } from './haptic.style';

@Directive({
    selector: '[appHapticFeedback]',
})
export class HapticFeedbackDirective {
    private readonly hapticFeedbackService = inject(HapticFeedbackService);

    @Input('appHapticFeedback')
    style: HapticStyle = 'medium';

    @HostListener('click', ['$event'])
    async onClick(): Promise<void> {
        await this.hapticFeedbackService.fire(this.style);
    }
}
