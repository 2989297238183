import { DomainPostStepProgressDto } from './domain-post-step-progress.dto';
import { IsArray, ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';

export class DomainPostManyStepProgressDto {
    @IsArray()
    @ValidateNested({ each: true })
    @Type(() => DomainPostStepProgressDto)
    stepProgress!: DomainPostStepProgressDto[];
}
