import { Component, inject, Input, ViewEncapsulation } from '@angular/core';
import { HeaderComponent } from '@frontend/feature/header';
import { ModalService } from '@frontend/utility/modal';
import { IonContent, IonHeader, IonToolbar } from '@ionic/angular/standalone';
import { TrainerInfoModalOptions } from './trainer-info-modal-options.model';

@Component({
    selector: 'app-trainer-info-modal',
    templateUrl: './trainer-info-modal.component.html',
    styleUrl: './trainer-info-modal.component.scss',
    encapsulation: ViewEncapsulation.None, // TODO: NBSon - look into this, can cause leaky css
    imports: [IonContent, HeaderComponent, IonHeader, IonToolbar],
})
export class TrainerInfoModalComponent {
    public readonly modalService = inject(ModalService);
    @Input() supportExpert: TrainerInfoModalOptions | undefined;

    closeModal(): void {
        void this.modalService.dismiss({
            dismissed: true,
        });
    }
}
