import { inject, Injectable } from '@angular/core';
import { ModalService } from '@frontend/utility/modal';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { trackEvent } from '@frontend/data-access/analytics';
import { RatingQualifierModalComponent } from '../rating-qualifier-modal.component';
import { RATING_QUALIFIER_MODAL_ID } from '../rating-qualifier-modal.constants';
import { launchRatingQualifierModal } from './rating-qualifier-modal.actions';
import { selectShouldLaunchRatingReviewModal } from './rating-qualifier-modal.selectors';
import { storeUserInteraction } from '@frontend/data-access/user-interactions';

@Injectable()
export class RatingQualifierModalEffects {
    private readonly store = inject(Store);
    private readonly actions$ = inject(Actions);
    private readonly modalService = inject(ModalService);

    showAppRatingQualifier$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(launchRatingQualifierModal),
            concatLatestFrom(() => this.store.select(selectShouldLaunchRatingReviewModal)),
            filter(([_, shouldLaunchRatingPrompt]) => shouldLaunchRatingPrompt),
            tap(() => {
                void this.modalService.showModal({
                    component: RatingQualifierModalComponent,
                    cssClass: 'modal-center',
                });
            }),
            switchMap(([{ trigger }]) => [
                storeUserInteraction({ id: RATING_QUALIFIER_MODAL_ID }),
                trackEvent({ eventName: '[Rating Qualifier] Show Modal', eventProperties: { trigger } }),
            ]),
        );
    });
}
