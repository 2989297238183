import { inject, Injectable } from '@angular/core';
import { FactorId, MultiFactorInfo } from '@angular/fire/auth';
import { Store } from '@ngrx/store';
import { BehaviorSubject, lastValueFrom, Observable, of, tap, throwError } from 'rxjs';
import { TotpSecret, User } from '../models/user.model';
import { watchForLoginStateChange } from '../store/authentication.actions';
import { AuthenticationService } from './authentication.service';

const mockAuthLoggedInKey = 'mock-auth-service-logged-in';

@Injectable()
export class MockAuthenticationService extends AuthenticationService {
    private readonly store = inject(Store);

    public token$: Observable<string | null> = of(null);

    private loggedIn$ = new BehaviorSubject<boolean>(false);
    private suffix = 'from-mock-auth-service';
    private innerUser?: User;

    constructor() {
        super();

        if (localStorage.getItem(mockAuthLoggedInKey)) {
            console.log('[Mock Authentication Service] Logged in user from local storage');

            this.loggedIn$.next(true);

            this.innerUser = {
                email: 'logged.in.from.localstorage@test.navara.nl',
                id: 'logged-in-from-local-storage',
                signInProvider: '',
            };
        }
    }

    public get user(): User | undefined {
        return undefined;
    }

    public initialize(): void {
        console.log('[Mock Authentication Service] Initialize');

        this.store.dispatch(watchForLoginStateChange());
    }

    public signUpWithEmailAndPassword(email: string, password: string): Observable<{ user: User }> {
        console.log(`[Mock Authentication Service] Sign Up With Email And Password ${email}`);

        return this.signUpOrLogin(email, password);
    }

    public loginWithEmailAndPassword(email: string, password: string): Observable<{ user: User }> {
        console.log(`[Mock Authentication Service] Login With Email And Password ${email}`);

        return this.signUpOrLogin(email, password);
    }

    public async loginWithEmailAndPasswordAndTotp(
        email: string,
        password: string,
        code: string,
    ): Promise<{ user: User }> {
        console.log(`[Mock Authentication Service] Login With Email And Password And Totp ${email}, ${code}`);

        return await lastValueFrom(this.signUpOrLogin(email, password));
    }

    public loginWithGoogle(): any {
        console.log('[Mock Authentication Service] Login With Google');
    }

    public loginWithApple(): any {
        console.log('[Mock Authentication Service] Login With Apple');
    }

    // Custom method - not part of the abstract class
    public signUpOrLogin(email: string, password: string): Observable<{ user: User }> {
        console.log(`[Mock Authentication Service] Sign Up Or Login ${email}`);

        if (password === 'wrong-password') {
            return throwError(() => new Error('Authentication error triggered in MockAuthenticationService'));
        }

        this.innerUser = { email, id: `fake-id-${this.suffix}`, signInProvider: '' };
        this.token$ = of(`fake-token-${this.suffix}`);

        this.loggedIn$.next(true);

        return of({ user: this.innerUser });
    }

    public logout(): Observable<void> {
        console.log('[Mock Authentication Service] Logout');

        localStorage.removeItem(mockAuthLoggedInKey);

        this.innerUser = undefined;

        this.loggedIn$.next(false);

        this.token$ = of(null);

        return of<void>(undefined);
    }

    public isLoggedIn(): Observable<boolean> {
        return this.loggedIn$.pipe(
            tap((value) => {
                console.log(`[Mock Authentication Service] Login State changed to: ${value}`);
            }),
        );
    }

    public requestPasswordReset(email: string): Observable<void> {
        console.log(`[Mock Authentication Service] Request Password Reset ${email}`);

        return of<void>(undefined);
    }

    public getSignInProvider(): Observable<string> {
        console.log('[Mock Authentication Service] Get Sign In Provider');

        return of('password');
    }

    public generateTOTPSecret(): Promise<TotpSecret> {
        console.log('[Mock Authentication Service] Generate TOTP Secret');

        return Promise.resolve({
            key: 'hello darkness',
            url: 'my old friend',
        });
    }

    public async getMultiFactorInfo(): Promise<MultiFactorInfo[]> {
        console.log('[Mock Authentication Service] Get Enrolled Multi Factor Info');

        return [
            {
                uid: 'factor-id-123',
                displayName: 'Zigzag MFA',
                enrollmentTime: new Date().toUTCString(),
                factorId: FactorId.TOTP,
            },
        ];
    }

    public enrollUserTOTP(): Promise<void> {
        console.log('[Mock Authentication Service] Enroll User TOTP');

        return Promise.resolve();
    }

    public unenrollMultiFactor(): Promise<void> {
        console.log('[Mock Authentication Service] Unenroll Multi Factor');

        return Promise.resolve();
    }
}
