import { Component, inject } from '@angular/core';
import { HeaderComponent } from '@frontend/feature/header';
import { IonContent, IonHeader, IonToolbar } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { closePaymentModalClicked } from '../../store/payment/store/payment.actions';
import { PaymentModalTieredPricingComponent } from '../payment-modal-tiered-pricing/payment-modal-tiered-pricing.component';

@Component({
    selector: 'app-payment-modal-parent',
    templateUrl: './payment-modal-parent.component.html',
    styleUrl: './payment-modal-parent.component.scss',
    imports: [HeaderComponent, IonContent, IonHeader, IonToolbar, PaymentModalTieredPricingComponent],
})
export class PaymentModalParentComponent {
    private readonly store = inject(Store);

    public onCloseClick(): void {
        this.store.dispatch(closePaymentModalClicked());
    }
}
