@for (option of options(); track option.title; let i = $index) {
    <button
        class="single-select-button as-div"
        type="button"
        [id]="option.value"
        [attr.data-test]="'single-select-button-' + option.value"
        (click)="onOptionSelected(option.value)"
    >
        @if (isSelected(option.value)) {
            <div class="single-select-button__selected-icon-container">
                <i class="single-select-button__selected-icon fa-solid fa-circle-check"></i>
            </div>
        } @else {
            <div class="single-select-button__selected-icon-container">
                <i class="single-select-button__selected-icon fa-light fa-circle"></i>
            </div>
        }

        <p class="single-select-button__text no-margin">
            {{ option.title }}
        </p>
    </button>
}
