import { AsyncPipe, NgStyle } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { trackEvent } from '@frontend/data-access/analytics';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { selectIsGBUser } from '@frontend/data-access/user/household';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { HeaderComponent } from '@frontend/feature/header';
import { AuthorChipComponent } from '@frontend/ui/author-chip';
import { GenericCardSlimComponent } from '@frontend/ui/generic-cards';
import { VoucherMediumComponent } from '@frontend/ui/voucher-medium';
import {
    IonButton,
    IonCard,
    IonContent,
    IonFooter,
    IonHeader,
    IonToolbar,
    NavController,
    ViewDidEnter,
} from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { DynamicContentBlockComponent } from '../../../step/dynamic-content-block/dynamic-content-block.component';
import { showLilysKitchenTreatModal, showSkipLessonNotificationModal } from '../../../store/modal/modal.actions';
import { showPaymentModal } from '../../../store/payment/store/payment.actions';
import { Step, StepStatus } from '../../../store/step/step.model';
import { launchTrainingReminderModal } from '../../../training-reminder/store/training-reminder-modal.actions';
import { selectSelectedLessonPagesVm } from '../lesson.page.selectors';

@Component({
    selector: 'app-lesson-intro-page',
    templateUrl: './lesson-intro.page.html',
    styleUrls: ['../lesson.scss', './lesson-intro.page.scss'],
    imports: [
        AnalyticsTrackClickDirective,
        AsyncPipe,
        AuthorChipComponent,
        DynamicContentBlockComponent,
        GenericCardSlimComponent,
        HeaderComponent,
        IonButton,
        IonCard,
        IonContent,
        IonFooter,
        IonHeader,
        IonToolbar,
        NgStyle,
        TextReplacementPipe,
        VoucherMediumComponent,
    ],
})
export class LessonIntroPage implements ViewDidEnter {
    private readonly store = inject(Store);
    private readonly activatedRoute = inject(ActivatedRoute);
    private readonly navController = inject(NavController);
    private readonly router = inject(Router);

    public isGBUser = this.store.selectSignal(selectIsGBUser);

    public vm$ = this.store.select(selectSelectedLessonPagesVm);

    protected readonly StepState = StepStatus;
    protected readonly Color = Color;

    public ionViewDidEnter(): void {
        this.store.dispatch(
            trackEvent({
                eventName: 'click',
                eventProperties: {
                    identifier: 'step-page-view-intro',
                    page: this.router.url,
                },
            }),
        );
    }

    public nextPage(): void {
        void this.navController.navigateForward(['../instruction'], {
            animated: true,
            relativeTo: this.activatedRoute,
            queryParams: this.activatedRoute.snapshot.queryParams,
        });
    }

    public openPaymentModal(): void {
        this.store.dispatch(showPaymentModal({ trigger: 'lesson-page' }));
    }

    public previousPage(): void {
        this.navController.back();
    }

    public closeLesson(): void {
        this.navController.back({ animated: false });
    }

    public setTrainingReminder(dogName: string): void {
        this.store.dispatch(launchTrainingReminderModal({ dogName, trigger: 'lesson-intro-page' }));
    }

    public onSkipClick(step: Step): void {
        this.store.dispatch(
            showSkipLessonNotificationModal({
                step,
            }),
        );
    }

    public lilysVoucherClicked(): void {
        this.store.dispatch(showLilysKitchenTreatModal());
    }
}
