import { AsyncPipe, Location, NgClass } from '@angular/common';
import { AfterViewInit, Component, inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { destroyChat } from '@frontend/data-access/chat';
import { HeaderComponent } from '@frontend/feature/header';
import { LoadingScreenComponent } from '@frontend/ui/loading-screen';
import { IonContent, IonHeader, IonToolbar, Platform, ViewWillEnter, ViewWillLeave } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { ChatChannelType } from '@shared/constants';
import { Subscription } from 'rxjs';
import { CustomTemplatesService, MessageContext, StreamChatModule, StreamMessage } from 'stream-chat-angular';
import { chatPageLoaded, userSentPuppyCoachMessage } from '../chat.actions';
import { TrainingCoachChatIntroComponent } from './chat-intro/training-coach-chat-intro.component';
import { selectTrainingCoachChatPageVm } from './training-coach-chat.page.selectors';

@Component({
    selector: 'app-training-coach-chat-page',
    templateUrl: './training-coach-chat.page.html',
    styleUrl: './training-coach-chat.page.scss',
    imports: [
        AsyncPipe,
        HeaderComponent,
        IonContent,
        IonHeader,
        IonToolbar,
        LoadingScreenComponent,
        NgClass,
        StreamChatModule,
        TrainingCoachChatIntroComponent,
    ],
})
export class TrainingCoachChatPage implements OnInit, AfterViewInit, OnDestroy, ViewWillEnter, ViewWillLeave {
    private readonly store = inject(Store);
    private readonly platform = inject(Platform);
    private readonly location = inject(Location);
    private readonly customTemplatesService = inject(CustomTemplatesService);

    @ViewChild('messageTemplate')
    private messageTemplate!: TemplateRef<MessageContext>;

    public vm$ = this.store.select(selectTrainingCoachChatPageVm);

    private backButtonSub: Subscription = new Subscription();

    public ngOnInit(): void {
        this.store.dispatch(chatPageLoaded({ chatType: ChatChannelType.AGENT }));
    }

    public ngAfterViewInit(): void {
        this.customTemplatesService.messageTemplate$.next(this.messageTemplate);
    }

    public ngOnDestroy(): void {
        this.store.dispatch(destroyChat());
    }

    public ionViewWillEnter(): void {
        this.backButtonSub = this.platform.backButton.subscribeWithPriority(20, () => this.routeToPrevious());
    }

    public ionViewWillLeave(): void {
        this.backButtonSub.unsubscribe();
    }

    public routeToPrevious(): void {
        this.location.back();
    }

    public messageSent(event: { message: StreamMessage }): void {
        const attachments = event.message?.attachments?.map((attachment) => attachment.type) ?? [];
        this.store.dispatch(
            userSentPuppyCoachMessage({
                imagesAttached: attachments.filter((type) => type === 'image').length,
                videosAttached: attachments.filter((type) => type === 'video').length,
                audioAttached: attachments.filter((type) => type === 'audio').length,
            }),
        );
    }
}
