import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { HeaderComponent } from '@frontend/feature/header';
import { SearchBarComponent } from '@frontend/ui/search-bar';
import { IonContent, IonHeader, IonItem, IonLabel, IonList, IonToolbar } from '@ionic/angular/standalone';
import MiniSearch, { SearchResult } from 'minisearch';
import { FoodProduct } from '../../../data-access/food-calculator.model';
import { FoodProductModalService } from '../food-product-modal.service';

@Component({
    selector: 'app-food-product-selector-modal',
    templateUrl: './food-product-selector-modal.component.html',
    styleUrl: './food-product-selector-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [SearchBarComponent, IonContent, IonList, IonItem, IonLabel, HeaderComponent, IonHeader, IonToolbar],
})
export class FoodProductSelectorModalComponent {
    private readonly foodProductModalService = inject(FoodProductModalService);
    public results: SearchResult[] = [];
    private miniSearch: MiniSearch<FoodProduct>;
    public allProducts: FoodProduct[] = [];

    @Input() set products(value: FoodProduct[]) {
        this.allProducts = value;
        this.miniSearch.removeAll();
        this.miniSearch.addAll(value);
    }

    constructor() {
        this.miniSearch = new MiniSearch({
            fields: ['name', 'brandName'],
            storeFields: ['id', 'name', 'brandName'],
            searchOptions: {
                fuzzy: 0.4,
                prefix: true,
                boost: { brandName: 11 },
            },
        });
    }

    searchItems(event: string): void {
        this.results = this.miniSearch.search(event);
    }

    selectProduct({ id, name, brandName }: Partial<FoodProduct>): void {
        void this.foodProductModalService.dismiss({ id, name, brandName });
    }

    public back(): void {
        void this.foodProductModalService.dismiss();
    }
}
