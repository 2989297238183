import { NgStyle } from '@angular/common';
import { Component, EventEmitter, input, Input, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { IonButton, IonInput, IonItem } from '@ionic/angular/standalone';
import { Color } from '@shared/utils/typescript';
import { RadioWithExplanationItem, RadioWithExplanationResult } from './radio-with-explanation.model';

// TODO: NBSon - look into refactoring this component?
// Use CVA, replace with signals, use form group for control + non-nullable
@Component({
    selector: 'ui-radio-with-explanation',
    templateUrl: './radio-with-explanation.component.html',
    styleUrl: './radio-with-explanation.component.scss',
    imports: [IonButton, ReactiveFormsModule, NgStyle, IonItem, IonInput],
})
export class RadioWithExplanationComponent {
    @Input({ required: true }) buttons: RadioWithExplanationItem[] = [];

    @Input() color: Color = Color.Harley;
    public heightStrategy = input<'auto' | 'fixed'>('auto');

    @Output() changeEvent = new EventEmitter<RadioWithExplanationResult>();

    public explanationInput = new FormControl('');
    public selected: RadioWithExplanationItem | undefined;

    public clickButton(value: RadioWithExplanationItem): void {
        if (this.selected === value) {
            this.selected = undefined;
        } else {
            this.selected = value;
        }

        this.valueChange();
    }

    public valueChange() {
        const incomplete =
            !this.selected || (this.selected.explainMore?.required && this.explanationInput.value === '');

        if (!this.selected && this.explanationInput.value !== '') {
            this.explanationInput.reset();
        }

        this.changeEvent.emit({
            complete: !incomplete,
            answer: {
                value: this.selected?.value || '',
                explanation: this.explanationInput.value || '',
            },
        });
    }
}
