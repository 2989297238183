import { InternalPostPartnerUserDto } from '../../../dtos/user/revenue-cat/internal-post-partner-user.dto';

export class InternalPostPartnerUserDtoMock {
    private defaultValue: InternalPostPartnerUserDto = {
        userId: 'uuid-1234',
        freeAccessLengthInDays: 365,
    };

    constructor(overrides?: Partial<InternalPostPartnerUserDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): InternalPostPartnerUserDto {
        return this.defaultValue;
    }
}
