import { inject, Injectable } from '@angular/core';
import { getLocalStorageSuccess, setLocalStorage } from '@frontend/data-access/capacitor';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { ofSourceType } from '@frontend/utility/angular';
import { combineLatest } from 'rxjs';
import { filter, map, take, timestamp } from 'rxjs/operators';
import { storeUserInteraction, storeUserInteractionWithTimestamp } from './user-interactions.actions';
import { USER_INTERACTIONS_ACTION_SOURCE, USER_INTERACTIONS_LOCAL_STORAGE_KEY } from './user-interactions.constants';
import { userInteractionFeature } from './user-interactions.reducer';

@Injectable()
export class UserInteractionsEffects {
    private readonly store = inject(Store);
    private readonly actions$ = inject(Actions);

    storeUserInteraction$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(storeUserInteraction),
            timestamp(),
            map(({ value: { id, dontShowAgain }, timestamp: time }) => {
                return storeUserInteractionWithTimestamp({
                    id,
                    dontShowAgain,
                    timestamp: new Date(time).toISOString(),
                });
            }),
        );
    });

    syncUserInteractionStateWithLocalStorage$ = createEffect(() => {
        return combineLatest([
            this.actions$.pipe(
                // State needs to be read first, before we want to start saving.
                ofType(getLocalStorageSuccess),
                filter(({ key }) => key === USER_INTERACTIONS_LOCAL_STORAGE_KEY),
                take(1),
            ),
            this.actions$.pipe(
                // Save on every action
                ofSourceType(USER_INTERACTIONS_ACTION_SOURCE),
            ),
        ]).pipe(
            concatLatestFrom(() => this.store.select(userInteractionFeature.selectUserInteractionState)),
            map(([, state]) => setLocalStorage({ key: USER_INTERACTIONS_LOCAL_STORAGE_KEY, data: state })),
        );
    });
}
