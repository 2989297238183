import { IsNumber, IsString } from 'class-validator';

export class IterablePostSendEmailDto {
    @IsString()
    recipientUserId!: string;

    @IsNumber()
    campaignId!: number;

    dataFields!: {
        locale: string;
        passwordResetLink?: string;
    };
}
