import { NgModule } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { PushListenersEffects } from './push-listeners/store/push-listeners.effects';
import { capacitorListenersFeature } from './app-listeners/store/app-listeners.reducer';
import { AppListenersEffects } from './app-listeners/store/app-listeners.effects';
import { SocialShareEffects } from './social-share/social-share.effects';
import { LocalStorageEffects } from './local-storage/store/local-storage.effects';
import { OpenSettingsEffects } from './open-settings/store/open-settings.effects';

@NgModule({
    providers: [
        provideState(capacitorListenersFeature),
        provideEffects([
            AppListenersEffects,
            PushListenersEffects,
            SocialShareEffects,
            LocalStorageEffects,
            OpenSettingsEffects,
        ]),
    ],
})
export class ZigzagFrontendDataAccessCapacitorModule {}
