import { inject, Injectable } from '@angular/core';
import { logout } from '@frontend/data-access/user/authentication';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { trackEvent } from '@frontend/data-access/analytics';
import { deleteHousehold, deleteHouseholdSuccess } from '@frontend/data-access/user/household';
import { routeTo } from '@frontend/data-access/router';
import { concatMap, map } from 'rxjs/operators';

@Injectable()
export class DeleteAccountEffects {
    private readonly actions$ = inject(Actions);

    deleteHousehold$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(deleteHousehold),
            map(() => routeTo({ dtos: ['logout'] })),
        );
    });

    deleteHouseholdSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(deleteHouseholdSuccess),
            concatMap(() => [trackEvent({ eventName: 'delete account success' }), logout()]),
        );
    });
}
