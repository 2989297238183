import { SaIterableUpsertUserDto } from '../../dtos/sa-iterable-upsert-user.dto';
import { InternalSaIterableDataFieldsMock } from './internal-sa-iterable-data-fields.mock';

export class InternalSaIterableUpsertUserMock {
    private defaultValue: SaIterableUpsertUserDto = {
        email: 'jasper@mattijs.milos',
        dataFields: new InternalSaIterableDataFieldsMock().value,
    };

    constructor(overrides?: Partial<SaIterableUpsertUserDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): SaIterableUpsertUserDto {
        return this.defaultValue;
    }
}
