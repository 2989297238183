import { IsArray, IsString } from 'class-validator';
import { ConversationTypeID } from '../../models/conversation/conversation.model';
import { InternalPostConversationMessage } from '../conversation-message/internal-post-conversation-message.dto';

export class InternalPostConversationDto {
    @IsString()
    channelId!: string;

    @IsString()
    userId!: string;

    @IsString()
    conversationTypeId!: ConversationTypeID;

    @IsArray()
    messages!: InternalPostConversationMessage[];
}
