@if (vm$ | async; as vm) { @if (vm.step) {
<ion-content class="page-ion-content">
    <div
        class="lesson-intro-page-header"
        [ngStyle]="{ 'background-color': 'var(--ion-color-' + vm.step.topic.color + ')' }"
    >
        <ion-header class="page-header ion-no-border">
            <ion-toolbar>
                <app-header
                    [leftButton]="{ analyticsIdentifier: 'step-prev-btn', background: 'white' }"
                    [rightButton]="{
                                analyticsIdentifier: 'step-close-btn',
                                sprite: 'light',
                                iconName: 'xmark',
                                background: 'white',
                                iconColour: 'black',
                            }"
                    (leftButtonPressed)="previousPage()"
                    (rightButtonPressed)="closeLesson()"
                />
            </ion-toolbar>
        </ion-header>
        <div class="lesson-intro-page-header__image-container">
            <img
                class="lesson-intro-page-header__image"
                [src]="vm.step.topic.imageUrl"
                [alt]="vm.step.topic.title + ' image'"
            />
        </div>
    </div>

    <section class="lesson-intro-page-content lesson-column">
        <div class="lesson-intro-page-content__situation">
            <span data-test="lesson-intro-page-duration">{{ vm.step.content.duration }} min | </span>
            <i class="fa-light {{ vm.locationIcon }} lesson-intro-page-content__location-icon"></i>
            <span data-test="lesson-intro-page-location">{{ vm.step.content.location }}</span>
        </div>

        <h1 data-test="step-intro-page-title">{{ vm.step.content.title | textReplacement }}</h1>

        @if (vm.step.content.lessonAuthor) {
        <ui-author-chip
            [author]="vm.step.content.lessonAuthor.authorName"
            [authorImageUrl]="vm.step.content.lessonAuthor.authorImageUrl"
        />
        }

        <p data-test="step-intro-page-introduction">{{ vm.step.content.introduction | textReplacement }}</p>

        @for (dynamicContent of vm.step.content.introductionDynamicContent; track dynamicContent.title) {
        <app-dynamic-content-block [title]="dynamicContent.title" [content]="dynamicContent.content" />
        } @if (vm.showWhatsNeeded && vm.step.content.whatsNeeded) {
        <ion-card class="lesson-intro-page-whats-needed">
            <h2 class="no-margin-top">You will need</h2>
            <div class="lesson-intro-page-whats-needed__container ion-no-padding">
                @if (vm.step.content.whatsNeeded.treats.length > 0) {
                <div class="lesson-intro-page-whats-needed__container-item">
                    <p class="no-margin">
                        <i class="fa-regular fa-bone lesson-intro-page-whats-needed__icon"></i>
                    </p>
                    <div>
                        @for (treat of vm.step.content.whatsNeeded.treats; track treat) {
                        <p class="lesson-intro-page-whats-needed__text no-margin">{{ treat }}</p>
                        }
                    </div>
                </div>
                } @if (vm.step.content.whatsNeeded.toys.length > 0) {
                <div class="lesson-intro-page-whats-needed__container-item">
                    <p class="no-margin">
                        <i class="fa-regular fa-duck lesson-intro-page-whats-needed__icon"></i>
                    </p>
                    <div>
                        @for (toy of vm.step.content.whatsNeeded.toys; track toy) {
                        <p class="lesson-intro-page-whats-needed__text no-margin">{{ toy }}</p>
                        }
                    </div>
                </div>
                } @if (vm.step.content.whatsNeeded.equipmentAccessories.length > 0) {
                <div class="lesson-intro-page-whats-needed__container-item">
                    <p class="no-margin">
                        <i class="fa-regular fa-briefcase lesson-intro-page-whats-needed__icon"></i>
                    </p>
                    <div>
                        @for (equipment of vm.step.content.whatsNeeded.equipmentAccessories; track equipment) {
                        <p class="lesson-intro-page-whats-needed__text no-margin">{{ equipment }}</p>
                        }
                    </div>
                </div>
                } @if (vm.step.content.whatsNeeded.other.length > 0) {
                <div class="lesson-intro-page-whats-needed__container-item">
                    <p class="no-margin">
                        <i class="fa-regular fa-paw lesson-intro-page-whats-needed__icon"></i>
                    </p>
                    <div>
                        @for (thing of vm.step.content.whatsNeeded.other; track thing) {
                        <p class="lesson-intro-page-whats-needed__text no-margin">{{ thing }}</p>
                        }
                    </div>
                </div>
                }
            </div>
        </ion-card>
        } @if (vm.showWhatsNeeded && vm.step.content.whatsNeeded && vm.step.content.whatsNeeded.treats.length > 0 &&
        isGBUser()) {
        <ui-voucher-medium
            [heading]="'Better training starts with the perfect treat 🦴'"
            [subHeading]="'Enjoy exclusive access to this treat!'"
            [cardColour]="'alvin'"
            [buttonColour]="'max'"
            [buttonText]="'Get 30% OFF'"
            [imageUrl]="'assets/images/third-party/lilys-kitchen/lilys-kitchen-logo-light.svg'"
            [imageAlt]="'Lily\'s Kitchen Logo'"
            [locked]="false"
            (handleButtonClick)="lilysVoucherClicked()"
        />
        } @if (vm.shouldShowSelfSetReminderTile) {
        <h2>Want to train later?</h2>
        <ui-generic-card-slim
            data-test="lesson-intro-training-reminder-card"
            [background]="Color.Luna"
            [imageUrl]="'assets/images/charly/charly-gets-notification.svg'"
            [title]="'Set training reminder'"
            [unlocked]="true"
            (click)="setTrainingReminder(vm.dogName)"
        />
        }
    </section>

    <ion-footer class="gradient-footer lesson-intro-page-footer">
        @if (vm.unlocked) { @if (!(vm.step.progress.state === StepState.COMPLETED || vm.step.progress.state ===
        StepState.SKIPPED)) {
        <ion-button
            class="lesson-intro-page-footer__skip"
            appTrackClick
            identifier="step-intro-skip-lesson"
            color="max"
            expand="block"
            fill="outline"
            data-test="lesson-intro-skip-lesson-btn"
            (click)="onSkipClick(vm.step)"
        >
            Skip
        </ion-button>
        }
        <ion-button
            class="lesson-intro-page-footer__start"
            appTrackClick
            identifier="step-page-view-steps"
            color="max"
            expand="block"
            data-test="lesson-intro-start-lesson-btn"
            (click)="nextPage()"
        >
            Start
        </ion-button>
        } @else {
        <ion-button
            class="lesson-intro-page-footer__start"
            appTrackClick
            identifier="step-intro-start-free-trial-btn"
            color="max"
            expand="block"
            data-test="lesson-intro-start-free-trial-btn"
            (click)="openPaymentModal()"
        >
            <i class="fa-regular fa-lock-keyhole ion-margin-end icon-large lesson-intro-page-footer__padlock-icon"></i>
            {{ !vm.hasHistoricalPurchase ? 'Start my free trial' : 'See plans' }}
        </ion-button>
        }
    </ion-footer>
</ion-content>
} }
