import { Component } from '@angular/core';
import { openUrl } from '@frontend/data-access/router';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { ProgressHeaderComponent } from '@frontend/ui/progress-header';
import { IonButton, IonContent, IonFooter, IonHeader, IonSpinner, IonToolbar } from '@ionic/angular/standalone';
import { onboardingQuestionSubmittedPurinaOptInUkAb } from '../../data-access/onboarding.actions';
import { OnboardingQuestionBasePage } from '../question-base.page';

@Component({
    selector: 'app-onboarding-question-purina-opt-in-uk-ab',
    templateUrl: './question-purina-opt-in-uk-ab.page.html',
    styleUrls: ['./question-purina-opt-in-uk-ab.page.scss', '../onboarding.scss'],
    imports: [
        AnalyticsTrackClickDirective,
        IonButton,
        IonContent,
        IonFooter,
        IonHeader,
        IonSpinner,
        IonToolbar,
        ProgressHeaderComponent,
    ],
})
export class OnboardingQuestionPurinaOptInUkAbPage extends OnboardingQuestionBasePage {
    public onExternalLinkClick(url: string): void {
        this.store.dispatch(openUrl({ url }));
    }

    public onOptInClick(optedIn: boolean) {
        if (this.isLoading()) {
            return;
        }

        this.store.dispatch(onboardingQuestionSubmittedPurinaOptInUkAb({ purinaMarketingAccepted: optedIn }));
    }
}
