import { Component, Input } from '@angular/core';
import { IonCard } from '@ionic/angular/standalone';
import { Color } from '@shared/utils/typescript';

@Component({
    selector: 'ui-simple-text-card-slim',
    imports: [IonCard],
    templateUrl: './simple-text-card-slim.component.html',
    styleUrl: './simple-text-card-slim.component.scss',
})
export class SimpleTextCardSlimComponent {
    @Input({ required: true }) title!: string;
    @Input({ required: true }) background!: Color;
    @Input({ required: true }) hasArrow!: boolean;
}
