import { Actions, createEffect, ofType } from '@ngrx/effects';
import { inject, Injectable } from '@angular/core';
import { concatMap } from 'rxjs';
import { lilysKitchenTreatVoucherClicked } from './lesson.actions';
import { openUrl } from '@frontend/data-access/router';
import { trackEvent } from '@frontend/data-access/analytics';

@Injectable()
export class LessonEffects {
    private readonly actions$ = inject(Actions);

    lilysKitchenTreatVoucherClicked$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(lilysKitchenTreatVoucherClicked),
            concatMap(({ url }) => {
                return [openUrl({ url }), trackEvent({ eventName: '[Lilys Kitchen Treat Voucher] Claimed' })];
            }),
        );
    });
}
