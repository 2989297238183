<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <ui-progress-header
            iconColour="black"
            [progressRatio]="progress()"
            [showBackButton]="!!previousOnboardingPage()"
            [showProgress]="false"
            (backButtonClicked)="onBackClicked()"
        />
    </ion-toolbar>
</ion-header>

<ion-content class="onboarding-ion-content" [fullscreen]="true">
    <main class="nourish-signpost page-content">
        <p class="nourish-signpost__speech-bubble no-margin s" data-test="nourish-signpost-speech-bubble">
            You're almost there! Feeding and training go paw-in-paw 🐾 for a lifetime of happiness 🐶
        </p>

        <h3 class="nourish-signpost__title no-margin" data-test="nourish-signpost-title">
            Zigzag has helped <span class="nourish-signpost__highlight scale-up">53,141</span> pup parents to feed their
            pups with confidence
        </h3>

        <ul class="nourish-signpost-list">
            @for (bullet of bulletPoints; let i = $index; track bullet) {
            <li class="nourish-signpost-list__item">
                <i class="nourish-signpost-list__circle-icon fa-solid fa-circle-check"></i>
                <p class="nourish-signpost-list__bullet-text" [attr.data-test]="'nourish-signpost-bullet-point-' + i">
                    {{ bullet | textReplacement }}
                </p>
            </li>
            }
        </ul>
    </main>
</ion-content>

<ion-footer class="nourish-signpost-footer ion-text-center page-footer">
    <ion-button
        appTrackClick
        identifier="nourish-signpost-next-btn"
        expand="block"
        data-test="nourish-signpost-next-btn"
        [color]="Color.Max"
        (click)="onContinueClick()"
    >
        Continue
    </ion-button>
</ion-footer>
