import { IterablePostEventDto } from '../dtos/iterable-post-event.dto';

export class InternalIterablePostEventDtoMock {
    private defaultValue: IterablePostEventDto = {
        userId: 'user-id',
        eventName: '[Event Source] Event Name',
        createdAt: 1,
        dataFields: {
            important: 'value',
        },
    };

    constructor(overrides?: Partial<IterablePostEventDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): IterablePostEventDto {
        return this.defaultValue;
    }
}
