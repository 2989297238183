import { inject, Injectable } from '@angular/core';
import { trackEvent } from '@frontend/data-access/analytics';
import { openTailsUrl, openUrl, routeTo, routeToPrevious } from '@frontend/data-access/router';
import {
    isItemInteracted,
    storeUserInteraction,
    userInteractionFeature,
} from '@frontend/data-access/user-interactions';
import { ModalService } from '@frontend/utility/modal';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { EMPTY, tap } from 'rxjs';
import { delay, filter, map } from 'rxjs/operators';
import { routeToAiChat } from '../../chat/chat.actions';
import { tabClicked } from '../../tabs/store/tab.actions';
import { TabRoute_NOURISH } from '../../tabs/tabs.constants';
import {
    NOURISH_PRODUCT_FEEDBACK_CLOSE_DELAY,
    NOURISH_PRODUCT_FEEDBACK_MODAL_ID,
    NOURISH_TAB_INDICATOR_ID,
    NourishModalId,
    NourishProductFeedbackModalState,
    NourishTileId,
    PET_FOOD_FINDER_LINK,
    TAILS_ADULT_CTA_LINK,
    TAILS_PUPPY_CTA_LINK,
} from '../constants/nourish.constants';
import { HelpWithTummyTroublesModalComponent } from '../modals/help-with-tummy-troubles/help-with-tummy-troubles-modal.component';
import { HowMuchExerciseModalComponent } from '../modals/how-much-exercise-is-enough/how-much-exercise-is-enough-modal.component';
import { HowToWeighYourDogModalComponent } from '../modals/how-to-weigh-your-dog-modal/how-to-weigh-your-dog-modal.component';
import { MakingTheRightFoodChoiceModalComponent } from '../modals/making-the-right-food-choice/making-the-right-food-choice-modal.component';
import { NutritionModalComponent } from '../modals/nutrition-modal/nutrition-modal.component';
import { PreventDiarrhoeaModalComponent } from '../modals/prevent-diarrhoea-modal/prevent-diarrhoea-modal.component';
import { PupNotEatingModalComponent } from '../modals/pup-not-eating-modal/pup-not-eating-modal.component';
import { WhatCanPoopTellYouModalComponent } from '../modals/what-can-poop-tell-you-modal/what-can-poop-tell-you-modal.component';
import { WhenAndHowToSwitchFoodModalComponent } from '../modals/when-and-how-to-switch-food-modal/when-and-how-to-switch-food-modal.component';
import { NourishProductFeedbackModalComponent } from '../nourish-product-feedback-modal/nourish-product-feedback-modal.component';
import {
    closeNourishModal,
    closeNourishProductFeedbackModal,
    nourishCalculateFoodButtonClicked,
    nourishFindFoodButtonClicked,
    nourishProductCtaClicked,
    nourishProductFeedbackModalSubmitted,
    nourishProductFeedbackModalThumbsDownClicked,
    nourishProductHeaderBackButtonClicked,
    nourishProductTileClicked,
    nourishSuggestedAiChatQuestionClicked,
    nourishTileClicked,
    openNourishModal,
} from './nourish.actions';

// TODO: NBSon - think about splitting up effects into smaller files, analytics, modals, routing, etc.
// also think about dropping the word 'nourish' in effects, as it's already in the file name
// think about making generic effects/store for modal
@Injectable()
export class NourishEffects {
    private readonly store = inject(Store);
    private readonly actions$ = inject(Actions);
    private readonly modalService = inject(ModalService);

    // -- Routing

    routeToNourishFoodCalculator$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishCalculateFoodButtonClicked),
            map(() => {
                return routeTo({ dtos: ['main', 'nourish', 'food-portion-calculator'] });
            }),
        );
    });

    routeForwardToNourish$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishTileClicked),
            map(() => {
                return routeTo({ dtos: ['main', 'nourish'] });
            }),
        );
    });

    routeBackToNourish$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishProductHeaderBackButtonClicked),
            map(() => {
                return routeToPrevious({ dtos: ['main', 'nourish'] });
            }),
        );
    });

    routeToProductPage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishProductTileClicked),
            map(({ id }) => {
                return routeTo({
                    dtos: ['main', 'nourish', 'product', id],
                });
            }),
        );
    });

    routeToAiChat$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishSuggestedAiChatQuestionClicked),
            map(({ optionId }) => {
                return routeToAiChat({ message: optionId });
            }),
        );
    });

    // -- Other

    openNourishModal$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(openNourishModal),
                tap(({ id }) => {
                    let component;

                    switch (id) {
                        case NourishTileId.WHAT_CAN_POOP_TELL_YOU: {
                            component = WhatCanPoopTellYouModalComponent;
                            break;
                        }
                        case NourishTileId.PREVENT_DIARRHOEA: {
                            component = PreventDiarrhoeaModalComponent;
                            break;
                        }
                        case NourishTileId.RIGHT_FOOD_CHOICE: {
                            component = MakingTheRightFoodChoiceModalComponent;
                            break;
                        }
                        case NourishTileId.SWITCH_FOOD: {
                            component = WhenAndHowToSwitchFoodModalComponent;
                            break;
                        }
                        case NourishTileId.HOW_MUCH_EXERCISE: {
                            component = HowMuchExerciseModalComponent;
                            break;
                        }
                        case NourishTileId.PUP_NOT_EATING: {
                            component = PupNotEatingModalComponent;
                            break;
                        }
                        case NourishTileId.WEIGH_YOUR_DOG: {
                            component = HowToWeighYourDogModalComponent;
                            break;
                        }
                        case NourishTileId.NUTRITIONAL_CONSULTATION: {
                            component = NutritionModalComponent;
                            break;
                        }
                        case NourishTileId.TUMMY_TROUBLES: {
                            component = HelpWithTummyTroublesModalComponent;
                            break;
                        }
                        default: {
                            return EMPTY;
                        }
                    }

                    return this.modalService.showModal({
                        component,
                        cssClass: ['modal', 'modal-large'],
                        // TODO: NBSon - we should look into creating a directive/service for this, confirm with Marija if we want it so that
                        // modals without a back arrow should all inherit this behaviour
                        /**
                         * NBSon - this is used in combination with the `ionViewWillEnter` and `ionViewWillLeave` lifecycle
                         * to prevent the user from being able to dismiss the modal through the back gesture/Android back button
                         * see {@link NourishModalBaseComponent.ionViewWillEnter}
                         */
                        backdropDismiss: false,
                    });
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    openNourishModalOnNourishTileClicked$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishTileClicked),
            filter(({ id }) => id !== NourishTileId.CALCULATE_FOOD && id !== NourishTileId.FIND_FOOD),
            map(({ id }) => {
                return openNourishModal({ id });
            }),
        );
    });

    closeNourishModal$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(closeNourishModal),
                tap(() => {
                    void this.modalService.dismiss();
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    closeNourishModalWhenProductTileClicked$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishProductTileClicked),
            filter(({ correlationId }) => correlationId === 'modal'),
            map(() => closeNourishModal({})),
        );
    });

    dismissCalculateFoodNourishTile$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishCalculateFoodButtonClicked),
            concatLatestFrom(() => this.store.select(userInteractionFeature.selectUserInteractions)),
            filter(([, userInteractions]) => !isItemInteracted(userInteractions, NourishTileId.CALCULATE_FOOD)),
            map(() => {
                return storeUserInteraction({
                    id: NourishTileId.CALCULATE_FOOD,
                    dontShowAgain: true,
                });
            }),
        );
    });

    dismissFindFoodNourishTile$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishFindFoodButtonClicked),
            concatLatestFrom(() => this.store.select(userInteractionFeature.selectUserInteractions)),
            filter(([, userInteractions]) => !isItemInteracted(userInteractions, NourishTileId.FIND_FOOD)),
            map(() => {
                return storeUserInteraction({
                    id: NourishTileId.FIND_FOOD,
                    dontShowAgain: true,
                });
            }),
        );
    });

    openPetFoodFinder$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishFindFoodButtonClicked),
            map(() => {
                return openUrl({
                    url: PET_FOOD_FINDER_LINK,
                });
            }),
        );
    });

    dismissNourishIndicator$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(tabClicked, nourishTileClicked),
            concatLatestFrom(() => this.store.select(userInteractionFeature.selectUserInteractions)),
            filter(
                ([{ id }, userInteractions]) =>
                    (id === TabRoute_NOURISH ||
                        id === NourishTileId.CALCULATE_FOOD ||
                        id === NourishTileId.FIND_FOOD) &&
                    !isItemInteracted(userInteractions, NOURISH_TAB_INDICATOR_ID),
            ),
            map(() => {
                return storeUserInteraction({
                    id: NOURISH_TAB_INDICATOR_ID,
                    dontShowAgain: true,
                });
            }),
        );
    });

    openTails$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishProductCtaClicked),
            filter(({ url }) => url === TAILS_ADULT_CTA_LINK || url === TAILS_PUPPY_CTA_LINK),
            map(({ url }) => openTailsUrl({ url })),
        );
    });

    openExternalUrl$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishProductCtaClicked),
            filter(({ url }) => url !== TAILS_ADULT_CTA_LINK && url !== TAILS_PUPPY_CTA_LINK),
            map(({ url }) => {
                return openUrl({
                    url,
                });
            }),
        );
    });

    showProductFeedbackModal$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(nourishProductHeaderBackButtonClicked),
                concatLatestFrom(() => this.store.select(userInteractionFeature.selectUserInteractions)),
                filter(
                    ([{ id }, dismissedInfo]) =>
                        !isItemInteracted(dismissedInfo, `${NOURISH_PRODUCT_FEEDBACK_MODAL_ID}-${id}`),
                ),
                tap(([{ id }]) => {
                    return this.modalService.showModal({
                        id: NOURISH_PRODUCT_FEEDBACK_MODAL_ID,
                        component: NourishProductFeedbackModalComponent,
                        cssClass: ['modal', 'nourish-product-review-modal'],
                        componentProps: {
                            id,
                        },
                    });
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    addProductFeedbackInteraction$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishProductHeaderBackButtonClicked),
            concatLatestFrom(() => this.store.select(userInteractionFeature.selectUserInteractions)),
            filter(
                ([{ id }, dismissedInfo]) =>
                    !isItemInteracted(dismissedInfo, `${NOURISH_PRODUCT_FEEDBACK_MODAL_ID}-${id}`),
            ),
            map(([{ id }]) => {
                return storeUserInteraction({
                    id: `${NOURISH_PRODUCT_FEEDBACK_MODAL_ID}-${id}`,
                    dontShowAgain: true,
                });
            }),
        );
    });

    showProductFeedbackModalForNutritionalConsultation$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(closeNourishModal),
                concatLatestFrom(() => this.store.select(userInteractionFeature.selectUserInteractions)),
                filter(([{ id }, dismissedInfo]) => {
                    return (
                        id === NourishModalId.NUTRITIONAL_CONSULTATION &&
                        !isItemInteracted(dismissedInfo, `${NOURISH_PRODUCT_FEEDBACK_MODAL_ID}-${id}`)
                    );
                }),
                tap(([{ id }]) => {
                    return this.modalService.showModal({
                        id: NOURISH_PRODUCT_FEEDBACK_MODAL_ID,
                        component: NourishProductFeedbackModalComponent,
                        cssClass: ['modal', 'nourish-product-review-modal'],
                        componentProps: {
                            id,
                        },
                    });
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    addNutritionalConsultationFeedbackInteraction$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(closeNourishModal),
            concatLatestFrom(() => this.store.select(userInteractionFeature.selectUserInteractions)),
            filter(([{ id }, dismissedInfo]) => {
                return (
                    id === NourishModalId.NUTRITIONAL_CONSULTATION &&
                    !isItemInteracted(dismissedInfo, `${NOURISH_PRODUCT_FEEDBACK_MODAL_ID}-${id}`)
                );
            }),
            map(([{ id }]) => {
                return storeUserInteraction({
                    id: `${NOURISH_PRODUCT_FEEDBACK_MODAL_ID}-${id}`,
                    dontShowAgain: true,
                });
            }),
        );
    });

    setProductFeedbackModalClass$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(nourishProductFeedbackModalThumbsDownClicked),
                tap(({ state }) => {
                    const modalClasses = ['modal', 'nourish-product-review-modal'];

                    if (state === NourishProductFeedbackModalState.NEGATIVE) {
                        modalClasses.push('nourish-product-review-modal--large');
                    }

                    void this.modalService.setModalClass(NOURISH_PRODUCT_FEEDBACK_MODAL_ID, modalClasses);
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    resetProductFeedbackModalClass$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(nourishProductFeedbackModalSubmitted),
                filter(({ response }) => response === NourishProductFeedbackModalState.POSITIVE),
                tap(() => {
                    void this.modalService.setModalClass(NOURISH_PRODUCT_FEEDBACK_MODAL_ID, [
                        'modal',
                        'nourish-product-review-modal',
                    ]);
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    closeProductFeedbackModal$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(closeNourishProductFeedbackModal),
                tap(() => {
                    void this.modalService.dismissById(NOURISH_PRODUCT_FEEDBACK_MODAL_ID);
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    closeProductFeedbackModalAfterPositiveRating$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishProductFeedbackModalSubmitted),
            filter(({ response }) => response === NourishProductFeedbackModalState.POSITIVE),
            delay(NOURISH_PRODUCT_FEEDBACK_CLOSE_DELAY),
            map(() => {
                return closeNourishProductFeedbackModal();
            }),
        );
    });

    closeProductFeedbackModalAfterNegativeRating$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishProductFeedbackModalSubmitted),
            filter(({ response }) => response === NourishProductFeedbackModalState.NEGATIVE),
            map(() => {
                return closeNourishProductFeedbackModal();
            }),
        );
    });

    // -- Tracking

    trackNourishTabClicked$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(tabClicked),
            filter(({ id }) => id === TabRoute_NOURISH),
            map(() => {
                return trackEvent({
                    eventName: '[Nourish] Tab Clicked',
                });
            }),
        );
    });

    trackNourishTileClicked$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishTileClicked),
            map(({ id }) => {
                return trackEvent({
                    eventName: '[Nourish] Tile Clicked',
                    eventProperties: {
                        tileId: id,
                    },
                });
            }),
        );
    });

    trackNourishProductTileClicked$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishProductTileClicked),
            map(({ id, correlationId }) => {
                return trackEvent({
                    eventName: '[Nourish] Product Tile Clicked',
                    eventProperties: {
                        productId: id,
                        origin: correlationId,
                    },
                });
            }),
        );
    });

    trackNourishProductCtaClicked$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishProductCtaClicked),
            map(({ id, url }) => {
                return trackEvent({
                    eventName: '[Nourish] Product CTA Clicked',
                    eventProperties: {
                        productId: id,
                        url,
                    },
                });
            }),
        );
    });

    trackProductFeedbackSubmitted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishProductFeedbackModalSubmitted),
            map(({ id, response, reason, comment }) => {
                return trackEvent({
                    eventName: '[Nourish] Product Feedback Submitted',
                    eventProperties: {
                        id,
                        response,
                        reason: reason ?? '',
                        comment: comment ?? '',
                    },
                });
            }),
        );
    });

    trackSuggestedAiChatQuestionClick$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(nourishSuggestedAiChatQuestionClicked),
            map(({ option, card }) => {
                return trackEvent({
                    eventName: '[Nourish] Ai Chat Suggested Question Clicked',
                    eventProperties: { option, card },
                });
            }),
        );
    });
}
