<ion-input
    class="breed-selector zz-input"
    [readonly]="true"
    [tabindex]="1"
    [placeholder]="'Search breed'"
    [value]="displayValue"
    (click)="openModal()"
>
    <i class="fa-regular fa-dog breed-selector__dog-icon"></i>
</ion-input>
