import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { LocalNotificationService } from '../services/local-notification.service';
import {
    clearLocalNotification,
    clearLocalNotificationFailure,
    clearLocalNotificationSuccess,
    getLocalNotificationFailure,
    getLocalNotifications,
    getLocalNotificationSuccess,
    requestPermissions,
    requestPermissionsFailure,
    requestPermissionsSuccess,
    scheduleLocalNotification,
    scheduleLocalNotificationFailure,
    scheduleLocalNotificationSuccess,
} from './local-notification.actions';

@Injectable()
export class LocalNotificationEffects {
    private readonly actions$ = inject(Actions);
    private readonly localNotificationService = inject(LocalNotificationService);

    requestPermissions$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(requestPermissions),
            concatMap(() => {
                return from(this.localNotificationService.requestPermissions()).pipe(
                    map((result) => {
                        return requestPermissionsSuccess({ result });
                    }),
                    catchError((error) => {
                        return of(requestPermissionsFailure({ error }));
                    }),
                );
            }),
        );
    });

    scheduleLocalNotification$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(scheduleLocalNotification),
            switchMap(({ notifications }) => {
                return from(this.localNotificationService.scheduleNotification(notifications)).pipe(
                    map((result) => {
                        return scheduleLocalNotificationSuccess({ result });
                    }),
                    catchError((error) => {
                        return of(scheduleLocalNotificationFailure({ error }));
                    }),
                );
            }),
        );
    });

    getLocalNotifications$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getLocalNotifications),
            switchMap(() => {
                return from(this.localNotificationService.getLocalNotifications()).pipe(
                    map((result) => {
                        return getLocalNotificationSuccess({ result });
                    }),
                    catchError((error) => {
                        return of(getLocalNotificationFailure({ error }));
                    }),
                );
            }),
        );
    });

    clearLocalNotifications$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(clearLocalNotification),
            switchMap((props) => {
                return from(this.localNotificationService.clearLocalNotification(props.notifications)).pipe(
                    map(() => {
                        return clearLocalNotificationSuccess({
                            notifications: props.notifications.map((notification) => notification.id),
                        });
                    }),
                    catchError((error) => {
                        return of(clearLocalNotificationFailure({ error }));
                    }),
                );
            }),
        );
    });
}
