import { platformFeature } from '@frontend/data-access/platform';
import {
    selectIsNonNativeSubscriptionActive,
    subscriptionFeature,
    SubscriptionOffering,
    SubscriptionPackage,
    SubscriptionPeriodType,
} from '@frontend/data-access/subscription';
import { timeFeature } from '@frontend/data-access/time';
import { selectIsDevWithFreeContent } from '@frontend/data-access/user/config-cat';
import { createSelector } from '@ngrx/store';
import { addDays, differenceInHours } from 'date-fns';
import { mapOfferingToLimitedOfferPaymentProductCardArray } from '../../../payment-modal/payment-modal.utils';
import { householdFeature } from '@frontend/data-access/user/household';
import { REVENUE_CAT_DEFAULT_OFFERINGS } from '../constants/payment.constants';

export const selectContentUnlockedEntitlement = createSelector(
    subscriptionFeature.selectEntitlements,
    (entitlements): boolean => {
        return entitlements.some((entitlement) => entitlement.identifier === 'content' && entitlement.isActive);
    },
);

export const selectIsBasicPlan = createSelector(subscriptionFeature.selectEntitlements, (entitlements): boolean => {
    return entitlements.some((entitlement) => entitlement.identifier === 'basic' && entitlement.isActive);
});

export const selectContentUnlocked = createSelector(
    selectIsDevWithFreeContent,
    selectContentUnlockedEntitlement,
    selectIsNonNativeSubscriptionActive,
    selectIsBasicPlan,
    (isDevWithFreeContent, contentUnlockedEntitlement, isSubscriptionActive, isBasicPlan) =>
        isDevWithFreeContent || contentUnlockedEntitlement || isSubscriptionActive || isBasicPlan,
);

export const selectDefaultOfferingAvailablePackages = createSelector(
    subscriptionFeature.selectDefaultOffering,
    (offering: SubscriptionOffering | undefined) => {
        return offering?.availablePackages;
    },
);

export const selectDefaultOfferingAvailablePackagesSortedByPrice = createSelector(
    selectDefaultOfferingAvailablePackages,
    (availablePackages) =>
        availablePackages ? [...availablePackages].sort((a, b) => b.product.price - a.product.price) : [],
);

export const selectTieredPricingPremiumOffering = createSelector(subscriptionFeature.selectOfferings, (offerings) => {
    return offerings.find(
        (offering) => offering.identifier === REVENUE_CAT_DEFAULT_OFFERINGS.TIERED_PRICING_PREMIUM_PLAN,
    )?.availablePackages;
});

export const selectTieredPricingPremiumOfferingSortedByPrice = createSelector(
    selectTieredPricingPremiumOffering,
    (premiumPackages) =>
        premiumPackages ? [...premiumPackages].sort((a, b) => b.product.price - a.product.price) : [],
);

export const selectTieredPricingBasicOffering = createSelector(subscriptionFeature.selectOfferings, (offerings) => {
    return offerings.find((offering) => offering.identifier === REVENUE_CAT_DEFAULT_OFFERINGS.TIERED_PRICING_BASIC_PLAN)
        ?.availablePackages;
});

export const selectTieredPricingBasicOfferingSortedByPrice = createSelector(
    selectTieredPricingBasicOffering,
    (basicPackages) => (basicPackages ? [...basicPackages].sort((a, b) => b.product.price - a.product.price) : []),
);

const selectTieredPricingLimitedOfferOffering = createSelector(
    subscriptionFeature.selectOfferings,
    (offerings: SubscriptionOffering[]) => {
        return offerings.find((offering) => offering.identifier === REVENUE_CAT_DEFAULT_OFFERINGS.DISCOUNT_PAYWALL);
    },
);

export const selectTieredPricingLimitedOfferOfferingAvailablePackages = createSelector(
    selectTieredPricingLimitedOfferOffering,
    (offering: SubscriptionOffering | undefined) => {
        return offering?.availablePackages ?? [];
    },
);

export const selectTieredPricingLimitedOfferOfferingAvailablePackagesSortedByPrice = createSelector(
    selectTieredPricingLimitedOfferOfferingAvailablePackages,
    (availablePackages) =>
        availablePackages ? [...availablePackages].sort((a, b) => b.product.price - a.product.price) : [],
);

export const selectTieredPricingLimitedOfferProductCards = createSelector(
    householdFeature.selectDateOfOnboarding,
    selectTieredPricingLimitedOfferOfferingAvailablePackagesSortedByPrice,
    (dateOfOnboarding, packages: SubscriptionPackage[]) => {
        const offerEndDate = dateOfOnboarding ? addDays(dateOfOnboarding, 1) : undefined;

        return mapOfferingToLimitedOfferPaymentProductCardArray(packages, offerEndDate);
    },
);

export const selectIsEligibleForDiscountPaymentModal = createSelector(
    subscriptionFeature.selectEntitlements,
    selectDefaultOfferingAvailablePackagesSortedByPrice,
    platformFeature.selectPlatformIsIos,
    timeFeature.selectToday,
    (entitlements, offeringPackages, isIos, today): boolean => {
        const hasActiveSubscription = entitlements.some(
            (entitlement) => entitlement.identifier === 'content' && entitlement.isActive,
        );
        const isMoreThan24HoursExpiredTrial = entitlements.some(
            (entitlement) =>
                entitlement.identifier === 'content' &&
                entitlement.periodType === SubscriptionPeriodType.TRIAL &&
                entitlement.expirationDate &&
                differenceInHours(today, entitlement.expirationDate) >= 24,
        );
        const hasDiscountProduct = offeringPackages.filter((aPackage) => aPackage.product.discount).length > 0;

        return isIos && !hasActiveSubscription && isMoreThan24HoursExpiredTrial && hasDiscountProduct;
    },
);

export const selectIsEligibleForLimitedOfferPaymentModal = createSelector(
    selectContentUnlocked,
    timeFeature.selectToday,
    selectTieredPricingLimitedOfferProductCards,
    subscriptionFeature.selectHasHistoricalPurchase,
    (isContentUnlocked, today, productCards, hasHistoricalPurchase) => {
        return (
            !hasHistoricalPurchase &&
            !isContentUnlocked &&
            productCards.filter((productCard) => productCard.offerEndDate && productCard.offerEndDate >= today).length >
                0
        );
    },
);
