@if (vm$ | async; as vm) {
    <form [formGroup]="signUpForm" class="authentication-form authentication-form--max">
        <ion-item>
            <ion-input
                class="zz-input"
                type="email"
                placeholder="Your email"
                formControlName="email"
                autocomplete="email"
                data-test="email-input"
                [tabindex]="0"
                (ionFocus)="onInputFocus()"
                (ionBlur)="onInputBlur()"
            />
        </ion-item>
        @if (showError(signUpForm.controls.email)) {
            <ui-invalid-input-error-message data-test="email-validation" errorMessage="Please fill in a valid email" />
        }
        <ion-item class="authentication-form__password-item ion-margin-top">
            <ion-input
                class="authentication-form__password-input zz-input"
                #passwordInput
                type="password"
                placeholder="Password"
                formControlName="password"
                enterkeyhint="done"
                data-test="password-input"
                [tabindex]="1"
                (ionBlur)="onInputBlur()"
                (ionFocus)="onInputFocus()"
            />
            @if (passwordVisible) {
                <button class="zz-button l" (click)="togglePasswordVisibility()">
                    <i class="authentication-form__password-visibility fa-regular fa-eye-slash"></i>
                </button>
            } @else {
                <button class="zz-button l" (click)="togglePasswordVisibility()">
                    <i class="authentication-form__password-visibility fa-regular fa-eye"></i>
                </button>
            }
        </ion-item>
        @if (showError(signUpForm.controls.password)) {
            <ui-invalid-input-error-message
                data-test="password-validation"
                errorMessage="Please fill in a password of minimum 6 characters"
            />
        }
        @if (!!vm.error) {
            <ui-invalid-input-error-message
                data-test="signup-validation"
                [errorMessage]="vm.error.message"
                [linkText]="vm.error.link?.text"
                (linkClicked)="routeToErrorLinkFromInvalidInput(vm.error.link?.route)"
            />
        }
    </form>
}
