import { Injectable, inject } from '@angular/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { from, Observable, of } from 'rxjs';
import { Platform } from '@ionic/angular/standalone';

@Injectable({
    providedIn: 'root',
})
export class PushNotificationService {
    private readonly platform = inject(Platform);

    public logout(): Observable<void> {
        if (this.platform.is('ios') || this.platform.is('android')) {
            return from(PushNotifications.unregister());
        }

        console.info('[Mock Push Notifications] logout()');
        return of(undefined);
    }

    async pushNotificationsEnabled(): Promise<boolean> {
        if (this.platform.is('ios') || this.platform.is('android')) {
            const permissions = await PushNotifications.checkPermissions();

            return permissions.receive === 'granted';
        }

        console.info('[Mock Push Notifications] pushNotificationsEnabled()');
        return true;
    }

    async registerIterablePushNotifications() {
        if (this.platform.is('ios') || this.platform.is('android')) {
            let permissions = await PushNotifications.checkPermissions();

            if (permissions.receive === 'prompt') {
                permissions = await PushNotifications.requestPermissions();
            }

            if (permissions.receive === 'granted') {
                await PushNotifications.register();
            }
        }
    }
}
