<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <ui-progress-header
            [progressRatio]="progress()"
            [showBackButton]="!!previousOnboardingPage()"
            [isDisabled]="isLoading()"
            [showProgress]="false"
            (backButtonClicked)="onBackClicked()"
        />
    </ion-toolbar>
</ion-header>

<ion-content class="onboarding-ion-content" [fullscreen]="true">
    <section class="page-content ion-justify-content-start">
        <section>
            <h1>How did you hear about Zigzag?</h1>
            <p data-test="question-how-did-you-hear-subtitle">Tell us how you found Zigzag</p>
        </section>

        <form class="question-how-did-you-hear-page__form" [formGroup]="howDidYouHearForm">
            <ui-single-select formControlName="selectedOption" [options]="options" />
        </form>
    </section>
</ion-content>

<ion-footer class="question-how-did-you-hear-page__footer gradient-footer ion-text-center">
    <ion-button
        class="ion-button-color-max"
        appTrackClick
        identifier="question-how-did-you-hear-next-btn"
        expand="block"
        type="submit"
        data-test="question-how-did-you-hear-next-btn"
        (click)="onNextClick()"
    >
        @if (isLoading()) {
        <ion-spinner name="crescent" />
        } @else { {{ howDidYouHearForm.controls.selectedOption.value.length > 0 ? 'Next' : 'Skip' }} }
    </ion-button>
</ion-footer>
