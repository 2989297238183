import { CourseProgressType } from '@shared/user-domain';
import { InternalCourseProgressDto } from '../../../dtos/progress/course-progress/internal-course-progress.dto';

export class InternalCourseProgressDtoMock {
    private defaultValue: InternalCourseProgressDto = {
        userId: 'uuid-1234',
        courseId: '6YMjvyGlnCtuUQ6DyRlp0l',
        progressType: CourseProgressType.NOT_COMPLETED,
        timestamp: 1686651113380,
    };

    constructor(overrides?: Partial<InternalCourseProgressDto>) {
        this.defaultValue = { ...this.defaultValue, ...overrides };
    }

    public get value(): InternalCourseProgressDto {
        return this.defaultValue;
    }
}
