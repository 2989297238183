import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BASE_ENVIRONMENT_CONFIG, BaseEnvironmentConfig } from '@frontend/configuration';
import { Observable } from 'rxjs';
import { InternalHouseholdUserDto, internalPaths } from '@shared/internal-api-interface';
import { httpOptions } from '@frontend/utility/constants';
import { HouseholdUser } from '../models/household-user.model';
import { toUTC } from '@shared/utils/typescript';

@Injectable({
    providedIn: 'root',
})
export class HouseholdUserService {
    private readonly http = inject(HttpClient);
    private readonly environment = inject<BaseEnvironmentConfig>(BASE_ENVIRONMENT_CONFIG);

    public patchUser(user: Partial<HouseholdUser>): Observable<InternalHouseholdUserDto> {
        const dto = {
            ...user,
            ...(user.dateOfOnboarding && { dateOfOnboarding: toUTC(new Date(user.dateOfOnboarding)).getTime() }),
        };

        return this.http.patch<InternalHouseholdUserDto>(
            `${this.environment.internalApiUrl}/api/${internalPaths.householdUserPath}`,
            dto,
            httpOptions,
        );
    }
}
