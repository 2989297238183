@if (chip()) {
    <ui-generic-chip class="nourish-product-tile__chip" [text]="chip()!" [textClass]="'xs'" />
}

<ion-card class="nourish-product-tile__card" [ngClass]="colour()">
    <h3 class="nourish-product-tile__title">
        {{ title() | textReplacement }}
    </h3>

    <img class="nourish-product-tile__image" [src]="image().src" [alt]="image().alt" />

    <ion-button class="nourish-product-tile__button ion-button--transparent"> Learn more</ion-button>
</ion-card>
