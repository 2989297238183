import { Course, selectCourses } from '@frontend/data-access/contentful';
import {
    courseProgressFeature,
    selectCompletedOrSkippedStepProgressIds,
    stepProgressFeature,
} from '@frontend/data-access/user/progress';
import { createSelector } from '@ngrx/store';
import { CourseType } from '@shared/content-api-interface';
import { ArticleProgressType, CourseProgressType, LessonProgressType } from '@shared/user-domain';
import { isSameDay } from 'date-fns';
import { CourseWithProgress } from '../../courses/models/courses.models';
import {
    countUniqueOccurrencesOfProgressInCourse,
    getStreak,
} from '../../today/progress-streak/utils/progress-streak.utils';
import { CourseStatus } from './course.model';
import { calculateCourseProgress } from './course.utils';
import { convertFractionToPercentage } from '@frontend/utility/angular';

export const selectCompletedCourseIds = createSelector(courseProgressFeature.selectProgress, (progress) => {
    return progress
        .filter((entry) => {
            return entry.progressType === CourseProgressType.COMPLETED;
        })
        .map((entry) => entry.courseId);
});

export const selectInProgressCourseIds = createSelector(courseProgressFeature.selectProgress, (progress) => {
    return progress
        .filter((entry) => {
            return entry.progressType === CourseProgressType.IN_PROGRESS;
        })
        .map((entry) => entry.courseId);
});

export const selectCoursesWithProgress = createSelector(
    selectCourses,
    selectCompletedOrSkippedStepProgressIds,
    selectCompletedCourseIds,
    selectInProgressCourseIds,
    (
        courses,
        completedOrSkippedStepIds,
        completedCourseIds,
        inProgressCourseIds,
    ): (Course & {
        status: CourseStatus;
        progress: number;
    })[] => {
        return courses
            .map((course) => {
                if (completedCourseIds.includes(course.id)) {
                    return {
                        ...course,
                        status: CourseStatus.COMPLETED,
                        progress: 100,
                    };
                }

                const progress = convertFractionToPercentage(
                    calculateCourseProgress(course, completedOrSkippedStepIds),
                );

                if (inProgressCourseIds.includes(course.id)) {
                    return {
                        ...course,
                        status: CourseStatus.IN_PROGRESS,
                        progress,
                    };
                }

                return {
                    ...course,
                    status: CourseStatus.NOT_COMPLETED,
                    progress,
                };
            })
            .sort((a, b) => a.rank - b.rank);
    },
);

// TODO: NBSon - we can break this down into smaller selectors, e.g. we pass in data derived from step progress a lot
// TODO: NBSon - Part of the startCourseAb, once accepted, we may want to replace throughout the codebase
// e.g. replace ProgressStreamModalInfo/selectCoursesWithProgress/selectCoursesWithStepsAndSplits and combine
export const selectCoursesWithProgressInfo = createSelector(
    selectCourses,
    selectCompletedOrSkippedStepProgressIds,
    selectCompletedCourseIds,
    selectInProgressCourseIds,
    stepProgressFeature.selectStepProgress,
    (
        courses,
        completedOrSkippedStepIds,
        completedCourseIds,
        inProgressCourseIds,
        allStepProgress,
    ): CourseWithProgress[] => {
        return courses
            .map((course) => {
                const completedStepProgress = allStepProgress.filter((stepProgress) => {
                    return (
                        stepProgress.progress === LessonProgressType.COMPLETED ||
                        stepProgress.progress === ArticleProgressType.COMPLETED
                    );
                });

                const completedStepProgressStepIdsForToday = completedStepProgress
                    .filter((stepProgress) => isSameDay(new Date(stepProgress.timestamp), new Date()))
                    .map((stepProgress) => stepProgress.stepId);

                const numberOfUniqueStepsCompletedToday = countUniqueOccurrencesOfProgressInCourse(
                    course.stepIds,
                    completedStepProgressStepIdsForToday,
                );

                const streakInfo = getStreak(course.stepIds, completedStepProgress);

                const streakLength = streakInfo ? streakInfo.length : 0;

                if (completedCourseIds.includes(course.id)) {
                    return {
                        ...course,
                        status: CourseStatus.COMPLETED,
                        progress: 100,
                        numberOfUniqueStepsCompletedToday,
                        streakLength,
                    };
                }

                const progress = convertFractionToPercentage(
                    calculateCourseProgress(course, completedOrSkippedStepIds),
                );

                if (inProgressCourseIds.includes(course.id)) {
                    return {
                        ...course,
                        status: CourseStatus.IN_PROGRESS,
                        progress,
                        numberOfUniqueStepsCompletedToday,
                        streakLength,
                    };
                }

                return {
                    ...course,
                    status: CourseStatus.NOT_COMPLETED,
                    progress,
                    numberOfUniqueStepsCompletedToday,
                    streakLength,
                };
            })
            .sort((a, b) => a.rank - b.rank);
    },
);

export const selectUncompletedCourses = createSelector(selectCoursesWithProgress, (courses) => {
    return courses.filter((course) => course.status !== CourseStatus.COMPLETED);
});

export const selectUnCompletedFoundationalCourses = createSelector(selectUncompletedCourses, (courses) => {
    return courses.filter((course) => course.type === CourseType.FOUNDATIONAL);
});

export const selectCoursesWith100PercentProgressButNotCompleted = createSelector(
    selectCoursesWithProgress,
    (courses) => {
        return courses.filter((course) => course.progress === 100 && course.status !== CourseStatus.COMPLETED);
    },
);
