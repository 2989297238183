import { NgClass } from '@angular/common';
import { Component, effect, ElementRef, input, OnDestroy, OnInit, output, viewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { IonInput, IonItem } from '@ionic/angular/standalone';
import { Color } from '@shared/utils/typescript';
import { Subscription } from 'rxjs';
import { otherFeedbackOption, otherFeedbackOptionKey } from '../constants/generic-feedback.constants';
import { FeedbackOption, UserFeedbackContent } from '../models/generic-feedback.model';

@Component({
    imports: [NgClass, ReactiveFormsModule, IonInput, IonItem],
    selector: 'ui-generic-feedback',
    templateUrl: './generic-feedback.component.html',
    styleUrl: './generic-feedback.component.scss',
})
export class GenericFeedbackComponent implements OnInit, OnDestroy {
    public feedbackOptions = input.required<FeedbackOption[]>();

    public contentfulId = input('');
    public autoSubmitOnChange = input(false);
    public existingRating = input<UserFeedbackContent>();

    public optionSelected = output<FeedbackOption>();
    public feedbackSubmitted = output<UserFeedbackContent>();

    public commentInput = viewChild('commentInput', { read: ElementRef });

    public commentFormControl = new FormControl();
    public selectedFeedbackOption: FeedbackOption | undefined;
    public shouldShowCommentInput = false;
    public thumbsDownRating: boolean | undefined = undefined;

    protected readonly Color = Color;

    private onClickDoneSubmission: number | undefined = undefined;
    private commentFormControlValueSubscription: Subscription | undefined = undefined;

    constructor() {
        // Scroll to the comment input when it is shown
        effect(() => {
            if (this.commentInput() && !this.commentFormControl.value) {
                setTimeout(() => {
                    this.commentInput()!.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }, 0);
            }
        });

        this.commentFormControlValueSubscription = this.commentFormControl.valueChanges.subscribe(() => {
            this.onClickDone();
        });
    }

    public ngOnInit(): void {
        if (!this.existingRating() || this.existingRating()!.thumbsDown === undefined) {
            return;
        }

        this.thumbsDownRating = this.existingRating()!.thumbsDown;

        if (!this.existingRating()!.thumbsDown || !this.existingRating()!.comment) {
            return;
        }

        const selectedFeedbackOption = this.feedbackOptions()!.find(
            (option) => option.value === this.existingRating()!.comment,
        );

        if (selectedFeedbackOption) {
            this.selectedFeedbackOption = selectedFeedbackOption;
        } else if (!selectedFeedbackOption && this.existingRating()!.comment) {
            this.shouldShowCommentInput = true;
            this.optionSelected.emit(otherFeedbackOption);
            this.selectedFeedbackOption = otherFeedbackOption;
            this.commentFormControl.patchValue(this.existingRating()!.comment, { emitEvent: false });
        }
    }

    public ngOnDestroy() {
        this.commentFormControlValueSubscription?.unsubscribe();
    }

    public onClickThumbRating(thumbsDown: boolean): void {
        this.shouldShowCommentInput = false;
        this.selectedFeedbackOption = undefined;

        if (thumbsDown === this.thumbsDownRating) {
            this.thumbsDownRating = undefined;
        } else {
            this.thumbsDownRating = thumbsDown;
            this.commentFormControl.reset(null, { emitEvent: false });
        }

        if (this.autoSubmitOnChange()) {
            this.onClickDone();
        }
    }

    public onClickFeedbackOption(option: FeedbackOption): void {
        this.selectedFeedbackOption = option;

        if (option.key === otherFeedbackOptionKey) {
            this.shouldShowCommentInput = true;
        } else {
            this.commentFormControl.reset(null, { emitEvent: false });
            this.shouldShowCommentInput = false;
        }

        this.optionSelected.emit(option);

        if (this.autoSubmitOnChange()) {
            this.onClickDone();
        }
    }

    public onClickDone(): void {
        if (this.onClickDoneSubmission) {
            clearTimeout(this.onClickDoneSubmission);
        }

        this.onClickDoneSubmission = window.setTimeout(() => {
            let request: UserFeedbackContent = {
                thumbsDown: this.thumbsDownRating,
                contentfulId: this.contentfulId(),
                comment: this.commentFormControl?.value ?? this.selectedFeedbackOption?.value,
            };

            if (this.existingRating()) {
                request = {
                    ...this.existingRating(),
                    ...request,
                };
            }

            if (request.thumbsDown === null) {
                request.comment = undefined;
            }

            this.feedbackSubmitted.emit(request);
        }, 1000);
    }
}
