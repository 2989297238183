import { InternalPostStepProgressDto } from './internal-post-step-progress.dto';
import { IsArray, ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';

export class InternalPostManyStepProgressDto {
    @IsArray()
    @ValidateNested({ each: true })
    @Type(() => InternalPostStepProgressDto)
    stepProgress!: InternalPostStepProgressDto[];
}
