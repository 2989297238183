import { IterablePostPushOpenEventDto } from '../dtos/iterable-post-push-open-event.dto';

export class InternalIterablePostPushOpenEventDtoMock {
    private defaultValue: IterablePostPushOpenEventDto = {
        campaignId: 0,
        messageId: '',
        templateId: 0,
        userId: 'user-id',
        createdAt: 1,
    };

    constructor(overrides?: Partial<IterablePostPushOpenEventDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): IterablePostPushOpenEventDto {
        return this.defaultValue;
    }
}
