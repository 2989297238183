import { inject, Injectable } from '@angular/core';
import { FirebaseApp } from '@angular/fire/app';
import {
    createUserWithEmailAndPassword,
    FactorId,
    getAdditionalUserInfo,
    getMultiFactorResolver,
    GoogleAuthProvider,
    idToken,
    indexedDBLocalPersistence,
    initializeAuth,
    multiFactor,
    MultiFactorError,
    MultiFactorInfo,
    OAuthProvider,
    sendPasswordResetEmail,
    signInWithCredential,
    signInWithEmailAndPassword,
    signOut,
    TotpMultiFactorGenerator,
    TotpSecret as FirebaseTotpSecret,
    user,
} from '@angular/fire/auth';
import { SignInWithApple } from '@capacitor-community/apple-sign-in';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { Store } from '@ngrx/store';
import { FirebaseErrorCode } from '@shared/constants';
import { BehaviorSubject, from, lastValueFrom, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { logInWithAppleOptions } from '../consts/login-with-apple-options.const';
import { TotpSecret, User } from '../models/user.model';
import { watchForLoginStateChange } from '../store/authentication.actions';
import { mapFireBaseUserToUser, mapFireBaseUserWithAdditionalUserInfoToUser } from '../utils/authentication.map';
import { AuthenticationService } from './authentication.service';
import { BASE_ENVIRONMENT_CONFIG } from '@frontend/configuration';
import { isString } from '@shared/utils/typescript';

@Injectable({ providedIn: 'root' })
export class FirebaseAuthenticationService extends AuthenticationService {
    private readonly app = inject(FirebaseApp);
    private readonly store = inject(Store);
    private readonly config = inject(BASE_ENVIRONMENT_CONFIG);

    private auth = initializeAuth(this.app, {
        persistence: [indexedDBLocalPersistence],
    });

    public token$ = idToken(this.auth);
    public user$: Observable<User | undefined> = user(this.auth).pipe(
        map((user) => {
            if (!user || !user.email) {
                return undefined;
            }

            return {
                id: user.uid,
                email: user.email,
                signInProvider: user.providerData[0].providerId,
            };
        }),
    );

    private totpSecret: FirebaseTotpSecret | undefined;
    private userSubject = new BehaviorSubject<User | undefined>(undefined);

    constructor() {
        super();
        this.user$.subscribe(this.userSubject);
    }

    public get user(): User | undefined {
        return this.userSubject.getValue();
    }

    public initialize(): void {
        this.store.dispatch(watchForLoginStateChange());
    }

    public signUpWithEmailAndPassword(email: string, password: string): Observable<{ user: User }> {
        return from(createUserWithEmailAndPassword(this.auth, email.trim(), password)).pipe(
            switchMap((credential) => of(mapFireBaseUserToUser(credential.user))),
        );
    }

    public loginWithEmailAndPassword(email: string, password: string): Observable<{ user: User }> {
        return from(signInWithEmailAndPassword(this.auth, email.trim(), password)).pipe(
            switchMap((credential) => of(mapFireBaseUserToUser(credential.user))),
        );
    }

    public async loginWithEmailAndPasswordAndTotp(email: string, password: string, code: string) {
        try {
            return await lastValueFrom(this.loginWithEmailAndPassword(email, password));
        } catch (error) {
            if ((error as MultiFactorError).code === FirebaseErrorCode.MULTI_FACTOR_REQUIRED) {
                const mfaError = error as MultiFactorError;
                const mfaResolver = getMultiFactorResolver(this.auth, mfaError);

                const totpFactor = mfaResolver.hints.find((factor) => factor.factorId === FactorId.TOTP);
                const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(totpFactor!.uid, code);

                const credential = await mfaResolver.resolveSignIn(multiFactorAssertion);

                return mapFireBaseUserToUser(credential.user);
            }

            throw error;
        }
    }

    // TODO: NBSon - we don't correctly handle when the additional user info is not returned, we should handle this case
    public loginWithGoogle() {
        // Authenticate the user with Google SSO
        return from(FirebaseAuthentication.signInWithGoogle()).pipe(
            switchMap((signInResponse) => {
                const credential = GoogleAuthProvider.credential(signInResponse.credential?.idToken);

                // Here, the additionalUserInfo returns isNewUser true for new users
                const additionalUserInfo = signInResponse.additionalUserInfo;

                return from(signInWithCredential(this.auth, credential)).pipe(
                    switchMap((credential) => {
                        const givenName = additionalUserInfo?.profile?.['given_name'];
                        const ownerName = isString(givenName) ? givenName : undefined;

                        // Here, the additionalUserInfo returns isNewUser false for new users, since it's created in the previous step
                        return of(
                            mapFireBaseUserWithAdditionalUserInfoToUser(
                                credential.user,
                                additionalUserInfo!,
                                ownerName,
                            ),
                        );
                    }),
                );
            }),
        );
    }

    public loginWithApple() {
        // Authenticate the user with Apple SSO
        return from(SignInWithApple.authorize(logInWithAppleOptions)).pipe(
            switchMap((signInResponse) => {
                // Sign the user up in Firebase
                const provider = new OAuthProvider('apple.com');
                const credential = provider.credential({ idToken: signInResponse.response.identityToken });

                return from(signInWithCredential(this.auth, credential)).pipe(
                    switchMap((credential) => {
                        // return the user and additional info from Firebase
                        const additionalUserInfo = getAdditionalUserInfo(credential);
                        const ownerName = signInResponse.response.givenName
                            ? signInResponse.response.givenName
                            : undefined;

                        return of(
                            mapFireBaseUserWithAdditionalUserInfoToUser(
                                credential.user,
                                additionalUserInfo!,
                                ownerName,
                            ),
                        );
                    }),
                );
            }),
        );
    }

    public logout(): Observable<void> {
        return from(signOut(this.auth));
    }

    public isLoggedIn(): Observable<boolean> {
        return this.user$.pipe(map((user): boolean => !!user));
    }

    public requestPasswordReset(email: string): Observable<void> {
        return from(sendPasswordResetEmail(this.auth, email.trim()));
    }

    // TODO: NBSon - we don't handle the case where the user is not found, we should handle this case
    public getSignInProvider(): Observable<string> {
        return of(this.auth.currentUser!.providerData[0].providerId);
    }

    public async generateTOTPSecret(): Promise<TotpSecret> {
        if (!this.auth.currentUser) {
            throw new Error('Current user not found');
        }

        if (!this.auth.currentUser.email) {
            throw new Error('Current user email not found');
        }

        const multiFactorSession = await multiFactor(this.auth.currentUser).getSession();
        this.totpSecret = await TotpMultiFactorGenerator.generateSecret(multiFactorSession);

        return {
            key: this.totpSecret.secretKey,
            url: this.totpSecret.generateQrCodeUrl(this.auth.currentUser.email, this.config.mfaDisplayName),
        };
    }

    public async getMultiFactorInfo(): Promise<MultiFactorInfo[]> {
        if (!this.auth.currentUser) {
            throw new Error('Current user not found');
        }

        return multiFactor(this.auth.currentUser).enrolledFactors;
    }

    // TODO: NBSon - we assume the totpSecret is set, we should handle the case where it's not set
    public async enrollUserTOTP(verificationCode: string): Promise<void> {
        if (!this.auth.currentUser) {
            throw new Error('Current user not found');
        }

        const multiFactorAssertion = TotpMultiFactorGenerator.assertionForEnrollment(
            this.totpSecret!,
            verificationCode,
        );

        return await multiFactor(this.auth.currentUser).enroll(multiFactorAssertion, this.config.mfaDisplayName);
    }

    public async unenrollMultiFactor(id: string): Promise<void> {
        if (!this.auth.currentUser) {
            throw new Error('Current user not found');
        }

        const allMultiFactorInfo = await this.getMultiFactorInfo();
        const foundMultiFactorInfo = allMultiFactorInfo.find((info) => info.uid === id);

        if (!foundMultiFactorInfo) {
            throw new Error(`Multi factor info with id ${id} not found`);
        }

        return await multiFactor(this.auth.currentUser).unenroll(foundMultiFactorInfo);
    }
}
