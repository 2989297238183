<ion-searchbar
    class="searchbar"
    #searchBar
    autocapitalize="sentences"
    data-test="search-input"
    inputmode="search"
    [showCancelButton]="showCancelButton()"
    [placeholder]="placeholder()"
    (ionInput)="searchItems($event.target.value)"
    (ionFocus)="focus()"
    (ionCancel)="blur()"
>
    @if (isBasicPlan() && isLibraryPage()) {
        <ui-generic-chip class="searchbar__premium-chip" text="Premium" icon="fa-regular fa-crown" />
    }
</ion-searchbar>

@if (isFocused) {
    @if (searchBar.value) {
        <ng-container
            *ngTemplateOutlet="
                hasResults() ? resultListTemplate() : noResultTemplate();
                context: { searchValue: searchBar.value }
            "
        >
        </ng-container>
    } @else {
        <ng-container *ngTemplateOutlet="instructionTemplate()"></ng-container>
    }
}
