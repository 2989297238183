import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BASE_ENVIRONMENT_CONFIG, BaseEnvironmentConfig } from '@frontend/configuration';
import { Observable } from 'rxjs';
import { internalPaths } from '@shared/internal-api-interface';
import {
    IterablePostEventDto,
    IterablePostPushOpenEventDto,
    IterableRegisterForPushNotificationsDto,
    IterableUpsertUserDto,
} from '@shared/iterable-api-interface';

@Injectable({ providedIn: 'root' })
export class IterableService {
    private readonly http = inject(HttpClient);
    private readonly environment = inject<BaseEnvironmentConfig>(BASE_ENVIRONMENT_CONFIG);

    upsertIterableUser(user: IterableUpsertUserDto): Observable<void> {
        return this.http.post<void>(`${this.environment.internalApiUrl}/api/${internalPaths.iterablePath}`, user);
    }

    registerIterableUserForPushNotifications(dto: IterableRegisterForPushNotificationsDto): Observable<void> {
        return this.http.post<void>(
            `${this.environment.internalApiUrl}/api/${internalPaths.iterablePath}/register-push`,
            dto,
        );
    }

    trackIterableEvent(dto: IterablePostEventDto): Observable<void> {
        return this.http.post<void>(`${this.environment.internalApiUrl}/api/${internalPaths.iterablePath}/event`, dto);
    }

    trackIterablePushOpenEvent(dto: IterablePostPushOpenEventDto): Observable<void> {
        return this.http.post<void>(
            `${this.environment.internalApiUrl}/api/${internalPaths.iterablePath}/push-open`,
            dto,
        );
    }
}
