import { Location } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { IndicatorComponent } from '@frontend/ui/indicator';
import { TabButton } from '@frontend/ui/tab';
import { IonIcon, IonLabel, IonTabBar, IonTabButton, IonTabs, Platform } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { tabClicked } from './store/tab.actions';
import { selectTabsVm } from './store/tabs.selectors';

@Component({
    selector: 'app-tabs',
    templateUrl: './tabs.page.html',
    styleUrl: './tabs.page.scss',
    imports: [AnalyticsTrackClickDirective, IndicatorComponent, IonIcon, IonLabel, IonTabBar, IonTabButton, IonTabs],
})
export class TabsPage {
    private readonly store = inject(Store);
    private readonly platform = inject(Platform);
    private readonly router = inject(Router);
    private readonly location = inject(Location);

    public vm = this.store.selectSignal(selectTabsVm);

    constructor() {
        this.platform.backButton.subscribeWithPriority(10, () => {
            if (!this.router.url.startsWith('/onboarding') && !(this.router.url === '/main/today')) {
                this.location.back();
            }
        });
    }

    public tabClicked(tabButton: TabButton) {
        this.store.dispatch(tabClicked({ id: tabButton.tab }));
    }
}
