import { inject, Injectable } from '@angular/core';
import { timeFeature } from '@frontend/data-access/time';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { exhaustMap, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TodayCourseProgressService } from '../services/today-course-progress.service';
import { mapInternalProgressDtoToCourseTodayProgress } from '../utils/today-course-progress.utils';
import {
    createTodayCourseProgress,
    createTodayCourseProgressFailure,
    createTodayCourseProgressSuccess,
    getTodayCourseProgress,
    getTodayCourseProgressFailure,
    getTodayCourseProgressSuccess,
    updateTodayCourseProgress,
    updateTodayCourseProgressFailure,
    updateTodayCourseProgressSuccess,
} from './today-course-progress.actions';
import { getHouseholdSuccess } from '@frontend/data-access/user/household';

@Injectable()
export class TodayCourseProgressEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject(Store);
    private readonly todayProgressService = inject(TodayCourseProgressService);

    triggerGetTodayCourseProgress$ = createEffect(() => {
        return this.actions$.pipe(ofType(getHouseholdSuccess)).pipe(map(() => getTodayCourseProgress()));
    });

    getTodayCourseProgress$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getTodayCourseProgress),
            concatLatestFrom(() => this.store.select(timeFeature.selectToday)),
            exhaustMap(([, date]) =>
                this.todayProgressService.getTodayProgress(date).pipe(
                    map((todayProgressDto) => {
                        const todayProgress = mapInternalProgressDtoToCourseTodayProgress(todayProgressDto);

                        return getTodayCourseProgressSuccess({ todayProgress });
                    }),
                    catchError((error: Error) => of(getTodayCourseProgressFailure({ error }))),
                ),
            ),
        );
    });

    createTodayCourseProgress$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createTodayCourseProgress),
            exhaustMap(({ dto }) =>
                this.todayProgressService.createTodayProgress(dto).pipe(
                    map((todayProgressDto) => {
                        const todayProgress = mapInternalProgressDtoToCourseTodayProgress(todayProgressDto);
                        return createTodayCourseProgressSuccess({ todayProgress });
                    }),
                    catchError((error: Error) => of(createTodayCourseProgressFailure({ error }))),
                ),
            ),
        );
    });

    updateTodayCourseProgress$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateTodayCourseProgress),
            exhaustMap(({ id, dto }) =>
                this.todayProgressService.updateTodayProgress(id, dto).pipe(
                    map((todayProgressDto) => {
                        const todayProgress = mapInternalProgressDtoToCourseTodayProgress(todayProgressDto);
                        return updateTodayCourseProgressSuccess({ todayProgress });
                    }),
                    catchError((error: Error) => of(updateTodayCourseProgressFailure({ error }))),
                ),
            ),
        );
    });
}
