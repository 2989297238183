import { InternalPostCourseProgressDto } from './internal-post-course-progress.dto';
import { IsArray, ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';

export class InternalPostManyCourseProgressDto {
    @IsArray()
    @ValidateNested({ each: true })
    @Type(() => InternalPostCourseProgressDto)
    courseProgress!: InternalPostCourseProgressDto[];
}
