import { createFeature, createReducer, on } from '@ngrx/store';
import { ChatTileTopic, ChatTileTopicsState } from '../../models/chat-tile-topic.model';
import { getChatTileTopicsSuccess } from './chat-tile.actions';

export const initialChatTileTopicsState: ChatTileTopicsState = {
    chatTileTopics: [],
};

export const chatTileTopicsFeature = createFeature({
    name: 'chatTileTopics',
    reducer: createReducer(
        initialChatTileTopicsState,
        on(
            getChatTileTopicsSuccess,
            (state, { chatTileTopics }): ChatTileTopicsState => ({
                ...state,
                chatTileTopics: chatTileTopics as ChatTileTopic[],
            }),
        ),
    ),
});
