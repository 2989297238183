import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'visibleHeight',
})
export class VisibleHeightPipe implements PipeTransform {
    transform(clientRects: DOMRect): number | undefined {
        const windowHeight = window.innerHeight;

        if (clientRects.bottom > windowHeight || windowHeight < clientRects.height) {
            return windowHeight - clientRects.y;
        }

        return undefined;
    }
}
