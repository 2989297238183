import { selectCompletedStepProgress } from '@frontend/data-access/user/progress';
import { createSelector } from '@ngrx/store';
import { selectCoursesWithStepsAndSplits } from '../../../courses/course-steps-and-splits.selectors';
import { getStreak } from '../utils/progress-streak.utils';

export const selectProgressStreakInfo = createSelector(
    selectCoursesWithStepsAndSplits,
    selectCompletedStepProgress,
    (courses, completedStepProgress) => {
        return courses
            .map((course) => {
                const courseStepIds = course.steps.map((step) => step.id);

                const streakInfo = getStreak(courseStepIds, completedStepProgress);

                return {
                    id: course.id,
                    color: course.color,
                    title: course.title,
                    length: streakInfo.length,
                    progressPercent: course.progress,
                    dateStarted: streakInfo.startDate,
                };
            })
            .filter((course) => course.length > 0);
    },
);

export const selectProgressStreakInfoNotIncludingYesterday = createSelector(
    selectCoursesWithStepsAndSplits,
    selectCompletedStepProgress,
    (courses, completedStepProgress) => {
        return courses
            .map((course) => {
                const courseStepIds = course.steps.map((step) => step.id);

                const streakInfo = getStreak(courseStepIds, completedStepProgress, false);

                return {
                    id: course.id,
                    color: course.color,
                    title: course.title,
                    length: streakInfo.length,
                    progressPercent: course.progress,
                    dateStarted: streakInfo.startDate,
                };
            })
            .filter((course) => course.length > 0);
    },
);
