@if (shouldShowHeading()) {
    <section class="ignore-global-color" data-test="nourish-pet-food-finder-heading">
        <h3>Find {{ dogName() }}'s perfect food</h3>
        <p class="no-margin s">
            Explore the Food Finder tool & get customized food recommendations from Purina products
        </p>
    </section>
}

<section class="nourish-pet-food-finder__image-container">
    <section class="nourish-pet-food-finder__image">
        <img
            class="nourish-pet-food-finder__purina-logo"
            src="/assets/images/third-party/purina/purina-logo.svg"
            alt="Powered by Purina logo"
        />
    </section>

    <ion-button
        class="s ignore-screen-based-font-scaling"
        appTrackClick
        identifier="nourish-{{ origin() }}-pet-food-finder-btn"
        data-test="nourish-pet-food-finder-btn"
        expand="block"
        [color]="Color.Max"
        (click)="onFindFoodClicked()"
    >
        <i class="fa-regular fa-bone nourish-pet-food-finder__button-icon"></i>Find {{ dogName() }}'s food
    </ion-button>
</section>
