import { NgClass } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { FeedbackOption, otherFeedbackOptionKey, UserFeedbackContent } from '@frontend/ui/generic-feedback';
import { IonButton, IonInput, IonItem } from '@ionic/angular/standalone';
import { Color } from '@shared/utils/typescript';

@Component({
    imports: [NgClass, ReactiveFormsModule, IonButton, IonInput, IonItem],
    selector: 'ui-chat-feedback',
    templateUrl: './chat-feedback.component.html',
    styleUrl: './chat-feedback.component.scss',
})
export class ChatFeedbackComponent {
    public feedbackOptions = input.required<FeedbackOption[]>();

    public optionSelected = output<FeedbackOption>();
    public feedbackSubmitted = output<UserFeedbackContent>();

    public commentFormControl = new FormControl();
    public selectedFeedbackOption: FeedbackOption | undefined;
    public shouldShowCommentInput = false;
    public thumbsDownRating: boolean | undefined = undefined;

    protected readonly Color = Color;

    private thumbsDownFormControl = new FormControl();
    private thumbsDownReasonFormControl = new FormControl();

    public onClickFeedbackOption(option: FeedbackOption): void {
        this.selectedFeedbackOption = option;
        this.thumbsDownReasonFormControl.patchValue(option, { emitEvent: true });

        if (option.key === otherFeedbackOptionKey) {
            this.shouldShowCommentInput = true;
        } else {
            this.commentFormControl.reset(null, { emitEvent: false });
            this.shouldShowCommentInput = false;
        }

        this.optionSelected.emit(option);
    }

    public onClickDone(): void {
        const request: UserFeedbackContent = {
            thumbsDown: this.thumbsDownFormControl?.value,
            contentfulId: '',
            comment: this.commentFormControl?.value ?? this.selectedFeedbackOption?.value,
        };

        if (request.thumbsDown === null) {
            request.comment = undefined;
        }

        this.feedbackSubmitted.emit(request);
    }
}
