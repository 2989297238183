import { Directive, HostListener, inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { trackEvent } from '@frontend/data-access/analytics';
import { Store } from '@ngrx/store';

@Directive({
    selector: '[appTrackClick]',
})
export class AnalyticsTrackClickDirective {
    private readonly router = inject(Router);
    private readonly store = inject(Store);

    @Input() identifier = '';
    @Input() appTrackClickEnabled = true;

    @HostListener('click', ['$event']) onClick(): void {
        if (this.appTrackClickEnabled) {
            this.store.dispatch(
                trackEvent({
                    eventName: 'click',
                    eventProperties: {
                        identifier: this.identifier,
                        page: this.router.url,
                    },
                }),
            );
        }
    }
}
