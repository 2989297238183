import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, from, mergeMap, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LocalStorageService } from '../local-storage.service';
import {
    getLocalStorage,
    getLocalStorageFailure,
    getLocalStorageSuccess,
    removeAllLocalStorage,
    removeAllLocalStorageFailure,
    removeAllLocalStorageSuccess,
    removeLocalStorage,
    removeLocalStorageFailure,
    removeLocalStorageSuccess,
    setLocalStorage,
    setLocalStorageFailure,
    setLocalStorageSuccess,
} from './local-storage.actions';

@Injectable()
export class LocalStorageEffects {
    private readonly actions$ = inject(Actions);
    private readonly localStorageService = inject(LocalStorageService);

    setLocalStorageAtKey$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(setLocalStorage),
            concatMap(({ key, data }) =>
                from(this.localStorageService.setLocalStorage(key, data)).pipe(
                    map(() => {
                        return setLocalStorageSuccess();
                    }),
                    catchError((error) => of(setLocalStorageFailure({ error }))),
                ),
            ),
        );
    });

    getLocalStorageAtKey$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getLocalStorage),
            mergeMap(({ key }) =>
                from(this.localStorageService.getLocalStorage(key)).pipe(
                    map((data) => {
                        return getLocalStorageSuccess({ key, data });
                    }),
                    catchError((error) => of(getLocalStorageFailure({ key, error }))),
                ),
            ),
        );
    });

    removeLocalStorageAtKey$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(removeLocalStorage),
            concatMap(({ key }) =>
                from(this.localStorageService.deleteLocalStorage(key)).pipe(
                    map(() => {
                        return removeLocalStorageSuccess();
                    }),
                    catchError((error) => of(removeLocalStorageFailure({ error }))),
                ),
            ),
        );
    });

    removeAllLocalStorage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(removeAllLocalStorage),
            concatMap(() =>
                from(this.localStorageService.clearLocalStorage()).pipe(
                    map(() => removeAllLocalStorageSuccess()),
                    catchError((error) => of(removeAllLocalStorageFailure({ error }))),
                ),
            ),
        );
    });
}
