import { NgStyle } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { IconComponent } from '@frontend/ui/icon';
import { IonButton, IonButtons, IonProgressBar, IonToolbar } from '@ionic/angular/standalone';
import { Color } from '@shared/utils/typescript';

@Component({
    selector: 'ui-progress-header',
    templateUrl: './progress-header.component.html',
    styleUrl: './progress-header.component.scss',
    imports: [IonToolbar, IonButtons, IonButton, IconComponent, IonProgressBar, NgStyle],
})
export class ProgressHeaderComponent {
    public progressRatio = input(0);
    public showBackButton = input(true);
    public showProgress = input(true);
    public isDisabled = input(false);
    public iconColour = input<'white' | 'black'>('white');

    public backButtonClicked = output();

    protected readonly Color = Color;

    public onClickBackButton(): void {
        if (this.isDisabled()) {
            return;
        }

        this.backButtonClicked.emit();
    }
}
