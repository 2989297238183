import { InternalPostRescheduledStepDto } from './internal-post-rescheduled-step.dto';
import { IsArray, ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';

export class InternalPostManyRescheduledStepDto {
    @IsArray()
    @ValidateNested({ each: true })
    @Type(() => InternalPostRescheduledStepDto)
    rescheduledSteps!: InternalPostRescheduledStepDto[];
}
