<ion-content [color]="Color.Alvin" class="nutrition-modal">
    <button
        (click)="onCloseClick(NourishModalId.NUTRITIONAL_CONSULTATION)"
        appTrackClick
        class="xl nutrition-modal__close-button"
        identifier="nourish-nutrition-modal-close-btn"
    >
        <i class="fa-light fa-xmark"></i>
    </button>

    <article class="nutrition-modal__article page-padding-sides page-padding-bottom ignore-global-color">
        <section class="nutrition-modal__heading">
            <b>Did you know:</b>
            <h2 class="no-margin-bottom">
                <span class="nutrition-modal__percentage">76%</span> of Puppy owners make nutritional mistakes
            </h2>
        </section>

        <img
            alt="Buddy nibbling on a phone wire"
            class="nutrition-modal__image"
            src="/assets/images/charly/charly-looks-at-poo-medium.svg"
        />

        <p>
            Book a <b>consultation with nutritional expert</b> to receive a <b>personalised nutrition plan</b> and avoid
            the pitfalls of improper nutrition
        </p>

        <section class="nutrition-modal__nutrition-benefits">
            <div class="nutrition-modal__benefit">
                <img
                    alt="Buddy with diarrhoea"
                    class="nutrition-modal__sad-image"
                    src="/assets/images/buddy/buddy-diarrhoea.svg"
                />
                <b class="nutrition-modal__benefit-text xs no-margin">Digestive issues or <br />soft poo</b>
            </div>

            <div class="nutrition-modal__benefit">
                <img
                    alt="Charly with a skin condition"
                    class="nutrition-modal__sad-image"
                    src="/assets/images/charly/charly-skin-condition.svg"
                />
                <b class="nutrition-modal__benefit-text xs no-margin">Skin issues</b>
            </div>

            <div class="nutrition-modal__benefit">
                <img
                    alt="Bailey with a growth disorder"
                    class="nutrition-modal__sad-image"
                    src="/assets/images/bailey/bailey-growth-disorder.svg"
                />
                <b class="nutrition-modal__benefit-text xs no-margin">Growth disorder</b>
            </div>

            <div class="nutrition-modal__benefit">
                <img
                    alt="Buddy licking or chewing paw"
                    class="nutrition-modal__sad-image"
                    src="/assets/images/buddy/buddy-licking-or-chewing.svg"
                />
                <b class="nutrition-modal__benefit-text xs no-margin">Licking or chewing paw</b>
            </div>
        </section>

        <h3>Why you need a consultation with a nutritional expert now!</h3>

        <ul [ngClass]="Color.AppBackground" class="nutrition-modal__nutrition-steps no-margin">
            <li class="nutrition-modal__nutrition-list-element no-margin">
                <span class="nutrition-modal__list-number fa-regular fa-circle-1"></span>
                <p class="no-margin">
                    You might unknowingly be harming the development of your puppy with the wrong food
                </p>
            </li>

            <li class="nutrition-modal__nutrition-list-element">
                <span class="nutrition-modal__list-number fa-regular fa-circle-2"></span>
                <p class="no-margin">
                    Right nutrition is your responsibility - that's why you should consult with an expert rather than
                    guess
                </p>
            </li>

            <li class="nutrition-modal__nutrition-list-element">
                <span class="nutrition-modal__list-number fa-regular fa-circle-3"></span>
                <p class="no-margin">
                    Our expert is able to create a personalised nutrition plan for your dog that could not only prevent
                    health issues but also be conveniently delivered to you
                </p>
            </li>
        </ul>

        <h3>How it works?!</h3>

        <section [ngClass]="Color.AppBackground" class="nutrition-modal__nutrition-steps-container">
            <div [ngClass]="Color.Lola" class="nutrition-modal__header">
                <b class="xs">FREE. NO OBLIGATION TO SUBSCRIBE</b>
            </div>
            <ul class="nutrition-modal__nutrition-steps nutrition-modal__nutrition-steps--with-header no-margin">
                <li class="nutrition-modal__nutrition-list-element">
                    <span class="nutrition-modal__icon fa-regular fa-headset"></span>
                    <div>
                        <h3 class="no-margin-top">Chat with nutritionist</h3>
                        <p class="s no-margin-top">
                            Have a <b class="s">free 30-minute</b> call with a <b class="s">nutrition expert</b> via
                            phone right from your home
                        </p>
                    </div>
                </li>

                <hr class="nutrition-modal__nutrition-steps-break" />

                <li class="nutrition-modal__nutrition-list-element">
                    <span class="nutrition-modal__icon fa-regular fa-envelope-open-text"></span>
                    <div>
                        <h3 class="no-margin-top">Get your nutrition plan</h3>
                        <p class="s no-margin-top">
                            Receive a <b class="s">personalised nutrition plan</b> tailored to your dog’s needs via
                            email
                        </p>
                    </div>
                </li>

                <hr class="nutrition-modal__nutrition-steps-break" />

                <li class="nutrition-modal__nutrition-list-element">
                    <span class="nutrition-modal__icon fa-regular fa-user-magnifying-glass"></span>
                    <div>
                        <h3 class="no-margin-top">Review your plan</h3>
                        <p class="s no-margin-top">
                            Review your plan at your convenience. <b class="s">No subscription obligation</b>
                        </p>
                    </div>
                </li>

                <section [ngClass]="Color.Lola" class="nutrition-modal__step-section">
                    <b class="xs">No payment obligation until you approve the plan & subscribe</b>
                </section>
            </ul>
        </section>

        <section [ngClass]="Color.AppBackground" class="nutrition-modal__nutrition-steps-container">
            <div [ngClass]="Color.Maggie" class="nutrition-modal__header">
                <b class="nutrition-modal__header-text xs">YOUR SUBSCRIPTION. YOUR CHOICE</b>
            </div>
            <ul class="nutrition-modal__nutrition-steps nutrition-modal__nutrition-steps--with-header no-margin">
                <li class="nutrition-modal__nutrition-list-element">
                    <span class="nutrition-modal__icon fa-regular fa-person-carry-box"></span>
                    <div>
                        <h3 class="no-margin-top">Food delivered</h3>
                        <p class="s no-margin-top">
                            Get {{ '[NAME_C]' | textReplacement }}’s
                            <b class="s">personalised food delivered monthly</b> to your doorstep
                        </p>
                    </div>
                </li>

                <section [ngClass]="Color.Maggie" class="nutrition-modal__step-section">
                    <b class="xs">100% money back guarantee on your first order</b>
                </section>

                <li class="nutrition-modal__nutrition-list-element">
                    <span class="nutrition-modal__icon fa-regular fa-shield-dog"></span>
                    <div>
                        <h3 class="no-margin-top">Support health</h3>
                        <p class="s no-margin">
                            Support {{ '[NAME_C]' | textReplacement }}’s <b class="s">health</b> with personalised
                            nutrition
                        </p>
                    </div>
                </li>
            </ul>
        </section>

        <section [ngClass]="Color.Maggie" class="nutrition-modal__content-section">
            <h3 class="no-margin-top">Curious about the subscription cost?</h3>
            <p class="s no-margin">
                Each plan is customised with a tailored price depending on {{ '[NAME_C]' | textReplacement }}’s needs.
                But you’ll be amazed at how much money the right nutrition can save you!
            </p>
        </section>

        <img
            alt="Powered by Purina logo"
            class="nutrition-modal__purina-image"
            src="/assets/images/third-party/powered-by-purina-white.svg"
        />
    </article>

    <ion-footer class="nutrition-modal__footer ion-text-center gradient-footer">
        <ion-button
            appTrackClick
            color="max"
            expand="full"
            shape="round"
            data-test="nutrition-modal-book-consultation-btn"
            [identifier]="unlocked() ? 'nutrition-modal-book-consultation-btn' : 'nutrition-modal-start-free-trial-btn'"
            (click)="unlocked() ? bookConsultation() : openPaymentModal()"
        >
            @if (unlocked()) {
                Book my consultation
            } @else if (!hasHistoricalPurchase()) {
                Start my free trial
            } @else {
                See plans
            }
        </ion-button>
    </ion-footer>
</ion-content>
