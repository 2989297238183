<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <app-header
            class="authentication-app-header"
            title="Reset Password"
            [leftButton]="leftButtonConfig"
            [rightButton]="false"
            (leftButtonPressed)="routeToPrevious()"
        />
    </ion-toolbar>
</ion-header>

<ion-content class="reset-password-ion-content authentication-ion-content" [fullscreen]="true">
    <main class="page-content">
        <section>
            <h1 data-test="reset-password-title">Enter your email</h1>
            <p data-test="reset-password-subtitle">We’ll send you a link to reset your password</p>
        </section>

        <form class="authentication-form authentication-form--alvin" [formGroup]="requestPasswordResetForm">
            <ion-item>
                <ion-input
                    class="zz-input"
                    type="email"
                    formControlName="email"
                    placeholder="Email"
                    data-test="reset-password-email-input"
                    [tabindex]="1"
                />
            </ion-item>

            @if (showEmailError()) {
            <ui-invalid-input-error-message
                errorMessage="Please fill in a valid email"
                data-test="reset-password-error-message"
            />
            }
        </form>
    </main>
</ion-content>

@if (!isInputOpenOnAndroid()) {
<ion-footer class="page-footer ion-text-center">
    <ion-button
        class="ion-button-color-max"
        appTrackClick
        identifier="send-email-button"
        expand="block"
        data-test="reset-password-send-email-btn"
        [disabled]="requestPasswordResetForm.invalid"
        (click)="send()"
    >
        Send Email
    </ion-button>
</ion-footer>
}
