import { IsNotEmpty, IsNumber, IsOptional, IsPositive, IsString, Max, Min } from 'class-validator';

export class DomainPostStepProgressDto {
    @IsString()
    @IsNotEmpty()
    userId!: string;

    @IsString()
    @IsOptional()
    dogId?: string;

    @IsString()
    @IsNotEmpty()
    stepId!: string;

    @IsNumber()
    @IsPositive()
    contentId!: number;

    @IsString()
    @IsNotEmpty()
    progressTypeId!: string;

    @IsNumber()
    @IsOptional()
    timestamp?: number;

    @IsNumber()
    @Min(0)
    @Max(5)
    @IsOptional()
    rating?: number;
}
