import { IsEnum, IsOptional, IsString } from 'class-validator';
import { ConversationMessageRatingTypeID } from '../../models/conversation/conversation.model';

export class DomainPostConversationMessage {
    // messages sent over when creating a conversation won't have an id
    @IsString()
    content!: string;

    @IsString()
    senderId!: string;

    @IsString()
    streamChatMessageId!: string;

    @IsOptional()
    @IsEnum(ConversationMessageRatingTypeID)
    ratingId?: ConversationMessageRatingTypeID;
}

export class DomainPostConversationMessageDto extends DomainPostConversationMessage {
    @IsString()
    conversationId!: string;
}
