import { DomainDogDto } from '../../../dtos/user/dog/domain-dog.dto';
import { Gender } from '@shared/user-domain';

export class DomainDogDtoMock {
    private defaultValue: DomainDogDto = {
        breedId: '',
        dateOfArrival: new Date('12-16-2024').getTime(),
        dateOfBirth: new Date('11-16-2024').getTime(),
        dateOfOnboarding: new Date('12-17-2024').getTime(),
        gender: Gender.MALE,
        hasArrived: true,
        isApproximateDateOfBirth: false,
        isRescue: false,
        name: 'Bella',
        id: 'uuid-1234',
    };

    constructor(overrides?: Partial<DomainDogDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): DomainDogDto {
        return this.defaultValue;
    }
}
