import { NgStyle } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IonCard } from '@ionic/angular/standalone';
import { Color } from '@shared/utils/typescript';

@Component({
    selector: 'app-chat-error-message',
    templateUrl: './chat-error-message.component.html',
    styleUrl: './chat-error-message.component.scss',
    imports: [NgStyle, IonCard],
})
export class ChatErrorMessageComponent {
    @Input({ required: true }) public backgroundColor!: Color;
    @Input({ required: true }) public textColor!: Color;
}
