import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { trackEvent } from '@frontend/data-access/analytics';
import {
    getGeoLocation,
    getGeoLocationFailure,
    getGeoLocationResolved,
    getGeoLocationSuccess,
} from './geo-location.actions';
import { GeoLocationService } from '../geo-location.service';

@Injectable()
export class GeoLocationEffects {
    private readonly actions$ = inject(Actions);
    private readonly geoLocationService = inject(GeoLocationService);
    getGeoLocation$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getGeoLocation),
            switchMap(() => {
                return this.geoLocationService.getGeoLocation().pipe(
                    map((response) => getGeoLocationSuccess({ geoLocation: response })),
                    catchError((error: Error) => of(getGeoLocationFailure({ error }))),
                );
            }),
        );
    });

    geoLocationResolved$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getGeoLocationSuccess, getGeoLocationFailure),
            switchMap((action) => [
                getGeoLocationResolved(),
                trackEvent({
                    eventName: '[GeoLocation] Geo Location Resolved',
                    eventProperties: {
                        success: action.type === getGeoLocationSuccess.type,
                        ...(action.type === getGeoLocationFailure.type && {
                            error: action.error.message,
                        }),
                    },
                }),
            ]),
        );
    });
}
