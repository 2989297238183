<ion-content class="how-much-exercise-modal" [color]="Color.Bailey" xmlns="http://www.w3.org/1999/html">
    <button
        class="xl how-much-exercise-modal__close-button"
        appTrackClick
        identifier="nourish-how-much-exercise-modal-close-btn"
        (click)="onCloseClick()"
    >
        <i class="fa-light fa-xmark"></i>
    </button>
    <article class="how-much-exercise-modal__article page-padding-sides page-padding-bottom ignore-global-color">
        <h2>How much exercise is enough? The 5-minute exercise rule</h2>

        <p class="no-margin">Young dogs thrive on exercise that's both safe and fun.</p>

        <section class="how-much-exercise-modal__highlighted-text" [ngClass]="Color.Oliver">
            <p class="no-margin">
                <b>Use the 5-minute rule:</b>
                allow 5 minutes of activity per month of age, twice a day.
            </p>
            <br />
            <p class="no-margin">
                For example, a 4-month-old dog should have two sessions of 20 minutes each per day, totaling 40 minutes
                of exercise.
            </p>
        </section>

        <p class="no-margin">
            Mix it up with short walks, gentle playtime, and brain-teasing activities like puzzle toys or basic
            training. Always tailor activities to your pup’s energy levels and avoid overdoing it to protect those
            growing joints.
        </p>

        <img
            class="how-much-exercise-modal__image"
            src="/assets/images/charly/charly-holds-tennis-ball-with-shadow.svg"
            alt="Charly holding a ball in their mouth"
        />

        <br />

        <ui-generic-feedback
            [autoSubmitOnChange]="true"
            [contentfulId]="currentInsightsModalId() ?? ''"
            [existingRating]="userFeedback()"
            [feedbackOptions]="DefaultFeedbackOptions"
            (feedbackSubmitted)="onFeedbackSubmitted($event)"
        />
    </article>
</ion-content>
