import { Gender } from '@shared/user-domain';
import { InternalUserDto } from '../../../dtos/user/internal-user.dto';

export class InternalUserDtoMock {
    private defaultValue: InternalUserDto = {
        // Set after creation
        id: 'uuid-1234',
        accountId: 'accountId-1234',
        mail: 'revenue-cat-user@e2e.zigzag.dog',
        countryCode: 'GB',
        region: 'United Kingdom',
        city: 'London',
        // Set during onboarding
        breedId: 'Bergamasco-id',
        dateOfArrival: '2021-03-24T00:00:00.000Z',
        dateOfBirth: '2020-11-11T00:00:00.000Z',
        dateOfOnboarding: '2021-03-24T00:00:00.000Z',
        firstDayAtHomeWhenOnboarding: false,
        gender: Gender.MALE,
        hasArrived: true,
        isApproximateDateOfBirth: undefined,
        isRescuePup: false,
        name: 'Fred',
        notifications: true,
        ownerName: 'Test Tester',
        partners: [],
        purinaMarketingAccepted: undefined,
        termsAndPrivacyAccepted: false,
        userToken: 'token-123',
    };

    private empty: InternalUserDto = {
        // Set after creation
        id: '',
        accountId: undefined,
        mail: '',
        countryCode: undefined,
        region: undefined,
        city: undefined,
        // Set during onboarding
        breedId: undefined,
        dateOfArrival: undefined,
        dateOfBirth: undefined,
        dateOfOnboarding: undefined,
        firstDayAtHomeWhenOnboarding: undefined,
        gender: undefined,
        hasArrived: undefined,
        isApproximateDateOfBirth: undefined,
        isRescuePup: undefined,
        name: undefined,
        notifications: false,
        ownerName: undefined,
        partners: [],
        purinaMarketingAccepted: undefined,
        termsAndPrivacyAccepted: false,
        userToken: 'token-123',
    };

    constructor(overrides?: Partial<InternalUserDto>) {
        this.defaultValue = { ...this.defaultValue, ...overrides };

        Object.assign(this.empty, overrides);
    }

    public get value(): InternalUserDto {
        return this.defaultValue;
    }

    public get emptyValue(): InternalUserDto {
        return this.empty;
    }
}
