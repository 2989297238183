<ion-content [fullscreen]="true">
    <div class="payment-modal-parent ion-align-items-center">
        <ion-header class="page-header ion-no-border">
            <ion-toolbar>
                <app-header
                    [leftButton]="false"
                    [rightButton]="{
                        analyticsIdentifier: 'close-parent-payment-modal-btn',
                        sprite: 'light',
                        iconName: 'xmark',
                        background: 'white',
                        iconColour: 'black',
                    }"
                    (rightButtonPressed)="onCloseClick()"
                />
            </ion-toolbar>
        </ion-header>

        <img class="payment-modal-parent__logo" [src]="'assets/images/zigzag/zigzag-logo.png'" alt="The Zigzag Logo" />

        <app-payment-modal-tiered-pricing />
    </div>
</ion-content>
