import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { stripTime } from '@frontend/utility/angular';

export const maxDateValidator = (date: Date): ValidatorFn => {
    return (control: AbstractControl<Date>): ValidationErrors | null => {
        const invalid = stripTime(control.value) > stripTime(date);

        if (!control.value || invalid) {
            return { maxDate: { value: control.value } };
        }

        return null;
    };
};
