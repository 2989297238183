<ui-slider [gap]="12">
    @for (card of suggestedQuestionsCards(); track card.id) {
        <ui-option-selector-square
            #questionCards
            [title]="card.title"
            [background]="card.colour"
            [imageUrl]="card.image"
            [options]="card.chatTileContent"
            [selected]="selectedOption()"
            (selectedChange)="selectedOptionChanged(card, $event)"
        />
    }
</ui-slider>
