<p class="tiered-pricing-comparison__most-popular unset-margin">Most Popular</p>

<section class="tiered-pricing-comparison__chip-container">
    <ion-chip
        class="tiered-pricing-comparison__chip"
        data-test="tiered-pricing-comparison-basic"
        [ngClass]="{ 'tiered-pricing-comparison--basic-selected': !isPremiumSelected() }"
        (click)="basicClicked()"
        ><h3 class="unset-margin">Basic</h3>
    </ion-chip>
    <ion-chip
        class="tiered-pricing-comparison__chip"
        data-test="tiered-pricing-comparison-premium"
        [ngClass]="{ 'tiered-pricing-comparison--premium-selected': isPremiumSelected() }"
        (click)="premiumClicked()"
        ><h3 class="unset-margin">Premium <i class="fa-regular fa-crown"></i></h3>
    </ion-chip>
</section>
