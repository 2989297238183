import { Gender } from '@shared/user-domain';
import { IsBoolean, IsEmail, IsOptional, IsString } from 'class-validator';

export class InternalPatchAccountDto {
    // todo: nl - patching email is not currently supported
    @IsEmail()
    @IsOptional()
    mail?: string;

    @IsString()
    @IsOptional()
    name?: string;

    @IsString()
    @IsOptional()
    ownerName?: string;

    @IsString()
    @IsOptional()
    dateOfBirth?: string;

    @IsString()
    @IsOptional()
    dateOfOnboarding?: string;

    @IsString()
    @IsOptional()
    dateOfArrival?: string;

    @IsString()
    @IsOptional()
    breedId?: string;

    @IsString()
    @IsOptional()
    gender?: Gender;

    @IsBoolean()
    @IsOptional()
    notifications?: boolean;

    @IsBoolean()
    @IsOptional()
    hasArrived?: boolean;

    @IsBoolean()
    @IsOptional()
    purinaMarketingAccepted?: boolean;

    @IsString()
    @IsOptional()
    countryCode?: string;

    @IsString()
    @IsOptional()
    region?: string;

    @IsString()
    @IsOptional()
    city?: string;

    @IsBoolean()
    @IsOptional()
    isRescuePup?: boolean;

    @IsBoolean()
    @IsOptional()
    isApproximateDateOfBirth?: boolean;
}
