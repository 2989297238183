import { selectUrlLastChild } from '@frontend/data-access/router';
import { selectIsHowDidYouHearOnboardingPage } from '@frontend/data-access/user/config-cat';
import { householdFeature, selectIsUsUser } from '@frontend/data-access/user/household';
import { createSelector } from '@ngrx/store';
import { differenceInWeeks } from 'date-fns';
import {
    ONBOARDING_ROUTES_CHECKPOINTS,
    ONBOARDING_ROUTES_CHECKPOINTS_NOURISH_AB,
    ONBOARDING_ROUTES_CHECKPOINTS_NOURISH_AB_AND_HDYHAU,
    ONBOARDING_ROUTES_CHECKPOINTS_NOURISH_AB_AND_HDYHAU_SSO,
    ONBOARDING_ROUTES_CHECKPOINTS_NOURISH_AB_SSO,
    ONBOARDING_ROUTES_CHECKPOINTS_US_NOURISH_AB,
    ONBOARDING_ROUTES_CHECKPOINTS_US_NOURISH_AB_AND_HDYHAU,
    ONBOARDING_ROUTES_CHECKPOINTS_US_NOURISH_AB_AND_HDYHAU_SSO,
    ONBOARDING_ROUTES_CHECKPOINTS_US_NOURISH_AB_SSO,
    ONBOARDING_ROUTES_PROGRESS,
    ONBOARDING_ROUTES_PROGRESS_NOURISH_AB,
    ONBOARDING_ROUTES_PROGRESS_NOURISH_AB_AND_HDYHAU,
    ONBOARDING_ROUTES_PROGRESS_NOURISH_AB_AND_HDYHAU_SSO,
    ONBOARDING_ROUTES_PROGRESS_NOURISH_AB_SSO,
    ONBOARDING_ROUTES_PROGRESS_US_NOURISH_AB,
    ONBOARDING_ROUTES_PROGRESS_US_NOURISH_AB_AND_HDYHAU,
    ONBOARDING_ROUTES_PROGRESS_US_NOURISH_AB_AND_HDYHAU_SSO,
    ONBOARDING_ROUTES_PROGRESS_US_NOURISH_AB_SSO,
} from '../constants/onboarding.constants';
import { specialNeedOptions, usSpecialNeedOptions } from '../constants/question-special-needs.constants';
import { OnboardingRoutes } from '../onboarding-routing.model';
import { onboardingFeature } from './onboarding.reducer';
import { authenticationFeature, SignInProviders } from '@frontend/data-access/user/authentication';

export const selectIsSSOUserWithOwnerName = createSelector(
    householdFeature.selectOwnerName,
    authenticationFeature.selectSignInProvider,
    (ownerName, signInProvider) => {
        return !!ownerName && (signInProvider === SignInProviders.Apple || signInProvider === SignInProviders.Google);
    },
);

export const selectRoutesForOnboardingProgress = createSelector(
    selectIsUsUser,
    selectIsHowDidYouHearOnboardingPage,
    selectIsSSOUserWithOwnerName,
    (isUSUser, isHowDidYouHearOnboardingPage, isSSOUserWithOwnerName) => {
        if (isUSUser) {
            if (isSSOUserWithOwnerName) {
                return isHowDidYouHearOnboardingPage
                    ? ONBOARDING_ROUTES_PROGRESS_US_NOURISH_AB_AND_HDYHAU_SSO
                    : ONBOARDING_ROUTES_PROGRESS_US_NOURISH_AB_SSO;
            }

            return isHowDidYouHearOnboardingPage
                ? ONBOARDING_ROUTES_PROGRESS_US_NOURISH_AB_AND_HDYHAU
                : ONBOARDING_ROUTES_PROGRESS_US_NOURISH_AB;
        }

        if (!isUSUser) {
            if (isSSOUserWithOwnerName) {
                return isHowDidYouHearOnboardingPage
                    ? ONBOARDING_ROUTES_PROGRESS_NOURISH_AB_AND_HDYHAU_SSO
                    : ONBOARDING_ROUTES_PROGRESS_NOURISH_AB_SSO;
            }

            return isHowDidYouHearOnboardingPage
                ? ONBOARDING_ROUTES_PROGRESS_NOURISH_AB_AND_HDYHAU
                : ONBOARDING_ROUTES_PROGRESS_NOURISH_AB;
        }

        return ONBOARDING_ROUTES_PROGRESS;
    },
);

export const selectOnboardingProgress = createSelector(
    selectUrlLastChild,
    selectRoutesForOnboardingProgress,
    (lastChild, routes) => {
        if (!lastChild) {
            return 0;
        }

        return routes[lastChild] / Object.keys(routes).length;
    },
);

export const selectRoutesForOnboardingPreviousPage = createSelector(
    selectIsUsUser,
    selectIsHowDidYouHearOnboardingPage,
    selectIsSSOUserWithOwnerName,
    (isUSUser, isHowDidYouHearOnboardingPage, isSSOUserWithOwnerName) => {
        if (isUSUser) {
            if (isSSOUserWithOwnerName) {
                return isHowDidYouHearOnboardingPage
                    ? ONBOARDING_ROUTES_CHECKPOINTS_US_NOURISH_AB_AND_HDYHAU_SSO
                    : ONBOARDING_ROUTES_CHECKPOINTS_US_NOURISH_AB_SSO;
            }

            return isHowDidYouHearOnboardingPage
                ? ONBOARDING_ROUTES_CHECKPOINTS_US_NOURISH_AB_AND_HDYHAU
                : ONBOARDING_ROUTES_CHECKPOINTS_US_NOURISH_AB;
        }

        if (!isUSUser) {
            if (isSSOUserWithOwnerName) {
                return isHowDidYouHearOnboardingPage
                    ? ONBOARDING_ROUTES_CHECKPOINTS_NOURISH_AB_AND_HDYHAU_SSO
                    : ONBOARDING_ROUTES_CHECKPOINTS_NOURISH_AB_SSO;
            }

            return isHowDidYouHearOnboardingPage
                ? ONBOARDING_ROUTES_CHECKPOINTS_NOURISH_AB_AND_HDYHAU
                : ONBOARDING_ROUTES_CHECKPOINTS_NOURISH_AB;
        }

        return ONBOARDING_ROUTES_CHECKPOINTS;
    },
);

export const selectOnboardingPreviousPage = createSelector(
    selectUrlLastChild,
    selectRoutesForOnboardingPreviousPage,
    (currentRoute, routes) => {
        if (!currentRoute) {
            return undefined;
        }

        const indexOfCurrentRoute = Object.keys(routes).indexOf(currentRoute);

        for (let i = indexOfCurrentRoute - 1; i >= 0; i--) {
            const route = Object.keys(routes)[i];

            if (routes[route]) {
                return route as OnboardingRoutes;
            }
        }

        return undefined; // if we can't find a previous route, we're at the beginning
    },
);

export const selectOnboardingGuardPage = createSelector(householdFeature.selectCurrentDog, (dog) => {
    // assumes:
    // we have already waited for a profile call to complete
    // date of onboarding has already been checked and user let through if it was defined

    // determine progress through onboarding otherwise
    if (!dog?.name) {
        return OnboardingRoutes.NAME;
    }

    if (dog.isRescue === undefined) {
        return OnboardingRoutes.RESCUE;
    }

    if (dog.dateOfBirth === undefined) {
        return OnboardingRoutes.AGE;
    }

    if (dog.hasArrived === undefined) {
        return OnboardingRoutes.HAS_ARRIVED;
    }

    if (!dog.hasArrived && dog.dateOfArrival === undefined) {
        return OnboardingRoutes.ARRIVAL;
    }

    if (dog.hasArrived && differenceInWeeks(new Date(), dog.dateOfBirth) <= 9 && dog.dateOfArrival === undefined) {
        return OnboardingRoutes.FIRST_DAY_AT_HOME;
    }

    if (dog.hasArrived && differenceInWeeks(new Date(), dog.dateOfBirth) >= 9 && dog.gender === undefined) {
        return OnboardingRoutes.GENDER;
    }

    if (dog.gender === undefined) {
        return OnboardingRoutes.GENDER;
    }

    if (dog.breedId === undefined) {
        return OnboardingRoutes.BREED;
    }

    // We don't store anything beyond this point, so we'll just go here
    return OnboardingRoutes.OWNER_NAME;
});

export const selectNeedsByCountry = createSelector(selectIsUsUser, (isUsUser) => {
    return isUsUser ? usSpecialNeedOptions : specialNeedOptions;
});

export const selectNeedsFromSelectedNeeds = createSelector(
    onboardingFeature.selectSelectedNeeds,
    selectNeedsByCountry,
    (selectedNeeds, needs) => {
        return needs.filter((need) => selectedNeeds.includes(need.value));
    },
);
