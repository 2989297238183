import { IterablePostSendEmailDto } from '../dtos/iterable-post-send-email.dto';

export class InternalIterablePostSendEmailDtoMock {
    private defaultValue: IterablePostSendEmailDto = {
        recipientUserId: 'user-id',
        campaignId: 1,
        dataFields: {
            locale: 'en-GB',
        },
    };

    constructor(overrides?: Partial<IterablePostSendEmailDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): IterablePostSendEmailDto {
        return this.defaultValue;
    }
}
