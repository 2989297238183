import { DomainPostHappinessCheckDto } from '../../../dtos/happiness-check/domain-post-happiness-check.dto';

export class DomainPostHappinessCheckDtoMock {
    private defaultValue: DomainPostHappinessCheckDto = {
        userId: 'userId',
        ownerHappinessScore: 0.5,
        dogHappinessScore: 0.6,
        timestamp: 0,
    };

    constructor(overrides?: Partial<DomainPostHappinessCheckDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): DomainPostHappinessCheckDto {
        return this.defaultValue;
    }
}
