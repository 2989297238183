<ion-content>
    <button
        class="xl tiered-pricing-comparison-modal__close-button"
        data-test="tiered-pricing-comparison-modal-close-btn"
        (click)="closeModalClicked()"
        appTrackClick
        identifier="tiered-pricing-comparison-modal-close-btn"
    >
        <i class="fa-light fa-xmark"></i>
    </button>

    <section class="tiered-pricing-comparison-modal">
        <div class="tiered-pricing-comparison-modal__title-container">
            <h1>Plan comparison</h1>
            <p class="s tiered-pricing-comparison-modal__subtitle">
                @if (vm().isBasicPlan) {
                    Go Premium <i class="fa-regular fa-crown"></i> now
                } @else {
                    Choose the best plan for you and {{ '[NAME]' | textReplacement }}
                }
            </p>
            @if (!vm().isBasicPlan) {
                <app-tiered-pricing-comparison-toggle
                    [isPremiumSelected]="isPremiumSelected()"
                    (selectTier)="selectTier($event)"
                />
            }
        </div>

        <section class="tiered-pricing-comparison-modal__products">
            @if (this.isPremiumSelected()) {
                @for (premiumProduct of premiumProducts(); track premiumProduct.identifier) {
                    <app-payment-modal-product
                        [isSelected]="premiumProduct.identifier === selectedProduct()!.identifier"
                        [product]="premiumProduct"
                        (productClicked)="selectProduct($event)"
                    />
                }
            } @else {
                @for (basicProduct of basicProducts(); track basicProduct.identifier) {
                    <app-payment-modal-product
                        [isBasicSelected]="basicProduct.identifier === selectedProduct()!.identifier"
                        [product]="basicProduct"
                        (productClicked)="selectProduct($event)"
                    />
                }
            }
        </section>

        <app-tiered-pricing-plan-comparison [isPremium]="this.isPremiumSelected()" />

        <button
            class="zz-button tiered-pricing-comparison-modal__restore-purchases"
            appTrackClick
            identifier="plan-comparison-paywall-payment-restore-button"
            (click)="onRestoreClick()"
        >
            Restore purchases
        </button>

        <app-payment-modal-terms class="tiered-pricing-comparison-modal__terms" />
    </section>

    <ion-footer class="tiered-pricing-comparison-modal-footer gradient-footer">
        <div class="tiered-pricing-comparison-modal-footer__button-group">
            <ion-button
                appTrackClick
                [identifier]="'tiered-pricing-comparison-modal-payment-purchase-' + selectedProduct()!.identifier"
                color="max"
                expand="full"
                shape="round"
                [disabled]="!selectedProduct() || vm().isPurchaseInProgress"
                (click)="onPurchaseClick(selectedProduct()!)"
            >
                @if (vm().isBasicPlan) {
                    Upgrade to Premium
                    <i class="fa-regular fa-crown tiered-pricing-comparison-modal-footer__premium-icon"></i>
                } @else {
                    {{ vm().hasHistoricalPurchase ? 'Start my subscription' : 'Start my free trial' }}
                }
            </ion-button>
        </div>
    </ion-footer>
</ion-content>
