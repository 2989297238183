import { Component, computed } from '@angular/core';
import { IonContent } from '@ionic/angular/standalone';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { NourishModalBaseComponent } from '../nourish-modal-base.component';
import { GenericFeedbackComponent } from '@frontend/ui/generic-feedback';

@Component({
    selector: 'app-what-poop-can-tell-you-modal',
    templateUrl: './what-can-poop-tell-you-modal.component.html',
    styleUrl: './what-can-poop-tell-you-modal.component.scss',
    imports: [IonContent, AnalyticsTrackClickDirective, GenericFeedbackComponent],
})
export class WhatCanPoopTellYouModalComponent extends NourishModalBaseComponent {
    public poopChartUrl = computed(() => {
        return this.isUsUser() ? '/assets/images/nourish/us-poop-chart.png' : '/assets/images/nourish/poop-chart.png';
    });

    public colourText = computed(() => {
        return this.isUsUser() ? 'color' : 'colour';
    });

    public greyText = computed(() => {
        return this.isUsUser() ? 'Gray' : 'Grey';
    });
}
