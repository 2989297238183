import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap } from 'rxjs/operators';
import { map, of } from 'rxjs';
import { patchDog, patchDogFailure, patchDogSuccess } from './dog.actions';
import { DogService } from '../../services/dog.service';

@Injectable()
export class DogEffects {
    private readonly actions$ = inject(Actions);
    private readonly dogService = inject(DogService);

    patchDog$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(patchDog),
            concatMap(({ dogId, dog, correlationId }) =>
                this.dogService.patchDog(dogId, dog).pipe(
                    map((dogResponse) => {
                        return patchDogSuccess({ dog: dogResponse, dto: dog, correlationId });
                    }),
                    catchError((error: Error) => of(patchDogFailure({ error, correlationId }))),
                ),
            ),
        );
    });
}
