import { selectCourseIds } from '@frontend/data-access/contentful';
import { householdFeature } from '@frontend/data-access/user/household';
import { createSelector } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import {
    selectCoursesWithProgressInfo,
    selectUncompletedCourses,
    selectUnCompletedFoundationalCourses,
} from '../../../store/course/course-with-progress.selectors';
import { Step, StepStatus } from '../../../store/step/step.model';
import { selectCoursesWithStepsAndSplits } from '../../course-steps-and-splits.selectors';
import { CourseWithProgress, CourseWithSteps } from '../../models/courses.models';
import {
    courseSettings,
    mapCourseAndCoordinatesToStepsAndSplitsWithCoordinates,
    pickSelectedCourseId,
} from '../utils/course-path.utils';
import { coursePathFeature } from './course-path.reducer';
import { CoursePageStep, CourseSplitWithCoordinates } from './models/course-path.model';

export const selectSelectedPathCourseId = createSelector(
    coursePathFeature.selectSelectedCourseId,
    selectCourseIds,
    householdFeature.selectHasArrivedOrUndefined,
    selectUnCompletedFoundationalCourses,
    selectUncompletedCourses,
    (selectedCourseId, courseIds, hasArrived, uncompletedFoundationalCourses, uncompletedCourses) => {
        const courseIdExists = !!selectedCourseId && courseIds.includes(selectedCourseId);
        return courseIdExists
            ? selectedCourseId
            : pickSelectedCourseId(
                  !!hasArrived,
                  uncompletedFoundationalCourses.map((course) => course.id),
                  uncompletedCourses.map((course) => course.id),
              );
    },
);

export const selectSelectedCourseWithStepsAndSplits = createSelector(
    selectSelectedPathCourseId,
    selectCoursesWithStepsAndSplits,
    (selectedCourseId, courses): CourseWithSteps | undefined =>
        selectedCourseId ? courses.find((course) => course.id === selectedCourseId) : undefined,
);

export const selectSelectedCourseWithProgressInfo = createSelector(
    selectSelectedPathCourseId,
    selectCoursesWithProgressInfo,
    (selectedCourseId, courses): CourseWithProgress | undefined =>
        selectedCourseId ? courses.find((course) => course.id === selectedCourseId) : undefined,
);

export const selectSelectedCourseStepsAndSplits = createSelector(
    selectSelectedCourseWithStepsAndSplits,
    (course): Step[] | undefined => course?.steps,
);

export const selectSelectedCourseColor = createSelector(selectSelectedCourseWithStepsAndSplits, (selectedCourse) => {
    return selectedCourse?.color ?? Color.Lola;
});

export const selectSelectedCourseWithCoordinates = createSelector(
    selectSelectedPathCourseId,
    coursePathFeature.selectCoursesCoordinates,
    selectSelectedCourseWithStepsAndSplits,
    (
        selectedCourseId,
        coursesWithCoordinates,
        selectedCourse,
    ): { steps: CoursePageStep[]; splits: CourseSplitWithCoordinates[] } | undefined => {
        const courseCoordinates = coursesWithCoordinates.find((course) => course.id === selectedCourseId);

        return courseCoordinates && selectedCourse
            ? mapCourseAndCoordinatesToStepsAndSplitsWithCoordinates(courseCoordinates, selectedCourse)
            : undefined;
    },
);

export const selectSelectedCourseSplits = createSelector(selectSelectedCourseWithCoordinates, (selectedCourse) => {
    return selectedCourse?.splits;
});

export const selectHeightOfFirstInactiveStep = createSelector(selectSelectedCourseWithCoordinates, (course) => {
    return course?.steps.find((step) => step.progress?.state === StepStatus.INACTIVE)?.coordinates.y;
});

export const selectCoursePathSvgSize = createSelector(selectSelectedCourseWithCoordinates, (course) => {
    const lastStep = course?.steps[course.steps.length - 1];
    if (lastStep && lastStep.coordinates) {
        return { width: courseSettings.svgWidth, height: lastStep.coordinates.y + 150 };
    }
    return undefined;
});
