import { inject, Injectable } from '@angular/core';
import { AdjustEventType, trackAdjustEvent } from '@frontend/data-access/adjust';
import { trackEvent } from '@frontend/data-access/analytics';
import { selectIsStartCourseAb } from '@frontend/data-access/user/config-cat';
import { getHouseholdSuccess, householdFeature } from '@frontend/data-access/user/household';
import {
    createRescheduledSteps,
    createStepProgress,
    createStepProgressSuccess,
    getRescheduledSteps,
    getStepProgress,
    selectCompletedStepProgress,
    selectCompletedStepsCount,
    selectRescheduledStepsPractice,
    selectRescheduledStepsPracticeIdsWithCount,
    setRescheduledStepsAsInactive,
} from '@frontend/data-access/user/progress';
import { onboardingComplete } from '@frontend/feature/onboarding';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { StepType, TopicName } from '@shared/content-api-interface';
import { CourseProgressType, LessonProgressType, RescheduledStepType } from '@shared/user-domain';
import { concatMap } from 'rxjs';
import { delay, filter, map, switchMap } from 'rxjs/operators';
import {
    selectSelectedCourseWithStepsAndSplits,
    selectSelectedPathCourseId,
} from '../../../courses/course-path/store/course-path.selectors';
import { launchRatingQualifierModal } from '../../../rating/app-rating-qualifier/store/rating-qualifier-modal.actions';
import {
    selectCoursesWith100PercentProgressButNotCompleted,
    selectInProgressCourseIds,
} from '../../course/course-with-progress.selectors';
import { selectSteps } from '../../step/step.selectors';
import { triggerCreateManyCourseProgress } from '../course-progress/course-progress.actions';
import {
    setStepCompleted,
    setStepSkipped,
    triggerCreateStepProgress,
    TRIGGERED_FROM_STEP_CORRELATION_ID,
    updateJumpToProgressForSelectedCourse,
} from './step-progress.actions';
import { CreateSkippedStepProgress } from './step-progress.model';
import { getInactiveStepIdsBeforeIndex, mapCreateStepProgressToStepProgressPayload } from './step-progress.utils';

@Injectable()
export class StepProgressEffects {
    private readonly store = inject(Store);
    private readonly actions$ = inject(Actions);

    triggerGetStepProgressGetHouseholdSuccess$ = createEffect(() => {
        return this.actions$.pipe(ofType(getHouseholdSuccess)).pipe(map(() => getStepProgress()));
    });

    triggerGetStepProgressOnboardingComplete$ = createEffect(() => {
        return this.actions$.pipe(ofType(onboardingComplete)).pipe(map(() => getStepProgress()));
    });

    triggerGetRescheduledStepsGetHouseholdSuccess$ = createEffect(() => {
        return this.actions$.pipe(ofType(getHouseholdSuccess)).pipe(map(() => getRescheduledSteps()));
    });

    triggerGetRescheduledStepsOnboardingComplete$ = createEffect(() => {
        return this.actions$.pipe(ofType(onboardingComplete)).pipe(map(() => getRescheduledSteps()));
    });

    triggerCreateStepProgress$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(triggerCreateStepProgress),
            concatLatestFrom(() => [
                this.store.select(selectSteps),
                this.store.select(householdFeature.selectCurrentDogId),
            ]),
            map(([{ progress, correlationId }, steps, dogId]) => {
                if (!dogId) {
                    throw new Error('No dog id to create step progress');
                }

                const mappedProgress = mapCreateStepProgressToStepProgressPayload(progress, steps, dogId);

                return createStepProgress({
                    progress: mappedProgress,
                    correlationId,
                });
            }),
        );
    });

    setStepSkipped$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(setStepSkipped),
            concatLatestFrom(() => this.store.select(selectCompletedStepProgress)),
            filter(([{ properties }, completedStepProgress]) => {
                return !completedStepProgress.find((progress) => progress.stepId === properties.stepId);
            }),
            map(([{ properties }]) => {
                return triggerCreateStepProgress({
                    progress: [
                        {
                            stepId: properties.stepId,
                            progressType: LessonProgressType.SKIPPED,
                            skipReason: properties.skipReason,
                        },
                    ],
                });
            }),
        );
    });

    setStepCompleted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(setStepCompleted),
            map(({ properties, correlationId }) => {
                return triggerCreateStepProgress({
                    progress: [
                        {
                            stepId: properties.stepId,
                            progressType: LessonProgressType.COMPLETED,
                            rating: properties.rating,
                            isPractice: properties.isPractice,
                        },
                    ],
                    correlationId,
                });
            }),
        );
    });

    trackFiveStepsCompleted$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createStepProgressSuccess),
            map(({ progress }) => {
                return progress.filter((progressItem) => progressItem.progress === LessonProgressType.COMPLETED);
            }),
            filter((completedProgress) => completedProgress.length > 0),
            concatLatestFrom(() => this.store.select(selectCompletedStepsCount)),
            filter(([_, completedStepCount]) => completedStepCount === 4),
            switchMap(() => [
                trackEvent({ eventName: 'Five lessons completed' }),
                trackAdjustEvent({ event: AdjustEventType.FIVE_LESSONS_COMPLETED }),
            ]),
        );
    });

    updateProgressForJumpToNextPartInCourse$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateJumpToProgressForSelectedCourse),
            concatLatestFrom(() => this.store.select(selectSelectedCourseWithStepsAndSplits)),
            map(([{ split }, course]) => {
                return course ? getInactiveStepIdsBeforeIndex(course.steps, split.splitIndex) : [];
            }),
            filter((stepIds) => stepIds.length > 0),
            map((stepIds) => {
                const progress: CreateSkippedStepProgress[] = stepIds.map((stepId) => ({
                    stepId,
                    progressType: LessonProgressType.SKIPPED,
                    skipReason: 'jump-to-next-part',
                }));

                return triggerCreateStepProgress({
                    progress,
                });
            }),
        );
    });

    checkIfStepCompletionShouldLaunchRatingPrompt$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createStepProgressSuccess),
            delay(1500),
            map(({ progress }) => {
                return progress.filter((progressItem) => progressItem.progress === LessonProgressType.COMPLETED);
            }),
            filter((completedProgress) => completedProgress.length > 0),
            concatLatestFrom(() => this.store.select(selectSteps)),
            filter(([progress, steps]) => {
                const stepsToCheck = steps.filter((step) =>
                    progress.some((progressItem) => progressItem.stepId === step.id),
                );

                return stepsToCheck.some(
                    (step) => step.topic.title === TopicName.SIT && step.content.contentType === StepType.LESSON,
                );
            }),
            map(() => {
                return launchRatingQualifierModal({ trigger: 'sit-lesson' });
            }),
        );
    });

    setExistingPracticeAsInactiveIfRatingIsHigh$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createStepProgressSuccess),
            map(({ progress }) => {
                return progress.filter(
                    (progressItem) =>
                        progressItem.progress === LessonProgressType.COMPLETED &&
                        !!progressItem.rating &&
                        progressItem.rating > 3,
                );
            }),
            filter((completedProgress) => completedProgress.length > 0),
            concatLatestFrom(() => this.store.select(selectRescheduledStepsPractice)),
            map(([completedProgress, rescheduledSteps]) => {
                const progressStepIds = completedProgress.map((progressItem) => progressItem.stepId);
                const rescheduledStepIds = rescheduledSteps.map((rescheduleStep) => rescheduleStep.stepId);

                return rescheduledStepIds.find((stepId) => progressStepIds.includes(stepId));
            }),
            filter((stepId) => stepId !== undefined),
            map((stepId) => {
                return setRescheduledStepsAsInactive({ stepId: stepId! });
            }),
        );
    });

    checkForCourseToComplete$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createStepProgressSuccess),
            concatLatestFrom(() => this.store.select(selectCoursesWith100PercentProgressButNotCompleted)),
            filter(([_, courses]) => courses.length > 0),
            map(([_, courses]) => {
                return triggerCreateManyCourseProgress({
                    courseProgress: courses.map((course) => ({
                        id: course.id,
                        progressType: CourseProgressType.COMPLETED,
                    })),
                });
            }),
        );
    });

    checkIfStepCompletionShouldSetCourseInProgress$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createStepProgressSuccess),
            concatLatestFrom(() => [
                this.store.select(selectIsStartCourseAb),
                this.store.select(selectSelectedPathCourseId),
                this.store.select(selectInProgressCourseIds),
            ]),
            filter(([{ progress, correlationId }, isStartCourseAb, selectedCoursePathId, inProgressCourseIds]) => {
                return (
                    isStartCourseAb &&
                    !!selectedCoursePathId &&
                    !inProgressCourseIds.includes(selectedCoursePathId) &&
                    correlationId === TRIGGERED_FROM_STEP_CORRELATION_ID &&
                    progress.some((progressItem) => progressItem.progress === LessonProgressType.COMPLETED)
                );
            }),
            map(([, , selectedPathCourseId]) => {
                return triggerCreateManyCourseProgress({
                    courseProgress: [
                        {
                            id: selectedPathCourseId,
                            progressType: CourseProgressType.IN_PROGRESS,
                        },
                    ],
                    correlationId: TRIGGERED_FROM_STEP_CORRELATION_ID,
                });
            }),
        );
    });

    createRescheduledStepForPracticeWhenRatingIsLow$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createStepProgressSuccess),
            concatLatestFrom(() => this.store.select(selectRescheduledStepsPracticeIdsWithCount)),
            map(([{ progress }, practiceItems]) => {
                // only return items that need to create a rescheduled step
                return progress.filter((progressItem) => {
                    const isRating3OrLower = !!progressItem.rating && progressItem.rating <= 3;

                    if (!isRating3OrLower) {
                        return false;
                    }

                    if (progressItem.isPractice) {
                        return true;
                    }

                    const hasExistingPractice = practiceItems.find(
                        (item) => item.stepId === progressItem.stepId,
                    )?.count;
                    return !hasExistingPractice;
                });
            }),
            filter((progress) => progress.length > 0),
            concatLatestFrom(() => this.store.select(householdFeature.selectCurrentDogId)),
            concatMap(([progress, dogId]) => {
                // for all items create a rescheduled step
                return progress.map((progressItem) => {
                    return createRescheduledSteps({
                        rescheduledSteps: [
                            {
                                dogId,
                                stepId: progressItem.stepId,
                                contentId: progressItem.contentId,
                                type: RescheduledStepType.PRACTICED,
                                isActive: true,
                            },
                        ],
                    });
                });
            }),
        );
    });
}
