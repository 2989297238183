<div class="ai-chat-input" data-test="today-ai-chat-input" (click)="onInputClicked()">
    <img class="ai-chat-input__image" [src]="'assets/images/ziggy/ziggy-close-up.svg'" alt="Ziggy" />
    <ion-textarea
        #input
        data-test="today-ai-chat-input-input"
        class="ai-chat-input__input reset"
        placeholder="Ask me anything"
        [autoGrow]="true"
        [rows]="1"
    />
</div>
@if (showSendButton) {
    <button
        class="zz-button ai-chat-send-btn"
        data-test="today-ai-chat-send-btn"
        [ngClass]="{
            'ai-chat-send-btn--disabled': !inputRef?.value,
        }"
        [disabled]="!inputRef?.value"
        (click)="onSubmitted()"
    >
        <i class="ai-chat-send-btn__icon fa-regular fa-paper-plane-top"></i>
    </button>
}
