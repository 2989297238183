import { Household } from '../models/household.model';
import { Dog } from '../models/dog.model';
import { HouseholdUser } from '../models/household-user.model';
import { PublicUser } from '../models/public-user.model';
import {
    InternalDogDto,
    InternalHouseholdDto,
    InternalHouseholdUserDto,
    InternalPublicUserDto,
} from '@shared/internal-api-interface';
import { upsertArrayItem } from '@frontend/utility/angular';

export function upsertDogInHousehold(household: Household | undefined, dog: Dog): Household | undefined {
    if (!household) {
        return undefined;
    }
    return {
        ...household,
        dogs: upsertArrayItem(
            household.dogs,
            [dog],
            (existingEntry: Dog, newEntry: Dog) => existingEntry.id === newEntry.id,
        ),
    };
}

export function upsertUserInHousehold(household: Household | undefined, user: HouseholdUser): Household | undefined {
    if (!household) {
        return undefined;
    }
    return {
        ...household,
        currentUser: user,
        users: upsertArrayItem(
            household.users,
            [mapHouseholdUserToPublicUser(user)],
            (existingEntry: PublicUser, newEntry: PublicUser) => existingEntry.id === newEntry.id,
        ),
    };
}

export function mapInternalHouseholdDtoToHousehold(household: InternalHouseholdDto): Household {
    return {
        ...household,
        currentUser: mapInternalHouseholdUserDtoToHouseholdUser(household.currentUser),
        users: household.users.map(mapInternalPublicUserDtoToPublicUser),
        dogs: household.dogs.map(mapInternalDogDtoToDog),
    };
}

export function mapInternalHouseholdUserDtoToHouseholdUser(user: InternalHouseholdUserDto): HouseholdUser {
    return {
        ...user,
        dateOfOnboarding: user.dateOfOnboarding ? new Date(user.dateOfOnboarding) : undefined,
    };
}

export function mapInternalPublicUserDtoToPublicUser(user: InternalPublicUserDto): PublicUser {
    return {
        ...user,
        dateOfOnboarding: user.dateOfOnboarding ? new Date(user.dateOfOnboarding) : undefined,
    };
}

export function mapHouseholdUserToPublicUser(user: HouseholdUser): PublicUser {
    return {
        id: user.id,
        mail: user.mail,
        name: user.name,
        dateOfOnboarding: user.dateOfOnboarding,
        countryCode: user.countryCode,
    };
}

export function mapInternalDogDtoToDog(dog: InternalDogDto): Dog {
    return {
        ...dog,
        dateOfArrival: dog.dateOfArrival ? new Date(dog.dateOfArrival) : undefined,
        dateOfBirth: dog.dateOfBirth ? new Date(dog.dateOfBirth) : undefined,
        dateOfOnboarding: dog.dateOfOnboarding ? new Date(dog.dateOfOnboarding) : undefined,
    };
}
