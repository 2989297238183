import { Component } from '@angular/core';

@Component({
    selector: 'ui-slider-item',
    templateUrl: './slider-item.component.html',
    styleUrl: './slider-item.component.scss',
})
export class SliderItemComponent {
    // NBSon - this component can be optionally used inside the app-slider component to apply display: block to the element, e.g. for components with absolutely positioned elements in them that don't want to use a wrapper div
}
