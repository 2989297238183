<section class="voucher-large">
    @if (locked()) {
        <div class="voucher-large__locked-background">
            <div class="voucher-large__locked-icon">
                <i class="fa-solid fa-lock" data-test="voucher-large-locked-icon"></i>
            </div>
        </div>
    }
    <h3 class="voucher-large__heading unset-margin" data-test="voucher-large-heading">{{ heading() }}</h3>
    <p class="voucher-large__sub-heading unset-margin" data-test="voucher-large-subheading">{{ subHeading() }}</p>
    <div class="voucher-large__divider"></div>
    <div class="voucher-large__divider-left"></div>
    <div class="voucher-large__divider-right"></div>
    <img class="voucher-large__image-1" data-test="voucher-large-image-1" [src]="image1Url()" [alt]="image1Alt()" />
    <img class="voucher-large__image-2" data-test="voucher-large-image-2" [src]="image2Url()" [alt]="image2Alt()" />
    <ion-button
        class="voucher-large__button s"
        type="button"
        size="small"
        data-test="voucher-large-btn"
        [color]="buttonColour()"
        (click)="handleClick()"
        >{{ buttonText() }}
    </ion-button>
</section>
