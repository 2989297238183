import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { ShowModalFrequency, UserInteractionItem } from '../models/user-interactions.model';
import { storeUserInteractionWithTimestamp } from './user-interactions.actions';
import { getLocalStorageSuccess } from '@frontend/data-access/capacitor';
import { USER_INTERACTIONS_LOCAL_STORAGE_KEY } from './user-interactions.constants';
import { upsertArrayItem } from '@frontend/utility/angular';
import { timeFeature } from '@frontend/data-access/time';
import { shouldDisplayModal } from '../utils/user-interactions.utils';

export interface UserInteractionState {
    userInteractions: UserInteractionItem[];
}

export const initialState: UserInteractionState = {
    userInteractions: [],
};

export const userInteractionFeature = createFeature({
    name: 'userInteraction',
    reducer: createReducer(
        initialState,
        on(getLocalStorageSuccess, (state, { key, data }): UserInteractionState => {
            if (key !== USER_INTERACTIONS_LOCAL_STORAGE_KEY) {
                return state;
            }

            return { ...state, ...(data as any) };
        }),
        on(
            storeUserInteractionWithTimestamp,
            (state: UserInteractionState, { id, timestamp, dontShowAgain }): UserInteractionState => {
                const { userInteractions: userInteractionItemsFromState } = state;

                const newUserInteractionItems: UserInteractionItem[] = [{ id, timestamp, dontShowAgain }];
                const updatedUserInteractionItems = upsertArrayItem(
                    userInteractionItemsFromState,
                    newUserInteractionItems,
                    (item: UserInteractionItem) => item.id === id,
                );

                return {
                    ...state,
                    userInteractions: updatedUserInteractionItems,
                };
            },
        ),
    ),
    extraSelectors: ({ selectUserInteractions }) => ({
        selectShouldDisplayInfoItem: (itemId: string, showModalFrequency: ShowModalFrequency) =>
            createSelector(selectUserInteractions, timeFeature.selectToday, (userInteractionItems, today) =>
                shouldDisplayModal(showModalFrequency, itemId, userInteractionItems, today),
            ),
    }),
});
