import { NgStyle } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';
import { Course } from '@frontend/data-access/contentful';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { SvgColorComponent } from '@frontend/ui/svg-color';
import { IonButton, IonCard } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { goToCourseFromTile } from '../../data-access/today.page.actions';
import { getSquiggleColor } from '@frontend/utility/angular';

@Component({
    selector: 'app-course-tile',
    templateUrl: './course-tile.component.html',
    styleUrl: './course-tile.component.scss',
    imports: [NgStyle, IonButton, IonCard, AnalyticsTrackClickDirective, SvgColorComponent],
})
export class CourseTileComponent {
    private readonly store = inject(Store);

    protected readonly Color = Color;

    public course = input.required<Course>();

    public squiggleColor = computed(() => getSquiggleColor(this.course().color));

    public onGoToCourseClick(): void {
        this.store.dispatch(
            goToCourseFromTile({
                courseId: this.course().id,
            }),
        );
    }
}
