import { Component, input } from '@angular/core';

@Component({
    selector: 'ui-voucher-small',
    templateUrl: './voucher-small.component.html',
    styleUrl: './voucher-small.component.scss',
})
export class VoucherSmallComponent {
    public heading = input.required<string>();
    public subHeading = input.required<string>();
    public text = input.required<string>();
    public imageUrl = input.required<string>();
    public imageAlt = input.required<string>();
}
