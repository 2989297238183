export const notEmpty = <TValue>(value: TValue | null | undefined): value is TValue =>
    !(value === null || value === undefined);

export const isStringNumber = (value: string): boolean => {
    return value != null && value !== '' && !Number.isNaN(Number(value));
};

export const emptyString = (item: string): boolean => {
    return item !== '';
};

export const isNotEmpty = <T>(value: unknown): value is T => {
    return !isEmpty(value);
};

export const isEmpty = (value: unknown) => {
    if (value === null || value === undefined) {
        return true;
    }

    if (typeof value === 'string' && value === '') {
        return true;
    }

    if (Array.isArray(value) && value.length === 0) {
        return true;
    }

    if (typeof value === 'object' && Object.keys(value).length === 0) {
        return true;
    }

    return (value instanceof Map || value instanceof Set) && value.size === 0;
};

export const isString = (value: unknown): value is string => {
    return typeof value === 'string';
};
