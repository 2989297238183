import { IsDate, IsNumber, IsOptional, IsString } from 'class-validator';

export class InternalPatchTodayCourseProgressDto {
    @IsString()
    @IsOptional()
    dogId?: string;

    @IsNumber()
    numberOfStepsCompleted!: number;

    @IsDate()
    date!: Date;
}
