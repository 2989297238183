import { Component, Input } from '@angular/core';
import { IonItem } from '@ionic/angular/standalone';
import type { CardItemModel } from './models/card-item.model';

@Component({
    selector: 'ui-card-item',
    templateUrl: './card-item.component.html',
    styleUrl: './card-item.component.scss',
    imports: [IonItem],
})
export class CardItemComponent {
    @Input({ required: true }) public content!: CardItemModel;
}
