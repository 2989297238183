import { createAction, props } from '@ngrx/store';
import { Dog } from '../../models/dog.model';

export const patchDog = createAction(
    '[Household] Patch Dog',
    props<{ dogId: string; dog: Partial<Dog>; correlationId: string }>(),
);

export const patchDogSuccess = createAction(
    '[Household] Patch Dog Success',
    props<{
        dog: Dog;
        dto: Partial<Dog>;
        correlationId: string;
    }>(),
);

export const patchDogFailure = createAction(
    '[Household] Patch Dog Failure',
    props<{ error: Error; correlationId: string }>(),
);
