import { InternalHouseholdDto } from '../../../dtos/user/household/household.dto';
import { InternalDogDtoMock } from '../dog/internal-dog.dto.mock';
import { InternalPublicUserDtoMock } from '../user/internal-public-user.dto.mock';
import { InternalHouseholdUserDtoMock } from '../user/internal-household-user.dto.mock';

export class InternalHouseholdDtoMock {
    private defaultValue: InternalHouseholdDto = {
        id: 'uuid-1234',
        name: 'household-123',
        currentUser: new InternalHouseholdUserDtoMock().value,
        users: [new InternalPublicUserDtoMock().value],
        dogs: [new InternalDogDtoMock().value],
    };

    constructor(overrides?: Partial<InternalHouseholdDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): InternalHouseholdDto {
        return this.defaultValue;
    }
}
