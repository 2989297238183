import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { getGeoLocation, getGeoLocationFailure, getGeoLocationSuccess } from './geo-location.actions';
import { GeoLocation } from '../models/geo-location.model';
import { LoadingState } from '@frontend/data-access/shared-models';

export interface GeoLocationState {
    geoLocation: GeoLocation | undefined;
    loadingState: LoadingState;
}

export const initialState: GeoLocationState = {
    geoLocation: undefined,
    loadingState: LoadingState.INIT,
};

export const geoLocationFeature = createFeature({
    name: 'geoLocation',
    reducer: createReducer(
        initialState,
        on(
            getGeoLocation,
            (state): GeoLocationState => ({
                ...state,
                loadingState: LoadingState.LOADING,
            }),
        ),
        on(
            getGeoLocationSuccess,
            (state, action): GeoLocationState => ({
                ...state,
                geoLocation: action.geoLocation,
                loadingState: LoadingState.LOADED,
            }),
        ),
        on(
            getGeoLocationFailure,
            (state): GeoLocationState => ({
                ...state,
                geoLocation: undefined,
                loadingState: LoadingState.LOADED,
            }),
        ),
    ),
    extraSelectors: ({ selectGeoLocation }) => ({
        selectIp: createSelector(selectGeoLocation, (geoLocation) => geoLocation?.ip),
        selectCountryCode: createSelector(selectGeoLocation, (geoLocation) => geoLocation?.countryCode),
    }),
});
