<section class="nourish-header-us page-padding-top page-padding-sides">
    <div class="nourish-header-us__title-container">
        <h1 class="page-title no-margin-bottom" data-test="nourish-header-us-title">Nourish</h1>
        <h2 class="no-margin-bottom no-margin-top" data-test="nourish-header-subtitle">Your personalized nutrition</h2>
    </div>

    <app-nourish-pet-food-finder [shouldShowHeading]="false" [origin]="NourishFoodFinderOriginId.NOURISH_PAGE_US" />
</section>

<section class="nourish-header-us__list-container" [ngClass]="Color.AppOutline">
    <h3 class="no-margin-top">Get the perfect food</h3>
    <ul class="nourish-header-us__list no-margin-top ignore-global-color">
        <li class="nourish-header-us__list-item">
            <img
                class="nourish-header-us__list-image"
                src="/assets/images/buddy/buddy-holds-checklist-in-mouth.svg"
                alt="Buddy holds a checklist in his mouth"
            />
            <p class="no-margin">Tell us about {{ dogName() }}</p>
        </li>

        <li>
            <hr class="nourish-header-us__list-break" />
        </li>

        <li class="nourish-header-us__list-item">
            <img
                class="nourish-header-us__list-image"
                src="/assets/images/oliver/oliver-drooling-food.svg"
                alt="Oliver drools next to a bag of dog food"
            />
            <p class="no-margin">Get a customized food recommendation</p>
        </li>

        <li>
            <hr class="nourish-header-us__list-break" />
        </li>

        <li class="nourish-header-us__list-item">
            <img
                class="nourish-header-us__list-image"
                src="/assets/images/buddy/buddy-licks-food-from-treat-mat.svg"
                alt="Buddy licking food from a treat mat"
            />
            <p class="no-margin">Receive a feeding guide unique to {{ dogName() }}'s diet</p>
        </li>
    </ul>
</section>
