import { IsDate, IsOptional, IsString } from 'class-validator';

export class InternalPatchHouseholdUserDto {
    @IsString()
    @IsOptional()
    name?: string;

    @IsString()
    @IsOptional()
    mail?: string;

    @IsDate()
    @IsOptional()
    dateOfOnboarding?: number;

    @IsString()
    @IsOptional()
    countryCode?: string;
}
