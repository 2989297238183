import { NgStyle } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { IonButton, IonCard } from '@ionic/angular/standalone';
import { Color } from '@shared/utils/typescript';

@Component({
    selector: 'ui-generic-card-large',
    templateUrl: './generic-card-large.component.html',
    styleUrl: './generic-card-large.component.scss',
    imports: [NgStyle, IonButton, IonCard],
})
export class GenericCardLargeComponent {
    public title = input.required<string>();
    public description = input.required<string>();
    public backgroundColor = input.required<Color>();
    public imageUrl = input.required<string>();
    public imageAltText = input.required<string>();

    public backgroundUrl = input<string>();
    public callToActionText = input<string>();
    public label = input<string>();

    public callToActionClicked = output();

    protected readonly Color = Color;

    public clicked(): void {
        this.callToActionClicked.emit();
    }
}
