<div [ngClass]="Color.Alvin" class="treat-modal">
    <img
        [src]="'assets/images/third-party/lilys-kitchen/lilys-kitchen-logo-light.svg'"
        [alt]="'Lilys kitchen logo'"
        class="treat-modal__logo"
    />
    <img
        [src]="'assets/images/bailey/bailey-jumps-in-front-of-tennis-ball.svg'"
        [alt]="'Bailey jumps in front of tennis ball'"
        class="treat-modal__image"
    />

    <h2 class="treat-modal__heading">Training treats with proper meat</h2>

    <section class="treat-modal__section">
        <p class="s unset-margin">
            <img [src]="'assets/images/zigzag/z-black.svg'" [alt]="'Black zigzag logo'" class="treat-modal__icon" />
            Special offer
        </p>
        <p class="l unset-margin">
            <span class="treat-modal__icon fa-regular fa-gift"></span>
            30% OFF
        </p>
        <p class="xs unset-margin">Use code <b class="xs treat-modal__section-code">LOVELILYS</b> at checkout</p>
    </section>

    <p class="treat-modal__subtext unset-margin">
        Supercharge your training sessions with our handpicked training treats supplier
        <b> Lily's Kitchen </b>
    </p>

    <div>
        <ion-button
            class="treat-modal__claim-button"
            appTrackClick
            identifier="lilys-kitchen-claim-discount-button"
            color="max"
            data-test="lilys-kitchen-treat-modal-claim-btn"
            (click)="onClaimNowClick()"
        >
            <span>Claim discount now</span>
        </ion-button>

        <ion-button
            class="treat-modal__dismiss-button"
            appTrackClick
            identifier="lilys-kitchen-dismiss-discount-button"
            color="Alvin"
            data-test="lilys-kitchen-treat-modal-cancel-btn"
            (click)="onMaybeLaterClick()"
            >Maybe later
        </ion-button>
    </div>
</div>
