import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-training-coach-chat-intro',
    templateUrl: './training-coach-chat-intro.component.html',
    styleUrl: './training-coach-chat-intro.component.scss',
})
export class TrainingCoachChatIntroComponent {
    @Input() dogName = 'your pup';
}
