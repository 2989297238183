<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <app-header
            [leftButton]="false"
            [rightButton]="{
                analyticsIdentifier: 'developmental-window-modal-close-btn',
                sprite: 'light',
                iconName: 'xmark',
                background: 'var(--ion-color-app-background)',
                iconColour: 'black',
            }"
            (rightButtonPressed)="closeModal()"
        />
    </ion-toolbar>
</ion-header>

<ion-content
    class="developmental-window-modal ignore-global-color"
    [fullscreen]="true"
    [color]="getDevelopmentalWindowModalColors(developmentalWindow.color).modalColor"
>
    <article class="developmental-window-modal__content page-padding-sides" [ngStyle]="getDynamicallyAppliedStyles()">
        <img class="developmental-window-modal__header-image" [src]="developmentalWindow.image" />
        <h2 class="center unset-margin">{{ developmentalWindow.title }}</h2>
        <div [innerHTML]="developmentalWindow.textMain | richContent | textReplacement"></div>
        @if (developmentalWindow.linkedTopics.length !== 0) {
            <p>Core socialisation skills in this period:</p>
            <ul class="developmental-window-modal__topics">
                @for (topic of developmentalWindow.linkedTopics; track topic.entityId) {
                    <li
                        class="developmental-window-modal__topic-tile"
                        [ngStyle]="{ 'background-color': 'var(--ion-color-' + topic.color + ')' }"
                    >
                        <img [src]="topic.imageUrl" />
                        <label
                            class="zz-label s"
                            [ngStyle]="{ color: 'var(--ion-color-' + topic.color + '-contrast)' }"
                            >{{ topic.title }}</label
                        >
                    </li>
                }
            </ul>
        }
        <ion-accordion-group class="developmental-window-modal__accordion-group">
            @for (accordion of developmentalWindow.accordions; track accordion.title) {
                <ion-accordion
                    class="developmental-window-modal__accordion-container"
                    [ngStyle]="{
                        'background-color':
                            'var(--ion-color-' +
                            getDevelopmentalWindowModalColors(developmentalWindow.color).modalSecondaryColor +
                            ')',
                    }"
                >
                    <ion-item
                        slot="header"
                        class="developmental-window-modal__accordion"
                        color="{{ getDevelopmentalWindowModalColors(developmentalWindow.color).modalSecondaryColor }}"
                        [lines]="'none'"
                    >
                        <ion-label class="developmental-window-modal__accordion-label">
                            {{ accordion.title }}
                        </ion-label>
                    </ion-item>
                    <div
                        class="developmental-window-modal__accordion-content ion-padding"
                        slot="content"
                        [ngStyle]="{
                            color:
                                'var(--ion-color-' +
                                getDevelopmentalWindowModalColors(developmentalWindow.color).modalSecondaryColor +
                                '-contrast)',
                        }"
                        [innerHTML]="accordion.content | richContent | textReplacement"
                    ></div>
                </ion-accordion>
            }
        </ion-accordion-group>
    </article>
</ion-content>
