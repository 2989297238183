export enum ShowModalFrequency {
    ONCE = 'Once',
    DAILY = 'Daily',
    MONTHLY = 'Monthly',
}

export interface UserInteractionItem {
    id: string;
    timestamp: string; // ISO string e.g. 2020-11-15T12:00:00.000Z
    dontShowAgain: boolean | undefined;
}
