import { Component, inject, Input } from '@angular/core';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { HeaderComponent } from '@frontend/feature/header';
import { ModalService } from '@frontend/utility/modal';
import { IonButton, IonContent, IonFooter, IonHeader, IonToolbar } from '@ionic/angular/standalone';

@Component({
    selector: 'app-error-screen',
    templateUrl: './error-screen.component.html',
    styleUrl: './error-screen.component.scss',
    imports: [AnalyticsTrackClickDirective, IonContent, IonFooter, IonButton, HeaderComponent, IonHeader, IonToolbar],
})
export class ErrorScreenComponent {
    private readonly modalService = inject(ModalService);
    @Input({ required: true }) errorMessage!: string;
    @Input({ required: true }) buttonText!: string;

    closeModal(): void {
        void this.modalService.dismiss({
            dismissed: true,
        });
    }

    buttonClick(): void {
        if (this.buttonText === 'Contact us') {
            window.location.href = 'mailto:contact@zigzag.dog';
        } else {
            this.closeModal();
        }
    }
}
