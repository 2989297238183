import { Component, computed, effect, ElementRef, input, model, output, viewChildren } from '@angular/core';
import { ChatTileContent, ChatTileTopic } from '@frontend/data-access/contentful';
import { SliderComponent } from '@frontend/ui/slider';
import { SuggestedQuestionsPromptClicked } from './suggested-ai-chat-questions.model';
import { OptionSelectorSquareComponent } from '@frontend/ui/option-selector';

@Component({
    selector: 'app-suggested-ai-chat-questions',
    templateUrl: './suggested-ai-chat-questions.component.html',
    imports: [SliderComponent, OptionSelectorSquareComponent],
})
export class SuggestedAiChatQuestionsComponent {
    public suggestedQuestionsCards = input.required<ChatTileTopic[]>();

    public selectedOption = model<ChatTileContent | undefined>();
    public selectedCard = model<ChatTileTopic | undefined>();

    public promptClicked = output<SuggestedQuestionsPromptClicked>();

    private questionCards = viewChildren(OptionSelectorSquareComponent);
    private questionsCardElementRefs = viewChildren('questionCards', { read: ElementRef });

    private selectedCardComponent = computed(() => {
        return this.questionCards().find((card) => card.title === this.selectedCard()?.title);
    });
    private selectedCardElementRef = computed(() => {
        const indexOfSelectedCard = this.suggestedQuestionsCards().findIndex(
            (card) => card.id === this.selectedCard()?.id,
        );

        return this.questionsCardElementRefs()[indexOfSelectedCard];
    });

    constructor() {
        // Scroll to the selected card when it changes
        effect(() => {
            if (this.selectedCardElementRef()) {
                setTimeout(() => {
                    this.selectedCardElementRef()!.nativeElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'nearest',
                        inline: 'center',
                    });
                }, 0);
            }
        });
    }

    public selectedOptionChanged(clickedCard: ChatTileTopic, option: ChatTileContent | undefined): void {
        if (!this.selectedCard()) {
            this.selectedCard.set(clickedCard);
        }

        // The user has clicked a new card - we should clear selection on the old card
        if (this.selectedCard()?.id !== clickedCard.id) {
            this.selectedCardComponent()?.clearSelection();
            this.selectedCard.set(clickedCard);
        }

        this.selectedOption.set(option);

        this.promptClicked.emit({
            card: clickedCard.id,
            optionId: option?.id,
            option: option === undefined ? null : option.shortQuestion,
            message: option === undefined ? null : option.longQuestion,
        });
    }

    public clearSelection(): void {
        this.selectedCardComponent()?.clearSelection();

        this.selectedCard.set(undefined);
        this.selectedOption.set(undefined);
    }
}
