<h3 class="no-margin">{{ ourPickString() }}s</h3>

<ui-slider [gap]="20" [lastItemPadding]="20">
    @for (tile of productTiles(); track tile.title) {
        <ui-slider-item>
            <app-nourish-product-tile
                [title]="tile.title"
                [chip]="tile.chip"
                [image]="tile.image"
                [colour]="tile.colour"
                (click)="onClickProductTile(tile.id)"
            />
        </ui-slider-item>
    }
</ui-slider>
