import { Component, input } from '@angular/core';
import { IonChip } from '@ionic/angular/standalone';

@Component({
    selector: 'ui-author-chip',
    templateUrl: './author-chip.component.html',
    styleUrl: './author-chip.component.scss',
    imports: [IonChip],
})
export class AuthorChipComponent {
    public author = input.required<string>();
    public authorImageUrl = input.required<string>();
}
