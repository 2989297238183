import { DomainPostTodayProgressDto } from '@shared/domain-api-interface';
import { InternalPatchTodayCourseProgressDto } from '@shared/internal-api-interface';
import { createAction, props } from '@ngrx/store';
import { TodayCourseProgress } from '../models/today-course-progress.model';

export const getTodayCourseProgress = createAction('[Progress] Get Today Course Progress');

export const getTodayCourseProgressSuccess = createAction(
    '[Progress] Get Today Course Progress Success',
    props<{
        todayProgress: TodayCourseProgress[];
    }>(),
);

export const getTodayCourseProgressFailure = createAction(
    '[Progress] Get Today Course Progress Failure',
    props<{ error: Error }>(),
);

export const createTodayCourseProgress = createAction(
    '[Progress] Create Today Course Progress',
    props<{ dto: DomainPostTodayProgressDto }>(),
);

export const createTodayCourseProgressSuccess = createAction(
    '[Progress] Create Today Course Progress Success',
    props<{ todayProgress: TodayCourseProgress[] }>(),
);

export const createTodayCourseProgressFailure = createAction(
    '[Progress] Create Today Course Progress Failure',
    props<{ error: Error }>(),
);

export const updateTodayCourseProgress = createAction(
    '[Progress] Update Today Course Progress',
    props<{ id: string; dto: InternalPatchTodayCourseProgressDto }>(),
);

export const updateTodayCourseProgressSuccess = createAction(
    '[Progress] Update Today Course Progress Success',
    props<{ todayProgress: TodayCourseProgress[] }>(),
);

export const updateTodayCourseProgressFailure = createAction(
    '[Progress] Update Today Course Progress Failure',
    props<{ error: Error }>(),
);
