import { Component, inject, input } from '@angular/core';
import { LoadingState } from '@frontend/data-access/shared-models';
import { householdFeature } from '@frontend/data-access/user/household';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { IonButton } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { nourishFindFoodButtonClicked } from '../store/nourish.actions';
import { NourishFoodFinderOriginId } from '../constants/nourish.constants';

@Component({
    selector: 'app-nourish-pet-food-finder',
    templateUrl: './nourish-pet-food-finder.component.html',
    styleUrl: './nourish-pet-food-finder.component.scss',
    imports: [IonButton, AnalyticsTrackClickDirective],
})
export class NourishPetFoodFinderComponent {
    private readonly store = inject(Store);

    protected readonly loadingState = LoadingState;
    protected readonly Color = Color;

    public dogName = this.store.selectSignal(householdFeature.selectDogName);

    public shouldShowHeading = input.required<boolean>();
    public origin = input.required<NourishFoodFinderOriginId>();

    public onFindFoodClicked(): void {
        this.store.dispatch(nourishFindFoodButtonClicked());
    }
}
