import { Component, computed, inject } from '@angular/core';
import { householdFeature, selectIsUsUser } from '@frontend/data-access/user/household';
import { Platform, ViewWillEnter, ViewWillLeave } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { Subscription } from 'rxjs';
import { NourishModalId } from '../constants/nourish.constants';
import { closeNourishModal } from '../store/nourish.actions';
import {
    createUserFeedbackContent,
    FeedbackContentTypeEnum,
    FeedbackContentTypeIdEnum,
    UserFeedbackContent,
    userFeedbackContentFeature,
} from '@frontend/data-access/feedback';
import { nourishFeature } from '../store/nourish.reducer';
import { defaultFeedbackOptions } from '@frontend/ui/generic-feedback';

@Component({
    selector: 'app-nourish-modal-base',
    template: '',
})
export class NourishModalBaseComponent implements ViewWillEnter, ViewWillLeave {
    protected readonly store = inject(Store);
    protected readonly Color = Color;
    protected readonly NourishModalId = NourishModalId;
    protected readonly DefaultFeedbackOptions = defaultFeedbackOptions;
    private readonly platform = inject(Platform);
    private backButtonSub: Subscription = new Subscription();

    public isUsUser = this.store.selectSignal(selectIsUsUser);
    public userId = this.store.selectSignal(householdFeature.selectCurrentUserId);
    public userFeedbackList = this.store.selectSignal(userFeedbackContentFeature.selectFeedback);
    public currentInsightsModalId = this.store.selectSignal(nourishFeature.selectCurrentInsightsModalId);

    public diarrhoeaText = computed(() => {
        return this.isUsUser() ? 'diarrhea' : 'diarrhoea';
    });

    public userFeedback = computed(() => {
        return this.userFeedbackList()
            ?.filter(
                (feedback) =>
                    feedback.contentfulId === this.currentInsightsModalId() && feedback.userId === this.userId(),
            )
            ?.sort((a, b) => +b.timestamp! - +a.timestamp!)
            ?.shift();
    });

    public ionViewWillEnter(): void {
        this.backButtonSub = this.platform.backButton.subscribeWithPriority(100, () => {
            return;
        });
    }

    public ionViewWillLeave(): void {
        this.backButtonSub.unsubscribe();
    }

    public onCloseClick(id?: NourishModalId): void {
        this.store.dispatch(closeNourishModal({ id }));
    }

    public onFeedbackSubmitted(feedback: UserFeedbackContent): void {
        this.store.dispatch(
            createUserFeedbackContent({
                feedback: {
                    contentTypeId: FeedbackContentTypeIdEnum.INSIGHTS,
                    contentfulId: feedback.contentfulId,
                    thumbsDown: feedback.thumbsDown,
                    thumbsUp: feedback.thumbsDown === null ? undefined : !feedback.thumbsDown,
                    comment: feedback.comment,
                },
                feedbackType: FeedbackContentTypeEnum.INSIGHTS,
            }),
        );
    }
}
