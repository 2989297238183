import { NavigationOptions } from '@ionic/angular/common/providers/nav-controller';
import { createAction, props } from '@ngrx/store';

export const routeTo = createAction(
    '[Router] Route to',
    props<{ dtos: string | string[]; extras?: NavigationOptions; isAnimated?: boolean }>(),
);

export const routeToPrevious = createAction(
    '[Router] Route to Previous',
    props<{ dtos?: string | string[]; extras?: NavigationOptions; isAnimated?: boolean }>(),
);

export const forceAppRefresh = createAction('[Router] Force App Refresh');

export const openPrivacy = createAction('[Router] Open Privacy', props<{ countryCode: string }>());

export const openTerms = createAction('[Router] Open Terms', props<{ countryCode: string }>());

export const openCookieNotice = createAction('[Router] Open Cookie Notice', props<{ countryCode: string }>());

export const openUrl = createAction('[Router] Open Url In Web View', props<{ url: string }>());

export const openTailsUrl = createAction('[Router] Open Tails', props<{ url: string }>());
