<ion-item class="card-item" button="true" lines="none" [detail]="!content.hideRightArrow">
    <div class="card-item__row">
        <div class="card-item__image-container">
            <img
                class="card-item__img"
                [class.card-item__image--rounded]="content.rounded"
                [src]="content.imageUrl"
                slot="start"
                data-test="card-item-image"
            />
        </div>
        <section class="card-item__text">
            <div class="card-item__title-container">
                <h3>{{ content.title }}</h3>
            </div>

            <div class="card-item__subtitle">
                <p class="s">
                    {{ content.subtitle }}
                </p>
            </div>
        </section>
    </div>
</ion-item>
