<ion-content class="when-and-how-to-switch-food" [color]="Color.Lola">
    <button
        class="when-and-how-to-switch-food__close-button xl"
        appTrackClick
        identifier="nourish-when-and-how-to-switch-food-modal-close-btn"
        data-test="nourish-when-and-how-to-switch-food-modal-close-btn"
        (click)="onCloseClick()"
    >
        <i class="fa-light fa-xmark"></i>
    </button>
    <article class="when-and-how-to-switch-food__article page-padding-sides page-padding-bottom ignore-global-color">
        <h2 class="no-margin-bottom">When and how to switch to adult food?</h2>

        <p class="no-margin">Transitioning your pup to adult food is a key milestone. Here’s how to do it right:</p>

        <h3 class="when-and-how-to-switch-food__subheading no-margin">When to switch</h3>
        <section class="when-and-how-to-switch-food__highlighted-text" [ngClass]="Color.Charly">
            <p class="no-margin">
                <b>Small/Medium Breeds</b>
            </p>
            <p class="no-margin">Around 12 months old when they reach adult size</p>
        </section>
        <section class="when-and-how-to-switch-food__highlighted-text" [ngClass]="Color.Charly">
            <p class="no-margin">
                <b>Large/Giant Breeds</b>
            </p>
            <p class="no-margin">Between 18-24 months as their growth phase is longer</p>
        </section>

        <img
            class="when-and-how-to-switch-food__image"
            src="/assets/images/oliver/oliver-drooling-food.svg"
            alt="Oliver drools next to a bag of dog food"
        />

        <h3 class="when-and-how-to-switch-food__subheading no-margin">How to switch</h3>

        <ol class="when-and-how-to-switch-food__list no-margin-top" [ngClass]="Color.AppOutline">
            <li class="when-and-how-to-switch-food__list-item">
                <h3 class="no-margin">
                    <span class="when-and-how-to-switch-food__list-number">①</span> Start gradually
                </h3>
                <p class="no-margin">
                    Over 7-10 days, mix 25% adult food with 75% current food and increase the new food daily
                </p>
            </li>

            <hr class="when-and-how-to-switch-food__list-break" />

            <li class="when-and-how-to-switch-food__list-item">
                <h3 class="no-margin">
                    <span class="when-and-how-to-switch-food__list-number">②</span> Watch for reactions
                </h3>
                <p class="no-margin">Monitor stool consistency and {{ behaviourText() }} for signs of discomfort</p>
            </li>

            <hr class="when-and-how-to-switch-food__list-break" />

            <li class="when-and-how-to-switch-food__list-item">
                <h3 class="no-margin"><span class="when-and-how-to-switch-food__list-number">③</span> Vet guidance</h3>
                <p class="no-margin">
                    Consult your vet for {{ personalisedText() }} recommendations based on your dog's breed, size, and
                    activity level
                </p>
            </li>
        </ol>

        @if (!isUsUser()) {
            <app-nourish-product-tiles [productTiles]="productTiles" [correlationId]="'modal'" />
        } @else {
            <app-nourish-pet-food-finder
                [shouldShowHeading]="true"
                [origin]="NourishFoodFinderOriginId.SWITCH_FOOD_MODAL"
            />
        }

        <br />

        <ui-generic-feedback
            [autoSubmitOnChange]="true"
            [contentfulId]="currentInsightsModalId() ?? ''"
            [existingRating]="userFeedback()"
            [feedbackOptions]="DefaultFeedbackOptions"
            (feedbackSubmitted)="onFeedbackSubmitted($event)"
        />
    </article>
</ion-content>
