import { SingleSelectOption } from '@frontend/ui/single-select';

export const HOW_DID_YOU_HEAR_OPTIONS: SingleSelectOption[] = [
    {
        title: 'Social media',
        value: 'social-media',
    },
    {
        title: 'Friends/family',
        value: 'friends-or-family',
    },
    {
        title: 'Blog/article',
        value: 'blog-or-article',
    },
    {
        title: 'Web search',
        value: 'web-search',
    },
    {
        title: 'App store',
        value: 'app-store',
    },
    {
        title: 'Influencer',
        value: 'influencer',
    },
    {
        title: 'Email',
        value: 'email',
    },
    {
        title: 'Other brand',
        value: 'other-brand',
    },
    {
        title: 'Breeder',
        value: 'breeder',
    },
    {
        title: 'Trainer',
        value: 'trainer',
    },
    {
        title: 'Other',
        value: 'other',
    },
];
