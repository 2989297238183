import { Pipe, PipeTransform } from '@angular/core';
import { mapDateOfBirthToAgeString } from '@frontend/utility/angular';

@Pipe({
    name: 'age',
})
export class AgePipe implements PipeTransform {
    transform(
        dateOfBirth: string | Date | undefined,
        dateOfReference?: Date | undefined,
        shouldPluralise = true,
    ): string {
        return mapDateOfBirthToAgeString(dateOfBirth, dateOfReference, shouldPluralise);
    }
}
