import { platformFeature } from '@frontend/data-access/platform';
import { LoadingState } from '@frontend/data-access/shared-models';
import { authenticationFeature, mapAuthenticationError } from '@frontend/data-access/user/authentication';
import { createSelector } from '@ngrx/store';
import { selectIsInputOpenOnAndroid } from '@frontend/data-access/keyboard';
import { householdFeature, mapFirebaseError } from '@frontend/data-access/user/household';
import { geoLocationFeature } from '@frontend/data-access/geo-location';

export const selectCreateAccountPageVm = createSelector(
    householdFeature.selectCreateHouseholdLoadingState,
    householdFeature.selectCreateHouseholdError,
    authenticationFeature.selectAuthenticationState,
    geoLocationFeature.selectCountryCode,
    selectIsInputOpenOnAndroid,
    platformFeature.selectPlatformIsIos,
    (
        createHouseholdLoadingState,
        createHouseholdError,
        authenticationState,
        countryCode,
        isInputFocusedOnAndroid,
        isIos,
    ) => {
        // signUpError means the email already exists

        return {
            error:
                mapFirebaseError(createHouseholdError) || mapAuthenticationError(authenticationState.signUpError?.code),
            isLoading:
                createHouseholdLoadingState === LoadingState.LOADING ||
                authenticationState.signUpLoadingState === LoadingState.LOADING,
            countryCode: countryCode ?? '',
            isInputFocusedOnAndroid,
            isIos,
        };
    },
);
