import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter',
    pure: false,
})
export class FilterPipe implements PipeTransform {
    transform<T>(items: T[], filter: (item: T) => boolean): T[] {
        if (!items || !filter) {
            return items;
        }
        return items.filter(filter);
    }
}
