import { IsEmail, IsOptional, IsString } from 'class-validator';

export class InternalPostUserDto {
    @IsOptional()
    @IsString()
    id?: string;

    @IsString()
    accountId!: string;

    @IsEmail()
    mail!: string;

    @IsString()
    countryCode!: string;

    @IsOptional()
    @IsString()
    ownerName?: string;
}
