import { NgIfContext, NgTemplateOutlet } from '@angular/common';
import {
    AfterContentInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { IonSearchbar } from '@ionic/angular/standalone';
import { GenericChipComponent } from '@frontend/ui/generic-chip';

export interface ResultTemplateContext {
    searchValue: string;
}

@Component({
    selector: 'ui-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrl: './search-bar.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgTemplateOutlet, IonSearchbar, GenericChipComponent],
})
export class SearchBarComponent implements AfterContentInit {
    public hasResults = input.required<boolean>();
    public resultListTemplate = input.required<TemplateRef<ResultTemplateContext>>();
    public noResultTemplate = input.required<TemplateRef<ResultTemplateContext>>();
    public instructionTemplate = input.required<TemplateRef<NgIfContext<string>>>();

    public showCancelButton = input<string>('never');
    public placeholder = input<string>('Search');
    public initialFocus = input<boolean>(false);
    public isLibraryPage = input<boolean>(false);
    public isBasicPlan = input<boolean>(false);

    @Output() public searchString = new EventEmitter<string>();
    @Output() public focusChange = new EventEmitter<boolean>();

    @ViewChild('searchBar', { static: false }) public searchBar: IonSearchbar | undefined;

    public isFocused = false;

    public ngAfterContentInit(): void {
        if (this.initialFocus()) {
            setTimeout((): void => void this.searchBar?.setFocus(), 700);
        }
    }

    public searchItems(value: string | null | undefined): void {
        if (value !== null) {
            this.searchString.emit(value);
        }
    }

    public focus(): void {
        this.focusChange.emit((this.isFocused = true));
    }

    public blur(): void {
        this.focusChange.emit((this.isFocused = false));
    }
}
