import { createAction, props } from '@ngrx/store';
import { USER_INTERACTIONS_ACTION_SOURCE } from './user-interactions.constants';

export const storeUserInteraction = createAction(
    `${USER_INTERACTIONS_ACTION_SOURCE} Store`,
    props<{
        id: string;
        accessGranted?: boolean;
        dontShowAgain?: boolean;
    }>(),
);

export const storeUserInteractionWithTimestamp = createAction(
    `${USER_INTERACTIONS_ACTION_SOURCE} Store with Timestamp`,
    props<{
        id: string;
        timestamp: string;
        dontShowAgain?: boolean;
    }>(),
);
