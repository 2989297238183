/**
 * Color enum for the app
 *
 * For more information on the colors, please refer to the color.scss and variables.scss files in the frontend/styles folder
 */
export enum Color {
    /** Red with contrast white */
    Max = 'max',
    /** Dark blue with contrast white */
    Harley = 'harley',
    /** Dark green with contrast white */
    Alvin = 'alvin',
    /** Dark orange/mustard with contrast white */
    Buddy = 'buddy',
    /** Salmon pink with contrast black */
    Luna = 'luna',
    /** Light blue with contrast black */
    Lola = 'lola',
    /** Light green with contrast black */
    Maggie = 'maggie',
    /** Tan / sand with contrast black */
    Bailey = 'bailey',
    /** Peach with contrast black */
    Bella = 'bella',
    /** Blue with contrast white */
    Charly = 'charly',
    /** Green with contrast white */
    Sophie = 'sophie',
    /** Yellow with contrast black */
    Oliver = 'oliver',
    /** Grey with contrast black */
    Disabled = 'disabled-color',
    /** Off-white with contrast black */
    AppBackground = 'app-background',
    /** Ivory with contrast black */
    AppOutline = 'app-outline',
    /** Black with contrast white */
    Jack = 'jack',
    /** White with contrast black */
    Snowy = 'snowy',
    /** Lighter black with contrast white */
    SemiJack = 'semi-jack',
    /** Dark grey with contrast white */
    Paragraph = 'paragraph-color',
    /** Lighter grey with contrast black */
    DisabledText = 'disabled-text-color',
    /** Light cyan with contrast black */
    Barney = 'barney',
}
