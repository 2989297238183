@if (this.isStreakCardOpen) {
    <section
        data-test="courses-page-streak-card"
        [class]="'courses-page-streak-card ' + selectedCourse()!.color"
        (click)="expandStreakCard()"
    >
        <section class="courses-page-streak-card__container">
            <div>
                <h3 class="courses-page-streak-card__title">Day Streak</h3>
                <label class="courses-page-streak-card__subtitle">Complete 3 lessons a day to earn a streak</label>
            </div>
            <div class="courses-page-streak-card__paw-container">
                <i class="courses-page-streak-card__icon fa-solid fa-paw fa-2xl"></i>
                <h3 class="courses-page-streak-card__text">{{ courseStreak()?.length ?? 0 }}</h3>
            </div>
        </section>
        <app-progress-streak [shouldShowDate]="true" />
    </section>
} @else {
    <button
        data-test="courses-page-streak-btn"
        [class]="'courses-page-streak-button as-div ' + selectedCourse()!.color"
        (click)="expandStreakCard()"
    >
        <i class="courses-page-streak-button__icon fa-solid fa-paw fa-2xl"></i>
        <h3 class="courses-page-streak-button__text">{{ courseStreak()?.length ?? 0 }}</h3>
    </button>
    <button
        data-test="courses-page-today-lessons-btn"
        [class]="'courses-page-today-lessons-button as-div ' + selectedCourse()?.color"
        (click)="todayLessonButtonClicked.emit()"
    >
        <span>Today's lessons</span>
        <span
            class="courses-page-today-lessons-button__steps-completed"
            [ngStyle]="{
                color: 'var(--ion-color-' + selectedCourse()?.color + ')',
            }"
        >
            @if (selectedCourse()?.numberOfUniqueStepsCompletedToday < 3) {
                {{ selectedCourse()?.numberOfUniqueStepsCompletedToday }}/3
            } @else {
                <div>
                    <i class="fa-regular fa-check"></i>
                </div>
            }
        </span>
    </button>
}
