import { NgModule } from '@angular/core';
import { ZigzagFrontendDataAccessTimeModule } from '@frontend/data-access/time';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { UserInteractionsEffects } from './store/user-interactions.effects';
import { userInteractionFeature } from './store/user-interactions.reducer';

@NgModule({
    imports: [ZigzagFrontendDataAccessTimeModule],
    providers: [provideState(userInteractionFeature), provideEffects([UserInteractionsEffects])],
})
export class ZigzagFrontendFeatureUserInteractionsModule {}
