import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Network } from '@capacitor/network';
import { openPrivacy, openTerms, routeTo } from '@frontend/data-access/router';
import { loginWithApple, loginWithGoogle } from '@frontend/data-access/user/authentication';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { ErrorScreenComponent } from '@frontend/feature/error-screen';
import { ModalService } from '@frontend/utility/modal';
import { IonButton, IonContent, IonFooter, IonText } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { getUserIdForFeatureFlags, signInClicked } from './landing.page.actions';
import { selectLandingPageVm } from './landing.page.selectors';

// TODO: NBSon - some members/methods are public (for the sake of testing), but not used in the template, consider making them private?

@Component({
    selector: 'app-authentication',
    templateUrl: './landing.page.html',
    styleUrls: ['./landing.page.scss', '../authentication.scss'],
    imports: [AnalyticsTrackClickDirective, IonButton, IonContent, IonFooter, IonText],
})
export class LandingPage implements OnInit, OnDestroy {
    private readonly store = inject(Store);
    private readonly modalService = inject(ModalService);

    public vm = this.store.selectSignal(selectLandingPageVm);

    public isConnectedToInternet = true;

    protected readonly Color = Color;

    public async ngOnInit(): Promise<void> {
        await Network.addListener('networkStatusChange', (networkStatus) => {
            this.isConnectedToInternet = networkStatus.connected;
        });

        this.store.dispatch(getUserIdForFeatureFlags());
    }

    public async ngOnDestroy(): Promise<void> {
        await Network.removeAllListeners();
    }

    public onSignInWithAppleClick(): void {
        if (this.isConnectedToInternet) {
            this.store.dispatch(loginWithApple());
        } else {
            this.openErrorModal();
        }
    }

    public onSignInWithGoogleClick(): void {
        if (this.isConnectedToInternet) {
            this.store.dispatch(loginWithGoogle());
        } else {
            this.openErrorModal();
        }
    }

    public onSignUpWithEmailClick(): void {
        if (this.isConnectedToInternet) {
            this.store.dispatch(
                routeTo({
                    dtos: ['auth', 'create-account'],
                    isAnimated: true,
                }),
            );
        } else {
            this.openErrorModal();
        }
    }

    public onSignInClick(): void {
        if (this.isConnectedToInternet) {
            this.store.dispatch(signInClicked());
        } else {
            this.openErrorModal();
        }
    }

    public onTermsClick(): void {
        this.store.dispatch(openTerms({ countryCode: this.vm().countryCode }));
    }

    public openPrivacyClick(): void {
        this.store.dispatch(openPrivacy({ countryCode: this.vm().countryCode }));
    }

    // TODO: NBSon - reevaluate if this method should be public, as it is only for testing
    public openErrorModal(): void {
        void this.modalService.showModal({
            component: ErrorScreenComponent,
            cssClass: ['modal', 'modal-fullscreen'],
            componentProps: {
                errorMessage:
                    'It looks like you aren’t connected to the internet. Please check your connection and try again!',
                buttonText: 'Try again',
            },
        });
    }
}
