import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { ElementRerenderDirective } from '@frontend/ui/directives';
import { IonCol, IonGrid, IonItem, IonRow } from '@ionic/angular/standalone';

@Component({
    selector: 'app-tiered-pricing-plan-comparison',
    templateUrl: './tiered-pricing-plan-comparison.component.html',
    styleUrl: './tiered-pricing-plan-comparison.component.scss',
    imports: [ElementRerenderDirective, IonCol, IonGrid, IonItem, IonRow, NgClass, TextReplacementPipe],
})
export class TieredPricingPlanComparisonComponent {
    public isPremium = input.required<boolean>();

    protected readonly featuresList = [
        { feature: 'Tailored foundation course', includedInBasic: true },
        { feature: 'Daily articles about [BREED]s', includedInBasic: true },
        { feature: 'Instant AI answers', includedInBasic: true },
        { feature: '24/7 training coach', includedInBasic: false },
        { feature: 'Unlimited access to 250+ lessons', includedInBasic: false },
        { feature: 'Tricks course', includedInBasic: false },
        { feature: 'Adolescence course', includedInBasic: false },
        { feature: 'Separation anxiety course', includedInBasic: false },
    ];
}
