import { BaseInternalHouseholdDto } from '../../../dtos/user/household/household.dto';
import { InternalDogDtoMock } from '../dog/internal-dog.dto.mock';
import { InternalPublicUserDtoMock } from '../user/internal-public-user.dto.mock';

export class BaseInternalHouseholdDtoMock {
    private defaultValue: BaseInternalHouseholdDto = {
        id: 'uuid-1234',
        name: 'household-123',
        users: [new InternalPublicUserDtoMock().value],
        dogs: [new InternalDogDtoMock().value],
    };

    constructor(overrides?: Partial<BaseInternalHouseholdDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): BaseInternalHouseholdDto {
        return this.defaultValue;
    }
}
