<ion-content
    class="page-safe-margin"
    [fullscreen]="true"
    [scrollEvents]="true"
    [scrollY]="canScroll()"
    (ionScroll)="setScrollHeight($any($event))"
>
    <div class="courses-page-course-header page-padding-sides page-padding-top">
        <h1 class="page-title">My Journey</h1>
    </div>

    @if (selectedCourse()) {
    <div class="courses-page-main">
        <app-course-slider data-test="course-swipe" [courses]="courses()" />
        <div class="courses-page-main__no-access-section">
            @if (displayNoAccess()) {
            <ui-no-access
                title="Unlock additional courses with premium"
                subTitle="You're on the Basic plan now."
                buttonText="Upgrade to Premium"
                (openPaymentModal)="openPaymentModal()"
            />
            }

            <div class="courses-page-main__course-path page-padding-sides">
                <app-course-path />
            </div>

            @if (!isStartCourseAb() && selectedCourse()!.status !== CourseStatus.COMPLETED) {
            <div
                *uiElementRerender="selectedCourse()!.color"
                class="courses-page-main__scroll-down-container"
                data-test="scroll-down-button"
                [ngClass]="{'courses-page-main__scroll-down-container--point-up': (heightOfFirstInactiveStep() ?? 0) < scrollHeight}"
                (click)="scrollToFirstInactiveStep()"
            >
                <i
                    class="fa-solid fa-circle-chevron-down fa-2xl"
                    [style.color]="'var(--ion-color-' + selectedCourse()!.color + ')'"
                ></i>
            </div>
            }

            <div class="page-padding-sides">
                @if (nextCourse()) {
                <ui-generic-card-large
                    callToActionText="View course"
                    label="Next Up"
                    data-test="next-up-tile"
                    [title]="nextCourse()!.title"
                    [description]="nextCourse()!.introduction"
                    [imageUrl]="nextCourse()!.imageUrl"
                    [imageAltText]="nextCourse()!.subTitle || 'An image of a dog excited about the next course'"
                    [backgroundColor]="nextCourse()!.color"
                    [backgroundUrl]="getCourseCardDoodle()"
                    (callToActionClicked)="openCourseInfoModal()"
                />
                }
            </div>

            @if (!displayNoAccess()) {
            <div class="courses-page-main__paw-container page-padding-sides">
                @if (showStartCourseButton()) {
                <div class="courses-page-main__start-container">
                    <button
                        data-test="course-page-start-course-btn"
                        [class]="'courses-page-main__start-course-button ' + selectedCourse()!.color"
                        (click)="setCourseAsInProgress()"
                    >
                        Start course
                    </button>
                </div>
                } @else if (isStartCourseAb() && selectedCourse()!.status === CourseStatus.IN_PROGRESS) {

                <app-course-streak
                    data-test="course-page-main-course-streak"
                    (todayLessonButtonClicked)="scrollToFirstInactiveStep()"
                />

                }
            </div>
            }
        </div>
    </div>
    }
</ion-content>
