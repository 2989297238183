import { NgClass, NgOptimizedImage } from '@angular/common';
import { Component, input } from '@angular/core';
import { TextReplacementPipe } from '@frontend/data-access/text-replacement';
import { StepType } from '@shared/content-api-interface';
import { Color } from '@shared/utils/typescript';
import { StepStatus } from '../../store/step/step.model';
import { LessonLocation } from './models/lesson-location.model';
import { StepCardSlimModel } from './models/step-card-slim.model';

@Component({
    selector: 'app-step-card-slim',
    templateUrl: './step-card-slim.component.html',
    styleUrl: './step-card-slim.component.scss',
    imports: [NgClass, NgOptimizedImage, TextReplacementPipe],
})
export class StepCardSlimComponent {
    public content = input.required<StepCardSlimModel>();

    public StepStatus = StepStatus;
    public Color = Color;
    public StepType = StepType;
    public LessonLocation = LessonLocation;
}
