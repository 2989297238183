import { Directive, inject, Input, TemplateRef, ViewContainerRef } from '@angular/core';

/**
 * Forces rerender of DOM elements to cover shortcomings with Angular change detection
 *
 * @example
 * ```html
 * <div *uiElementRerender="inputToRerenderOn"></div>
 * ```
 */
@Directive({
    selector: '[uiElementRerender]',
})
export class ElementRerenderDirective {
    private readonly templateRef = inject(TemplateRef);
    private readonly viewContainer = inject(ViewContainerRef);

    @Input()
    public set uiElementRerender(_val: unknown) {
        this.viewContainer.clear();
        this.viewContainer.createEmbeddedView(this.templateRef);
    }
}
