import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    async setLocalStorage(key: string, value: unknown): Promise<void> {
        await Preferences.set({ key, value: JSON.stringify(value) });
    }

    async getLocalStorage<TValue = unknown>(key: string): Promise<TValue | undefined> {
        const ret = await Preferences.get({ key });
        return ret && ret.value !== null ? JSON.parse(ret.value) : undefined;
    }

    async deleteLocalStorage(key: string): Promise<void> {
        await Preferences.remove({ key });
    }

    async clearLocalStorage(): Promise<void> {
        await Preferences.clear();
    }
}
