<div class="nourish-product-header page-padding-sides" [ngStyle]="computedContainerStyles()">
    <app-header
        data-test="nourish-product-header-app-header"
        [ngStyle]="computedAppHeaderStyles()"
        [leftButton]="leftButtonConfig"
        [rightButton]="false"
        (leftButtonPressed)="onBackClick()"
    />

    <img class="nourish-product-header__logo" [src]="logo().src" [alt]="logo().alt" />
</div>
