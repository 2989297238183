import { createAction, props } from '@ngrx/store';

export const setLocalStorage = createAction(
    '[LocalStorage] Set Local Storage',
    props<{ key: string; data: unknown }>(),
);
export const setLocalStorageSuccess = createAction('[LocalStorage] Set Local Storage Success');
export const setLocalStorageFailure = createAction(
    '[LocalStorage] Set Local Storage Failure',
    props<{ error: Error }>(),
);

export const getLocalStorage = createAction('[LocalStorage] Get Local Storage', props<{ key: string }>());
export const getLocalStorageSuccess = createAction(
    '[LocalStorage] Get Local Storage Success',
    props<{ key: string; data: unknown }>(),
);
export const getLocalStorageFailure = createAction(
    '[LocalStorage] Get Local Storage Failure',
    props<{ key: string; error: Error }>(),
);

export const removeLocalStorage = createAction('[LocalStorage] Remove Local Storage', props<{ key: string }>());
export const removeLocalStorageSuccess = createAction('[LocalStorage] Remove Local Storage Success');
export const removeLocalStorageFailure = createAction(
    '[LocalStorage] Remove Local Storage Failure',
    props<{ error: Error }>(),
);

export const removeAllLocalStorage = createAction('[LocalStorage] Remove All Local Storage');
export const removeAllLocalStorageSuccess = createAction('[LocalStorage] Remove All Local Storage Success');
export const removeAllLocalStorageFailure = createAction(
    '[LocalStorage] Remove All Local Storage Failure',
    props<{ error: Error }>(),
);
