import { inject, Injectable } from '@angular/core';
import {
    LocalNotificationDescriptor,
    LocalNotifications,
    LocalNotificationSchema,
    PendingResult,
    ScheduleResult,
} from '@capacitor/local-notifications';
import { Platform } from '@ionic/angular/standalone';

@Injectable({
    providedIn: 'root',
})
export class LocalNotificationService {
    private readonly platform = inject(Platform);

    public async checkAllPermissions() {
        if (this.platform.is('ios') || this.platform.is('android')) {
            return await LocalNotifications.checkPermissions();
        }

        throw new Error('method_not_available_on_web');
    }

    public async requestPermissions() {
        if (this.platform.is('ios') || this.platform.is('android')) {
            return await LocalNotifications.requestPermissions();
        }
        throw new Error('method_not_available_on_web');
    }

    public async scheduleNotification(notifications: LocalNotificationSchema[]): Promise<ScheduleResult> {
        if (this.platform.is('ios') || this.platform.is('android')) {
            return await LocalNotifications.schedule({
                notifications,
            });
        }

        throw new Error('method_not_available_on_web');
    }

    public async getLocalNotifications(): Promise<PendingResult> {
        if (this.platform.is('ios') || this.platform.is('android')) {
            return await LocalNotifications.getPending();
        }
        throw new Error('method_not_available_on_web');
    }

    public async clearLocalNotification(notifications: LocalNotificationDescriptor[]): Promise<void> {
        if (this.platform.is('ios') || this.platform.is('android')) {
            return await LocalNotifications.cancel({ notifications });
        }
        throw new Error('method_not_available_on_web');
    }
}
