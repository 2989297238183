import { InternalHouseholdUserDto } from '../../../dtos/user/internal-user.dto';

export class InternalHouseholdUserDtoMock {
    private defaultValue: InternalHouseholdUserDto = {
        // Set after creation
        id: 'uuid-1234',
        mail: 'revenue-cat-user@e2e.zigzag.dog',
        accountId: 'account-123',
        countryCode: 'GB',
        region: 'United Kingdom',
        city: 'London',
        // Set during onboarding
        dateOfOnboarding: new Date('2021-03-24T00:00:00.000Z').getTime(),
        name: 'Fred',
        userToken: 'token-38I',
        purinaMarketingAccepted: false,
        // Possibly set before the user goes into the app
        partners: [],
    };

    private empty: InternalHouseholdUserDto = {
        id: '',
        mail: '',
        accountId: undefined,
        countryCode: undefined,
        region: undefined,
        city: undefined,
        dateOfOnboarding: undefined,
        name: undefined,
        userToken: undefined,
        purinaMarketingAccepted: undefined,
        partners: [],
    };

    constructor(overrides?: Partial<InternalHouseholdUserDto>) {
        this.defaultValue = { ...this.defaultValue, ...overrides };

        Object.assign(this.empty, overrides);
    }

    public get value(): InternalHouseholdUserDto {
        return this.defaultValue;
    }

    public get emptyValue(): InternalHouseholdUserDto {
        return this.empty;
    }
}
