import { Component, forwardRef, inject, input, viewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { DatetimeCustomEvent } from '@ionic/angular';
import { IonDatetime, IonDatetimeButton, IonModal, Platform } from '@ionic/angular/standalone';
import { Subscription } from 'rxjs';

@Component({
    selector: 'ui-datetime',
    templateUrl: './datetime.component.html',
    styleUrl: './datetime.component.scss',
    imports: [ReactiveFormsModule, IonDatetime, IonDatetimeButton, IonModal],
    providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DatetimeComponent), multi: true }],
})
export class DatetimeComponent implements ControlValueAccessor {
    public id = input.required<string>();
    public presentation = input.required<string | undefined>();
    public currentDate = input.required<Date>();
    public isLoading = input.required<boolean>();
    public defaultValue = input.required<string | null>();
    public minDate = input.required<string | null>();
    public maxDate = input<string>();
    public backButtonStrategy = input<'close-modal' | 'keep-modal-open'>('keep-modal-open');

    public modal = viewChild<IonModal>('datetimeModal');

    private readonly platform = inject(Platform);

    public value = '';

    private backButtonSub = new Subscription();

    public onTouched = () => {
        /* Do nothing */
    };

    public onChange: (value: string) => void = () => {
        /* Do nothing */
    };

    public writeValue(value: string): void {
        this.value = value;
    }

    public registerOnChange(onChange: (value: string) => void): void {
        this.onChange = onChange;
    }

    public registerOnTouched(onTouched: () => void): void {
        this.onTouched = onTouched;
    }

    public onModalDidPresent(): void {
        this.backButtonSub = this.platform.backButton.subscribeWithPriority(100, () => {
            if (this.backButtonStrategy() === 'close-modal') {
                this.modal()?.dismiss();
            }

            return;
        });
    }

    public onModalDidDismiss(): void {
        this.backButtonSub.unsubscribe();
    }

    public onDatetimeSelect(event: DatetimeCustomEvent): void {
        this.value = event.detail.value as string;

        this.onChange(this.value);
        this.onTouched();
    }
}
