<section class="arrival-modal">
    <button
        class="arrival-modal__close-button zz-button xl"
        appTrackClick
        identifier="arrival-modal-close-btn"
        data-test="arrival-modal-close-btn"
        (click)="closeModal(false)"
    >
        <i class="fa-light fa-xmark"></i>
    </button>
    <img
        class="arrival-modal__animation image-no-max-width"
        src="/assets/images/oliver/oliver-jumps-out-of-box.gif"
        alt="Oliver jumping out of a box in excitement with confetti"
    />

    <div class="arrival-modal__text">
        <h2>{{ 'Woohoo! [NAME_C] is home!' | textReplacement }}</h2>
        <p class="arrival-modal__subtext">
            {{ '[NAME_C] is finally home and now all the fun can begin!' | textReplacement }}
        </p>
    </div>
</section>

<ion-footer class="arrival-modal-footer">
    <button
        class="arrival-modal-footer__secondary-btn zz-button"
        appTrackClick
        identifier="arrival-modal-has-not-arrived-btn"
        data-test="arrival-modal-has-not-arrived-btn"
        (click)="closeModal(false)"
    >
        {{ '[NAME_C]' | textReplacement }} has not arrived
    </button>
    <ion-button
        appTrackClick
        identifier="arrival-modal-has-arrived-btn"
        color="max"
        expand="block"
        data-test="arrival-modal-has-arrived-btn"
        (click)="closeModal(true)"
        >Let's get started!
    </ion-button>
</ion-footer>
