import { Pipe, PipeTransform } from '@angular/core';
import { ZIGGY_AI_ID } from '@shared/constants';
import { StreamMessage } from 'stream-chat-angular';

/**
 * Returns a StreamChatMessage with the text replaced with HTML.
 *
 * Use in conjunction with the `streamChatMessageService.displayAs = 'html'`
 * @see https://getstream.io/chat/docs/sdk/angular/code-examples/custom-textarea/
 */
@Pipe({
    name: 'appStreamChatHtmlMessage',
})
export class StreamChatHtmlMessagePipe implements PipeTransform {
    transform(message: StreamMessage): StreamMessage {
        if (!message.user || message.user.id !== ZIGGY_AI_ID) {
            return message;
        }

        return {
            ...message,
            text: message.html?.replace(
                '…',
                `<span class="stream-chat-ellipsis"><span>.</span><span>.</span><span>.</span></span>`,
            ),
        };
    }
}
