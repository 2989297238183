import { createSelector } from '@ngrx/store';
import { RATING_QUALIFIER_MODAL_ID } from '../rating-qualifier-modal.constants';
import { ShowModalFrequency, userInteractionFeature } from '@frontend/data-access/user-interactions';

export const selectShouldLaunchRatingReviewModal = createSelector(
    userInteractionFeature.selectShouldDisplayInfoItem(RATING_QUALIFIER_MODAL_ID, ShowModalFrequency.MONTHLY),
    (shouldDisplayInfoItem) => {
        return shouldDisplayInfoItem;
    },
);
