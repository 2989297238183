import { createSelector } from '@ngrx/store';
import { configCatFeature } from './config-cat.reducer';
import { filterConfigCatStateFlag } from './config-cat.utils';

export const selectFreeContent = createSelector(configCatFeature.selectConfigCatFlags, (state): boolean =>
    filterConfigCatStateFlag('freeContent', state),
);

export const selectIsTestUser = createSelector(configCatFeature.selectConfigCatFlags, (state): boolean =>
    filterConfigCatStateFlag('isTestUser', state),
);

export const selectIsDevWithFreeContent = createSelector(
    selectFreeContent,
    selectIsTestUser,
    (freeContent, devUser): boolean => freeContent && devUser,
);

export const selectIsStartCourseAb = createSelector(configCatFeature.selectConfigCatFlags, (state) => {
    return filterConfigCatStateFlag('isStartCourseAb', state);
});

export const selectIsNutritionReferral = createSelector(configCatFeature.selectConfigCatFlags, (state) => {
    return filterConfigCatStateFlag('nutritionReferral', state);
});

export const selectIsPurinaOptInUkAb = createSelector(configCatFeature.selectConfigCatFlags, (state) => {
    return filterConfigCatStateFlag('purinaOptInUkAb', state);
});

export const selectIsZukesFreeBox = createSelector(configCatFeature.selectConfigCatFlags, (state) => {
    return filterConfigCatStateFlag('zukesFreeBox', state);
});

export const selectIsHowDidYouHearOnboardingPage = createSelector(configCatFeature.selectConfigCatFlags, (state) => {
    return filterConfigCatStateFlag('howDidYouHearOnboardingPage', state);
});
