import { AuthError } from '../store/authentication.reducer';

export class AuthErrorMock {
    private defaultValue: AuthError = {
        ...new Error('Auth error'),
        code: 'auth-error',
    };

    constructor(overrides?: Partial<AuthError>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): AuthError {
        return this.defaultValue;
    }
}
