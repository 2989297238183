import { RadioWithExplanationResult } from './radio-with-explanation.model';

export class RadioWithExplanationResultMock {
    private defaultValue: RadioWithExplanationResult = {
        complete: false,
        answer: {
            value: '',
            explanation: '',
        },
    };

    constructor(overrides?: Partial<RadioWithExplanationResult>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): RadioWithExplanationResult {
        return this.defaultValue;
    }

    public setIncomplete(): this {
        this.defaultValue = {
            complete: false,
            answer: {
                value: '',
                explanation: '',
            },
        };

        return this;
    }

    public setComplete(): this {
        this.defaultValue = {
            complete: true,
            answer: {
                value: 'other',
                explanation: 'because',
            },
        };

        return this;
    }
}
