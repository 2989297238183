import { DomainPatchRescheduledStepDto } from './domain-patch-rescheduled-step.dto';
import { IsArray, IsString, ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';

export class DomainPatchManyRescheduledStepDto {
    @IsArray()
    @ValidateNested({ each: true })
    @Type(() => DomainUpdateManyRescheduledStep)
    rescheduledSteps!: DomainUpdateManyRescheduledStep[];
}

//todo: LB move this into its own file
export class DomainUpdateManyRescheduledStep {
    @IsString()
    id!: string;

    @Type(() => DomainPatchRescheduledStepDto)
    updateRescheduledStepDto!: DomainPatchRescheduledStepDto;
}
