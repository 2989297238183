import { Gender } from '@shared/user-domain';
import { DomainUserDto } from '../../../dtos/user/user/domain-user.dto';

export class DomainUserDtoMock {
    private defaultValue: DomainUserDto = {
        termsAndPrivacyAccepted: false,
        isApproximateDateOfBirth: null,
        partners: [],
        purinaMarketingAccepted: null,
        id: 'uuid-1234',
        accountId: 'accountId-1234',
        mail: 'revenue-cat-user@e2e.zigzag.dog',
        name: 'Fred',
        breedId: 'Bergamasco-id',
        gender: Gender.MALE,
        ownerName: 'Test Tester',
        dateOfOnboarding: '2021-03-24T00:00:00.000Z',
        dateOfArrival: '2021-03-24T00:00:00.000Z',
        dateOfBirth: '2020-11-11T00:00:00.000Z',
        countryCode: 'GB',
        region: 'United Kingdom',
        city: 'London',
        hasArrived: true,
        firstDayAtHomeWhenOnboarding: false,
        notifications: true,
        isRescuePup: false,
        userToken: 'token-123',
    };

    private empty: DomainUserDto = {
        termsAndPrivacyAccepted: false,
        isApproximateDateOfBirth: null,
        partners: [],
        purinaMarketingAccepted: null,
        id: '',
        accountId: null,
        mail: '',
        name: null,
        breedId: null,
        gender: null,
        ownerName: null,
        dateOfOnboarding: null,
        dateOfArrival: null,
        dateOfBirth: null,
        countryCode: null,
        region: null,
        city: null,
        hasArrived: null,
        firstDayAtHomeWhenOnboarding: false,
        notifications: false,
        isRescuePup: null,
        userToken: 'token-123',
    };

    constructor(overrides?: Partial<DomainUserDto>) {
        this.defaultValue = { ...this.defaultValue, ...overrides };
        Object.assign(this.empty, overrides);
    }

    public get value(): DomainUserDto {
        return this.defaultValue;
    }

    public get emptyValue(): DomainUserDto {
        return this.empty;
    }
}
