import { shouldDisplayModal, ShowModalFrequency, UserInteractionItem } from '@frontend/data-access/user-interactions';
import { StepProgress } from '@frontend/data-access/user/progress';
import { isSameDay, isYesterday, subDays } from 'date-fns';
import { CourseWithSteps } from '../../../courses/models/courses.models';
import { MINIMUM_STEPS_REQUIRED_FOR_STREAK } from '../constants/progress-streak.constants';
import { ProgressStreakModelInfo } from '../models/progress-streak.model';

/**
 * Get the current streak for a course
 *
 * @param courseStepIds
 * @param completedStepProgress
 * @param includeYesterday - Whether to end the streak count early if the minimum steps are not completed today
 */
export const getStreak = (courseStepIds: string[], completedStepProgress: StepProgress[], includeYesterday = true) => {
    let currentDate = new Date();
    let stepsCompletedOnDay = 0;

    let streakStartDate = new Date();
    let streakLength = 0;

    do {
        // get completed step ids for the day we are checking
        const completedStepProgressIds = completedStepProgress
            .filter((stepProgress) => isSameDay(new Date(stepProgress.timestamp), currentDate))
            .map((stepProgress) => stepProgress.stepId);

        // see how often step ids of this day occur in the course step ids
        stepsCompletedOnDay = countUniqueOccurrencesOfProgressInCourse(courseStepIds, completedStepProgressIds);

        // add to streak, and check previous day
        if (stepsCompletedOnDay >= MINIMUM_STEPS_REQUIRED_FOR_STREAK) {
            streakLength++;
            streakStartDate = currentDate;
        }

        currentDate = subDays(currentDate, 1);
    } while (
        stepsCompletedOnDay >= MINIMUM_STEPS_REQUIRED_FOR_STREAK ||
        (includeYesterday && isYesterday(currentDate))
    );

    return {
        length: streakLength,
        startDate: streakStartDate,
    };
};

export function didStepCompletionCauseStreakUserHasNotSeen(
    completedStepId: string,
    courses: CourseWithSteps[],
    streaks: ProgressStreakModelInfo[],
    userInteractionItems: UserInteractionItem[],
    today: Date,
) {
    const courseWhereStepWasCompleted = courses.find((course) =>
        course.steps.find((step) => step.id === completedStepId),
    );

    if (!courseWhereStepWasCompleted) {
        return undefined;
    }

    const courseStreak = streaks.find((streak) => streak.id === courseWhereStepWasCompleted.id);

    if (!courseStreak) {
        return undefined;
    }

    const shouldDisplayStreakModal = shouldDisplayModal(
        ShowModalFrequency.DAILY,
        `progress-streak-modal-${courseStreak.id}`,
        userInteractionItems,
        today,
    );

    if (!shouldDisplayStreakModal) {
        return undefined;
    }
    return courseStreak;
}

export function countUniqueOccurrencesOfProgressInCourse(courseStepIds: string[], stepProgressIds: string[]) {
    const foundIds: string[] = [];

    stepProgressIds.forEach((stepProgressId) => {
        const stepIdOccursInCourse = courseStepIds.find((courseStepId) => courseStepId === stepProgressId);

        if (!!stepIdOccursInCourse && !foundIds.find((foundId) => foundId === stepProgressId)) {
            foundIds.push(stepProgressId);
        }
    });

    return foundIds.length;
}
