<app-header
    class="app-header--modal-small"
    data-test="nourish-product-feedback-modal-header"
    [leftButton]="false"
    [rightButton]="rightButtonConfig"
    (rightButtonPressed)="onCloseButtonClick()"
/>

<ion-content>
    <div
        class="nourish-product-feedback page-padding-sides page-padding-bottom"
        [class.nourish-product-feedback--negative]="rating === NourishProductFeedbackModalState.NEGATIVE"
    >
        <img
            class="nourish-product-feedback__image"
            [class.nourish-product-feedback__image--small]="rating === NourishProductFeedbackModalState.NEGATIVE"
            [src]="'/assets/images/bailey/bailey-holds-one-ear-up-listening.svg'"
            alt="Bailey holding one ear up listening"
        />

        <h2
            class="nourish-product-feedback__title heading-2 ion-text-center no-margin"
            data-test="nourish-product-feedback-modal-title"
        >
            @switch (rating) {
                @case (NourishProductFeedbackModalState.INITIAL) {
                    Did you find this recommendation helpful?
                }
                @case (NourishProductFeedbackModalState.POSITIVE) {
                    Thank you for your feedback!
                }
                @default {
                    Why did you choose this rating?
                }
            }
        </h2>

        @if (rating === NourishProductFeedbackModalState.INITIAL) {
            <p
                class="nourish-product-feedback__subtitle body-1 ion-text-center no-margin"
                data-test="nourish-product-feedback-modal-subtitle"
            >
                We would love to get your feedback
            </p>
        }

        <div class="nourish-product-feedback-buttons">
            <button
                class="nourish-product-feedback-buttons__thumbs-down as-div"
                [ngClass]="{
                    'nourish-product-feedback-buttons__thumbs-down--small nourish-product-feedback-buttons__thumbs-down--active':
                        rating === NourishProductFeedbackModalState.NEGATIVE,
                }"
                data-test="nourish-product-feedback-modal-thumbs-down-btn"
                [disabled]="rating === NourishProductFeedbackModalState.POSITIVE"
                (click)="onThumbsDownClick()"
            >
                <i class="fa-light fa-thumbs-down"></i>
            </button>

            <button
                class="nourish-product-feedback-buttons__thumbs-up as-div"
                [ngClass]="{
                    'nourish-product-feedback-buttons__thumbs-up--small':
                        rating === NourishProductFeedbackModalState.NEGATIVE,
                    'nourish-product-feedback-buttons__thumbs-up--active':
                        rating === NourishProductFeedbackModalState.POSITIVE,
                }"
                data-test="nourish-product-feedback-modal-thumbs-up-btn"
                [disabled]="rating === NourishProductFeedbackModalState.POSITIVE"
                (click)="onThumbsUpClick()"
            >
                <i class="fa-light fa-thumbs-up"></i>
            </button>
        </div>

        @if (rating === NourishProductFeedbackModalState.NEGATIVE) {
            <ui-radio-with-explanation
                data-test="nourish-product-feedback-modal-explanation-radio"
                heightStrategy="fixed"
                [buttons]="buttons"
                [color]="Color.Max"
                (changeEvent)="onExplanationChange($event)"
            />
        }

        @if (rating === NourishProductFeedbackModalState.NEGATIVE) {
            <ion-button
                class="nourish-product-feedback__submit-button no-margin-bottom"
                expand="block"
                data-test="nourish-product-feedback-modal-submit-btn"
                [disabled]="!radioResult?.complete"
                [color]="Color.Max"
                (click)="onNegativeFeedbackSubmit()"
            >
                Submit
            </ion-button>
        }
    </div>
</ion-content>
