<div
    class="radio-with-explanation"
    data-test="radio-with-explanation"
    [ngStyle]="{ height: (selected && heightStrategy() === 'auto' ? 130 : buttons.length * 60) + 'px' }"
>
    <div class="radio-with-explanation__radio-container">
        @for (button of buttons; track button.title) {
            @if (!selected?.explainMore || (selected?.explainMore && button.value === selected?.value)) {
                <div
                    class="radio-with-explanation__button-container"
                    [ngStyle]="{
                        top: selected?.explainMore && button.value === selected?.value ? 0 : $index * 60 + 'px',
                    }"
                >
                    <ion-button
                        class="radio-with-explanation__button"
                        [ngStyle]="{
                            '--background':
                                button.value === selected?.value
                                    ? 'var(--ion-color-' + color + ')'
                                    : 'var(--ion-color-snowy)',
                            '--border-color':
                                button.value === selected?.value
                                    ? 'var(--ion-color-' + color + ')'
                                    : 'var(--ion-color-paragraph-color)',
                            '--color':
                                button.value === selected?.value
                                    ? 'var(--ion-color-' + color + '-contrast)'
                                    : 'var(--ion-color-paragraph-color)',
                        }"
                        expand="block"
                        fill="solid"
                        [attr.data-test]="'radio-button-' + button.value"
                        (click)="clickButton(button)"
                    >
                        {{ button.title }}
                    </ion-button>
                </div>
            }
        }
    </div>

    @if (selected && selected.explainMore) {
        <div
            class="radio-with-explanation__input-container"
            [ngStyle]="{ top: (selected ? 70 : buttons.length * 60 + 20) + 'px' }"
            data-test="explanation-input-container"
        >
            <ion-item class="radio-with-explanation__ion-item">
                <ion-input
                    class="radio-with-explanation__input zz-input"
                    id="explanation-input"
                    type="text"
                    data-test="explanation-input"
                    [formControl]="explanationInput"
                    [placeholder]="selected?.explainMore?.placeholder"
                    [tabindex]="1"
                    (ionInput)="valueChange()"
                />
            </ion-item>
        </div>
    }
</div>
