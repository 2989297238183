import { NgStyle } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';
import { SeasonalTopic } from '@frontend/data-access/contentful';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { IonButton, IonCard } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { goToSeasonalTopicFromTile } from '../../data-access/today.page.actions';
import { setCourseBackgroundColor } from '@frontend/utility/angular';

@Component({
    selector: 'app-topic-tile',
    templateUrl: './topic-tile.component.html',
    styleUrl: './topic-tile.component.scss',
    imports: [NgStyle, IonButton, IonCard, AnalyticsTrackClickDirective],
})
export class TopicTileComponent {
    private readonly store = inject(Store);

    public seasonalTopic = input.required<SeasonalTopic>();

    public backgroundColor = input(Color.Harley);

    protected readonly Color = Color;

    public background = computed(() => {
        return {
            ...setCourseBackgroundColor(this.seasonalTopic().backgroundColour),
        };
    });

    public onGoToTopicClick(): void {
        this.store.dispatch(
            goToSeasonalTopicFromTile({
                topicId: this.seasonalTopic().topic,
            }),
        );
    }
}
