import { NgClass } from '@angular/common';
import { Component, inject } from '@angular/core';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { ModalResultEnum } from '@frontend/utility/modal';
import { IonButton } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { dismissLilysKitchenTreatModal } from '../../store/modal/modal.actions';
import { lilysKitchenTreatVoucherClicked } from '../store/lesson.actions';

@Component({
    selector: 'app-lilys-kitchen-treat-modal',
    templateUrl: './lilys-kitchen-treat-modal.component.html',
    styleUrl: './lilys-kitchen-treat-modal.component.scss',
    imports: [AnalyticsTrackClickDirective, IonButton, NgClass],
})
export class LilysKitchenTreatModalComponent {
    private readonly store = inject(Store);
    protected readonly Color = Color;

    public onClaimNowClick(): void {
        this.store.dispatch(
            lilysKitchenTreatVoucherClicked({
                url: 'https://www.lilyskitchen.co.uk/for-dogs/dog-training-treats',
            }),
        );
    }

    public onMaybeLaterClick(): void {
        this.store.dispatch(
            dismissLilysKitchenTreatModal({
                result: ModalResultEnum.CANCELLED_BY_USER,
            }),
        );
    }
}
