import { Component } from '@angular/core';
import { IonSpinner } from '@ionic/angular/standalone';

@Component({
    selector: 'app-logout-page',
    templateUrl: './logout.page.html',
    styleUrl: './logout.page.scss',
    imports: [IonSpinner],
})
export class LogoutPage {}
