import { Directive, EventEmitter, HostListener, Input, Output, inject } from '@angular/core';
import { FoodProduct } from '../../data-access/food-calculator.model';
import { FoodProductModalService } from './food-product-modal.service';

@Directive({
    selector: '[appFoodProductSelector]',
})
export class FoodProductSelectorDirective {
    private readonly modalService = inject(FoodProductModalService);
    @Input() products: FoodProduct[] = [];
    @Output() foodProductChange = new EventEmitter<FoodProduct>();

    @HostListener('click')
    async openModal(): Promise<void> {
        const selectedProduct: FoodProduct | undefined = await this.modalService.showSelectorModal(this.products);
        if (selectedProduct) {
            this.foodProductChange.emit(selectedProduct);
        }
    }
}
