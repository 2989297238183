<ion-content class="page-ion-content" [fullscreen]="false">
    <div class="delete-account ion-text-center">
        <h2>Are you sure you wish to delete your account?</h2>
        <p>All the data in this device will be reset, you won't have any access anymore to progress etc.</p>
        <ion-button
            class="delete-account-button"
            (click)="deleteAccount()"
            data-test="delete-account-btn"
            color="max"
            expand="block"
            appTrackClick
            identifier="delete-account-btn"
            appHapticFeedback="warning"
            >Delete account</ion-button
        >
        <ion-button
            class="cancel-delete-account-button plain-text s unset-margin"
            fill="clear"
            data-test="cancel-delete-account-btn"
            (click)="closeModal()"
        >
            Cancel
        </ion-button>
    </div>
</ion-content>
