import { IsString } from 'class-validator';

import { IterablePlatformType } from '../interfaces/iterable-platform-type.model';

export class IterableRegisterForPushNotificationsDto {
    @IsString()
    userId!: string;

    device!: {
        token: string;
        platform: IterablePlatformType;
        applicationName: string;
    };
}
