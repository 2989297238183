import { createSelector } from '@ngrx/store';
import { GenderToYouthTitle } from '@shared/user-domain';
import { createCoursePreviewContent, createNextCoursesContent } from './course-preview.utils';
import {
    householdFeature,
    selectAgeInDays,
    selectAgeInWeeks,
    selectBreedName,
    selectHasArrived,
    selectIsUsUser,
    selectPartner,
} from '@frontend/data-access/user/household';
import { selectContentUnlocked } from '../../../store/payment/store/payment.selectors';

export const selectProfileInfo = createSelector(
    householdFeature.selectGender,
    householdFeature.selectDateOfBirth,
    selectAgeInDays,
    selectAgeInWeeks,
    selectBreedName,
    (gender, dateOfBirth, ageInDays, ageInWeeks, breedName) => {
        return {
            gender: gender ? GenderToYouthTitle[gender] : '',
            dateOfBirth,
            ageInDays,
            ageInWeeks,
            breedName,
        };
    },
);

export const selectCoursePreviewPageVm = createSelector(
    selectProfileInfo,
    selectPartner,
    selectHasArrived,
    selectIsUsUser,
    selectContentUnlocked,
    (profileInfo, partner, hasArrived, isUSUser, contentUnlocked) => {
        return {
            profileInfo,
            partner,
            hasPartner: !!partner,
            hasArrived,
            isUSUser,
            courseInfo: createCoursePreviewContent(hasArrived, profileInfo.ageInWeeks, profileInfo.breedName, isUSUser),
            nextCourses: createNextCoursesContent(hasArrived),
            contentUnlocked,
        };
    },
);
