import { NgClass } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { HeaderComponent, RightHeaderButton } from '@frontend/feature/header';
import {
    RadioWithExplanationComponent,
    RadioWithExplanationItem,
    RadioWithExplanationResult,
} from '@frontend/ui/radio-with-explanation';
import { IonButton, IonContent } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { NourishProductFeedbackModalState } from '../constants/nourish.constants';
import {
    closeNourishProductFeedbackModal,
    nourishProductFeedbackModalSubmitted,
    nourishProductFeedbackModalThumbsDownClicked,
} from '../store/nourish.actions';

@Component({
    selector: 'app-nourish-product-feedback-modal',
    templateUrl: './nourish-product-feedback-modal.component.html',
    styleUrl: './nourish-product-feedback-modal.component.scss',
    imports: [HeaderComponent, IonContent, IonButton, RadioWithExplanationComponent, NgClass],
})
export class NourishProductFeedbackModalComponent {
    private readonly store = inject(Store);

    public id = input.required<string>();

    protected readonly Color = Color;
    protected readonly NourishProductFeedbackModalState = NourishProductFeedbackModalState;
    protected readonly rightButtonConfig: RightHeaderButton = {
        analyticsIdentifier: 'nourish-product-feedback-close-btn',
        sprite: 'light',
        iconName: 'xmark',
        background: 'white',
        iconColour: 'black',
    };
    protected readonly buttons: RadioWithExplanationItem[] = [
        {
            title: "Not relevant to my dog's needs",
            value: 'not-relevant-to-dogs-needs',
        },
        {
            title: 'Not the right time for me to buy',
            value: 'not-right-time-to-buy',
        },
        {
            title: 'I already use a similar product',
            value: 'already-use-similar-product',
        },
        {
            title: 'I don’t trust this brand or product',
            value: 'dont-trust-brand-or-product',
        },
        {
            title: 'The information is unclear',
            value: 'information-unclear',
        },
        {
            title: 'It doesn’t represent value for money',
            value: 'doesnt-represent-value-for-money',
        },
        {
            title: 'Other',
            value: 'other',
            explainMore: {
                placeholder: 'Please add your comment',
            },
        },
    ];

    public rating: NourishProductFeedbackModalState = NourishProductFeedbackModalState.INITIAL;
    public radioResult: RadioWithExplanationResult | undefined;

    public onCloseButtonClick(): void {
        this.store.dispatch(closeNourishProductFeedbackModal());
    }

    public onThumbsDownClick(): void {
        if (this.rating === NourishProductFeedbackModalState.NEGATIVE) {
            this.rating = NourishProductFeedbackModalState.INITIAL;
        } else {
            this.rating = NourishProductFeedbackModalState.NEGATIVE;
        }

        this.store.dispatch(
            nourishProductFeedbackModalThumbsDownClicked({
                state: this.rating,
            }),
        );
    }

    public onThumbsUpClick(): void {
        this.rating = NourishProductFeedbackModalState.POSITIVE;

        this.store.dispatch(
            nourishProductFeedbackModalSubmitted({
                id: this.id(),
                response: this.rating,
            }),
        );
    }

    public onExplanationChange(result: RadioWithExplanationResult): void {
        this.radioResult = result;
    }

    public onNegativeFeedbackSubmit(): void {
        if (!this.radioResult?.complete) {
            return;
        }

        this.store.dispatch(
            nourishProductFeedbackModalSubmitted({
                id: this.id(),
                response: NourishProductFeedbackModalState.NEGATIVE,
                reason: this.radioResult.answer.value,
                comment: this.radioResult.answer.explanation,
            }),
        );
    }
}
