import { DatePipe, NgClass, NgStyle } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { openSettingsAndroid, openSettingsIos, socialShare } from '@frontend/data-access/capacitor';
import { selectBreeds } from '@frontend/data-access/contentful';
import { selectIsInputOpenOnAndroid } from '@frontend/data-access/keyboard';
import { platformFeature } from '@frontend/data-access/platform';
import { openPrivacy, openTerms, openUrl, routeToPrevious } from '@frontend/data-access/router';
import { authenticationFeature, logout, SignInProviders } from '@frontend/data-access/user/authentication';
import { householdFeature, selectCountryCode } from '@frontend/data-access/user/household';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { HeaderComponent, RightHeaderButton } from '@frontend/feature/header';
import { ProfileImageComponent, ProfileImageType } from '@frontend/feature/profile-picture';
import { InvalidInputErrorMessageComponent } from '@frontend/ui/invalid-input-error-message';
import { openToast } from '@frontend/ui/toast';
import { maxDateValidator } from '@frontend/utility/max-date-validator';
import { ModalService } from '@frontend/utility/modal';
import {
    IonButton,
    IonCard,
    IonContent,
    IonFooter,
    IonHeader,
    IonInput,
    IonItem,
    IonList,
    IonSelect,
    IonSelectOption,
    IonToolbar,
} from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Gender } from '@shared/user-domain';
import { Color, toUTC } from '@shared/utils/typescript';
import { AndroidSettings, IOSSettings } from 'capacitor-native-settings';
import { format } from 'date-fns';
import { genderOptionValues } from '../../../constants/profile-form.constants';
import { DeleteAccountComponent } from '../../delete-account/delete-account.component';
import { emailInputClicked, triggerPatchDogAndHouseholdUser } from './store/profile-edit.page.actions';

@Component({
    selector: 'app-profile-edit',
    templateUrl: './profile-edit.page.html',
    styleUrl: './profile-edit.page.scss',
    imports: [
        AnalyticsTrackClickDirective,
        DatePipe,
        HeaderComponent,
        InvalidInputErrorMessageComponent,
        IonButton,
        IonCard,
        IonContent,
        IonFooter,
        IonHeader,
        IonInput,
        IonItem,
        IonList,
        IonSelect,
        IonSelectOption,
        IonToolbar,
        NgClass,
        NgStyle,
        ProfileImageComponent,
        ReactiveFormsModule,
        RouterLink,
    ],
})
export class ProfileEditPage implements OnInit {
    // TODO: NBSon - test this, might be better to select from state
    public currentDate = new Date();
    public subscriptionLink = '';
    public customerSupportLink = 'https://zigzagpuppytrainingapp.zendesk.com/hc/en-us';
    public signInProviderLogoString = '';

    protected readonly ProfileImageType = ProfileImageType;
    protected readonly genderOptions = genderOptionValues;
    protected readonly SignInProviders = SignInProviders;
    protected readonly rightButtonConfig: RightHeaderButton = {
        analyticsIdentifier: 'profile-close-btn',
        sprite: 'light',
        iconName: 'xmark',
        background: 'none',
        iconColour: 'black',
    };

    private readonly store = inject(Store);
    private readonly modalService = inject(ModalService);
    private readonly formBuilder = inject(FormBuilder);

    public platformIsIos = this.store.selectSignal(platformFeature.selectPlatformIsIos);
    public platformIsAndroid = this.store.selectSignal(platformFeature.selectPlatformIsAndroid);
    public householdUser = this.store.selectSignal(householdFeature.selectCurrentUser);
    public householdDog = this.store.selectSignal(householdFeature.selectCurrentDog);
    public breeds = this.store.selectSignal(selectBreeds);
    public countryCode = this.store.selectSignal(selectCountryCode);
    public isInputFocusedOnAndroid = this.store.selectSignal(selectIsInputOpenOnAndroid);
    public signInProvider = this.store.selectSignal(authenticationFeature.selectSignInProvider);

    public userForm: FormGroup = this.formBuilder.group({
        name: new FormControl(null, [Validators.required, Validators.maxLength(16)]),
        breedId: new FormControl(null, [Validators.required]),
        gender: new FormControl(null, [Validators.required]),
        dateOfBirth: new FormControl(null, [Validators.required, maxDateValidator(this.currentDate)]),
        ownerName: new FormControl(null, [Validators.required, Validators.maxLength(32)]),
        mail: new FormControl(null, [Validators.required]),
    });

    public ngOnInit(): void {
        this.sortSubscriptionLink();
        this.sortSignInProvider();
        this.patchProfileFormValues();
    }

    public back(): void {
        this.store.dispatch(routeToPrevious({}));
    }

    public showIsReadonlyModal(): void {
        this.store.dispatch(emailInputClicked());
    }

    public share(): void {
        this.store.dispatch(
            socialShare({
                message:
                    'Puppyhood made easy! The #1 app 100% dedicated to puppyhood training. Join me on https://zigzag.dog',
            }),
        );
    }

    public saveProfile(): void {
        if (this.userForm.valid) {
            const breedIdChanged = this.userForm.get('breedId')?.dirty;
            const genderChanged = this.userForm.get('gender')?.dirty;
            const mailChanged = this.userForm.get('mail')?.dirty;
            const ownerNameChanged = this.userForm.get('ownerName')?.dirty;
            const dateOfBirthChanged = this.userForm.get('dateOfBirth')?.dirty;
            const nameChanged = this.userForm.get('name')?.dirty;

            if (
                breedIdChanged ||
                genderChanged ||
                mailChanged ||
                ownerNameChanged ||
                nameChanged ||
                dateOfBirthChanged
            ) {
                this.store.dispatch(
                    triggerPatchDogAndHouseholdUser({
                        ...(dateOfBirthChanged && {
                            dateOfBirth: new Date(this.userForm.get('dateOfBirth')?.value) as Date,
                            isApproximateDateOfBirth: false,
                        }),
                        ...(breedIdChanged && { breedId: this.userForm.get('breedId')?.value as string }),
                        ...(genderChanged && { gender: this.userForm.get('gender')?.value as Gender }),
                        ...(mailChanged && { mail: this.userForm.get('mail')?.value as string }),
                        ...(ownerNameChanged && { ownerName: this.userForm.get('ownerName')?.value as string }),
                        ...(nameChanged && { name: this.userForm.get('name')?.value as string }),
                    }),
                );
            }
            this.back();
        } else {
            void this.store.dispatch(
                openToast({
                    message: 'Please fill in all fields correctly',
                    color: Color.Jack,
                }),
            );
        }
    }

    // TODO: NBSon - can we make this an effect?
    public openApplicationDetails(platform: { ios: boolean; android: boolean; capacitor: boolean }) {
        if (platform.android) {
            this.store.dispatch(openSettingsAndroid({ option: AndroidSettings.ApplicationDetails }));
        }

        if (platform.capacitor && platform.ios) {
            this.store.dispatch(openSettingsIos({ option: IOSSettings.App }));
        }
    }

    public openLink(link: string): void {
        this.store.dispatch(openUrl({ url: link }));
    }

    public showTerms(): void {
        this.store.dispatch(openTerms({ countryCode: this.countryCode() }));
    }

    public showPrivacy(): void {
        this.store.dispatch(openPrivacy({ countryCode: this.countryCode() }));
    }

    public logout(): void {
        this.store.dispatch(logout());
    }

    public showDeleteAccount(): void {
        void this.modalService.showModal({
            component: DeleteAccountComponent,
            cssClass: ['modal', 'modal-info-notification'],
        });
    }

    private patchProfileFormValues(): void {
        const user = this.householdUser()!;
        const dog = this.householdDog()!;

        const setValues = {
            name: dog.name,
            breedId: dog.breedId,
            gender: dog.gender,
            ownerName: user.name,
            mail: user.mail,
            dateOfBirth: format(toUTC(dog.dateOfBirth ?? new Date()), 'yyyy-MM-dd'),
        };
        this.userForm.setValue(setValues);
    }

    private sortSubscriptionLink(): void {
        if (this.platformIsIos()) {
            this.subscriptionLink = 'https://apps.apple.com/account/subscriptions';
        } else if (this.platformIsAndroid()) {
            this.subscriptionLink = 'https://play.google.com/store/account/subscriptions';
        }
    }

    private sortSignInProvider(): void {
        if (this.signInProvider() === SignInProviders.Apple) {
            this.signInProviderLogoString = 'apple';
        } else if (this.signInProvider() === SignInProviders.Google) {
            this.signInProviderLogoString = 'google';
        }
    }
}
