import { AdditionalUserInfo, MultiFactorInfo } from '@angular/fire/auth';
import { createAction, props } from '@ngrx/store';
import { TotpSecret, User } from '../models/user.model';
import { AuthError } from './authentication.reducer';

export const initializeAuthenticationService = createAction('[Authentication] Initialize Authentication Service');
export const initializeAuthenticationServiceFailure = createAction(
    '[Authentication] Initialize Authentication Service Failure',
    props<{ error: Error }>(),
);

export const clearState = createAction('[Authentication] Clear State');

export const signUpWithEmailAndPassword = createAction(
    '[Authentication] Sign Up With Email And Password',
    props<{ email: string; password: string }>(),
);
export const signUpWithEmailAndPasswordSuccess = createAction(
    '[Authentication] Sign Up With Email And Password Success',
    props<{ user: User }>(),
);
export const signUpWithEmailAndPasswordFailure = createAction(
    '[Authentication] Sign Up With Email And Password Failure',
    props<{ error: AuthError }>(),
);

export const loginWithEmailAndPassword = createAction(
    '[Authentication] Login With Email And Password',
    props<{ email: string; password: string }>(),
);
export const loginWithEmailAndPasswordSuccess = createAction(
    '[Authentication] Login With Email And Password Success',
    props<{ user: User }>(),
);
export const loginWithEmailAndPasswordFailure = createAction(
    '[Authentication] Login With Email And Password Failure',
    props<{ error: AuthError }>(),
);

export const loginWithEmailAndPasswordAndTotp = createAction(
    '[Authentication] Login With Email And Password And Totp',
    props<{ email: string; password: string; code: string }>(),
);
export const loginWithEmailAndPasswordAndTotpSuccess = createAction(
    '[Authentication] Login With Email And Password And Totp Success',
    props<{ user: User }>(),
);
export const loginWithEmailAndPasswordAndTotpFailure = createAction(
    '[Authentication] Login With Email And Password And Totp Failure',
    props<{ error: AuthError }>(),
);

export const loginWithGoogle = createAction('[Authentication] Login With Google');
export const loginWithGoogleSuccess = createAction(
    '[Authentication] Login With Google Success',
    props<{ user: User; additionalUserInfo?: AdditionalUserInfo }>(),
);
export const loginWithGoogleFailure = createAction(
    '[Authentication] Login With Google Failure',
    props<{ error: AuthError }>(),
);

export const loginWithApple = createAction('[Authentication] Login With Apple');
export const loginWithAppleSuccess = createAction(
    '[Authentication] Login With Apple Success',
    props<{ user: User; additionalUserInfo?: AdditionalUserInfo }>(),
);
export const loginWithAppleFailure = createAction(
    '[Authentication] Login With Apple Failure',
    props<{ error: AuthError }>(),
);

export const logout = createAction('[Authentication] Logout');
export const logoutSuccess = createAction('[Authentication] Logout Success');
export const logoutFailure = createAction('[Authentication] Logout Failure', props<{ error: AuthError }>());

export const requestPasswordReset = createAction('[Authentication] Request Password Reset', props<{ email: string }>());
export const requestPasswordResetSuccess = createAction(
    '[Authentication] Request Password Reset Success',
    props<{ email: string }>(),
);
export const requestPasswordResetFailure = createAction(
    '[Authentication] Request Password Reset Failure',
    props<{ error: AuthError }>(),
);

export const watchForLoginStateChange = createAction('[Authentication] Watch For Login State Change');

export const foundValidUserSession = createAction('[Authentication] Found Valid User Session');
export const noValidUserSession = createAction('[Authentication] No Valid User Session');

export const getUserSignInProvider = createAction('[Authentication] Get User Is SSO');
export const getUserSignInProviderSuccess = createAction(
    '[Authentication] Get User Is SSO Success',
    props<{ signInProvider: string }>(),
);
export const getUserSignInProviderFailure = createAction(
    '[Authentication] Get User Is SSO Failure',
    props<{ error: AuthError }>(),
);

export const generateTOTPSecret = createAction('[Authentication] Generate TOTP Secret');
export const generateTOTPSecretSuccess = createAction(
    '[Authentication] Generate TOTP Secret Success',
    props<{ totpSecret: TotpSecret }>(),
);
export const generateTOTPSecretFailure = createAction(
    '[Authentication] Generate TOTP Secret Failure',
    props<{ error: AuthError }>(),
);

export const getMultiFactorInfo = createAction(`[Authentication] Get Multi Factor Info`);
export const getMultiFactorInfoSuccess = createAction(
    `[Authentication] Get Multi Factor Info Success`,
    props<{ multiFactorInfo: MultiFactorInfo[] }>(),
);
export const getMultiFactorInfoFailure = createAction(
    `[Authentication] Get Multi Factor Info Failure`,
    props<{ error: AuthError }>(),
);

export const enrollUserTOTP = createAction('[Authentication] Enroll User Totp', props<{ verificationCode: string }>());
export const enrollUserTOTPSuccess = createAction('[Authentication] Enroll User Totp Success');
export const enrollUserTOTPFailure = createAction(
    '[Authentication] Enroll User Totp Failure',
    props<{ error: AuthError }>(),
);

export const unenrollMultiFactor = createAction('[Authentication] Unenroll Multi Factor', props<{ id: string }>());
export const unenrollMultiFactorSuccess = createAction(
    '[Authentication] Unenroll Multi Factor Success',
    props<{ id: string }>(),
);
export const unenrollMultiFactorFailure = createAction(
    '[Authentication] Unenroll Multi Factor Failure',
    props<{ error: AuthError }>(),
);
