import { InternalPatchDogDto } from '../../../dtos/user/dog/internal-patch-dog.dto';

export class InternalPatchDogDtoMock {
    private defaultValue: InternalPatchDogDto = {
        name: 'Bella',
    };

    constructor(overrides?: Partial<InternalPatchDogDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): InternalPatchDogDto {
        return this.defaultValue;
    }
}
