<ion-card
    class="course-slider-tile tile"
    [ngStyle]="setBackground()"
    [attr.data-test]="'course-slider-tile-' + course.id"
>
    <div class="course-slider-tile-left-section">
        <div>
            <!-- This div is needed to position the top and middle sections into the same flexbox -->
            <div class="course-slider-tile-top-section">
                <h2 class="ignore-screen-based-font-scaling" data-test="course-slider-tile-title">
                    {{ course.title }}
                </h2>
            </div>
            <div class="course-slider-tile-middle-section">
                <p class="course-slider-tile-intro s" data-test="course-slider-tile-introduction">
                    {{ course.introduction }}
                </p>
            </div>
        </div>
        <div class="course-slider-tile-bottom-section">
            <ion-button
                class="s course-slider-tile-learn-more-btn ignore-screen-based-font-scaling"
                [ngStyle]="{
                    '--color': course.color + '-contrast',
                    '--border-color': course.color + '-contrast',
                }"
                appTrackClick
                identifier="course-slider-learn-more-btn"
                size="small"
                fill="outline"
                data-test="course-slider-learn-more-btn"
                (click)="openCourseInformationModal(course)"
                >Learn more
            </ion-button>
        </div>
    </div>
    @if (course.showProgress) {
        <div class="course-slider-tile-right-section">
            <circle-progress
                [options]="getCircleProgressOptions(course)"
                [attr.data-test]="'course-circle-progress-' + course.id"
            />
        </div>
    }
</ion-card>
