<section class="chat-message-rating-feedback-heading">
    @if (thumbsDownRating === undefined) {
        <img
            class="chat-message-rating-feedback-heading__image"
            alt="Ziggy reading a book"
            [src]="'/assets/images/ziggy/ziggy-reading-close-up.svg'"
        />
    }

    @if (thumbsDownRating === false) {
        <img
            class="chat-message-rating-feedback-heading__image"
            alt="Ziggy reading a book"
            [src]="'/assets/images/ziggy/ziggy-phone.svg'"
        />
    }

    <h3 class="chat-message-rating-feedback-heading__title">
        {{
            thumbsDownRating === undefined
                ? 'Did you find this helpful?'
                : thumbsDownRating
                  ? 'Why did you choose this rating?'
                  : 'Thank you for your feedback!'
        }}
    </h3>
</section>

<section class="chat-message-rating-feedback-icons">
    <button
        class="chat-message-rating-feedback-icons__thumbs-down"
        [ngClass]="{ active: thumbsDownRating === true, hidden: thumbsDownRating === false }"
        (click)="onClickThumbRating(true)"
    >
        <i class="fa-light fa-thumbs-down"></i>
    </button>

    <button
        class="chat-message-rating-feedback-icons__thumbs-up"
        [ngClass]="{ active: thumbsDownRating === false, hidden: thumbsDownRating === true }"
        (click)="onClickThumbRating(false)"
    >
        <i class="fa-light fa-thumbs-up"></i>
    </button>
</section>

@if (thumbsDownRating === true) {
    <section class="chat-message-rating-feedback-options">
        @for (option of feedbackOptions(); track option.key) {
            <button
                class="zz-button chat-message-rating-feedback-options__option"
                [ngClass]="{
                    'chat-message-rating-feedback-options__option--selected':
                        option.key === selectedFeedbackOption?.key,
                }"
                [attr.data-test]="'chat-message-rating-feedback-option-' + option.key"
                (click)="onClickFeedbackOption(option)"
            >
                {{ option.value }}
            </button>
        }
    </section>
}

@if (shouldShowCommentInput) {
    <ion-item class="chat-message-rating-feedback-comment">
        <ion-input
            #commentInput
            [tabindex]="1"
            [formControl]="commentFormControl"
            class="chat-message-rating-feedback-comment__input"
            autocapitalize="sentences"
            placeholder="Please add your comment"
            type="text"
            data-test="chat-message-rating-feedback-comment-input"
        />
    </ion-item>
}
