import { createAction, props } from '@ngrx/store';
import { IterablePostEventDto, IterableUpsertUserDto } from '@shared/iterable-api-interface';

export const setPushNotifications = createAction('[PushNotification] Set Push Notifications');

export const logoutPushNotifications = createAction('[PushNotification] Logout User');
export const logoutPushNotificationsSuccess = createAction('[PushNotification] Logout User Success');
export const logoutPushNotificationsFailure = createAction(
    '[PushNotification] Logout User Failure',
    props<{ error: Error }>(),
);

export const upsertIterableUser = createAction(
    '[Iterable] Upsert Iterable User',
    props<{ user: IterableUpsertUserDto }>(),
);

export const upsertIterableUserSuccess = createAction('[Iterable] Upsert Iterable User Success');

export const upsertIterableUserFailure = createAction(
    '[Iterable] Upsert Iterable User Failure',
    props<{ error: Error }>(),
);

export const registerIterableUserForPushNotificationsSuccess = createAction(
    '[Iterable] Register Iterable User For Push Notifications Success',
);

export const registerIterableUserForPushNotificationsFailure = createAction(
    '[Iterable] Register Iterable User For Push Notifications Failure',
    props<{ error: Error }>(),
);

export const trackIterableEvent = createAction('[Iterable] Track Event', props<{ dto: IterablePostEventDto }>());
export const trackIterableEventSuccess = createAction('[Iterable] Track Event Success');
export const trackIterableEventFailure = createAction('[Iterable] Track Event Failure', props<{ error: Error }>());

export const trackIterablePushOpenEventSuccess = createAction('[Iterable] Track Push Open Event Success');
export const trackIterablePushOpenEventFailure = createAction(
    '[Iterable] Track Push Open Event Failure',
    props<{ error: Error }>(),
);
