import { Component, inject } from '@angular/core';
import { openSettingsAndroid, openSettingsIos } from '@frontend/data-access/capacitor';
import { clearLocalNotification, selectLocalNotifications } from '@frontend/data-access/local-notification';
import { platformFeature } from '@frontend/data-access/platform';
import { routeToPrevious } from '@frontend/data-access/router';
import { householdFeature } from '@frontend/data-access/user/household';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { HeaderComponent } from '@frontend/feature/header';
import { IonButton, IonContent, IonHeader, IonItem, IonToolbar } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { AndroidSettings, IOSSettings } from 'capacitor-native-settings';
import { launchTrainingReminderModal } from '../../training-reminder/store/training-reminder-modal.actions';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.page.html',
    styleUrl: './notifications.page.scss',
    imports: [IonButton, IonContent, IonHeader, IonItem, IonToolbar, AnalyticsTrackClickDirective, HeaderComponent],
})
export class NotificationsPage {
    private readonly store = inject(Store);

    public platformIsIos = this.store.selectSignal(platformFeature.selectPlatformIsIos);
    public platformIsAndroid = this.store.selectSignal(platformFeature.selectPlatformIsAndroid);
    public platformIsCapacitor = this.store.selectSignal(platformFeature.selectPlatformIsCapacitor);
    public notifications = this.store.selectSignal(selectLocalNotifications);

    private dogName = this.store.selectSignal(householdFeature.selectDogName);

    protected readonly Color = Color;

    public previousPage(): void {
        this.store.dispatch(routeToPrevious({}));
    }

    public setTrainingReminder(): void {
        this.store.dispatch(
            launchTrainingReminderModal({
                dogName: this.dogName(),
                trigger: 'notifications-page',
            }),
        );
    }

    public openApplicationDetails() {
        if (this.platformIsAndroid()) {
            this.store.dispatch(
                openSettingsAndroid({
                    option: AndroidSettings.ApplicationDetails,
                }),
            );
        }

        if (this.platformIsCapacitor() && this.platformIsIos()) {
            this.store.dispatch(
                openSettingsIos({
                    option: IOSSettings.App,
                }),
            );
        }
    }

    public async clearTrainingReminder(notificationIds: number[]) {
        const notifications = notificationIds.map((id) => {
            return { id };
        });

        this.store.dispatch(clearLocalNotification({ notifications }));
    }
}
