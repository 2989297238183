import { InternalPublicUserDto } from '../../../dtos/user/internal-user.dto';

export class InternalPublicUserDtoMock {
    private defaultValue: InternalPublicUserDto = {
        // Set after creation
        id: 'uuid-1234',
        mail: 'revenue-cat-user@e2e.zigzag.dog',
        countryCode: 'GB',
        // Set during onboarding
        dateOfOnboarding: '2021-03-24T00:00:00.000Z',
        name: 'Fred',
    };

    private empty: InternalPublicUserDto = {
        id: '',
        mail: '',
        dateOfOnboarding: undefined,
        name: undefined,
        countryCode: undefined,
    };

    constructor(overrides?: Partial<InternalPublicUserDto>) {
        this.defaultValue = { ...this.defaultValue, ...overrides };

        Object.assign(this.empty, overrides);
    }

    public get value(): InternalPublicUserDto {
        return this.defaultValue;
    }

    public get emptyValue(): InternalPublicUserDto {
        return this.empty;
    }
}
