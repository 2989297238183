import { Component, EventEmitter, Output } from '@angular/core';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { IonButton, IonButtons } from '@ionic/angular/standalone';

@Component({
    selector: 'app-ai-chat-header',
    templateUrl: './ai-chat-header.component.html',
    styleUrl: './ai-chat-header.component.scss',
    imports: [AnalyticsTrackClickDirective, IonButtons, IonButton],
})
export class AiChatHeaderComponent {
    @Output()
    backClicked: EventEmitter<void> = new EventEmitter<void>();

    onBackClicked() {
        this.backClicked.emit();
    }
}
