import { FactorId, MultiFactorInfo } from '@angular/fire/auth';
import { LoadingState } from '@frontend/data-access/shared-models';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { TotpSecret } from '../models/user.model';
import {
    clearState,
    enrollUserTOTP,
    enrollUserTOTPFailure,
    enrollUserTOTPSuccess,
    foundValidUserSession,
    generateTOTPSecret,
    generateTOTPSecretFailure,
    generateTOTPSecretSuccess,
    getMultiFactorInfo,
    getMultiFactorInfoFailure,
    getMultiFactorInfoSuccess,
    getUserSignInProviderSuccess,
    loginWithApple,
    loginWithAppleFailure,
    loginWithAppleSuccess,
    loginWithEmailAndPassword,
    loginWithEmailAndPasswordAndTotpFailure,
    loginWithEmailAndPasswordAndTotpSuccess,
    loginWithEmailAndPasswordFailure,
    loginWithEmailAndPasswordSuccess,
    loginWithGoogle,
    loginWithGoogleFailure,
    loginWithGoogleSuccess,
    noValidUserSession,
    requestPasswordReset,
    requestPasswordResetFailure,
    requestPasswordResetSuccess,
    signUpWithEmailAndPassword,
    signUpWithEmailAndPasswordFailure,
    signUpWithEmailAndPasswordSuccess,
    unenrollMultiFactor,
    unenrollMultiFactorFailure,
    unenrollMultiFactorSuccess,
} from './authentication.actions';

export const authenticationFeatureKey = 'authentication';

export interface AuthError extends Error {
    code: string;
}

export interface AuthState {
    loggedIn: boolean;
    isNewUser: boolean | undefined;
    signInProvider: string | undefined;
    // Sign up
    signUpLoadingState: LoadingState;
    signUpError: AuthError | undefined;
    // Login
    loginLoadingState: LoadingState;
    loginError: AuthError | undefined;
    // Password reset
    resetPasswordLoading: boolean;
    resetPasswordError: AuthError | undefined;
    resetPasswordSuccess: boolean;
    // TOTP
    totpSecret: TotpSecret | undefined;
    totpSecretLoading: boolean;
    totpSecretError: AuthError | undefined;
    // Multi-factor
    multiFactorInfo: MultiFactorInfo[];
    multiFactorInfoLoadingState: LoadingState;
    multiFactorInfoError: AuthError | undefined;
}

export const initialState: AuthState = {
    loggedIn: false,
    isNewUser: false,
    signInProvider: undefined,
    // Sign up
    signUpLoadingState: LoadingState.INIT,
    signUpError: undefined,
    // Login
    loginLoadingState: LoadingState.INIT,
    loginError: undefined,
    // Password reset
    resetPasswordLoading: false,
    resetPasswordError: undefined,
    resetPasswordSuccess: false,
    // TOTP
    totpSecret: undefined,
    totpSecretLoading: false,
    totpSecretError: undefined,
    // Multi-factor
    multiFactorInfo: [],
    multiFactorInfoLoadingState: LoadingState.INIT,
    multiFactorInfoError: undefined,
};

export const authenticationFeature = createFeature({
    name: authenticationFeatureKey,
    reducer: createReducer(
        initialState,
        on(clearState, (state) => {
            return {
                ...state,
                ...initialState,
            };
        }),
        on(loginWithEmailAndPassword, (state): AuthState => {
            return {
                ...state,
                loginLoadingState: LoadingState.LOADING,
                loginError: undefined,
            };
        }),
        on(loginWithEmailAndPasswordSuccess, loginWithEmailAndPasswordAndTotpSuccess, (state, { user }): AuthState => {
            return {
                ...state,
                signInProvider: user.signInProvider,
                loginLoadingState: LoadingState.LOADED,
                loginError: undefined,
            };
        }),
        on(loginWithEmailAndPasswordFailure, loginWithEmailAndPasswordAndTotpFailure, (state, { error }): AuthState => {
            return {
                ...state,
                loginLoadingState: LoadingState.LOADED,
                loginError: error,
            };
        }),
        on(signUpWithEmailAndPassword, (state): AuthState => {
            return {
                ...state,
                isNewUser: true,
                signUpLoadingState: LoadingState.LOADING,
                signUpError: undefined,
            };
        }),
        on(signUpWithEmailAndPasswordSuccess, (state, { user }): AuthState => {
            return {
                ...state,
                signInProvider: user.signInProvider,
                signUpLoadingState: LoadingState.LOADED,
                signUpError: undefined,
            };
        }),
        on(signUpWithEmailAndPasswordFailure, (state, { error }): AuthState => {
            return {
                ...state,
                signUpLoadingState: LoadingState.LOADED,
                signUpError: error,
            };
        }),
        on(loginWithApple, loginWithGoogle, (state): AuthState => {
            return {
                ...state,
                signUpLoadingState: LoadingState.LOADING,
                signUpError: undefined,
                loginLoadingState: LoadingState.LOADING,
                loginError: undefined,
            };
        }),
        on(loginWithAppleSuccess, loginWithGoogleSuccess, (state, { user, additionalUserInfo }): AuthState => {
            return {
                ...state,
                loginError: undefined,
                isNewUser: additionalUserInfo?.isNewUser,
                signInProvider: user.signInProvider,
                signUpLoadingState: LoadingState.LOADED,
                signUpError: undefined,
                loginLoadingState: LoadingState.LOADED,
            };
        }),
        on(loginWithAppleFailure, loginWithGoogleFailure, (state, action): AuthState => {
            return {
                ...state,
                signUpLoadingState: LoadingState.LOADED,
                signUpError: action.error,
                loginLoadingState: LoadingState.LOADED,
                loginError: action.error,
            };
        }),
        on(foundValidUserSession, (state): AuthState => {
            return {
                ...state,
                loggedIn: true,
            };
        }),
        on(noValidUserSession, (state): AuthState => {
            return {
                ...state,
                loggedIn: false,
            };
        }),
        on(requestPasswordReset, (state): AuthState => {
            return {
                ...state,
                resetPasswordLoading: true,
                resetPasswordError: undefined,
            };
        }),
        on(requestPasswordResetSuccess, (state): AuthState => {
            return {
                ...state,
                resetPasswordLoading: false,
                resetPasswordError: undefined,
                resetPasswordSuccess: true,
            };
        }),
        on(requestPasswordResetFailure, (state, action): AuthState => {
            return {
                ...state,
                resetPasswordLoading: false,
                resetPasswordError: action.error,
                resetPasswordSuccess: false,
            };
        }),
        on(getUserSignInProviderSuccess, (state, { signInProvider }) => {
            return {
                ...state,
                signInProvider,
            };
        }),
        on(generateTOTPSecret, enrollUserTOTP, (state): AuthState => {
            return {
                ...state,
                totpSecretLoading: true,
                totpSecretError: undefined,
            };
        }),
        on(generateTOTPSecretSuccess, (state, { totpSecret }): AuthState => {
            return {
                ...state,
                totpSecret,
                totpSecretLoading: false,
                totpSecretError: undefined,
            };
        }),
        on(enrollUserTOTPSuccess, (state): AuthState => {
            return {
                ...state,
                totpSecretError: undefined,
            };
        }),
        on(generateTOTPSecretFailure, enrollUserTOTPFailure, (state, { error }): AuthState => {
            return {
                ...state,
                totpSecretLoading: false,
                totpSecretError: error,
            };
        }),
        on(getMultiFactorInfo, unenrollMultiFactor, (state): AuthState => {
            return {
                ...state,
                multiFactorInfoLoadingState: LoadingState.LOADING,
                multiFactorInfoError: undefined,
            };
        }),
        on(getMultiFactorInfoSuccess, (state, { multiFactorInfo }): AuthState => {
            return {
                ...state,
                multiFactorInfo,
                multiFactorInfoLoadingState: LoadingState.LOADED,
                multiFactorInfoError: undefined,
            };
        }),
        on(unenrollMultiFactorSuccess, (state, { id }): AuthState => {
            const updatedMultiFactorInfo = state.multiFactorInfo.filter((info) => info.uid !== id);

            return {
                ...state,
                multiFactorInfo: updatedMultiFactorInfo,
                multiFactorInfoLoadingState: LoadingState.LOADED,
                multiFactorInfoError: undefined,
            };
        }),
        on(getMultiFactorInfoFailure, unenrollMultiFactorFailure, (state, { error }): AuthState => {
            return {
                ...state,
                multiFactorInfoLoadingState: LoadingState.LOADED,
                multiFactorInfoError: error,
            };
        }),
    ),
    extraSelectors: ({ selectAuthenticationState }) => ({
        selectSignUpLoading: createSelector(
            selectAuthenticationState,
            (state) => state.signUpLoadingState === LoadingState.LOADING,
        ),
        selectLoginLoading: createSelector(
            selectAuthenticationState,
            (state) => state.loginLoadingState === LoadingState.LOADING,
        ),
        selectIsNewUser: createSelector(selectAuthenticationState, (state) => state.isNewUser),
        selectIsEnrolledWithTOTP: createSelector(selectAuthenticationState, (state) =>
            state.multiFactorInfo.some((info) => info.factorId === FactorId.TOTP),
        ),
    }),
});
