import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IonCard, IonChip, IonCol, IonGrid, IonLabel, IonRow } from '@ionic/angular/standalone';
import type { PaymentProductCard } from './payment-product-card.model';

@Component({
    selector: 'app-payment-modal-product',
    templateUrl: './payment-modal-product.component.html',
    styleUrl: './payment-modal-product.component.scss',
    imports: [NgClass, IonChip, IonCard, IonLabel, IonGrid, IonRow, IonCol],
})
export class PaymentModalProductComponent {
    @Input({ required: true }) public product!: PaymentProductCard;
    @Input() public isSelected = false;
    @Input() public isBasicSelected = false;
    @Output() public productClicked = new EventEmitter<PaymentProductCard>();

    public clickProduct(product: PaymentProductCard): void {
        this.productClicked.emit(product);
    }
}
