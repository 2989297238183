<ion-content [color]="Color.AppBackground">
    @if (product(); as product) {
    <ion-header class="page-header ion-no-border">
        <ion-toolbar>
            <app-nourish-product-header
                [productId]="product.id"
                [logo]="product.logo"
                [colour]="product.colour"
                [background]="product.background"
            />
        </ion-toolbar>
    </ion-header>

    <main class="nourish-product-page__content ignore-global-color">
        <h2 class="nourish-product-page__title no-margin" data-test="nourish-product-page-title">
            {{ product.title | textReplacement }}
        </h2>

        <section class="nourish-product-page__section">
            <div class="nourish-product-perks">
                @for (perk of product.perks; track perk.text; let i = $index) {
                <div class="nourish-product-perk" [ngClass]="product.colour">
                    <i class="nourish-product-perk__icon" [ngClass]="perk.icon"></i>
                    <p class="no-margin s" [attr.data-test]="'nourish-product-page-perk' + i">{{ perk.text }}</p>
                </div>
                }
            </div>

            @if (product.offer) {
            <div class="nourish-product-offer" [ngClass]="Color.Max" data-test="nourish-product-page-offer">
                <div class="nourish-product-offer__badge">
                    <img [src]="'assets/images/zigzag/z-white.svg'" alt="The 'Z' character of the 'Zigzag' logo" />
                    <p class="no-margin">Exclusive offer</p>
                </div>
                <div class="nourish-product-offer__heading">
                    <h2 class="nourish-product-offer__title no-margin" data-test="nourish-product-page-offer-title">
                        {{ product.offer.title }}
                    </h2>
                    @if (product.offer?.subtitle) {
                    <p
                        class="nourish-product-offer__subtitle no-margin"
                        data-test="nourish-product-page-offer-subtitle"
                    >
                        {{ product.offer.subtitle }}
                    </p>
                    }
                </div>
            </div>
            } @if (product.discount) {
            <div class="nourish-product-discount" [ngClass]="Color.Max" data-test="nourish-product-page-discount">
                <div class="nourish-product-discount__heading">
                    <i class="nourish-product-discount__icon fa-regular fa-gift"></i>
                    <h2
                        class="nourish-product-discount__title no-margin"
                        data-test="nourish-product-page-discount-title"
                    >
                        {{ product.discount.title }}
                    </h2>
                </div>
                <p class="nourish-product-discount__text no-margin s" data-test="nourish-product-page-discount-text">
                    Use discount code
                    <span
                        class="nourish-product-discount__code"
                        [ngClass]="Color.Bailey"
                        data-test="nourish-product-page-discount-code"
                        >{{ product.discount.code }}</span
                    >
                    at checkout
                </p>
            </div>
            }
        </section>

        <section class="nourish-product-pitch" [ngClass]="Color.AppOutline">
            <h3 class="no-margin" data-test="nourish-product-page-pitch-title">
                Why choose {{ product.productName }}?
            </h3>
            <div class="nourish-product-pitch__content">
                @for (bullet of product.bulletPoints; track bullet.title; let i = $index) {
                <div class="nourish-product-pitch__bullet">
                    <img class="nourish-product-pitch__image" [src]="bullet.image.src" [alt]="bullet.image.alt" />
                    <div class="nourish-product-pitch__right">
                        <h3
                            class="nourish-product-pitch__title no-margin"
                            [attr.data-test]="'nourish-product-page-bullet-title' + i"
                        >
                            {{ bullet.title }}
                        </h3>
                        <p
                            class="nourish-product-pitch__description no-margin s"
                            [attr.data-test]="'nourish-product-page-bullet-description' + i"
                        >
                            {{ bullet.description | textReplacement }}
                        </p>
                    </div>
                </div>
                }
            </div>
        </section>

        <section class="nourish-product-page__section">
            <h3 class="no-margin" data-test="nourish-product-page-coach-title">What our coaches think?</h3>
            <div class="nourish-coach-card ignore-global-color" [ngClass]="Color.AppOutline">
                <div class="nourish-coach-card__heading">
                    <img
                        class="nourish-coach-card__image"
                        [src]="product.coach.image.src"
                        [alt]="product.coach.image.alt"
                    />
                    <p
                        class="nourish-coach-card__name no-margin s"
                        [ngStyle]="{
                                'color': 'var(--ion-color-' + product.colour + ')'
                            }"
                        data-test="nourish-product-page-coach-name"
                    >
                        {{ product.coach.name }}
                    </p>
                </div>
                <p
                    class="nourish-coach-card__comment no-margin s"
                    [ngStyle]="{
                        'color': 'var(--ion-color-' + product.colour + ')'
                    }"
                    data-test="nourish-product-page-coach-comment"
                >
                    “{{ product.coach.comment }}”
                </p>
            </div>
        </section>
    </main>

    <ion-footer class="ion-text-center gradient-footer">
        <ion-button expand="block" data-test="nourish-product-page-cta-btn" [color]="Color.Max" (click)="onCtaClick()">
            {{ product.callToAction.text }}
        </ion-button>
    </ion-footer>
    }
</ion-content>
