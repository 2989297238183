import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { ElementRerenderDirective } from '@frontend/ui/directives';

@Component({
    selector: 'ui-icon',
    templateUrl: './icon.component.html',
    styleUrl: './icon.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [ElementRerenderDirective],
})
export class IconComponent {
    public iconName = input.required<string>();
    public sprite = input.required<string>();

    public rerenderOn = computed(() => {
        return `${this.iconName()}-${this.sprite()}`;
    });
}
