@if (vm$ | async; as vm) {
    <div class="pinned-topic-overview">
        <h2 class="no-margin-bottom">Pinned topics</h2>
        <p class="s no-margin-top">Work on topics important to {{ '[NAME]' | textReplacement }}</p>
        <ui-slider>
            @for (topic of vm.topics; track topic.entityId) {
                <div class="carousel-inner">
                    <app-topic-card-component
                        [attr.data-test]="topic.title + '-pinned-topic-card'"
                        [routerLinkPath]="['/main', 'library', 'topic', topic.entityId]"
                        [showProgressBar]="true"
                        [showPinButton]="false"
                        [topic]="topic"
                        [isLoading]="vm.arePinnedTopicsLoading"
                        clickEventIdentifier="click-pinned-topic-{{ topic.entityId }}-from-today-page"
                    >
                    </app-topic-card-component>
                </div>
            }
            <div class="add-topic-box">
                <ion-card
                    class="add-topic-tile"
                    button="true"
                    [routerLink]="['/main', 'library']"
                    appTrackClick
                    identifier="add-new-pinned-topic-from-today-page"
                >
                    <div class="add-topic-container">
                        <i class="fa-regular fa-plus add-topic-icon"></i>
                        <p class="s add-topic-text unset-margin">Add Topic</p>
                    </div>
                </ion-card>
            </div>
        </ui-slider>
    </div>
}
