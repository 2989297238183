<div class="today-developmental-window page-padding-sides">
    <div class="today-developmental-window__profile-title-image-section">
        <section class="today-developmental-window__profile-title-section">
            @if (showDevelopmentalWindows()) {
                <h2 class="no-margin" data-test="today-developmental-window-age-title">
                    {{ dogName() + '\’s ' }}<span class="ion-text-nowrap">developmental age</span>
                </h2>
            } @else {
                <h2 class="no-margin" data-test="today-developmental-window-welcome-title">
                    Welcome back, {{ dogName() }} and {{ ownerName() }}!
                </h2>
            }
        </section>
        <section
            class="today-developmental-window__profile-image-section"
            data-test="today-profile-image-clickable"
            (click)="routeToProfile()"
        >
            <app-profile-image size="48px" borderWidth="2px" [type]="ProfileImageType.GO_TO_PROFILE" />
        </section>
    </div>
    @if (showDevelopmentalWindows()) {
        <app-developmental-window-timeline data-test="today-developmental-window-timeline" />
    }
</div>
