@if (vm()) {
<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <app-header
            class="authentication-app-header authentication-app-header--contrast"
            title="Sign in"
            [leftButton]="{ analyticsIdentifier:'login-back-btn', background: 'none' }"
            [rightButton]="false"
            [isDisabled]="vm().isLoading"
            (leftButtonPressed)="onBackButtonPressed()"
        />
    </ion-toolbar>
</ion-header>

<ion-content class="login-page-ion-content" [fullscreen]="true">
    <main class="login-page page-content ion-justify-content-start">
        <section class="authentication-title-container">
            <h1 class="login-page__title">Log in to your Zigzag account</h1>
            <p class="login-page__subtitle" data-test="login-page-subtitle">Let’s start training again!</p>
        </section>

        <form class="authentication-form authentication-form--luna" [formGroup]="loginForm">
            <ion-item>
                <ion-input
                    class="zz-input"
                    formControlName="email"
                    type="email"
                    placeholder="Your email"
                    data-test="email-input"
                    [tabindex]="1"
                />
            </ion-item>

            @if (showEmailError()) {
            <ui-invalid-input-error-message class="login-page__error" errorMessage="Please fill in a valid email" />
            }

            <ion-item class="authentication-form__password-item ion-margin-top">
                <ion-input
                    class="authentication-form__password-input zz-input"
                    #passwordInput
                    formControlName="password"
                    type="password"
                    placeholder="Password"
                    data-test="password-input"
                    [tabindex]="2"
                />

                @if (isPasswordVisible) {
                <button class="zz-button l" type="button" (click)="onPasswordVisibilityToggle()">
                    <i
                        class="authentication-form__password-visibility authentication-form__password-visibility--contrast fa-regular fa-eye-slash"
                    ></i>
                </button>
                } @else {
                <button class="zz-button l" type="button" (click)="onPasswordVisibilityToggle()">
                    <i
                        class="authentication-form__password-visibility authentication-form__password-visibility--contrast fa-regular fa-eye"
                    ></i>
                </button>
                }
            </ion-item>

            @if (!!vm().error) {
            <ui-invalid-input-error-message
                class="login-page__error"
                [errorMessage]="vm().error!.message"
                [linkText]="vm().error!.link?.text"
                (linkClicked)="onErrorLinkClick(vm().error!.link?.route ?? [])"
            />
            }
        </form>

        <div class="login-page__footer">
            <div class="hide-on-keyboard-small-screen">
                @if (!vm().isInputFocusedOnAndroid) {
                <button
                    class="zz-button login-page__forgot-password"
                    appTrackClick
                    identifier="forgot-password"
                    data-test="forgot-password"
                    (click)="onForgotPasswordClick()"
                >
                    Forgot your password?
                </button>
                <ion-button
                    class="ion-button-color-max"
                    appTrackClick
                    identifier="sign-in-btn"
                    expand="block"
                    data-test="login-btn"
                    [class.login-page-footer__sign-in-button--disabled]="!loginForm.valid"
                    [disabled]="!loginForm.valid || vm().isLoading"
                    (click)="onLoginClick()"
                >
                    @if (vm().isLoading) {
                    <ion-spinner name="crescent" />
                    } @else {
                    <div>Sign In</div>
                    }
                </ion-button>

                <p class="login-page__or">or</p>

                @if (vm().isIos) {
                <ion-button
                    class="authentication-button"
                    appTrackClick
                    identifier="auth-apple-btn"
                    expand="block"
                    data-test="auth-apple-btn"
                    [color]="Color.Snowy"
                    [disabled]="vm().isLoading"
                    (click)="onSignInWithAppleClick()"
                >
                    <h3 class="authentication-button__content">
                        <i class="authentication-button__icon fa-brands fa-apple"></i>
                        <span class="authentication-button__text">Continue with Apple</span>
                    </h3>
                </ion-button>
                }

                <ion-button
                    class="authentication-button"
                    appTrackClick
                    identifier="auth-google-btn"
                    expand="block"
                    data-test="auth-google-btn"
                    [color]="Color.Snowy"
                    [disabled]="vm().isLoading"
                    (click)="onSignInWithGoogleClick()"
                >
                    <h3 class="authentication-button__content">
                        <img src="assets/images/third-party/google-logo-color.svg" alt="Continue with Google" />
                        <span class="authentication-button__text">Continue with Google</span>
                    </h3>
                </ion-button>
                }
            </div>
        </div>
    </main>
</ion-content>

}
