import { IsNumber, IsOptional, IsString } from 'class-validator';

export class InternalPostChannelTagDto {
    @IsString()
    channelId!: string;

    @IsString()
    tagId!: string;

    @IsOptional()
    @IsNumber()
    timeStamp?: number;
}
