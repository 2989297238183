<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <ui-progress-header
            [showBackButton]="!!previousOnboardingPage()"
            [progressRatio]="progress()"
            [isDisabled]="isLoading()"
            (backButtonClicked)="onBackClicked()"
        />
    </ion-toolbar>
</ion-header>

<ion-content class="onboarding-ion-content" [fullscreen]="true">
    <main class="page-content ion-justify-content-start">
        <section>
            <h1>Treat yourself and {{ dogName() }}!</h1>
            <p data-test="question-purina-opt-in-subtitle">
                Click "YES" to hear from our trusted partner Purina and receive:
            </p>
        </section>

        <ul class="purina-opt-in-benefits">
            <li class="purina-opt-in-benefits__item">
                <i class="fa-2xl fa-regular fa-check"></i>
                <h3 class="purina-opt-in-benefits__text">Reward points</h3>
            </li>
            <li class="purina-opt-in-benefits__item">
                <i class="fa-2xl fa-regular fa-check"></i>
                <h3 class="purina-opt-in-benefits__text">Personalized pup nutrition</h3>
            </li>
            <li class="purina-opt-in-benefits__item">
                <i class="fa-2xl fa-regular fa-check"></i>
                <h3 class="purina-opt-in-benefits__text">Exclusive free gifts</h3>
            </li>
        </ul>
    </main>
</ion-content>

<ion-footer class="ion-text-center page-footer">
    <p class="purina-opt-in-footer__policy xs" data-test="question-purina-opt-in-policy">
        I would like to get marketing communications from Zigzag's trusted partner Purina, by having the information I
        provide to Zigzag shared with Purina. I agree Purina will use my data according to its
        <strong
            data-test="purina-opt-in-show-privacy"
            (click)="onExternalLinkClick('https://www.purina.com/privacy-policy')"
            >Privacy Policy
        </strong>
        &
        <strong
            data-test="purina-opt-in-show-terms"
            (click)="onExternalLinkClick('https://www.purina.com/terms-and-conditions')"
            >Terms.</strong
        >
    </p>

    <div class="onboarding-footer-buttons">
        <ion-button
            class="ion-outline-button-color ion-outline-button-color--buddy"
            appTrackClick
            identifier="question-purina-opt-in-yes-btn"
            expand="block"
            data-test="question-purina-opt-in-yes-btn"
            [disabled]="isLoading()"
            (click)="onOptInClick(true)"
        >
            @if (isLoading()) {
            <ion-spinner name="crescent" />
            } @else { Yes }
        </ion-button>
        <button
            class="purina-opt-in-footer__button zz-button"
            appTrackClick
            data-test="question-purina-opt-in-no-btn"
            identifier="question-purina-opt-in-no-btn"
            [disabled]="isLoading()"
            (click)="onOptInClick(false)"
        >
            No thanks
        </button>
    </div>
</ion-footer>
