<section class="voucher-small">
    <h3 class="voucher-small__heading unset-margin" data-test="voucher-small-heading">{{ heading() }}</h3>
    <div class="voucher-small__text">
        <p
            class="voucher-small__sub-heading unset-margin xs"
            data-test="voucher-small-subheading"
            [innerHtml]="subHeading()"
        ></p>
        <p class="voucher-small__text unset-margin xs" data-test="voucher-small-text">{{ text() }}</p>
    </div>
    <div class="voucher-small__divider-left"></div>
    <div class="voucher-small__divider-right"></div>
    <img
        class="voucher-small__zukes-logo-image"
        data-test="voucher-small-image"
        [src]="imageUrl()"
        [alt]="imageAlt()"
    />
</section>
