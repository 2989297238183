import { NgStyle } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';
import { HeaderButton, HeaderComponent } from '@frontend/feature/header';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { NourishProductId } from '../constants/nourish.constants';
import { nourishProductHeaderBackButtonClicked } from '../store/nourish.actions';

@Component({
    selector: 'app-nourish-product-header',
    templateUrl: './nourish-product-header.component.html',
    styleUrl: './nourish-product-header.component.scss',
    imports: [NgStyle, HeaderComponent],
})
export class NourishProductHeaderComponent {
    private readonly store = inject(Store);

    public productId = input.required<NourishProductId>();
    public logo = input.required<{ src: string; alt: string }>();
    public colour = input.required<Color>();

    public background = input<Color | undefined>();

    public computedContainerStyles = computed(() => {
        return {
            background: this.background()
                ? `var(--ion-color-${this.background()})`
                : `var(--ion-color-${this.colour()}-secondary-light)`,
        };
    });
    public computedAppHeaderStyles = computed(() => {
        return {
            '--zz-back-button-color': `var(--ion-color-${this.colour()})`,
        };
    });

    protected readonly leftButtonConfig: HeaderButton = {
        analyticsIdentifier: 'nourish-product-back-btn',
        background: 'transparent',
    };

    public onBackClick(): void {
        this.store.dispatch(
            nourishProductHeaderBackButtonClicked({
                id: this.productId(),
            }),
        );
    }
}
