import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BASE_ENVIRONMENT_CONFIG, BaseEnvironmentConfig } from '@frontend/configuration';
import { httpOptions } from '@frontend/utility/constants';
import {
    internalPaths,
    InternalPostRescheduledStepDto,
    InternalRescheduledStepDto,
} from '@shared/internal-api-interface';
import { Observable } from 'rxjs';

@Injectable()
export class RescheduledStepService {
    private readonly http = inject(HttpClient);
    private readonly environment = inject<BaseEnvironmentConfig>(BASE_ENVIRONMENT_CONFIG);

    public getRescheduledSteps(): Observable<InternalRescheduledStepDto[]> {
        return this.http.get<InternalRescheduledStepDto[]>(
            `${this.environment.internalApiUrl}/api/${internalPaths.rescheduledStepPath}`,
            httpOptions,
        );
    }

    public createRescheduledSteps(
        createRescheduledStepDto: InternalPostRescheduledStepDto[],
    ): Observable<InternalRescheduledStepDto[]> {
        return this.http.post<InternalRescheduledStepDto[]>(
            `${this.environment.internalApiUrl}/api/${internalPaths.rescheduledStepPath}`,
            { rescheduledSteps: createRescheduledStepDto },
            httpOptions,
        );
    }

    public setRescheduledStepsAsInactive(stepId: string): Observable<InternalRescheduledStepDto[]> {
        return this.http.patch<InternalRescheduledStepDto[]>(
            `${this.environment.internalApiUrl}/api/${internalPaths.rescheduledStepPath}/${stepId}/set-as-inactive`,
            {},
            httpOptions,
        );
    }
}
