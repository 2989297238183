import { Component, inject } from '@angular/core';
import { routeTo } from '@frontend/data-access/router';
import { LoadingState } from '@frontend/data-access/shared-models';
import { householdFeature } from '@frontend/data-access/user/household';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { IonButton } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { showFoodCalculatorDisclaimerModal } from '../../food-calculator/data-access/store/food-calculator.actions';
import { foodCalculatorFeature } from '../../food-calculator/data-access/store/food-calculator.reducer';
import { nourishCalculateFoodButtonClicked } from '../store/nourish.actions';

@Component({
    selector: 'app-nourish-header',
    templateUrl: './nourish-header.component.html',
    styleUrl: './nourish-header.component.scss',
    imports: [IonButton, AnalyticsTrackClickDirective],
})
export class NourishHeaderComponent {
    private readonly store = inject(Store);

    public dogName = this.store.selectSignal(householdFeature.selectDogName);
    public foodPortion = this.store.selectSignal(foodCalculatorFeature.selectFoodPortionAmount);

    protected readonly loadingState = LoadingState;
    protected readonly Color = Color;

    public showDisclaimerModal(): void {
        this.store.dispatch(showFoodCalculatorDisclaimerModal());
    }

    public goToFoodCalculator(): void {
        this.store.dispatch(
            routeTo({
                dtos: ['main', 'nourish', 'food-portion-calculator'],
            }),
        );
    }

    public calculateFoodPortionClicked(): void {
        this.store.dispatch(nourishCalculateFoodButtonClicked());
    }
}
