import { SaIterableDogFields } from '../../dtos/sa-iterable-upsert-user.dto';

export class InternalSaIterableDogFieldsMock {
    private defaultValue: SaIterableDogFields = {
        breedId: 'breedId',
        gender: 'female',
        neuterStatus: 'yes',
        ageOfOnset: 2,
        knownTraumaticEvent: 'yes',
        separationRelatedProblemLength: '3',
    };

    constructor(overrides?: Partial<SaIterableDogFields>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): SaIterableDogFields {
        return this.defaultValue;
    }
}
