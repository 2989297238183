import { IsArray, IsString } from 'class-validator';
import { ConversationTypeID } from '../../models/conversation/conversation.model';
import { DomainPostConversationMessage } from './domain-post-conversation-message.dto';

export class DomainPostConversationDto {
    @IsString()
    channelId!: string;

    @IsString()
    userId!: string;

    @IsString()
    conversationTypeId!: ConversationTypeID;

    @IsArray()
    messages!: DomainPostConversationMessage[];
}
