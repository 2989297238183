import { createAction, props } from '@ngrx/store';
import { GeoLocation } from '../models/geo-location.model';

export const getGeoLocation = createAction('[GeoLocation] Get Geo Location');
export const getGeoLocationSuccess = createAction(
    '[GeoLocation] Get Geo Location Success',
    props<{ geoLocation: GeoLocation }>(),
);
export const getGeoLocationFailure = createAction('[GeoLocation] Get Geo Location Failure', props<{ error: Error }>());
export const getGeoLocationResolved = createAction('[GeoLocation] Get Geo Location Resolved');
