<!--swiper event exists but it is a web component so angular doesn't recognise event-->
<swiper-container
    #swiper
    uiSwiper
    data-test="swiper-container"
    [config]="config"
    (swiperslidechange)="onSlideChange($event)"
>
    @for (course of courses(); track course.id) {
        <swiper-slide>
            <app-course-slider-tile [course]="course" />
        </swiper-slide>
    }
</swiper-container>
