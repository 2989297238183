import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { IonButton, IonContent, IonRouterOutlet, NavController, Platform } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';
import { Subscription } from 'rxjs';
import { LessonRatingComponent } from '../../lesson-rating/lesson-rating.component';
import { selectSelectedLessonPagesVm } from '../lesson.page.selectors';

@Component({
    selector: 'app-lesson-rating-page',
    templateUrl: './lesson-rating.page.html',
    styleUrls: ['./lesson-rating.page.scss', '../lesson.scss'],
    imports: [AsyncPipe, IonButton, IonContent, AnalyticsTrackClickDirective, LessonRatingComponent],
})
export class LessonRatingPage {
    private readonly store = inject(Store);
    private readonly ionRouterOutlet = inject(IonRouterOutlet);
    private readonly navController = inject(NavController);
    private readonly platform = inject(Platform);
    private readonly route = inject(ActivatedRoute);

    public vm$ = this.store.select(selectSelectedLessonPagesVm);

    public currentRating = 0;
    public hasProvidedRating = false;

    public backButtonSub: Subscription = new Subscription();

    protected readonly Color = Color;

    public ionViewWillEnter(): void {
        this.ionRouterOutlet.swipeGesture = false;

        this.backButtonSub = this.platform.backButton.subscribeWithPriority(20, () => {
            // Do nothing
        });
    }

    public ionViewWillLeave(): void {
        this.backButtonSub.unsubscribe();
    }

    public nextPage(): void {
        void this.navController.navigateForward(['../completion'], {
            relativeTo: this.route,
            queryParams: {
                rating: this.currentRating,
                from: this.route.snapshot.queryParams['from'],
                fromPracticeStep: this.route.snapshot.queryParams['fromPracticeStep'],
            },
            animated: true,
        });
    }

    public updateRating(ratingUpdated: { ratedStep: boolean; currentRating: number }): void {
        this.hasProvidedRating = ratingUpdated.ratedStep;
        this.currentRating = ratingUpdated.currentRating;
    }
}
