<ion-header class="page-header ion-no-border">
    <ion-toolbar>
        <app-header
            title="Select a product"
            [leftButton]="{ analyticsIdentifier: 'food-product-selector-modal-back-btn', background: 'none' }"
            [rightButton]="false"
            (leftButtonPressed)="back()"
        />
    </ion-toolbar>
</ion-header>

<ui-search-bar
    (searchString)="searchItems($event)"
    [placeholder]="'Type product name'"
    [resultListTemplate]="resultListTemplate"
    [noResultTemplate]="noResults"
    [instructionTemplate]="instructions"
    [initialFocus]="true"
    [hasResults]="results.length > 0"
/>

<ng-template #resultListTemplate>
    <ion-content>
        <ion-list>
            @for (result of results; track result.id) {
                <ion-item (click)="selectProduct(result)" class="search-result" data-test="product-search-result">
                    <ion-label class="ion-text-wrap">
                        <h3>{{ result['brandName'] }}</h3>
                        <p class="ion-text-wrap">{{ result['name'] }}</p>
                    </ion-label>
                </ion-item>
            }
        </ion-list>
    </ion-content>
</ng-template>

<ng-template #instructions>
    <div class="center">
        <img src="assets/images/buddy/buddy-looks-longingly-at-steak.png" />
        <h2>Find your brand & product</h2>
    </div>
</ng-template>

<ng-template #noResults let-searchValue="searchValue">
    <div class="center">
        <img src="assets/images/oliver/oliver-looks-confused.png" />
        <h3>No results for "{{ searchValue }}"</h3>
        <p>We might not have added the product yet. Check your packaging for the spelling or try again later!</p>
    </div>
</ng-template>
