@if (vm().step) {
<ion-content [fullscreen]="true" [color]="Color.Charly">
    <div
        class="lesson-completion-page-content-container lesson-page-doodle page-padding-sides page-padding-bottom page-content-padding-top"
    >
        <section class="lesson-completion-page-feedback">
            <img class="lesson-completion-page-feedback__image" [src]="getAnimationPath()" />

            <h1 class="lesson-completion-page-feedback__title text-snowy">
                {{ vm().routeParams.rating > 3 ? 'Awesome!' : 'No worries!' }}
            </h1>

            @if (vm().routeParams.rating <= 3) {
            <div
                class="lesson-completion-page-feedback__description"
                data-test="lesson-completion-page-low-rating-text"
                [innerHTML]="
                            (vm().step?.content.lowRatingHtml ? vm().step?.content.lowRatingHtml : noWorriesText)
                                | textReplacement
                        "
            ></div>
            } @else {
            <div
                class="lesson-completion-page-feedback__description"
                data-test="lesson-completion-page-high-rating-text"
                [innerHTML]="
                            (vm().step?.content.highRatingHtml ? vm()?.step?.content.highRatingHtml : awesomeText)
                                | textReplacement
                        "
            ></div>
            }
        </section>

        @if (vm().shouldShowSelfSetReminderTile) {
        <section class="lesson-completion-page-reminder">
            <h2 class="lesson-completion-page-reminder__title">Get reminded to stay on track</h2>
            <ui-generic-card-slim
                class="lesson-completion-page-reminder__card app-generic-card-slim--responsive app-generic-card-slim--no-box-shadow"
                data-test="lesson-completion-training-reminder-card"
                [background]="Color.Luna"
                [imageUrl]="'assets/images/charly/charly-gets-notification.svg'"
                [title]="'Set training reminder'"
                [unlocked]="true"
                (click)="setTrainingReminder(vm().dogName)"
            />
        </section>
        }
    </div>

    <ion-footer class="lesson-completion-page-footer gradient-footer" slot="fixed">
        <ion-button
            appTrackClick
            identifier="step-page-complete"
            expand="block"
            data-test="lesson-completion-complete-btn"
            [color]="Color.Max"
            (click)="completeStep()"
        >
            Complete Lesson
        </ion-button>
        @if (vm().routeParams.rating > 3) {
        <ion-button
            [color]="Color.Snowy"
            appTrackClick
            identifier="step-feedback-share-btn"
            fill="clear"
            data-test="lesson-completion-share-btn"
            (click)="share()"
        >
            Share
            <i class="fa-light fa-share-nodes lesson-completion-page-footer__share-icon"></i>
        </ion-button>
        }
    </ion-footer>
</ion-content>
}
