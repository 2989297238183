import { Location } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { routeTo } from '@frontend/data-access/router';
import { AnalyticsTrackClickDirective } from '@frontend/feature/directives';
import { HeaderButton, HeaderComponent } from '@frontend/feature/header';
import { IonButton, IonContent, IonFooter, IonHeader, IonToolbar } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { Color } from '@shared/utils/typescript';

@Component({
    selector: 'app-reset-password-check-email',
    templateUrl: './reset-password-check-email.page.html',
    styleUrls: ['../authentication.scss', './reset-password-check-email.page.scss'],
    imports: [AnalyticsTrackClickDirective, HeaderComponent, IonButton, IonContent, IonFooter, IonHeader, IonToolbar],
})
export class ResetPasswordCheckEmailPage {
    private readonly store = inject(Store);
    private readonly location = inject(Location);

    protected readonly Color = Color;
    protected readonly leftButtonConfig: HeaderButton = {
        analyticsIdentifier: 'reset-password-check-email-back-btn',
        background: 'none',
    };

    public email = input.required<string>();

    public onNoEmailReceivedClick(): void {
        void this.location.back();
    }

    public onLoginClick(): void {
        void this.store.dispatch(routeTo({ dtos: ['auth', 'login'] }));
    }
}
